import React, { useEffect, useState } from "react";
import { App, Button, Checkbox, Divider, InputNumber, message, Modal, Space, Typography } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, HistoryOutlined, MoreOutlined } from "@ant-design/icons";
import { formatMetrics, getMetrics } from "../form/comp/metricsInput";
import { ResultTable, SimplePair } from ".";
import FileDisplayer from "../../components/input/fileDisplayer";
import { findFormText, findFormTexts, findSite } from "../../helper/attribute";
import icons from "../../static/icons.static";
import { findForm, fixedDataFields } from "../../static/formRoutes";
import RadioSelector from "../../components/select/radioselector";
import moment from "moment";
import { fixDecimals, generateRandomString } from "../../helper/wodash";
import { setResults } from "../../redux/features/appSlice";
import { useDispatch } from "react-redux";
import { deleteResult } from "../../firebase/data/delete";
import { timeFormat } from "../../helper/time";
import { createFormData } from "../../firebase/data/create";
import store from "../../redux/store";
import Loading from "../../components/loading";
import { catcher } from "../../firebase/util";
import { fetchResults } from "../../firebase/data/get";
import ResultHistory from "./comp/history";
import { LearnMore } from "../extras/learn/form_tuts";
import { copyFolder } from "../../firebase/storage/copy";

const LIAdditionStyle = ({ items, reverse, total }) => {
  if (!Array.isArray(items)) return "Error displaying items";
  if (reverse) items.reverse();

  // Calculate the total sum of all values
  const totalSum = items.reduce((sum, item) => sum + item.value, 0);

  return (
    <div
      style={{
        padding: "5px",
        margin: "5px",
      }}
    >
      <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
        {items.map((item, index) => (
          <>
            <li
              style={{
                padding: "10px 0",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "14px",
              }}
            >
              <Typography.Text style={{ fontWeight: 500 }}>{item?.title}</Typography.Text>
              <Typography.Text style={{ fontWeight: 400 }}>{item?.value}</Typography.Text>
            </li>
            {index !== items.length - 1 && <Divider style={{ margin: "8px 0" }} />}
          </>
        ))}
        <Divider style={{ margin: "8px 0" }} />
        <li
          style={{
            padding: "10px 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography.Text strong>Total</Typography.Text>
          <Typography.Text strong>{total}</Typography.Text>
        </li>
      </ul>
    </div>
  );
};

const getSpreadMonths = (
  date,
  months,
  format,
  reverse
  //if true it will add months
) => {
  return Array.from({ length: months }, (_, index) => {
    const resultDate = reverse ? moment(date, "DD/MM/YYYY").add(index, "months") : moment(date, "DD/MM/YYYY").subtract(index, "months");
    const result = resultDate.format(format);
    return result;
  });
};

export const handleSpreadResult = async (data, result) => {
  if (result.spreadtag) return;
  const mul = 1 / data.spreadMonths;

  if (isNaN(mul)) {
    message.error("Not a number");
    return data;
  }
  let dataFields = findForm(data.name)?.dataFields(data, true); // true for spread tag option
  dataFields = [...dataFields, ...fixedDataFields];

  const metrics = getMetrics(data);
  if (data.spread_metrics && Array.isArray(metrics)) {
    dataFields = [...dataFields, ...metrics];
  }

  dataFields.forEach((key) => {
    data[key] = data[key] * mul;
  });

  // create individual spread results based on year
  const dates = getSpreadMonths(data.date, data.spreadMonths, timeFormat, data.spreadPos === "previous" ? false : true);
  const results = [];
  for (let i = 0; i < dates.length; i++) {
    const resultx = await createFormData(
      {
        ...findForm(data.name).pack({ ...data, date: dates[i] }),
        date: dates[i],
      },
      "result_spread",
      false
    );
    if (data.file) {
      // create copy of folder
      await copyFolder("formdata/" + data.id, "formdata/" + resultx.id);
    }
    results.push(resultx);
  }
  await deleteResult(result.id);
  await fetchResults(result.name);
};

const SpreadModal = ({ result, visible, setVisible, spreadData }) => {
  const [spreadMonths, setSpreadMonths] = useState(2); // Number of months to spread data
  const [spreadPos, setSpreadPos] = useState("previous"); // previous or later months
  const dataFields = findForm(result.name)?.dataFields(result, true);
  const [spreadMetrics, setSpreadMetrics] = useState(true);

  const metrics = getMetrics(result);

  const handleUpdate = () => {
    Modal.confirm({
      title: "Confirm Spread?",
      content: "This result will be spread into multiple months. Spread results will be treated as individual results.",
      onOk: () => {
        spreadData({ ...result, spreadMonths, spreadPos, spread_metrics: spreadMetrics, spreadtag: spreadPos + generateRandomString(9) }, result);
      },
    });
  };
  return (
    <div>
      <Modal width="50%" title={"Spread data"} open={visible} onCancel={() => setVisible()} onOk={handleUpdate} okText="Spread result">
        <LearnMore path={"Spread"} />
        <Typography.Text strong>Spread Results Over Multiple Months</Typography.Text>

        <div style={{ margin: "1rem 0rem" }}>
          <span style={{ fontSize: "larger", fontWeight: "bold" }}>Q. </span>
          Would you like to spread data across previous or upcoming months?
          <RadioSelector options={["previous", "later"]} value={spreadPos} setValue={setSpreadPos} />
        </div>

        <div style={{ margin: "1rem 0rem" }}>
          <span style={{ fontSize: "larger", fontWeight: "bold" }}>Q. </span>
          How many months would you like to spread the data over?
          <br />
          <Space>
            {/* <Button>
              <ArrowLeftOutlined onClick={() => setSpreadMonths((val) => (val < 12 ? val + 1 : val))} />
            </Button> */}
            <InputNumber style={{ width: "100px" }} min={2} max={12} defaultValue={spreadMonths} onChange={(value) => setSpreadMonths(value)} />
            {/* <Button>
              <ArrowRightOutlined onClick={() => setSpreadMonths((val) => (val > 2 ? val - 1 : val))} />
            </Button> */}
          </Space>
        </div>
        <Typography.Text strong>Spread result inputs will be divided like this</Typography.Text>
        <LIAdditionStyle
          items={getSpreadMonths(result.date, spreadMonths, "MMM YY", spreadPos === "previous" ? false : true)?.map((month) => ({
            title: month,
            value: (
              <ul>
                {dataFields.map((field, index) => {
                  const value = fixDecimals(result[field] / spreadMonths);
                  return (
                    <li key={index}>
                      {findFormText(result.name, field)}: {isNaN(value) ? "Not present" : value}
                    </li>
                  );
                })}
              </ul>
            ),
          }))}
          reverse={spreadPos === "previous" ? true : false}
          total={dataFields.map((field, index) => {
            const totalValue = fixDecimals(result[field]);
            return (
              <li key={index}>
                {findFormText(result.name, field)}: {isNaN(totalValue) ? "Not present" : totalValue}
              </li>
            );
          })}
        />
        <div>
          {metrics && metrics.length > 0 ? (
            <Checkbox
              checked={spreadMetrics}
              onChange={(val) => {
                setSpreadMetrics(val.target.checked);
              }}
            >
              Spread Metrics
            </Checkbox>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default function More({ openSpread, result, form, icon = <MoreOutlined />, handleFileDownload, setSpreadFilter }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [spreadModal, setSpreadModal] = useState(false);
  const [isSpreading, setIsSpreading] = useState(false);
  const { modal } = App.useApp();
  const dispatch = useDispatch();

  const metrics = formatMetrics(result);

  useEffect(() => {
    if (openSpread && isModalVisible) setSpreadModal(true);
  }, [openSpread, isModalVisible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //----
  const handleSpread = () => {
    // if (result.file) return message.error("Cannot spread the result because it contains a file. Please remove the file and add individually later on.");
    setSpreadModal(true);
  };

  const viewRelatedSpreadResults = () => {
    setSpreadFilter(result.spreadtag);
    setIsModalVisible(false);
  };

  const startSpread = async (data, result) => {
    catcher(
      async () => {
        setSpreadModal(false);

        await handleSpreadResult(data, result);
        //quick fix applied
        // dispatch(setResults([...results, ...store.getState().app.results.filter((r) => r.id !== result.id)]));
        setSpreadModal(false);
        setIsModalVisible(false);
        message.success("Result spread successfully");
      },
      { setLoading: setIsSpreading }
    );
  };
  const content = (
    <>
      <Typography.Title level={4}>History</Typography.Title>
      <Button
        onClick={() => {
          modal.info({ width: "90%", title: "History of edits", content: <ResultHistory id={result.id} /> });
        }}
      >
        <HistoryOutlined /> {"  "}View history of changes.
      </Button>
      {result.name !== "spending" ? (
        <>
          <Typography.Title level={4}>Spread Result</Typography.Title>
          {!result.spreadtag ? (
            <Button onClick={handleSpread} type="primary" style={{ padding: 5, cursor: "pointer", border: "1px solid darkgreen", borderRadius: "4px" }}>
              {" "}
              Start Spread
            </Button>
          ) : (
            <>
              This result is already spread. (Spread ID: {result.spreadtag})
              {setSpreadFilter && (
                <Button type="link" onClick={viewRelatedSpreadResults}>
                  View related spread results
                </Button>
              )}
            </>
          )}
        </>
      ) : (
        ""
      )}
      <Divider />
      <Typography.Title level={4}>Other Details</Typography.Title>
      {findFormTexts(form.name, "tables", true, "e").map((field) => (
        <SimplePair key={field.name} title={field.name} value={result[field.name]} />
      ))}
      <SimplePair title={"Comment"} value={result.comment} />
      <SimplePair title={"Data Link"} value={result.dataLink} options={{ link: true }} />
      <SimplePair
        title={"Cost"}
        value={result.cost}
        options={{
          rightElem: findSite(result.siteId)?.curr ?? "GBP",
        }}
      />
      <SimplePair title={"Tag used"} value={result.resulttag} />
      <FileDisplayer
        files={result.file}
        onClickDownload={(filename) => {
          handleFileDownload(result, filename);
        }}
      />
      {metrics.length > 0 && (
        <Typography.Title style={{ textDecoration: "underline" }} level={5}>
          Additional Metrics
        </Typography.Title>
      )}
      {metrics?.map(({ title, value }) => (
        <SimplePair key={title} title={title} value={value} />
      ))}
      {/*----------Modals------- */}
      {<SpreadModal result={result} visible={spreadModal} setVisible={setSpreadModal} spreadData={startSpread} />}
    </>
  );
  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={showModal}>
        {icon}
      </span>
      <Modal title="Complete Result details" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} width="50%">
        {isSpreading ? <Loading height="10vh" title="spreading data, please wait..." /> : content}
      </Modal>
    </>
  );
}
