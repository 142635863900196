import { RollbackOutlined, InfoCircleOutlined, PlusCircleTwoTone, SettingOutlined, RightOutlined, EditOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Drawer, InputNumber, Tag, Tooltip, Tabs, Typography, message, Space, Popconfirm, Modal, Checkbox } from "antd";
import commaNumber from "comma-number";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { T1 } from "../../appconfig/texts";
import TextInput from "../../components/input/TextInput";
import NumberInput from "../../components/input/number";
import SelectSearch from "../../components/select/SearchSelect";
import SimpleSelect from "../../components/select/simpleselect";
import formatEmissions from "../../components/text/resultEmissions";
import FormContext from "../../contexts/FormContext";
import { updateTestResult } from "../../firebase/testresult/create";
import electricityData from "../../assets/electricityData.json";

import { catcher } from "../../firebase/util";
import { designations, findCompany, findFormInstructions, findFormText, findFormTexts, findSite, getCurrentUserDesignation } from "../../helper/attribute";
import { fixDecimals, generateRandomString } from "../../helper/wodash";
import { selectTheme } from "../../redux/features/appSlice";
import vars from "../../static/attributes.static";
import { findForm } from "../../static/formRoutes";
import { getGHGTitle, getGHGs } from "../../static/ghg.static";
import "../../styles/reviewpage.css";
import { scope3overwrite } from "../../helper/results";
import RadioSelector from "../../components/select/radioselector";
import FileDisplayer from "../../components/input/fileDisplayer";
import icons from "../../static/icons.static";
import { getMetrics } from "./comp/metricsInput";
import useSize from "../../hooks/useSize";
import { LearnMore } from "../extras/learn/form_tuts";
import { getCurrentPlan, planValue } from "../../helper/plans";

const notToDisplayKeys = ["name", "siteId", "file", "comment", "id"];
export const decimalKeys = [
  "wtt",
  "wtt_tnd",
  "kwh",
  "tnd",
  "marketbased_wtt_tnd",
  "marketbased_wtt",
  "marketbased_tnd",
  "marketbased_result",
  "result",
  "waste_result",
  "supply_result",
  "amount",
  "distance",
  "wtt",
  "result",
  "kwh",
  "supplier_factors",
];

const TAG_COLOR = "firebrick";

const GHGDrawer = ({ data, setData, systemGeneratedData }) => {
  // Local state to manage temporary changes
  const [localData, setLocalData] = useState(data);

  // Update local data on external data changes
  useEffect(() => {
    setLocalData(data);
  }, [data]);

  // Handle the update button click
  const handleUpdate = () => {
    if (!localData.newghgtag) return message.error("Nothing to update.");
    message.success("Updated");
    setData(localData);
  };

  return (
    <div>
      <p>You can overwrite default GHG category by selecting the one from below.</p>
      <SimpleSelect
        listHeight={400}
        dropdownStyle={{ maxWidth: "200px" }}
        minWidthElem={"600px"}
        bgless
        borderless
        title={"GHG Category"}
        value={localData.ghg}
        options={getGHGs()
          .filter((ghg) => ghg.code !== systemGeneratedData.ghg)
          .map((x) => ({
            value: x.code,
            label: x.title,
          }))}
        setValue={(ghg) =>
          setLocalData((prevData) => ({
            ...prevData,
            [vars.result["Overwritten GHG"]]: systemGeneratedData.ghg,
            newghgtag: ghg,
          }))
        }
      />
      <Button disabled={!localData.newghgtag} onClick={handleUpdate} type="primary">
        Update
      </Button>
    </div>
  );
};

const ElectricityFactor = ({ data, setData, systemGeneratedData }) => {
  // Local state to manage temporary changes
  const [localData, setLocalData] = useState(data);

  // Update local data on external data changes
  useEffect(() => {
    setLocalData(data);
  }, [data]);

  // Handle the update button click
  const handleUpdate = () => {
    if (!localData.newsupplierfactortag) return message.error("Nothing to update.");
    message.success("Updated");
    setData(localData);
  };

  console.log(localData);
  return (
    <div>
      <p>
        Select a supplier factor from a different country. <br />
        <b>Note: </b>The current supplier factors will be overwritten with the selected country supplier factors.
      </p>
      <SelectSearch
        listHeight={400}
        dropdownStyle={{ maxWidth: "200px" }}
        minWidthElem={"600px"}
        bgless
        borderless
        title={"Country"}
        value={localData.newsupplierfactor_country}
        options={electricityData.map((e) => e?.Entity)}
        setValue={(country) =>
          setLocalData((prevData) => ({
            ...prevData,
            newsupplierfactor_value: electricityData.find((c) => c?.Entity === country)?.Entity,
            newsupplierfactor_country: country,
            newsupplierfactortag: true,
          }))
        }
      />
      <Button disabled={!localData.newsupplierfactortag} onClick={handleUpdate} type="primary">
        Update
      </Button>
    </div>
  );
};

export const ScaleDrawer = ({ data = {}, setData = () => {}, systemGeneratedData, viewOnly }) => {
  const [formData, setFormData] = useState({
    scale_base: data.scale_base || null,
    scale_base_other_value: data.scale_base_other_value || null,
    scale_details: data.scale_details || null,
    scale_numBase: data.scale_numBase || 1,
    scale_scaleBy: data.scale_scaleBy || 1,
    scaletag: data.scaletag !== undefined ? data.scaletag : true,
    scale_metrics: true,
  });
  const dataFields = findForm(systemGeneratedData.name)?.dataFields(systemGeneratedData);
  const mainDataField = dataFields[0];

  const errorMessages = {
    incompleteForm: "Please fill all fields.",
  };

  const titles = {
    scaleBase: "Scaling Unit",
    details: "Details",
    initialQuantity: "Initial Quantity",
    scaleQuantity: "Scale Quantity",
  };

  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onUpdate = () => {
    if (!formData.scale_base || formData.scale_numBase === 0 || formData.scale_scaleBy === 0) {
      message.error({ content: errorMessages.incompleteForm });
      return;
    }
    message.success("Result updated");
    setData({ ...data, ...formData });
  };

  const emissionsPerBase = useMemo(() => {
    if (formData.scale_base && formData.scale_numBase) {
      return fixDecimals(systemGeneratedData[mainDataField] / formData.scale_numBase);
    }
    return null;
  }, [formData.scale_base, formData.scale_numBase, systemGeneratedData[mainDataField]]);

  const totalEmissions = useMemo(() => {
    if (emissionsPerBase && formData.scale_scaleBy) {
      return fixDecimals(emissionsPerBase * formData.scale_scaleBy);
    }
    return null;
  }, [emissionsPerBase, formData.scale_scaleBy]);

  // if (systemGeneratedData.name === "product") return <Typography.Title level={4}>⚠️Scaling is not available for this form.</Typography.Title>;

  const metrics = getMetrics(systemGeneratedData);
  console.log(metrics, "**METRICS");
  return (
    <div>
      <LearnMore path={"Scale"} />
      <br />
      <FormContext.Provider
        value={{
          maxWidthLabel: "100px",
          minWidthElem: "400px",
          bgless: true,
          borderless: true,
          displayOnly: viewOnly ? true : false,
        }}
      >
        {viewOnly ? <h3></h3> : <Typography.Text level={3}>Scale your data according to your needs. Fill in the scale details below.</Typography.Text>}
        <SelectSearch
          title={titles.scaleBase}
          options={["Monetary Value", "Floor Area", "Employee Number", "Survey Response", "Other"]}
          setValue={(value) => handleChange("scale_base", value)}
          value={formData.scale_base}
        />
        {formData.scale_base === "Other" && (
          <TextInput
            title="Please specify scale base"
            value={formData.scale_base_other_value}
            setValue={(value) => {
              handleChange("scale_base_other_value", value);
            }}
          />
        )}

        <TextInput
          type="textarea"
          title={
            <>
              {titles.details}
              <br />
              <span style={{ fontWeight: "400", fontSize: "smaller" }}>e.g. Employee survey responses scaled to total employees</span>
            </>
          }
          setValue={(e) => handleChange("scale_details", e)}
          value={formData.scale_details}
        />

        <NumberInput
          title={
            <>
              {titles.initialQuantity}
              <br />
              <span style={{ fontWeight: "400", fontSize: "smaller" }}>Representative of data collected e.g. Number of survey responses</span>
            </>
          }
          setValue={(value) => handleChange("scale_numBase", value)}
          value={formData.scale_numBase}
        />
        {emissionsPerBase !== null && (
          <TextInput
            displayOnly={true}
            title={`${findFormText(systemGeneratedData.name, mainDataField)} per Scaling Unit (${formData.scale_base === "Other" ? formData.scale_base_other_value : formData.scale_base})`}
            value={emissionsPerBase}
          />
        )}
        <NumberInput
          title={
            <>
              {titles.scaleQuantity}
              <br />
              <span style={{ fontWeight: "400", fontSize: "smaller" }}>e.g. Total number of employees</span>
            </>
          }
          setValue={(value) => handleChange("scale_scaleBy", value)}
          value={formData.scale_scaleBy}
        />
        {metrics && metrics.length > 0 ? (
          <>
            <Checkbox
              checked={formData.scale_metrics}
              onChange={(e) => {
                setFormData({ ...formData, scale_metrics: e.target.checked });
              }}
            >
              Scale metrics
            </Checkbox>
          </>
        ) : (
          ""
        )}
        {totalEmissions !== null && (
          <div>
            <Divider />
            <TextInput displayOnly={true} title={"Scaled " + findFormText(systemGeneratedData.name, mainDataField)} value={totalEmissions} />
          </div>
        )}
      </FormContext.Provider>
      {totalEmissions && !viewOnly && (
        <Popconfirm onConfirm={onUpdate} title="Do you confirm to update results?">
          <Button type="primary">
            Update <RightOutlined />
          </Button>
        </Popconfirm>
      )}
    </div>
  );
};

const { TabPane } = Tabs;

const SettingsDrawer = ({ open, setOpen, data, setData, systemGeneratedData, getSpreadMonths, editId, activeTabDefault, dataToDisplay }) => {
  const [activeTab, setActiveTab] = useState(activeTabDefault === true ? "ghg" : activeTabDefault);
  console.log(activeTabDefault);

  const handleUpdate = () => {
    message.success("Updated");
    // Implement the logic to handle updates based on the selected tab here
    setOpen(false);
  };

  return (
    <Drawer destroyOnClose={true} title="Data Management" placement="right" onClose={() => setOpen(false)} open={open} width={800}>
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        {!["additional", "product"].includes(systemGeneratedData.name) && (
          <TabPane tab="GHG Category" key="ghg">
            <GHGDrawer data={data} setData={setData} systemGeneratedData={systemGeneratedData} />
          </TabPane>
        )}
        {["electricity"].includes(systemGeneratedData.name) && !editId && (
          <TabPane tab="Electricity factor" key="electricity">
            <ElectricityFactor data={data} setData={setData} systemGeneratedData={systemGeneratedData} />
          </TabPane>
        )}
        <TabPane tab="Scale Data" key="scale">
          <ScaleDrawer data={data} setData={setData} systemGeneratedData={systemGeneratedData} />
        </TabPane>
        {/* {!editId && (
          <TabPane tab="Spread Data" key="spread">
            <SpreadDrawer data={data} setData={setData} systemGeneratedData={systemGeneratedData} getSpreadMonths={getSpreadMonths} />
          </TabPane>
        )} */}
      </Tabs>
    </Drawer>
  );
};

export function Pair({ option, value, description, name, widthLabel = "200px" }) {
  if (value >= 0 && decimalKeys.includes(name)) value = formatEmissions(value);
  const theme = useSelector(selectTheme);
  const title = option;
  const desc = description;
  const { isMobile } = useSize();

  if (isMobile) {
    widthLabel = "100px";
  }

  function getValue() {
    if (decimalKeys.includes(name) || typeof Number(value) === "number") return commaNumber(fixDecimals(value, 2, false, 2));
    else return value;
  }
  if (notToDisplayKeys.includes(option)) return null;

  if (getCurrentPlan() === "ma" && name === "date") return null;
  if (value !== undefined && value !== null)
    return (
      <div style={{ padding: "4px 4px", width: "fit-content", fontSize: "larger" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <div
            style={{
              fontWeight: 600,
              minWidth: widthLabel,
              maxWidth: widthLabel,
              color: theme.isDarkMode ? "orange" : "darkblue",
            }}
          >
            {title + ": "}
          </div>
          <Typography.Text>
            <span>{getValue()}</span>
          </Typography.Text>
        </div>
        {desc && desc !== title && desc !== "" && (
          <Typography.Text
            style={{
              fontSize: "10px",
              display: "flex",
              gap: "3px",
            }}
          >
            <InfoCircleOutlined />
            <div>{desc}</div>
          </Typography.Text>
        )}
      </div>
    );
}

export default function ReviewPage({ data, optionsData, setOptionsData, getData, getSpreadMonths, editId }) {
  const site = findSite(data.siteId);
  const company = findCompany(site?.companyId);
  const form = findForm(data.name);
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState({ settings: false });
  const { isMobile } = useSize();

  const handleAddToTest = () => {
    catcher(
      async () => {
        await updateTestResult(data);
      },
      { setLoading }
    );
  };
  // RULES
  let formTexts = findFormTexts(form.name, "reviewpages", true);
  if (planValue("onlyDisplayInputsInCombinedResults")) {
    formTexts = findFormTexts(form.name, "forms");
  }
  if (form.developmentMode) {
    formTexts = Object.keys(data).map((x) => ({ name: x, title: x }));
  }
  const instructions = findFormInstructions(form.name, "reviewpages");
  const addToTestData = getCurrentUserDesignation() === designations.super_admin && (
    <Tooltip title="Does this data good look? Add this to automated test.">
      <Button loading={loading} type="ghost" onClick={handleAddToTest}>
        <PlusCircleTwoTone />{" "}
      </Button>
    </Tooltip>
  );

  const removeChanges = (name) => {
    const keys = Object.keys(optionsData);
    delete optionsData[name + "tag"];
    keys.forEach((key) => {
      if (key.includes(name + "_")) {
        delete optionsData[key];
      }
    });
    if (name === "spread") {
      delete optionsData["spreadMonths"];
      delete optionsData["spreadPos"];
    }
    if (name === "newghg") {
      delete optionsData["owg"];
    }
    if (name === "newsupplierfactor") {
      delete optionsData["newsupplierfactortag"];
    }
    console.log("debug 29", optionsData);
    setOptionsData({ ...optionsData });
  };

  const dataToDisplay = getData(data);
  const inputMetrics = company?.inputMetrics;
  console.log("data on the review page", data, "optionsdata", optionsData);
  return (
    <div className="reviewpage">
      <div className="alert-boxes" style={{ display: "flex", flexDirection: "column" }}>
        {optionsData.scaletag || optionsData.newghgtag || optionsData.newsupplierfactortag || (!editId && optionsData.spreadtag) || (editId && optionsData.spreadtag) ? (
          <Alert
            type="info"
            message={
              <Typography.Title style={{ lineHeight: "0px" }} level={4}>
                More info
              </Typography.Title>
            }
            description={
              <ul>
                {optionsData.newsupplierfactortag && (
                  <li>
                    New supplier factor country selected.{" "}
                    <Tag color={TAG_COLOR} onClick={() => (editId ? message.info("Cannot undo supplier factor change. Please delete and recreate this record.") : removeChanges("newsupplierfactor"))}>
                      Undo <RollbackOutlined />
                    </Tag>
                  </li>
                )}
                {optionsData.scaletag && (
                  <li>
                    Data has been scaled.{" "}
                    <Tag color={"darkblue"} onClick={() => setModals({ settings: "scale" })}>
                      Edit <EditOutlined />
                    </Tag>
                    <Tag color={TAG_COLOR} onClick={() => removeChanges("scale")}>
                      Undo <RollbackOutlined />
                    </Tag>
                  </li>
                )}
                {optionsData.newghgtag && (
                  <li>
                    GHG has been overwritten.{" "}
                    <Tag color={"darkblue"} onClick={() => setModals({ settings: "ghg" })}>
                      Edit <EditOutlined />
                    </Tag>
                    <Tag color={TAG_COLOR} onClick={() => removeChanges("newghg")}>
                      Undo <RollbackOutlined />
                    </Tag>
                  </li>
                )}
                {!editId && optionsData.spreadtag && (
                  <li>
                    <Tooltip title={getSpreadMonths(data.date, optionsData.spreadMonths).join(", ")}>
                      Data will be spread over <a style={{ color: "royalblue", textDecoration: "underline" }}>{optionsData.spreadMonths} months. </a>
                    </Tooltip>
                    <Tag color={"darkblue"} onClick={() => setModals({ settings: true })}>
                      Edit <EditOutlined />
                    </Tag>
                    <Tag color={TAG_COLOR} onClick={() => removeChanges("spread")}>
                      Undo <RollbackOutlined />
                    </Tag>
                  </li>
                )}
                {editId && optionsData.spreadtag && <li>This data was set to spread across {optionsData.spreadMonths} months. Editing will only change this month's result.</li>}
              </ul>
            }
          />
        ) : null}
      </div>
      <div className="topbar" style={{ display: isMobile ? "none" : "" }}>
        <div>
          <Typography.Title level={4} style={{ display: "flex", gap: 3 }}>
            <T1 pos="form.review.title" /> for {form.title}
            {addToTestData}
          </Typography.Title>
        </div>
        <Space style={{ display: data.name === "spending" ? "none" : "flex" }}>
          <Typography.Title level={4} style={{ transform: "translateY(-7px)" }}>
            Options
          </Typography.Title>
          <Button
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              cursor: "pointer",
              fontWeight: "500",
            }}
            onClick={() => setModals({ ...modals, settings: true })}
          >
            <SettingOutlined style={{ fontWeight: "bold" }} />
            Settings
          </Button>
          <RightOutlined />
          {!["additional", "product"].includes(data.name) && (
            <Button
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                cursor: "pointer",
                fontWeight: "500",
              }}
              onClick={() => setModals({ ...modals, settings: "ghg" })}
            >
              {icons.ghgTag} GHG Settings
            </Button>
          )}
          {["electricity"].includes(data.name) && !editId && (
            <Button
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                cursor: "pointer",
                fontWeight: "500",
              }}
              onClick={() => setModals({ ...modals, settings: "electricity" })}
            >
              {icons.supplier_factor} Electricity Factor
            </Button>
          )}
          {![].includes(data.name) && (
            <Button
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                cursor: "pointer",
                fontWeight: "500",
              }}
              onClick={() => setModals({ ...modals, settings: "scale" })}
            >
              {icons.scaleTag} Scale Data
            </Button>
          )}
          {/* {!editId && (
            <Button
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                cursor: "pointer",
                fontWeight: "500",
              }}
              onClick={() => setModals({ ...modals, settings: "spread" })}
            >
              {icons.spreadTag} Spread Data
            </Button>
          )} */}
        </Space>
      </div>
      <div style={{ borderLeft: "2px solid grey", paddingLeft: "10px" }}>
        <pre>{instructions}</pre>
      </div>
      <div
        style={{
          padding: 10,
        }}
      >
        <Pair option={"Site"} value={`${findSite(dataToDisplay.siteId).title} ( ${findCompany(findSite(dataToDisplay.siteId).companyId).title} )`} />
        <Divider />
        {formTexts.map((textObj, i) => (
          <Pair
            key={i + "pair"}
            name={textObj.name}
            option={textObj.title}
            value={textObj.name.includes("ghg") ? getGHGTitle(scope3overwrite([dataToDisplay])[0][textObj.name]) : dataToDisplay[textObj.name]}
            formname={dataToDisplay.name}
            description={textObj.desc}
          />
        ))}
        <Divider />
        {dataToDisplay.cost ? <Pair option={"Cost"} value={dataToDisplay.cost + " " + (site?.curr ?? "GBP")} /> : ""}
        {inputMetrics ? (
          <>
            {inputMetrics.map((metric, index) => (
              <Pair option={metric.title} value={dataToDisplay["m_" + Number(index + 1)]} />
            ))}
          </>
        ) : (
          ""
        )}
        <Pair option={"Comment"} value={dataToDisplay.comment} />
        <Pair option={"Data Link"} value={dataToDisplay.dataLink} />
        <Pair option={"Tag"} value={dataToDisplay.resulttag} />
        {dataToDisplay.file ? <FileDisplayer files={dataToDisplay.file} /> : ""}
      </div>
      {/*Config Modal*/}
      <SettingsDrawer
        key={modals.settings}
        open={modals.settings}
        setOpen={(settings) => setModals({ ...modals, settings })}
        data={optionsData}
        setData={setOptionsData}
        systemGeneratedData={data}
        dataToDisplay={dataToDisplay}
        getSpreadMonths={getSpreadMonths}
        editId={editId}
        activeTabDefault={modals.settings}
      />
    </div>
  );
}
