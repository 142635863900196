import React, { useEffect, useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Select1 from "../../../../components/select/select1";
import { filterObjectsByObj, fixDecimals } from "../../../../helper/wodash";
import {
  combineAndSumProperties,
  combineData,
  GraphWrap,
  graph_colors,
} from "./config";
import { Row, Col, Empty, Button } from "antd";
import { routePath } from "../../../../static/routes.static";
import { useNavigate } from "react-router-dom";
const EmissionsScopePieChart = ({ data, filters, scopePieChartElem }) => {
  const [outerRadius, setOuterRadius] = useState(120);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const newOuterRadius = window.innerWidth <= 600 ? 100 : 120;
      setOuterRadius(newOuterRadius);
    };

    // Initial call
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let [type, setType] = useState("Scope");
  const dataobj = combineAndSumProperties(filterObjectsByObj(data, filters));

  if (!dataobj) return "Data not calculated!";
  data = [];
  for (let key of Object.keys(dataobj)) {
    let keys = ["1", "2", "3"];
    if (type === "Emissions Source") {
      keys = Object.keys(dataobj).filter((key) => !keys.includes(key));
    }
    if ([...keys].includes(key)) data.push({ key, result: dataobj[key] });
  }

  const combinedData = type === "Scope" ? data : combineData(data);

  // Calculate total value
  const totalValue = combinedData.reduce((sum, entry) => sum + entry.result, 0);

  // Calculate percentages and add to data
  let dataWithPercentages = combinedData.map((entry) => ({
    ...entry,
    result: fixDecimals(entry.result),
    percentage: (entry.result / totalValue) * 100,
  }));

  //FORMATTING
  if (type === "Scope") {
    dataWithPercentages = dataWithPercentages.map((row) => ({
      ...row,
      key: "Scope " + row.key,
    }));
  }
  const pie_chart = (
    <PieChart>
      <Pie
        data={dataWithPercentages}
        animationDuration={300}
        dataKey="result"
        nameKey="key"
        cx="50%"
        cy="50%"
        outerRadius={outerRadius}
        fill="#8884d8"
        label={({
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          percentage,
          index,
        }) => {
          const RADIAN = Math.PI / 180;
          const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          const y = cy + radius * Math.sin(-midAngle * RADIAN);
          return (
            <text
              x={x}
              y={y}
              fill="#ffffff"
              textAnchor={x > cx ? "start" : "end"}
              dominantBaseline="central"
            >
              {`${percentage.toFixed(2)}%`}
            </text>
          );
        }}
        labelLine={false}
      >
        {dataWithPercentages.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={graph_colors[index % graph_colors.length]}
          />
        ))}
      </Pie>
      <Legend />
      <Tooltip
        formatter={(value, name, props) => [
          `${value} (${props.payload.percentage.toFixed(2)}%)`,
          name,
        ]}
      />
    </PieChart>
  );
  return (
    <>
      {scopePieChartElem}
      <Select1
        title="Type"
        value={type}
        options={["Scope", "Emissions Source"]}
        setValue={setType}
      ></Select1>

      <GraphWrap>
        {/* <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <ResponsiveContainer
              width="100%"
              height={type === "Scope" ? 300 : 450}
            > */}
        {data?.length ? (
          pie_chart
        ) : (
          <Empty
            style={{ marginTop: 10, transform: "translateY(30px)" }}
            description={
              <>
                No data found!
                <br /> Please input some data in the{" "}
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => navigate(routePath.Form(""))}
                >
                  input tabs
                </Button>{" "}
                to see the charts.
              </>
            }
          />
        )}
        {/* </ResponsiveContainer>
          </Col>
        </Row> */}
      </GraphWrap>
    </>
  );
};

export default EmissionsScopePieChart;
