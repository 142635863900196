import { message } from "antd";
import translations from "../assets/translations.google.json";

export default translations;

export const findLanguageByCode = (code) => {
  return translations.find((t) => t.code === code).language || code;
};

export const findCodeByLanguage = (language) => {
  return translations.find((t) => t.language === language).code || language;
};

export const ChangeLanguage = async (selectedOption) => {
  try {
    const currentURL = window.location.href;
    const siteName = currentURL
      .replace(/^(https?:\/\/)|\/.*$/g, "")
      .replace(/\./g, "-");

    const url = `https://${siteName}.translate.goog/dashboard?_x_tr_sl=auto&_x_tr_tl=${findCodeByLanguage(
      selectedOption
    )}&_x_tr_hl=en&_x_tr_pto=wapp&_x_tr_hist=true`;
    window.location.href = url;
  } catch (err) {
    message.error(err.message);
  }
};
