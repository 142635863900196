import { collection, getCountFromServer, getDocs, query, where } from "firebase/firestore";
import { getUserSiteRole, isSuperAdmin } from "../../helper/attribute";
import { selectCompanies, setSites, setWarning } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsWithIds } from "../util";

const batchSize = 29; // Specify the batch size here
export async function getSitesCount() {
  const coll = collection(db, firestoreStatic.sites);
  const snapshot = await getCountFromServer(coll);
  return snapshot.data().count;
}

export async function fetchSites() {
  store.dispatch(setSites(undefined));
  let companies = store.getState().app.companies;
  const collectionRef = collection(db, firestoreStatic.sites);

  let sites = [];

  if (false) {
    // Fetch all sites without batching
    const q = query(collectionRef);
    const docs = await getDocs(q);
    const allSites = docsWithIds(docs).filter((site) => getUserSiteRole(site));
    return store.dispatch(setSites(allSites));
  }

  // Iterate through companies in batches
  for (let i = 0; i < companies.length; i += batchSize) {
    const batchCompanies = companies.slice(i, i + batchSize);
    // console.log(batchCompanies.map((c) => c.id));

    // Construct a Firestore query to fetch sites for the batch of companies
    const q = query(
      collectionRef,
      where(
        "companyId",
        "in",
        batchCompanies.map((c) => c.id)
      )
    );

    const docs = await getDocs(q);
    const batchSites = docsWithIds(docs).filter((site) => getUserSiteRole(site));

    sites.push(...batchSites);
  }
  // // things on sites
  // sites = sites.map((site) => {
  //   return { tags: [], ...site };
  // });
  store.dispatch(setSites(sites));

  if (sites.length !== sites.length) {
    // The user is not seeing all sites, so set a warning.
    store.dispatch(setWarning({ key: "siteLengthMismatch", value: true }));
  }
}
