import { openIndexedDB } from "./indexedDbUtils";

/**
 * Deletes a single result by its ID from IndexedDB.
 * @param {string} id - The unique ID of the record to delete.
 * @returns {Promise<void>} - Resolves when the deletion is complete.
 */
export const deleteResultOffline = async (id) => {
  try {
    const dbName = "FormDataDB";
    const storeName = "FormDataStore";

    const db = await openIndexedDB(dbName, storeName);
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);

    return new Promise((resolve, reject) => {
      const request = store.delete(id);
      request.onsuccess = () => {
        console.log(`Result with ID ${id} deleted successfully.`);
        resolve();
      };
      request.onerror = (event) => {
        console.error("Error deleting result:", event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error("Error opening IndexedDB for deletion:", error);
    throw error;
  }
};

/**
 * Deletes multiple results by their IDs from IndexedDB.
 * @param {string[]} ids - An array of unique IDs to delete.
 * @returns {Promise<void>} - Resolves when all deletions are complete.
 */
export const bulkDeleteResultsOffline = async (ids) => {
  try {
    const dbName = "FormDataDB";
    const storeName = "FormDataStore";

    const db = await openIndexedDB(dbName, storeName);
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);

    const deletionPromises = ids.map(
      (id) =>
        new Promise((resolve, reject) => {
          const request = store.delete(id);
          request.onsuccess = () => resolve();
          request.onerror = (event) => reject(event.target.error);
        })
    );

    await Promise.allSettled(deletionPromises);

    console.log(`Bulk deletion completed for IDs: ${ids.join(", ")}`);
  } catch (error) {
    console.error("Error during bulk deletion:", error);
    throw error;
  }
};
