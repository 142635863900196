import React, { useState } from 'react';
import { Input, Button, Space, Tag } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';


const SelectInlineTags = ({ placeholder, onChange, initial_data = [] }) => {
  const [items, setItems] = useState(initial_data);
  const [inputValue, setInputValue] = useState('');

  // Add a new item
  const handleAddItem = () => {
    if (inputValue && !items.includes(inputValue)) {
      const newItems = [...items, inputValue];
      setItems(newItems);
      setInputValue('');
      onChange(newItems);  // Notify parent of changes
    }
  };

  // Delete an item
  const handleDeleteItem = (item) => {
    const filteredItems = items.filter((i) => i !== item);
    setItems(filteredItems);
    onChange(filteredItems);  // Notify parent of changes
  };

  return (
    <div>
      <Space wrap>
        {/* Display the added items in a line with a close icon */}
        {items.map((item) => (
          <Tag
            key={item}
            closable
            onClose={() => handleDeleteItem(item)}
            closeIcon={<CloseOutlined />}
            style={{
              backgroundColor: 'white',
              border: '1px solid #d9d9d9',
              padding: '5px 10px',
              display: 'flex',
              alignItems: 'center',
              color: '#333',
              zoom:1.1
            }}
          >
            {item}
          </Tag>
        ))}
        {/* Input field for adding new items */}
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
          style={{ width: '200px' }}
          onPressEnter={handleAddItem} // Handle Enter key press
        />
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddItem}>
          Add
        </Button>
      </Space>
    </div>
  );
};

export default SelectInlineTags;
