import React, { useState, useRef } from "react";

function FileUpload({ onChange }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  // Function to handle file selection when the custom button is clicked
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle file selection
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    onChange(file); // Call the onChange prop immediately upon file selection
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
      <button onClick={handleButtonClick}>+ Upload</button>
    </div>
  );
}

export default FileUpload;
