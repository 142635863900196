import { ArrowRightOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Result,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { T1 } from "../../../appconfig/texts";
import TextInput from "../../../components/input/TextInput";
import FileInput from "../../../components/input/datafile";
import CountrySelect from "../../../components/select/country";
import SimpleSelect from "../../../components/select/simpleselect";
import FormContext from "../../../contexts/FormContext";
import { createSite, createSitesInBulk } from "../../../firebase/site/create";
import { catcher, except } from "../../../firebase/util";
import {
  findCompany,
  firstCompany,
  isSuperAdmin,
  userIsCompanyAdminOrAbove,
} from "../../../helper/attribute";
import { selectCompanies } from "../../../redux/features/appSlice";
import { routePath } from "../../../static/routes.static";

import _ from "lodash";
import * as XLSX from "xlsx";
import { getCountries } from "../../../assets/countries";
import FakeLabel from "../../../components/input/select";
import Wexel from "../../../helper/excel";
import { arrayToObjects } from "../../../helper/obj";
import useSize from "../../../hooks/useSize";
const bulkUploadSheetName = "Sites Data";
export default function AddSiteModal({ visible, setVisible }) {
  const { isExtraSmall } = useSize();

  // User Must be able to add site
  const [bulkMode, setBulkMode] = useState(false);
  const [bulkSites, setBulkSites] = useState();
  const companies = useSelector(selectCompanies).filter((company) =>
    isSuperAdmin()
      ? ["premium", "ma", "pro", "demo"].includes(company.plan)
      : true
  );
  const [status, setStatus] = useState({});
  const [data, setData] = useState({ companyId: companies[0]?.id });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onOk = async () => {
    except(async () => {
      setLoading(true);
      const id = await createSite(data);
      navigate(routePath.SiteDetails(id));
      setVisible(false);
      setLoading(false);
    });
  };
  const handleAddBulkSites = () => {
    setBulkMode(true);
  };

  const bulkFileUpload = (file) => {
    try {
      if (!data) return message.error("Please select Company first");
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = async (event) => {
        try {
          const rows = event.target.result;
          const workbook = XLSX.read(rows, { type: "array" });
          const sheet = workbook.Sheets[bulkUploadSheetName];
          const data = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            raw: true,
          });

          setBulkSites(
            arrayToObjects(data)
              .map((site) => {
                return site;
              })
              .filter((site) => {
                return site.title && site.country;
              })
          );
        } catch (err) {}
      };
    } catch (err) {
      console.log(err);
      message.info("Something bad happened!");
    }
  };
  const handleUploadFilesInBulk = () => {
    if (
      window.confirm(
        `Confirm create ${bulkSites.length} sites for ${
          findCompany(data.companyId)?.title
        }?`
      )
    ) {
      catcher(
        async () => {
          await createSitesInBulk(
            bulkSites?.map((site) => ({ ...site, companyId: data?.companyId }))
          );
        },
        { setLoading }
      );
    }
  };
  const handleDownloadBulkTemplate = () => {
    const excel = new Wexel();
    excel.addSheet(bulkUploadSheetName);
    excel.addHeader({
      headers: ["title", "country"],
      sheet_ref: bulkUploadSheetName,
    });
    const formula_site = excel.addDropdownRow(getCountries());
    excel.applyColFormula(
      bulkUploadSheetName,
      1,
      {
        type: "list",
        formulae: [formula_site],
        showErrorMessage: false,
      },
      200
    );
    excel.hideAllSheets([bulkUploadSheetName]);
    excel.download(`${firstCompany()?.title}-Bulk User Upload.xlsx`);
  };
  const companyInput = (
    <SimpleSelect
      title={<T1 pos="sites.table.company" />}
      value={data.companyId}
      setValue={(companyId) => setData({ ...data, companyId })}
      options={companies.map((e) => ({
        label: e.title,
        value: e?.id,
      }))}
    ></SimpleSelect>
  );
  if (bulkMode) {
    return (
      <Modal
        title={"Add sites in bulk"}
        okText={
          <span>
            Ok <ArrowRightOutlined />{" "}
          </span>
        }
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={handleUploadFilesInBulk}
        okButtonProps={{ loading: loading, disabled: !bulkSites }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="link" onClick={handleDownloadBulkTemplate}>
            Download template <DownloadOutlined />
          </Button>
        </div>
        {companyInput}
        {!bulkSites ? (
          <FileInput setFile={bulkFileUpload} />
        ) : (
          <Table
            title={() => (
              <div>
                {
                  <b>
                    <u> Number of sites:</u> {bulkSites?.length}
                  </b>
                }
              </div>
            )}
            columns={[
              {
                title: "Title",
                dataIndex: "title",
                key: "title",
              },
              {
                title: "Country",
                dataIndex: "country",
                key: "country",
              },
            ]}
            dataSource={bulkSites}
          />
        )}
      </Modal>
    );
  }
  return (
    <Modal
      title={<T1 pos="sites.add.title" />}
      okText={
        <span>
          Create <ArrowRightOutlined />{" "}
        </span>
      }
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={onOk}
      okButtonProps={{ loading: loading }}
    >
      {" "}
      {userIsCompanyAdminOrAbove() ? (
        <>
          {isSuperAdmin() && (
            <Button className="saf" onClick={handleAddBulkSites}>
              Add sites in bulk
            </Button>
          )}
          <p>
            <T1 pos="sites.add.desc" />
          </p>
          <FormContext.Provider
            value={{
              bgless: true,
              borderless: true,
              minWidthElem: "100%",
            }}
          >
            {isExtraSmall ? (
              <div direction="vertical">
                <SimpleSelect
                  title={<T1 pos="sites.table.company" />}
                  value={data.companyId}
                  setValue={(companyId) => setData({ ...data, companyId })}
                  options={companies.map((e) => ({
                    label: e.title,
                    value: e?.id,
                  }))}
                ></SimpleSelect>
                <TextInput
                  value={data.title}
                  setValue={(e) => setData({ ...data, title: e })}
                  status={status.title === "error" && "error"}
                  title={<T1 pos="sites.table.name" />}
                />
                <CountrySelect
                  title={<T1 pos="sites.table.country" />}
                  value={data.country}
                  setValue={(country) => setData({ ...data, country })}
                />
              </div>
            ) : (
              <Space direction="vertical">
                <SimpleSelect
                  title={<T1 pos="sites.table.company" />}
                  value={data.companyId}
                  setValue={(companyId) => setData({ ...data, companyId })}
                  options={companies.map((e) => ({
                    label: e.title,
                    value: e?.id,
                  }))}
                ></SimpleSelect>
                <TextInput
                  value={data.title}
                  setValue={(e) => setData({ ...data, title: e })}
                  status={status.title === "error" && "error"}
                  title={<T1 pos="sites.table.name" />}
                />
                <CountrySelect
                  title={<T1 pos="sites.table.country" />}
                  value={data.country}
                  setValue={(country) => setData({ ...data, country })}
                />
              </Space>
            )}
          </FormContext.Provider>
        </>
      ) : (
        <>
          <Result title="Permission Error" />
          <Typography.Title level={5}>
            You cannot add a site as you are not an admin. Contact company
            Admins
          </Typography.Title>
        </>
      )}
    </Modal>
  );
}
