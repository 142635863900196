import { ref, deleteObject } from "firebase/storage";
import { storage } from "../setup";

export async function deleteFile(filePath) {
  const fileRef = ref(storage, filePath);

  try {
    await deleteObject(fileRef);
  } catch (error) {
    console.error(`Error deleting file ${filePath}:`, error);
  }
}
