import React from "react";
import store from "../../../redux/store";
import { useSelector } from "react-redux";
import { selectResults } from "../../../redux/features/appSlice";
import { downloadXLSX } from "../../../helper/wodash";
import { selectUserDoc } from "../../../redux/features/userSlice";
import { Button, Divider } from "antd";
import { BeautifyData } from "../../../components/text/BeautifyData";

export default function DownloadRawUserInformation() {
  const results = useSelector(selectResults);
  const userdoc = useSelector(selectUserDoc);
  const handleTableDownload = () => {
    downloadXLSX(userdoc?.firstName + " " + userdoc?.lastName + "- Raw results export.xlsx", results);
  };
  const handleInfoDownload = () => {
    downloadXLSX(userdoc?.firstName + " " + userdoc?.lastName + "- Raw info export.xlsx", [store.getState().user.doc]);
  };
  return (
    <div>
      <Button onClick={handleTableDownload}>Download Results</Button>
      <Divider />
      <Button onClick={handleInfoDownload}>Download Raw User Info</Button>
      <BeautifyData data={store.getState().user.doc} />
    </div>
  );
}
