import React, { useState, useEffect } from "react";
import * as Comlink from "comlink";

const PrimeGenerator = () => {
  const [limit, setLimit] = useState(100000);
  const [primes, setPrimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [worker, setWorker] = useState(null);

  const handleGeneratePrimes = async () => {
    setLoading(true);
    setPrimes([]);

    // Initialize the worker from the public folder
    const newWorker = new Worker("/workers/primeWorker.js");
    const { generatePrimes } = Comlink.wrap(newWorker);
    setWorker(newWorker);

    // Set up an event listener to handle messages from the worker
    newWorker.onmessage = (event) => {
      const { type, primes: newPrimes } = event.data;
      if (type === "progress") {
        setPrimes((prevPrimes) => [...prevPrimes, ...newPrimes]);
      } else if (type === "done") {
        setLoading(false);
        newWorker.terminate();
      }
    };

    // Start prime generation
    await generatePrimes(limit, 1000);  // Specify chunk size for progress updates
  };

  // Clean up the worker if the component unmounts
  useEffect(() => {
    return () => worker && worker.terminate();
  }, [worker]);

  return (
    <div>
      <h1>Prime Number Generator</h1>
      <input
        type="number"
        value={limit}
        onChange={(e) => setLimit(Number(e.target.value))}
      />
      <button onClick={handleGeneratePrimes} disabled={loading}>
        {loading ? "Generating..." : "Generate Primes"}
      </button>
      <div>
        {loading ? (
          <p>Calculating primes...</p>
        ) : (
          <p>Found {primes.length} primes.</p>
        )}
      </div>
      <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
        {primes.map((prime, index) => (
          <div key={index}>{prime}</div>
        ))}
      </div>
    </div>
  );
};

export default PrimeGenerator;
