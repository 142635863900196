import { EditFilled } from "@ant-design/icons";
import { Divider, Dropdown, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import {
  designations,
  findCompanies,
  getCurrentUserDesignation,
  openSites,
} from "../../helper/attribute";
import { selectResults } from "../../redux/features/appSlice";
import { routePath } from "../../static/routes.static";
import "./style.css";
import { createCache } from "../../cache";
import analysisConfig from "../../static/analysis.routes.static";
import ErrorBoundary from "../../ErrorBoundary";

/**GLOBAL ANALYSIS PAGE CACHE */

const cache = createCache();

const storeCache = (keysList, data) => {
  const key = JSON.stringify(keysList);
  cache.set(key, data);
};
const getCache = (keysList) => {
  const key = JSON.stringify(keysList);
  if (cache.has(key)) {
    return cache.get(key);
  }
};

export default function Analysis() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const location = useLocation();
  const results = useSelector(selectResults);
  const [queryParams, setQueryParams] = useState(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setQueryParams(Object.fromEntries(searchParams.entries()));
  }, [location.search]);
  useEffect(() => {
    if (queryParams) {
      setData({
        year: data.year ? data.year : null,
        name: queryParams.name,
        site:
          getCurrentUserDesignation() !== designations.group_admin
            ? [findCompanies()[0]?.id]
            : findCompanies().map((c) => c?.id),
      });
    }
  }, [queryParams]);
  if (!queryParams) return <Loading />;
  if (!results) return <Loading />;
  const Component = analysisConfig[queryParams.name].component;

  {
    /*
     * Drop down items
     */
  }
  const items = Object.keys(analysisConfig).map((label) => ({
    key: label,
    label,
    onClick: () => {
      navigate(routePath.Analysis(label));
    },
  }));
  if (!openSites().length) return <Empty description="No sites found"></Empty>
  return (
    <div>
      <h2 style={{ width: "fit-content", display: "none" }}>
        Analysis by{" "}
        <Dropdown
          menu={{ items }}
          overlayStyle={{ color: "blanchedalmond !important" }}
        >
          <span className="analysis-tab-change">
            {data.name} <EditFilled />
          </span>
        </Dropdown>
      </h2>
      <Divider style={{ display: "none" }} />
      <ErrorBoundary>
        {Component && (
          <Component
            data={data}
            setData={setData}
            storeCache={storeCache}
            getCache={getCache}
          />
        )}
      </ErrorBoundary>
    </div>
  );
}
