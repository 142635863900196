import { Button, notification } from "antd";

export const notifyreload = (
  message = "The changes are saved. Please reload to avoid conflicts."
) => {
  return notification.info({
    message,
    btn: (
      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload
      </Button>
    ),
  });
};
