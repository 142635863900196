import React, { useEffect, useState } from "react";
import { Spin, Timeline, Typography, Switch, Space, Checkbox } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import moment from "moment";
import { getHistory } from "../../../firebase/data/updateHistory";
import { findFormText, findFormTexts, findResult } from "../../../helper/attribute";

const { Text } = Typography;

const ResultHistory = ({ id }) => {
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDescending, setIsDescending] = useState(true);
  const [fullResult, setFullResult] = useState(false); //whether to display full result or just emissions

  const ignoredFields = ["id", "updatedAt", "createdAt"];

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await getHistory(id);
        setHistory(historyData);
      } catch (error) {
        console.error("Failed to fetch history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [id]);

  const handleToggle = () => {
    setIsDescending(!isDescending);
  };

  const generateDiff = (prev, current) => {
    const changes = [];
    Object.keys(current).forEach((key) => {
      if (ignoredFields.includes(key)) return;

      if (!prev[key]) {
        changes.push({ type: "added", key, value: current[key] });
      } else if (prev[key] !== current[key]) {
        changes.push({ type: "modified", key, from: prev[key], to: current[key] });
      }
    });
    Object.keys(prev).forEach((key) => {
      if (ignoredFields.includes(key)) return;

      if (!current[key]) {
        changes.push({ type: "deleted", key, value: prev[key] });
      }
    });
    return changes;
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!history) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Text type="warning">No history found for the given ID. Please note that the history feature is active on results after 30th August, 2024.</Text>
      </div>
    );
  }

  const sortedKeys = Object.keys(history).sort((a, b) => {
    return isDescending ? new Date(b) - new Date(a) : new Date(a) - new Date(b);
  });

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ textAlign: "right", marginBottom: "20px" }}>
        <Switch checked={isDescending} onChange={handleToggle} checkedChildren={<CaretDownOutlined />} unCheckedChildren={<CaretUpOutlined />} />
      </div>
      <div>
        <Checkbox checked={fullResult} onChange={(e) => setFullResult(e.target.checked)}>
          Display full result?
        </Checkbox>
      </div>
      <Timeline style={{ padding: "20px" }}>
        {sortedKeys.map((time, index) => {
          let parsedResult;
          try {
            parsedResult = JSON.parse(history[time].value);
          } catch (error) {
            console.error("Failed to parse result:", error);
            return null;
          }

          const previousResult = index < sortedKeys.length - 1 ? JSON.parse(history[sortedKeys[index + 1]].value) : {};
          const diff = generateDiff(previousResult, parsedResult);

          return (
            <Timeline.Item key={index}>
              <Text
                strong
                style={{
                  fontSize: "16px",
                  color: "#1890ff",
                  textTransform: "capitalize",
                }}
              >
                {history[time].type.replace("_", " ")}
              </Text>
              <Text
                style={{
                  display: "block",
                  marginTop: "5px",
                  fontSize: "14px",
                  color: "#595959",
                }}
              >
                {moment(time).format("MMMM Do YYYY, h:mm:ss A")}
              </Text>
              <pre
                style={{
                  background: "#f6f6f6",
                  padding: "10px",
                  borderRadius: "4px",
                  marginTop: "10px",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {findFormTexts(parsedResult.name, "tables").map((x) => (
                  <div>
                    {parsedResult[x.name] && (
                      <Space key={x.name} style={{ display: fullResult ? "flex" : ["result", "wtt"].includes(x.name) ? "flex" : "none" }}>
                        <b>{x.title}</b>: {parsedResult[x.name]}
                      </Space>
                    )}
                  </div>
                ))}
              </pre>
              {diff.length > 0 && (
                <div style={{ marginTop: "10px" }}>
                  <Text strong style={{ color: "#d32f2f" }}>
                    Input changes:
                  </Text>
                  <ul>
                    {diff.map((change, i) => {
                      const title = findFormText(parsedResult.name, change.key, "title", "forms", false);
                      if (title)
                        return (
                          <li key={i} style={{ color: change.type === "added" ? "#388e3c" : change.type === "deleted" ? "#d32f2f" : "#f57c00" }}>
                            {change.type.charAt(0).toUpperCase() + change.type.slice(1)}: <b>{title}</b>
                            {change.type === "modified" ? ` (from "${change.from}" to "${change.to}")` : ` - ${change.value}`}
                          </li>
                        );
                    })}
                  </ul>
                </div>
              )}
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default ResultHistory;
