import React from "react";
import Users from "../users";

export default function CompanyUsers({ companyId }) {
  return (
    <div>
      <Users filterByCompanyId={companyId} />
    </div>
  );
}
