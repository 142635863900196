import { createSlice } from "@reduxjs/toolkit";
import { updateDataForms } from "../../static/formRoutes";

export const configSlice = createSlice({
  name: "config",
  initialState: {
    loading: undefined, // undefined || message
    forms: undefined,
    tables: undefined,
    reviewpages: undefined,
    ghg_categories: undefined,
    texts: undefined,
    updates: undefined,
    plans: undefined,
  },
  reducers: {
    setConfigData: (state, action) => {
      const keys = Object.keys(action.payload);
      keys.forEach((key) => {
        try {
          state[key] = JSON.parse(action.payload[key].data);
        } catch (err) {
          state[key] = action.payload[key];
        }
      });
      try {
        updateDataForms(JSON.parse(action.payload.forms.data));
      } catch (err) {}
    },
  },
});

export const { setLoading, setConfigData } = configSlice.actions;

export const selectConfigData = (state) => state.config;

export default configSlice.reducer;
