import { PDFDocument } from "pdf-lib";
import { firstCompany } from "../../../helper/attribute";

async function mergeAndDownloadPDF(srcData) {
  const fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.accept = "application/pdf";

  fileInput.onchange = async (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) {
      alert("No file selected. Please select a PDF file to upload.");
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const newPdfBytes = e.target.result;
      const srcPdfDoc = await PDFDocument.load(srcData);
      const newPdfDoc = await PDFDocument.load(newPdfBytes);
      const mergedPdfDoc = await PDFDocument.create();

      const newPages = await mergedPdfDoc.copyPages(newPdfDoc, newPdfDoc.getPageIndices());
      newPages.forEach((page) => mergedPdfDoc.addPage(page));

      const srcPages = await mergedPdfDoc.copyPages(srcPdfDoc, srcPdfDoc.getPageIndices());
      srcPages.forEach((page) => mergedPdfDoc.addPage(page));

      const mergedPdfBytes = await mergedPdfDoc.save();
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Verified PDF Report - ${firstCompany()?.title}.pdf`;
      a.click();
      URL.revokeObjectURL(url);
    };

    fileReader.readAsArrayBuffer(uploadedFile);
  };

  fileInput.click();
}

export default mergeAndDownloadPDF;
