import { doc, updateDoc } from "firebase/firestore";
import {
  designations,
  getCurrentUserDesignation,
  getDesignation,
} from "../../helper/attribute";
import { getCurrentDate } from "../../helper/time";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { fetchSites } from "./get";

function beforeAll() {
  if (
    getCurrentUserDesignation() === designations.company_admin ||
    getCurrentUserDesignation() === designations.super_admin
  ) {
  } else throw "Only your company's admin can close/reopen this site.";
}

export const closeSite = async (id) => {
  beforeAll();
  await updateDoc(doc(db, firestoreStatic.sites, id), {
    closed: true,
    lastClosed: getCurrentDate(),
  });
};
export const openSite = async (id) => {
  beforeAll();
  await updateDoc(doc(db, firestoreStatic.sites, id), {
    closed: false,
    lastOpened: getCurrentDate(),
  });
};
