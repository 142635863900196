import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Drawer,
  Input,
  message,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { T1 } from "../../../../appconfig/texts";
import FileInput from "../../../../components/input/datafile";
import { updateSite } from "../../../../firebase/site/update";
import { createFile } from "../../../../firebase/storage/create";
import { deleteFile } from "../../../../firebase/storage/delete";
import { downloadFileFromStorage } from "../../../../firebase/storage/download";
import { catcher } from "../../../../firebase/util";
import { findCompany, findSite } from "../../../../helper/attribute";
import { setCompanies, setSites } from "../../../../redux/features/appSlice";
import store from "../../../../redux/store";
import { updateCompany } from "../../../../firebase/company/update";

const MAX_FILES = 50;

function SelectFileDrawer({ id, type }) {
  const entity = type === "company" ? findCompany(id) : findSite(id);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [desc, setDesc] = useState("");
  const [file, setFile] = useState(null);
  const filesLength = entity.files?.length || 0;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const hideDrawer = () => {
    setDrawerVisible(false);
    setDesc("");
    setFile(undefined);
  };

  const handleNameChange = (e) => {
    setDesc(e.target.value);
  };

  const handleUpload = async () => {
    catcher(
      async () => {
        const downloadURL = await createFile(
          `${type}data/${id}/${file.name}`,
          file
        );
        const prevFiles = entity.files || [];

        const updatedData = {
          files: [...prevFiles, { name: file.name, desc }],
        };

        if (type === "company") {
          await updateCompany(id, updatedData);
          dispatch(
            setCompanies(
              store
                .getState()
                .app.companies.map((company) =>
                  company.id === id ? { ...company, ...updatedData } : company
                )
            )
          );
        } else {
          await updateSite(id, updatedData);
          dispatch(
            setSites(
              store
                .getState()
                .app.sites.map((site) =>
                  site.id === id ? { ...site, ...updatedData } : site
                )
            )
          );
        }

        message.success("File uploaded successfully");
        hideDrawer();
      },
      { setLoading, success_msg: "" }
    );
  };

  const drawerContent = (
    <div>
      <Typography.Text>
        <T1 pos="site.files.uploadInfo" />
      </Typography.Text>
      <Typography.Title level={5}>Options</Typography.Title>
      <Input
        placeholder="Enter name or description"
        value={desc}
        onChange={handleNameChange}
      />
      <FileInput minHeight={250} file={file} setFile={setFile} fileSize={5} />
      <Button
        loading={loading}
        style={{ marginTop: 10 }}
        type="primary"
        onClick={handleUpload}
        disabled={!file || !desc || filesLength >= MAX_FILES}
      >
        Upload
      </Button>
      {filesLength >= MAX_FILES && (
        <Alert
          description={`Only ${MAX_FILES} files are allowed per ${type}.`}
        ></Alert>
      )}
    </div>
  );

  return (
    <div>
      <Button type="primary" onClick={showDrawer}>
        <FileAddOutlined /> <T1 pos="site.files.add" />
      </Button>
      <Drawer
        destroyOnClose={true}
        title="Upload File"
        placement="bottom"
        closable={false}
        onClose={hideDrawer}
        open={drawerVisible}
        height={500}
      >
        {drawerContent}
      </Drawer>
    </div>
  );
}

export default function ShowFiles({ id, type = "site" }) {
  const entity = type === "company" ? findCompany(id) : findSite(id);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const dispatch = useDispatch();

  const handleDownload = async (filenames) => {
    await catcher(
      async () => {
        for (const filename of filenames) {
          await downloadFileFromStorage(
            `${type}data/${id}/${filename}`,
            filename
          );
        }
      },
      { setLoading, success_msg: "" }
    );
  };

  const handleDelete = async (filenames) => {
    await catcher(
      async () => {
        for (const filename of filenames) {
          await deleteFile(`${type}data/${id}/${filename}`);
        }

        const updatedData = {
          files: entity.files.filter((file) => !filenames.includes(file.name)),
        };

        if (type === "company") {
          await updateCompany(id, updatedData);
          dispatch(
            setCompanies(
              store
                .getState()
                .app.companies.map((company) =>
                  company.id === id ? { ...company, ...updatedData } : company
                )
            )
          );
        } else {
          await updateSite(id, updatedData);
          dispatch(
            setSites(
              store
                .getState()
                .app.sites.map((site) =>
                  site.id === id ? { ...site, ...updatedData } : site
                )
            )
          );
        }
      },
      { setLoading, success_msg: "" }
    );
  };

  const columns = [
    {
      title: <T1 pos="site.files.desc" />,
      dataIndex: "desc",
      key: "desc",
    },
    {
      title: <T1 pos="site.files.name" />,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <T1 pos="site.files.options" />,
      key: "options",
      render: (text, record) => (
        <div>
          <Button
            type="ghost"
            icon={<DownloadOutlined />}
            onClick={() => handleDownload([record.name])}
          />
          <Popconfirm
            title="Confirm delete"
            onConfirm={async () => await handleDelete([record.name])}
            okButtonProps={{ loading: loading }}
          >
            <Button type="danger" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDownloadAll = async () => {
    await handleDownload(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  const handleDeleteAll = async () => {
    await handleDelete(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  const selectOptions = selectedRowKeys.length > 0 && (
    <Space>
      <Button
        type="primary"
        onClick={handleDownloadAll}
        icon={<DownloadOutlined />}
      >
        Download Files
      </Button>
      <Popconfirm
        title="Confirm delete all"
        onConfirm={handleDeleteAll}
        okButtonProps={{ loading: loading }}
      >
        <Button
          style={{ color: "darkred !important" }}
          icon={<DeleteOutlined />}
        >
          Delete Files
        </Button>
      </Popconfirm>
    </Space>
  );

  return (
    <div>
      <div className="files-list">
        <Table
          loading={loading}
          title={() => (
            <>
              {!selectOptions && <SelectFileDrawer id={id} type={type} />}
              {selectOptions}
            </>
          )}
          dataSource={entity.files?.map((file) => ({
            ...file,
            key: file.name,
          }))}
          columns={columns}
          rowSelection={rowSelection}
        />
      </div>
    </div>
  );
}
