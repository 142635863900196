import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tour from "reactour";
import { selectAffiliate, selectState } from "../../redux/features/appSlice";
import { routePath } from "../../static/routes.static";
import { useLocation, useNavigate } from "react-router-dom";
import { EditOutlined, LeftOutlined, RedoOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { updateUser } from "../../firebase/user/update";
import { selectUserDoc } from "../../redux/features/userSlice";
import { getCurrentDate, getCurrentTime } from "../../helper/time";
import { catcher } from "../../firebase/util";
import "./style.css";
import useSize from "../../hooks/useSize";
const createSteps = (setStep, navigate, closeTutorial) => {
  // const wait_for_click = (node) => {
  //   setLockTour(true);
  //   node.onclick = () => {
  //     setLockTour(false);
  //   };
  // };
  const input_tour = [
    {
      content: "Click here to edit your profile, where you can change your user details, country, and password.",
      selector: ".profile-siderbar-option",
      // nav: routePath.Dashboard,
    },
    {
      content: "Click any of these to input data for the relevant emission source. If you reach the entry limit for any category, this will turn grey.",
      selector: ".input-sidebar-option",
      // nav: routePath.Form("fuel"),
      action: () => navigate(routePath.Form("fuel")),
    },
    {
      content: "Each tab has instructions and an input form for entering activity data. For example, this page allows for entry of fuels, including natural gas.",
      selector: ".input-form",
      action: () => navigate(routePath.Form("fuel")),
    },
    {
      content: "Each input tab also has a tutorial video that can be found at the top right of each page.",
      selector: ".input-tutorial",
      action: () => navigate(routePath.Form("fuel")),
    },
    {
      content: "Click here to see a breakdown of your emission results.",
      selector: ".analysis-sidebar-option",
      action: () => navigate(routePath.Analysis("Years")),
    },
    {
      content: "Here, your results are displayed in a pie chart. This can be broken down into scope 1, 2, and 3, or into each individual emission source.",
      selector: ".analysis-graph",
      action: () => navigate(routePath.Analysis("Years")),
    },
    {
      content: "Click the + symbols next to scope 1, 2, and 3 to view a breakdown of these scopes by emission source.",
      selector: ".analysis-result",
      action: () => navigate(routePath.Analysis("Years")),
    },
    {
      content: "If you have entered metrics (number of employees, annual turnover, and a custom additional metric), the results of these against your total emissions are displayed here.",
      selector: ".analysis-metrics",
      action: () => navigate(routePath.Analysis("Years")),
    },
    {
      content:
        "Here, you can toggle well-to-tank emissions on or off, toggle between location and market-based electricity, and display the results in terms of the GHG Protocol categories, or individual categories.",
      selector: ".analysis-options",
      action: () => navigate(routePath.Analysis("Years")),
    },
    {
      content: "Click ‘Offset Now’ to see our offset portfolios in order to offset your emissions. This is also viewable from the ‘Offset’ tab. ",
      selector: ".analysis-offset",
      action: () => navigate(routePath.Analysis("Years")),
    },
    {
      content: "Click here, to download a PDF report of your results.	",
      selector: ".analysis-download-pdf",
      action: () => navigate(routePath.Analysis("Years")),
    },
    {
      content: "In Combined Results, you can see all your inputted data in one place, and data can also be deleted if needed. ",
      selector: ".combined-sidebar-option",
      action: () => navigate(routePath.combined_data_table),
    },
    {
      content: "You can view FAQs here. If your question is not covered by these, please let us know.",
      selector: ".faq-sidebar-option",
      action: () => navigate(routePath.FAQ),
    },
    {
      content: "Here, you can view and upgrade to our different Sustrax Lite plans and see more details on our enterprise Sustrax MX version.",
      selector: ".upgrade-sidebar-option",
      action: () => navigate(routePath.Upgrade),
    },
    {
      content: (
        <div>
          <b>Tutorial completed!</b>
          <p>You can access the tutorial again by clicking on the floating button in the bottom-right corner.</p>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {/* <Button onClick={() => closeTutorial(routePath.Profile)}>
              <UserOutlined /> Your Profile
            </Button> */}
            <Button onClick={() => closeTutorial(routePath.Form(""))}>
              <EditOutlined /> Input data
            </Button>
            <Button onClick={() => setStep(0)}>
              <RedoOutlined /> Restart tutorial
            </Button>
          </div>
        </div>
      ),
      selector: ".floating-buttons",
    },
  ];
  return input_tour;
};

export default function UserTour({ open, setOpen }) {
  const state = useSelector(selectState);
  const [step, setStep] = useState(0);
  const [lockTour, setLockTour] = useState(false);
  const [disabledNav, setDisabledNav] = useState(false);
  const af = useSelector(selectAffiliate);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUserDoc);
  const [updateHash, setUpdateHash] = useState(1);
  const { isSmall, isExtraSmall } = useSize();
  useEffect(() => {
    setInterval(() => {
      setUpdateHash((u) => u + 1);
    }, 800);
  }, []);
  // useEffect(() => {
  //   setTimeout(() => setOpen(true), 5000);
  // }, []);
  // useEffect(() => {
  //   setStep(0);
  // }, [setOpen]);
  const steps = createSteps(setStep, navigate, async (path) => {
    setOpen(false);
    setStep(0);
    catcher(
      async () => {
        await updateUser(user.id, {
          ...user,
          tut_completed_on: getCurrentDate(),
        });
      },
      { setLoading: () => {}, success_msg: "" }
    );
    navigate(path);
  });
  // useEffect(() => {
  //   const currentStepItem = steps[step];
  //   const newNavStatus = currentStepItem.nav
  //     ? currentStepItem.nav !== location.pathname + location.search
  //     : false;
  //   if (disabledNav === true && newNavStatus === false) {
  //     setTimeout(() => handleSetStep(step + 1), 500);
  //   }
  //   setDisabledNav(newNavStatus);
  //   // console.log(
  //   //   disabledNav,
  //   //   currentStepItem.nav,
  //   //   location.pathname + location.search
  //   // );
  // }, [location, step]);

  const handleSetStep = (step) => {
    if (steps >= steps.length) {
      setOpen(false);
    }
    if (step === steps.length - 1) {
      catcher(async () => {
        console.log("saving tutcompletd on");
        await updateUser(user.id, {
          ...user,
          tut_completed_on: getCurrentDate(),
        });
      }, {});
    }
    setStep(step < 0 ? 0 : step >= steps.length ? steps.length - 1 : step);
    if (step > 0) {
      const prevStepItem = steps[step - 1];
      if (prevStepItem.nav) setTimeout(() => navigate(prevStepItem.nav), 500);
    }
  };
  const prevButton = () => {
    return (
      <Button disabled={disabledNav} onClick={() => handleSetStep(step - 1)}>
        <LeftOutlined />
      </Button>
    );
  };
  const nextButton = () => {
    return (
      <Button disabled={disabledNav} style={{ display: disabledNav && "none" }} onClick={() => handleSetStep(step + 1)}>
        <RightOutlined />
      </Button>
    );
  };
  if (isSmall || isExtraSmall) return <></>;
  if (af) return <></>;
  if (state === "trial") return <></>;
  return (
    <Tour
      showCloseButton={true}
      closeWithMask={false}
      onRequestClose={() => {
        setOpen(false);
        setStep(0);
        catcher(
          async () => {
            await updateUser(user.id, {
              ...user,
              tut_shown_on: getCurrentDate(),
            });
          },
          { setLoading: () => {}, success_msg: "" }
        );
      }}
      getCurrentStep={setStep}
      goToStep={step}
      prevButton={prevButton()}
      nextButton={nextButton()}
      steps={steps}
      isOpen={open}
      update={updateHash}
      updateDelay={0.8}
      highlightedMaskClassName="highlighted-window"
    />
  );
}
