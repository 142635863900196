import axios from "axios";
import store from "../../redux/store";
import { adminApi } from "../../static/adminApi";
import { fetchUsers } from "./get";

export const deleteUserFunction = async (id) => {
  if (store.getState().user.doc.id === id) throw "You cannot delete yourself!";

  const x = await axios.post(adminApi.deleteUser, { id });
  fetchUsers(true);
};
