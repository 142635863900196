import React, { useState } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import Settings from "../../../../components/Settings/Settings";
import { fixDecimals } from "../../../../helper/wodash";
import { combineData, GraphWrap } from "./config";
import { EmptyChart } from "../../../../components/charts/charts.config";

const COLORS = [
  "#3d3a67",
  "#2b6442",
  "#9c6f15",
  "#8c4200",
  "#8a6e8a",
  "#387a5e",
  "#9c1f1f",
  "#0056B3",
];

const EmissionsPieChart = ({ data, type = "not ghg" }) => {
  // We need title and result to present this graph.
  let convertedData;
  const [config, setConfig] = useState({
    showOther: {
      value: true,
      type: "boolean",
      title: "Compact View",
      info: "Combines those emissions which are lesser than 5% into other category.",
      bold: true,
    },
  });
  if (type === "GHG Category") {
    convertedData = data;
  } else {
    convertedData = data.map((row) => {
      // adding premade scope results
      const result = (row[1] || 0) + (row[2] || 0) + (row[3] || 0);
      return {
        key: row.col,
        result,
      };
    });
  }

  if (config.showOther.value) {
    convertedData = combineData(convertedData);
  }
  // Calculate total value
  const totalValue = convertedData.reduce(
    (sum, entry) => sum + entry.result,
    0
  );

  // Calculate percentages and add to data
  const dataWithPercentages = convertedData.map((entry) => ({
    ...entry,
    result: fixDecimals(entry.result),
    percentage: fixDecimals((entry.result / totalValue) * 100),
  }));
  return (
    <>
      <Settings config={config} setConfig={setConfig} />
      <GraphWrap xLabel={""} yLabel={""}>
        <PieChart>
          <Pie
            data={dataWithPercentages}
            animationDuration={300}
            dataKey="result"
            nameKey="key"
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#8884d8"
            label={({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              percentage,
            }) => {
              const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
              const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
              const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
              return (
                <text
                  x={x}
                  y={y}
                  fill="#ffffff"
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >
                  {`${percentage.toFixed(2)}%`}
                </text>
              );
            }}
          >
            {dataWithPercentages.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend />
          <Tooltip
            formatter={(value, name, props) => [
              `${value} (${props.payload.percentage.toFixed(2)}%)`,
              name,
            ]}
          />
        </PieChart>
      </GraphWrap>
    </>
  );
};

export default EmissionsPieChart;
