import React from "react";
import Sites from "../sites";

export default function CompanySites({ companyId }) {
  return (
    <div>
      <Sites filterByCompanyId={companyId} />
    </div>
  );
}
