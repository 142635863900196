import { Input, message } from "antd";
import React from "react";
import FakeLabel from "./select";
import validator from "validator";
import { useState } from "react";
import { LinkOutlined } from "@ant-design/icons";

export default function DataLinkInput({ value, setValue, title }) {
  const [err, setErr] = useState(false);
  const handleBlur = () => {
    if (value && !validator.isURL(value)) {
      setErr("Enter valid link.");
      setValue("");
    } else {
      setErr(false);
    }
  };
  return (
    <FakeLabel
      title={<div>{title || "Link"}</div>}
      description={<div style={{ color: "red" }}>{err}</div>}
      elem={
        <Input
          onBlur={handleBlur}
          status={err && "error"}
          placeholder="e.g. Sharepoint or Google Drive Links"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      }
    />
  );
}
