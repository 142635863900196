import Public from "./layout/Public";
import Private from "./layout/Private";
import AuthGuard from "./auth/Guard";
import Login from "./auth/login";
import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import Companies from "./pages/admin/companies";
import Sites from "./pages/admin/sites";
import Users from "./pages/admin/users";
import CompanyDetails from "./pages/admin/companies/details";
import SiteDetails from "./pages/admin/sites/details";
import DataForm from "./pages/form";
import EmissionFactors from "./pages/super-admin/EmissionFactors";
import BulkUploadForm from "./pages/bulk-upload";
import Result from "./pages/results";
import FourOhFour from "./components/404";
import Analysis from "./pages/analysis";
import ConfigPage from "./pages/super-admin/AppConfig";
import Bugs from "./pages/super-admin/Bugs";
import { routePath } from "./static/routes.static";
import SuperAdminSection from "./pages/super-admin/SuperAdminSection";
import CombinedResultTable from "./pages/results/combined";
import ErrorBoundary from "./ErrorBoundary";
import FAQ from "./pages/extras/faq/FAQ";
import { getCurrentPlan, isSustraxAnnual, planValue } from "./helper/plans";
import Upgrade from "./pages/extras/upgrade/Upgrade";
import { matchPath, useLocation } from "react-router-dom";
import Offset from "./pages/extras/offset/offset";
import Pay from "./pages/extras/upgrade/Pay";
import PaymentCancellationPage from "./pages/extras/upgrade/Cancel";
import PaymentSucceeded from "./pages/extras/upgrade/Success";
import Learn from "./pages/extras/learn/form_tuts";
import EditAffiliates from "./pages/super-admin/SuperAdminSectionPages/Affiliates/Edit";
import Admin from "./pages/super-admin/SuperAdminSectionPages/Affiliates/Admin";
import Conn from "./pages/super-admin/SuperAdminSectionPages/Affiliates/Conn";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SustBot from "./pages/chatbot";
import DownloadRawUserInformation from "./pages/admin/other/downloadRawUserInformation";
import Verification from "./pages/consultants/verification/Verification";
import RoughWork from "./pages/extras/RoughWork/RoughWork";
import Testing from "./pages/super-admin/SuperAdminSectionPages/Testing";

export const allRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/companies",
    element: <Companies />,
    planFilter: "canViewCompanies",
  },
  {
    path: "/sites",
    element: <Sites />,
    planFilter: "canViewSites",
  },
  {
    path: "/users",
    element: <Users />,
    planFilter: "canViewUsers",
  },
  {
    path: "/company/:id",
    element: <CompanyDetails />,
    planFilter: "canViewCompanyDetails",
  },
  {
    path: "/site/:id",
    element: <SiteDetails />,
    planFilter: "canViewSiteDetails",
  },
  {
    path: "/form",
    element: <DataForm />,
  },
  {
    path: "/bulk-upload",
    element: (
      <ErrorBoundary>
        <BulkUploadForm />
      </ErrorBoundary>
    ),
  },
  {
    path: "/emission_factors",
    element: <EmissionFactors />,
  },
  {
    path: "/super_admin_config",
    element: <ConfigPage />,
  },
  {
    path: "/bugs",
    element: <Bugs />,
  },
  {
    path: "/results",
    element: <Result />,
    planFilter: "canViewResults",
  },
  {
    path: routePath.combined_data_table,
    element: <CombinedResultTable />,
    planFilter: "canViewCombinedResults",
  },
  {
    path: "/analysis",
    element: <Analysis />,
  },
  {
    path: "/testing",
    element: <Testing />,
  },
  {
    path: "/rough",
    element: <RoughWork />,
  },
  {
    path: routePath.SuperAdminSection,
    element: <SuperAdminSection />,
  },
  {
    path: routePath.FAQ,
    element: <FAQ />,
  },
  {
    path: routePath.Offset,
    element: <Offset />,
    planFilter: "canViewOffsetPage",
  },
  {
    path: routePath.Upgrade,
    element: <Upgrade />,
    planFilter: "canViewUpgradePage",
  },
  {
    path: routePath.Pay(),
    element: <Pay />,
    // planFilter: "canViewUpgradePage",
  },
  {
    path: routePath.Cancel(),
    element: <PaymentCancellationPage />,
    // planFilter: "canViewUpgradePage",
  },
  {
    path: routePath.Success(),
    element: <PaymentSucceeded />,
    // planFilter: "canViewUpgradePage",
  },
  {
    path: routePath.learn(),
    element: <Learn />,
    // planFilter: "canViewUpgradePage",
  },
  {
    path: routePath.editAffiliate(),
    element: <EditAffiliates />,
    // planFilter: "canViewUpgradePage",
  },
  {
    path: routePath.adminAffiliate(),
    element: <Admin />,
    // planFilter: "canViewUpgradePage",
  },
  {
    path: routePath.adminConn(),
    element: <Conn />,
    // planFilter: "canViewUpgradePage",
  },
  {
    path: routePath.chat,
    element: <SustBot />,
    // planFilter: "canViewUpgradePage",
  },

  {
    path: routePath.downloadRawUserInformation,
    element: <DownloadRawUserInformation />,
    // planFilter: "canViewUpgradePage",
  },
  {
    path: "consultant_user_verification",
    element: <Verification />,
    // planFilter: "canViewUpgradePage",
  },
];

export const useCheckCurrentRoute = (routes = allRoutes) => {
  const location = useLocation();

  // Function to check if the current path is in the list of routes
  const isRoutePresent = () => {
    return routes.some((route) => {
      // Check if the current location matches the route path
      const match = matchPath(route.path, location.pathname);
      return match !== null;
    });
  };

  return isRoutePresent;
};
const routes = () => [
  {
    path: "/login",
    element: <Login />,
    // children: [{ path: "/login", element: <Login /> }],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <Private />
      </AuthGuard>
    ),
    children: allRoutes.filter((route) => {
      if (route.planFilter) {
        return planValue(route.planFilter);
      }
      return true;
    }),
  },
  {
    path: "/",
    element: <Public />,
    children: [{ path: "*", element: <FourOhFour /> }],
  },
];

// export function Redirector() {
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     const plan = getCurrentPlan();
//     console.log(location.pathname, routePath.Result());
//     if (isSustraxAnnual()) {
//       if (location.pathname === routePath.Result()) {
//         navigate(routePath.combined_data_table);
//       }
//     }
//   }, [location, navigate]);

//   return null;
// }

export default routes;
