import React from "react";
import { useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import Select1 from "../../../components/select/select1";
import { findFormText } from "../../../helper/attribute";
import { genPut } from "../helper/create";

export const INPUTS = (data) => [
  {
    elem: DateInput,
    key: "date",
  },
  {
    elem: SelectSearch,
    key: "occupancy",
    options: ["Single", "Multiple"],
  },
  {
    elem: NumberInput,
    key: "workers",
  },
  {
    elem: NumberInput,
    key: "hours_per_day",
    max: 24,
  },
  {
    elem: NumberInput,
    key: "days_per_week",
    max: 7,
  },
  {
    elem: NumberInput,
    key: "weeks_per_year",
    max: 53,
  },
];

export default function HomeWorkers({ data, setData }) {
  useEffect(() => {
    setData({ ...data, occupancy: data.occupancy || "Single" });
  }, []);
  const formname = data.name;
  return (
    <div>
      {INPUTS(data).map((input) => genPut(input, formname, data, setData))}
    </div>
  );
}
