import { collection, deleteDoc, doc } from "firebase/firestore";
import {
  findSitesByCompanyId,
  findUsersByCompanyId,
  getAllCompanyData,
} from "../../helper/attribute";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { Modal, message } from "antd";
import { bulkDeleteResults } from "../data/delete";
import { deleteSite } from "../site/delete";
import { deleteUserFunction } from "../user/delete";
import store from "../../redux/store";
import { setSites } from "../../redux/features/appSlice";

export const deleteCompany = async (id, force = false) => {
  if (force === false) {
    if (findSitesByCompanyId(id).length > 0)
      throw "Company has sites under it.";
    if (findUsersByCompanyId(id).length > 0) throw "Company has active users.";
  }
  await deleteDoc(doc(db, firestoreStatic.companies, id));
  //todo: ask to delete site data or not
};
export const forceDeleteCompany = async (id) => {
  const data = getAllCompanyData(id);
  const value = prompt(
    "Please type in company name again in order to initiate deletion process!"
  );
  if (value !== data?.company?.title)
    return message.error("Force delete failed! reason: name mismatch");
  await bulkDeleteResults(data?.results?.map((result) => result?.id));
  if (data && data.sites) {
    const sitesArray = data.sites;
    for (let i = 0; i < sitesArray.length; i++) {
      await deleteSite(sitesArray[i]);
    }
  }
  if (data && data.sites) {
    const sitesArray = data.sites;
    for (let i = 0; i < sitesArray.length; i++) {
      await deleteSite(sitesArray[i]);
    }
  }
  store.dispatch(
    setSites(
      store.getState().app.sites.filter((site) => {
        return !data.sites?.map((site) => site.id).includes(site.id);
      })
    )
  );
  if (data && data.users) {
    const usersArray = data.users;
    for (let i = 0; i < usersArray.length; i++) {
      await deleteUserFunction(usersArray[i]?.id);
    }
  }
  await deleteCompany(id, true);
  window.location.reload();
};
