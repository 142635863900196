import { DeleteOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { Checkbox, message, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import SimpleFileUpload from "../../../components/input/simplefileupload";
import Loading from "../../../components/loading";
import antdPrompt from "../../../components/prompts/antdPrompt";
import { getAppInfo, updateAppInfo } from "../../../firebase/appinfo/create";
import { createFile } from "../../../firebase/storage/create";
import { downloadFileFromStorage } from "../../../firebase/storage/download";
import { catcher } from "../../../firebase/util";
import { generateRandomString } from "../../../helper/wodash";
import { dataForms } from "../../../static/formRoutes";

const getFileRoute = (formname, filename) =>
  `bulkupload/${formname}-${filename}.xlsx`;

export const downloadBulkTemplate = async (form, title, filename) => {
  console.log("downloading bulk template", form, title, filename);
  if ((await getAppInfo("bulk-" + form.name))?.hasFile) {
    const URL = await downloadFileFromStorage(
      getFileRoute(form.name, filename),
      title || `${form.name}.xlsx`,
      true
    );
    console.log("URL FOUND", URL);
    return URL;
  } else return false;
};

function BulkUploadTemplates({ setKey }) {
  const [bulks, setBulks] = useState([]);
  const [loading, setLoading] = useState(true);
  const key = generateRandomString();
  const handleUpdateCheckedInfo = async (
    form,
    checked,
    filename,
    status = "create"
  ) => {
    //check if file has been added or not

    const oldobj = await getAppInfo("bulk-" + form.name);
    let templateNames = [...(oldobj?.files || []), filename];
    if (!filename) {
      templateNames = oldobj?.files || [];
    }
    if (status === "delete") {
      templateNames = oldobj?.files?.filter((name) => name !== filename);
    }
    await updateAppInfo(
      { hasFile: checked, files: templateNames },
      "bulk-" + form.name
    );
    if (checked) {
      setBulks((prev) => [...prev, form.name]);
    } else {
      setBulks((prev) => prev.filter((e) => e !== form.name));
    }
    setKey(key);
  };
  const handleFileUpload = async (form, file) => {
    await catcher(
      async () => {
        const TemplateName = await antdPrompt(
          "Template Name",
          "Ok",
          "Write Template name for the uploaded file! " + file.name
        );
        if (!TemplateName || TemplateName === "")
          return message.error("Template Name required.");
        await createFile(getFileRoute(form.name, TemplateName), file);
        await handleUpdateCheckedInfo(form, true, TemplateName);
        setKey(key);
      },
      { loading_msg: "Waiting for You to enter Name", setLoading }
    );
  };
  const handleDownloadFile = async (form, filename) => {
    catcher(
      async () =>
        await downloadFileFromStorage(
          getFileRoute(form.name, filename),
          `${form.name}-${filename}.xlsx`
        ),

      { setLoading }
    );
  };
  const handleDeleteTemplate = async (form, filename) => {
    catcher(
      async () => {
        await handleUpdateCheckedInfo(form, true, filename, "delete");
        setKey(key);
      },
      { setLoading }
    );
  };
  useEffect(() => {
    catcher(
      async () => {
        dataForms.forEach(async (form) => {
          try {
            const obj = await getAppInfo("bulk-" + form.name);
            if (obj.hasFile) {
              setBulks((prev) => [
                ...prev,
                { name: form.name, files: obj.files },
              ]);
            }
          } catch (err) {}
        });
        setLoading(false);
      },
      { success_msg: "" }
    );
  }, []);
  const columns = [
    { title: "Name", dataIndex: "title" },
    {
      title: "File",
      render: (_) => {
        const bulkObj = bulks.find((bulk) => bulk.name === _.name);
        return (
          <div style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {bulkObj &&
              bulkObj?.files?.map((filename) => {
                return (
                  <div>
                    <button
                      style={{ background: "green", color: "white" }}
                      onClick={() => handleDownloadFile(_, filename)}
                    >
                      Download "{filename}"
                    </button>
                    <button
                      style={{ color: "white", background: "firebrick" }}
                      onClick={() => handleDeleteTemplate(_, filename)}
                    >
                      <DeleteOutlined />
                    </button>
                  </div>
                );
              })}
            <SimpleFileUpload onChange={(file) => handleFileUpload(_, file)} />
          </div>
        );
      },
    },
    {
      title: "Use Template/ Code",
      render: (_) => {
        const bulkObj = bulks.find((bulk) => bulk.name === _.name);
        return (
          <div>
            <Checkbox
              checked={bulkObj}
              onChange={(e) => handleUpdateCheckedInfo(_, e.target.checked)}
            />
          </div>
        );
      },
    },
  ];
  const dataSource = dataForms.map((form) => ({
    title: form.title,
    name: form.name,
  }));

  if (loading) return <Loading />;
  return (
    <div>
      <Typography.Title level={3}>Bulk Upload Templates</Typography.Title>
      <Typography.Title level={5}>
        If these are uploaded, they will be downloaded in lieu of the
        programmatically generated version.
      </Typography.Title>
      <Typography.Text>
        <InfoCircleTwoTone />
        Please leave first column Site as empty while uploading them.
      </Typography.Text>

      <Table columns={columns} dataSource={dataSource} pagination={false} />
    </div>
  );
}

export default function Cover() {
  const [key, setKey] = useState(1);
  return <BulkUploadTemplates key={key} setKey={setKey} />;
}
