const ids = {
  bulk_materials: [
    "19_500_5000_15_1",
    "19_500_5001_15_1",
    "19_500_5002_15_1",
    "19_500_5003_15_1",
    "19_500_5004_15_1",
    "19_500_5005_15_1",
    "19_500_5006_15_1",
    "19_500_5007_15_1",
    "19_500_5008_15_1",
    "19_500_5009_15_1",
    "19_500_5010_15_1",
    "19_500_5011_15_1",
    "19_500_5012_15_1",
    "19_500_5013_15_1",
    "19_500_5014_15_1",
    "19_500_5015_15_1",
    "19_500_5016_15_1",
    "19_500_5017_15_1",
    "19_500_5018_15_1",
    "19_500_5019_15_1",
    "19_500_5020_15_1",
    "19_500_5021_15_1",
    "19_500_5022_15_1",
    "19_500_5023_15_1",
    "19_500_5024_15_1",
    "19_500_5025_15_1",
    "19_500_5026_15_1",
    "19_500_5027_15_1",
    "19_500_5028_15_1",
    "19_500_5029_15_1",
    "19_500_5030_15_1",
    "19_500_5031_15_1",
    "19_500_5032_15_1",
    "19_500_5033_15_1",
    "19_500_5034_15_1",
    "19_500_5035_15_1",
    "19_500_5036_15_1",
    "19_500_5037_15_1",
    "19_500_5038_15_1",
    "19_500_5039_15_1",
    "19_500_5040_15_1",
    "19_500_5041_15_1",
    "19_500_5042_15_1",
    "19_500_5043_15_1",
    "19_500_5044_15_1",
    "19_500_5045_15_1",
    "19_500_5046_15_1",
    "19_500_5047_15_1",
    "19_500_5048_15_1",
    "19_500_5049_15_1",
    "19_500_5050_15_1",
    "19_500_5051_15_1",
    "19_501_5052_15_1",
    "19_501_5053_15_1",
    "19_501_5054_15_1",
    "19_501_5055_15_1",
    "19_501_5056_15_1",
    "19_501_5057_15_1",
    "19_501_5058_15_1",
    "19_501_5059_15_1",
    "19_501_5060_15_1",
    "19_501_5061_15_1",
    "19_501_5062_15_1",
    "19_501_5063_15_1",
    "19_501_5064_15_1",
    "19_501_5065_15_1",
    "19_501_5066_15_1",
    "19_501_5067_15_1",
    "19_502_5068_15_1",
    "19_502_5069_15_1",
    "19_502_5070_15_1",
    "19_502_5071_15_1",
    "19_502_5072_15_1",
    "19_502_5073_15_1",
    "19_502_5074_15_1",
    "19_502_5075_15_1",
    "19_503_5076_15_1",
    "19_503_5077_15_1",
    "19_503_5078_15_1",
    "19_503_5079_15_1",
    "19_503_5080_15_1",
    "19_503_5081_15_1",
    "19_503_5082_15_1",
    "19_503_5083_15_1",
    "19_503_5084_15_1",
    "19_503_5085_15_1",
    "19_503_5086_15_1",
    "19_503_5087_15_1",
    "19_503_5088_15_1",
    "19_503_5089_15_1",
    "19_503_5090_15_1",
    "19_503_5091_15_1",
    "19_503_5092_15_1",
    "19_503_5093_15_1",
    "19_503_5094_15_1",
    "19_503_5095_15_1",
    "19_503_5096_15_1",
    "19_503_5097_15_1",
    "19_503_5098_15_1",
    "19_503_5099_15_1",
    "19_503_5100_15_1",
    "19_503_5101_15_1",
    "19_503_5102_15_1",
    "19_503_5103_15_1",
    "19_504_5104_15_1",
    "19_504_5105_15_1",
    "19_504_5106_15_1",
    "19_504_5107_15_1",
    "19_504_5108_15_1",
    "19_504_5109_15_1",
    "19_504_5110_15_1",
    "19_504_5111_15_1",
    "19_504_5112_15_1",
    "19_504_5113_15_1",
    "19_504_5114_15_1",
    "19_504_5115_15_1",
    "19_504_5116_15_1",
    "19_504_5117_15_1",
    "19_504_5118_15_1",
    "19_504_5119_15_1",
    "19_505_5120_15_1",
    "19_505_5121_15_1",
    "19_505_5122_15_1",
    "19_505_5123_15_1",
    "19_505_5124_15_1",
    "19_505_5125_15_1",
    "19_505_5126_15_1",
    "19_505_5127_15_1",
    "19_505_5128_15_1",
    "19_505_5129_15_1",
    "19_505_5130_15_1",
    "19_505_5131_15_1",
    "19_505_5132_15_1",
    "19_505_5133_15_1",
    "19_505_5134_15_1",
    "19_505_5135_15_1",
    "19_505_5136_15_1",
    "19_505_5137_15_1",
    "19_505_5138_15_1",
    "19_505_5139_15_1",
    "19_505_5140_15_1",
    "19_505_5141_15_1",
    "19_505_5142_15_1",
    "19_505_5143_15_1",
    "19_505_5144_15_1",
    "19_505_5145_15_1",
    "19_505_5146_15_1",
    "19_505_5147_15_1",
    "19_505_5148_15_1",
    "19_505_5149_15_1",
    "19_505_5150_15_1",
    "19_505_5151_15_1",
    "19_505_5152_15_1",
    "19_505_5153_15_1",
    "19_505_5154_15_1",
    "19_505_5155_15_1",
    "19_506_5156_15_1",
    "19_506_5157_15_1",
    "19_506_5159_15_1",
    "19_506_5160_15_1",
    "19_506_5161_15_1",
    "19_506_5163_15_1",
    "19_506_5164_15_1",
    "19_506_5165_15_1",
    "19_506_5167_15_1",
  ],
};
export default ids;
