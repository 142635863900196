//it is my lodash
import _ from "lodash";
import moment from "moment";
import { findCompany, findSite, firstCompany } from "./attribute";
import { timeFormat } from "./time";
import * as XLSX from "xlsx";
import commaNumber from "comma-number";

//returns something from the array which object doesnot have
export function objDontHave(obj, arr = Object.keys(obj), zeroCheck = true) {
  for (let i = 0; i < arr.length; i++) {
    if (!_.has(obj, arr[i])) return arr[i];
    if (obj[arr[i]] === "") return arr[i];
    if (obj[arr[i]] === undefined || obj[arr[i]] === null) return arr[i];
  }
  return false;
}

export function valuesNotNull(obj, arr = Object.keys(obj)) {
  for (let i = 0; i < arr.length; i++) {
    if (obj[arr[i]] === "") return arr[i];
  }
  return false;
}

export const downloadFile = async (url, name) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const url2 = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url2;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
};

export function aggregateDataByXValue(data, xField, yField) {
  return data.reduce((acc, item) => {
    const index = acc.findIndex((d) => d[xField] === item[xField]);
    if (index !== -1) {
      acc[index][yField] += item[yField];
    } else {
      acc.push({ ...item });
    }
    return acc;
  }, []);
}

export function sortByDate(a, b) {
  return moment(a["date"], timeFormat).toDate().getTime() - moment(b["date"], timeFormat).toDate().getTime();
}
export function sortByDateCustom(a, b, name) {
  return moment(a[name], timeFormat).toDate().getTime() - moment(b[name], timeFormat).toDate().getTime();
}
export function sortByNumber(field) {
  return (b, a) => {
    return (a[field] || 0) - (b[field] || 0);
  };
}

export function filterObjectsByObj(data, filters) {
  data = data.map((r) => {
    let currentDate = moment(r.date, timeFormat);
    let starting_month = moment(findCompany(findSite(r.siteId).companyId).reporting_year_starting_month, "MMMM").format("M") - 1;
    currentDate.subtract(starting_month, "month");
    return { ...r, date: currentDate.format(timeFormat) };
  });
  return data.filter((o) => Object.keys(filters).every((k) => filters[k] === o[k]));
}

export function reverseObj(obj) {
  const keys = Object.entries(obj).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});
  return keys;
}

export function removeBracketContents(str) {
  if (str) return str.replace(/\s*\([^)]*\)\s*/g, "").trim();
  else return str;
}

export function appendNameToProperties(obj, name) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const newName = name + prop;
      obj[newName] = obj[prop];
      delete obj[prop];
    }
  }
  return obj;
}

export function separateObject(originalObj, propsArray) {
  const matchedProps = {};
  const unmatchedProps = {};

  Object.keys(originalObj).forEach((key) => {
    if (propsArray.includes(key)) {
      matchedProps[key] = originalObj[key];
    } else {
      unmatchedProps[key] = originalObj[key];
    }
  });

  return [matchedProps, unmatchedProps];
}

export function removeDuplicates(list) {
  return list.filter((item, index, self) => {
    // Check if the current item is the first occurrence in the list
    return (
      index ===
      self.findIndex((obj) => {
        // Deep comparison of objects
        return JSON.stringify(obj) === JSON.stringify(item);
      })
    );
  });
}

export function removeDuplicatesFast(list) {
  const seen = new Set();
  return list.filter((item) => {
    const itemString = JSON.stringify(item);
    if (seen.has(itemString)) {
      return false;
    } else {
      seen.add(itemString);
      return true;
    }
  });
}

export function filterListByAnotherList(mainList, filterList, key) {
  return mainList.filter((item) => filterList.some((filterItem) => filterItem[key] === item[key]));
}

export const sumByKey = (arrOfObjs, key = "result") => {
  let sum = 0;
  arrOfObjs?.forEach((obj) => {
    if (obj?.[key]) {
      sum += obj[key];
    }
  });
  return parseFloat(sum.toFixed(2));
};

export function matchObjects(obj1, obj2) {
  try {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      // Base case: if both objects are primitive types, compare them directly
      return obj1 === obj2;
    }

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      // Recursive case: handle arrays
      if (obj1.length !== obj2.length) {
        return false;
      }

      for (let i = 0; i < obj1.length; i++) {
        if (!matchObjects(obj1[i], obj2[i])) {
          return false;
        }
      }

      return true;
    }

    if (!Array.isArray(obj1) && !Array.isArray(obj2)) {
      // Recursive case: handle objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (!obj2.hasOwnProperty(key) || !matchObjects(obj1[key], obj2[key])) {
          return false;
        }
      }

      return true;
    }

    // Objects have different types
    return false;
  } catch (err) {
    return false;
  }
}

export function generateRandomString(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const randomChars = _.sampleSize(characters, length);
  return randomChars.join("");
}

export function changeObjectsKeys(array, keyTransform) {
  return array.map((obj) => {
    const newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = keyTransform(key);
        newObj[newKey] = obj[key];
      }
    }
    return newObj;
  });
}

export function intersection(arr1, arr2) {
  try {
    return arr1.filter((element) => arr2.includes(element));
  } catch (err) {
    return [];
  }
}

export function getUniqueProps(array, prop) {
  const propValues = array.map((obj) => obj[prop]);
  const uniqueProps = [...new Set(propValues)];
  return uniqueProps;
}

/**
 * Groups objects in an array based on a specified property.
 * @param {Array} array - The array of objects to be grouped.
 * @param {string} property - The property name to group the objects by.
 * @returns {Object} - An object with keys representing unique property values and values representing arrays of corresponding objects.
 */
export function groupObjectsByProperty(array, property) {
  return array.reduce((acc, obj) => {
    const value = obj[property];
    if (value !== undefined && value !== null) {
      if (acc[value]) {
        acc[value].push(obj);
      } else {
        acc[value] = [obj];
      }
    }
    return acc;
  }, {});
}

/**
 * Filters an array of objects by excluding objects whose properties (except those specified in the `except` array) match a certain value.
 * @param {Array} array - The array of objects to filter.
 * @param {*} value - The value to match against the properties.
 * @param {Array} except - The properties to exclude from the matching check.
 * @returns {Array} - The filtered array of objects.
 */
export function filterObjectsByPropertyValue(array, value, except) {
  return array.filter((obj) => {
    for (const prop in obj) {
      // Skip properties specified in the except array
      if (!except.includes(prop) && obj[prop] !== value) {
        return true;
      }
    }
    return false;
  });
}

export function removeProps(object, properties) {
  const copiedObject = { ...object };
  properties.forEach((prop) => delete copiedObject[prop]);
  return copiedObject;
}

export function cleanArray(arr) {
  return arr.map((obj) => {
    // Create a new object
    let newObj = {};
    // Iterate over the keys of the object
    for (let key in obj) {
      // Check if the value is not NaN
      if (typeof obj[key] === "string" || !isNaN(obj[key])) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}

export function removePropsFromArray(array, properties) {
  // remove props from objects of an array
  return array.map((element) => removeProps(element, properties));
}

export function removeIndexes(array, arrayOfIndexes) {
  // Create a new array to store the result
  const result = [];

  // Iterate over each element in the original array
  for (let i = 0; i < array.length; i++) {
    // Check if the current index is present in the array of indexes
    if (!arrayOfIndexes.includes(i)) {
      // If the index is not present, add the element to the result array
      result.push(array[i]);
    }
  }

  // Return the resulting array with indexes removed
  return result;
}

export function addRankings(objects, objects2) {
  // used in react dnd to update the object with new rankings
  return objects.map((obj, index) => {
    const newObj = { ...obj }; // Create a new object to avoid modifying the original object

    const matchingObj = objects2.find((o) => o.id === newObj.id); // Find the matching object in objects2
    if (matchingObj) {
      newObj.sortingnum = index + 1; // Add newPosition property based on the current index
    }

    return newObj;
  });
}

export function removePropertyKeyNested(obj, propKey = "key") {
  // often time we add the key in react, so we don't want to update it as well.
  if (Array.isArray(obj)) {
    // If the input is an array, iterate over each element
    for (let i = 0; i < obj.length; i++) {
      removePropertyKeyNested(obj[i], propKey); // Recursively call removePropertyKey for each element
    }
  } else if (typeof obj === "object" && obj !== null) {
    // If the input is an object, iterate over each property
    for (let key in obj) {
      if (key === propKey) {
        delete obj[key]; // Remove the property if the key matches
      } else {
        removePropertyKeyNested(obj[key], propKey); // Recursively call removePropertyKey for each property value
      }
    }
  }
}

export function fixDecimals(number, max = 2, addCommas = false, minDecimals = 2) {
  if (Number.isFinite(number) && !Number.isInteger(number)) {
    // Get the decimal part length
    const decimalCount = number.toString().split(".")[1]?.length || 0;

    // Fix the number to the specified maximum decimals if needed
    if (decimalCount > max) {
      number = parseFloat(number.toFixed(max));
    }

    // Pad the number with minimum decimals if needed
    if (minDecimals > -1 && decimalCount < minDecimals) {
      number = number.toFixed(minDecimals);
    }
  } else if (Number.isInteger(number) && minDecimals > 0) {
    // If it's an integer, add the minimum decimals as needed
    number = number.toFixed(minDecimals);
  }

  // Add commas if the flag is set
  return addCommas ? commaNumber(number) : number;
}

export function filterStringsBySubstrings(strings, substrings) {
  try {
    return strings.filter((string) => !substrings.some((substring) => string.includes(substring)));
  } catch (err) {
    console.error(err);
    return strings;
  }
}
export function transformKeys(objects, transformFn) {
  if (objects)
    return objects.map((obj) => {
      const transformedObj = {};
      for (let key in obj) {
        const transformedKey = transformFn(key);
        transformedObj[transformedKey] = obj[key];
      }
      return transformedObj;
    });
}

export function arrayToObject(arrayOfObjects) {
  return arrayOfObjects.reduce((result, obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      result[key] = [...(result[key] || []), value];
    });
    return result;
  }, {});
}

export function combineExcelProps(list, prop) {
  for (let obj of list) {
    const propList = [];
    for (let i = 0; i <= 10; i++) {
      const propName = i !== 0 ? `${prop}_${i}` : prop;
      if (obj.hasOwnProperty(propName) && obj[propName]) {
        propList.push(obj[propName]);
      }
      delete obj[propName];
    }
    obj.form = propList;
  }
  return list;
}

export const cleanAndMatchSubstring = (mainString, substringToMatch) => {
  try {
    mainString
      ?.toLowerCase()
      .replace(/[^a-z]/g, "")
      .includes(substringToMatch?.toLowerCase().replace(/[^a-z]/g, ""));
  } catch (err) {}
};

export function convertToTitleCase(inputString) {
  // Convert the first character of the string to uppercase
  let titleCaseString = inputString.charAt(0).toUpperCase() + inputString.slice(1);

  // Add spaces before uppercase characters (except the first character)
  titleCaseString = titleCaseString.replace(/([A-Z])/g, " $1");

  return titleCaseString;
}

export function getYearFromDateFormatWithMonth(dateString, monthName) {
  const [day, month, year] = dateString.split("/");
  const givenMonth = monthNamesToNumber(monthName);
  const currentMonth = 7; // January is 0 in JavaScript, so we add 1
  if (givenMonth < currentMonth) {
    return parseInt(year, 10);
  }
  return parseInt(year, 10) + 1;
}

function monthNamesToNumber(monthName) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthIndex = months.findIndex((month) => month.toLowerCase() === monthName.toLowerCase());
  return monthIndex !== -1 ? monthIndex + 1 : null;
}

export function downloadXLSX(name, data, divideByProperty = null, replacementRow = null, moresheets = [], headerNames = undefined) {
  const convertJSONToXLSX = (jsonList) => {
    const createWorksheet = (data) => {
      const worksheet = XLSX.utils.json_to_sheet(data, { header: headerNames });
      try {
        // Calculate and set column widths based on header lengths
        const columnWidths = [];
        for (const key in data[0]) {
          const columnLength = XLSX.utils.encode_col(XLSX.utils.decode_col(String(key)) + 1).length;
          columnWidths.push({ wch: Math.max(10, columnLength) }); // Minimum width is set to 10
        }
        worksheet["!cols"] = columnWidths;

        return worksheet;
      } catch (err) {
        return worksheet;
      }
    };

    let workbook;
    if (divideByProperty) {
      workbook = XLSX.utils.book_new();
      const dividedData = {};

      // Divide the data based on the provided property
      jsonList.forEach((item) => {
        const propertyValue = item[divideByProperty].slice(0, 30);
        if (!dividedData[propertyValue]) {
          dividedData[propertyValue] = [item];
        } else {
          dividedData[propertyValue].push(item);
        }
      });

      // Create separate sheets for each property value
      Object.keys(dividedData).forEach((propertyValue) => {
        const worksheet = createWorksheet(dividedData[propertyValue]);
        XLSX.utils.book_append_sheet(workbook, worksheet, propertyValue);
      });
    } else {
      const worksheet = createWorksheet(jsonList);
      workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Results");
      moresheets.forEach((sheet) => {
        const worksheet = createWorksheet(sheet.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
      });
    }

    const excelBuffer = XLSX.write(workbook, { type: "array" });
    return new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  };

  const xlsxData = convertJSONToXLSX(data);
  const url = URL.createObjectURL(xlsxData);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${name}.xlsx`;
  link.click();
  URL.revokeObjectURL(url);
}

export function areAllNumericalValuesZero(obj) {
  const nums = Object.values(obj).filter((value) => typeof value === "number");
  if (!nums.length) return true;
  for (let value of nums) {
    if (value > 0) {
      return true;
    }
  }
  return false;
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function countOccurrences(mainString, subString) {
  const regex = new RegExp(subString, "g");
  const matches = mainString.match(regex);

  if (matches) {
    return matches.length;
  } else {
    return 0;
  }
}
export default function flattenArray(arr) {
  return arr.flat(1);
}

export function fillArray(arr, mode = "forwardFill", replaceArray = [null]) {
  const a = JSON.parse(JSON.stringify(arr));
  if (!Array.isArray(a)) {
    throw new Error("Input must be an array.");
  }
  if (!Array.isArray(replaceArray)) {
    throw new Error("replaceArray must be an array.");
  }

  const shouldReplace = (value) => replaceArray.includes(value);

  if (mode === "forwardFill") {
    // Fill empty slots (replaceable values) with the last seen value.
    return a.map((value, index) => {
      if (shouldReplace(value)) {
        let i = index - 1;
        while (i >= 0 && shouldReplace(a[i])) i--; // Look for the last non-replaceable value
        return i >= 0 ? a[i] : value; // Use it if found, otherwise stay as is
      }
      return value;
    });
  }

  if (mode === "backwardFill") {
    // Fill empty slots (replaceable values) with the next non-replaceable value.
    const result = [...a];
    for (let i = a.length - 1; i >= 0; i--) {
      if (shouldReplace(result[i])) {
        let j = i + 1;
        while (j < a.length && shouldReplace(result[j])) j++; // Look ahead for the next value
        result[i] = j < a.length ? result[j] : result[i]; // Use it if found, otherwise stay as is
      }
    }
    return result;
  }

  if (mode === "smooth") {
    // Gradually fill replaceable slots with evenly spaced values.
    const result = [...a];
    let start = null,
      end = null;

    for (let i = 0; i < a.length; i++) {
      if (shouldReplace(a[i])) {
        if (start === null) start = i - 1; // Mark the start of a replaceable sequence
      } else if (start !== null) {
        end = i; // Found the end of the sequence
        const step = (a[end] - a[start]) / (end - start); // Calculate the step
        for (let j = start + 1; j < end; j++) {
          result[j] = parseFloat((result[start] + step * (j - start)).toFixed(2)); // Fill values
        }
        start = end = null; // Reset markers
      }
    }
    return result;
  }
}

export function shortenArray(arr, shouldTrim = true, replaceArray = [0, null, undefined], replaceValue = null) {
  if (!Array.isArray(arr)) {
    throw new Error("Input must be an array");
  }

  // Replace specified values with `replaceValue`
  const processedArray = arr.map((item) => (replaceArray.includes(item) ? replaceValue : item));

  // Trim trailing `replaceValue` from the array
  if (shouldTrim)
    while (processedArray.length > 0 && processedArray[processedArray.length - 1] === replaceValue) {
      processedArray.pop();
    }

  return processedArray;
}
