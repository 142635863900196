//contains all of the app assets related management and tools

import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    state: window.location.href.includes("sustraxmx-offline") ? "trial" : "", // offline || online
    sites: undefined,
    groups: undefined,
    companies: undefined,
    users: undefined,
    emissionFactors: undefined, // list of objects
    results: undefined, //the emission results
    theme: undefined, //dark, light etc
    companiesFilter: [],
    warnings: {},
    affiliate: undefined,
    other: {}, //for keeping the data which we get in app like faq etc, that doesnt need much changing
  },
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
      localStorage.setItem("theme", JSON.stringify(action.payload));
    },
    setAffiliate: (state, action) => {
      state.affiliate = action.payload;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
    setSites: (state, action) => {
      const sites = action.payload;
      if (sites === undefined) {
        state.sites = undefined;
      } else if (Array.isArray(sites)) {
        state.sites = sites.sort((a, b) => a.index - b.index);
      }
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setEmissionFactors: (state, action) => {
      state.emissionFactors = action.payload;
      // console.info("Emissions Factors are set.", action.payload);
    },
    setCompaniesFilter: (state, action) => {
      state.companiesFilter = action.payload;
      console.info("Companies Filter are set.", action.payload);
    },
    setWarning: (state, action) => {
      state.warnings[action.payload.key] = action.payload.value;
    },
    setOther: (state, action) => {
      state.other[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setTheme,
  setCompanies,
  setGroups,
  setSites,
  setUsers,
  setResults,
  setEmissionFactors,
  setWttEmissionFactors,
  setEmissionCodes,
  setWTTEmissionCodes,
  setCompaniesFilter,
  setWarning,
  setOther,
  setAffiliate,
} = appSlice.actions;

//themes

export const selectTheme = (state) => {
  if (!localStorage.getItem("theme")) return {};
  try {
    if (localStorage.getItem("theme")) return JSON.parse(localStorage.getItem("theme"));
  } catch (err) {
    return {};
  }
};
export const selectAffiliate = (state) => {
  return state.app.affiliate;
};
export const selectCompanies = (state) => (state.app.companiesFilter.length ? state.app.companies.filter((company) => !state.app.companiesFilter.includes(company.id)) : state.app.companies);

export const selectOther = (what) => (state) => {
  return state.app.other[what];
};
export const selectCompaniesFilter = (state) => state.app.companiesFilter;
export const selectState = (state) => state.app.state;
export const selectSites = (state) => state.app.sites;
export const selectUsers = (state) => state.app.users;
export const selectResults = (state) => state.app.results;
// Selector with filtering by name
export const selectFilteredResults = (state, name) => {
  const results = selectResults(state); // Get results from state
  try {
    return results
      .filter((item) => item.name === name) // Filter by name
      .map((result) => ({
        ...result,
        createdOrUpdatedAt: result.updatedAt ?? result.createdAt, // Add createdOrUpdatedAt
      }));
  } catch (err) {
    return [];
  }
};

export const selectFactors = (state) => state.app.emissionFactors;
export const selectWttFactors = (state) => state.app.wttEmissionFactors;
export const selectWttEmissionCodes = (state) => state.app.wttEmissionCodes;
export const selectEmissionCodes = (state) => state.app.emissionCodes;
export const selectWarnings = (state) => state.app.warnings;

export default appSlice.reducer;
