import { AlertOutlined, ArrowRightOutlined, DeleteOutlined, EditOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Input, Modal, Select, Space } from "antd";
import lodash from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUserFunction } from "../../../firebase/user/delete";
import { updateUser } from "../../../firebase/user/update";
import { catcher, except } from "../../../firebase/util";

export default function DeleteUser({ _: user }) {
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
  });
  const onOk = async () => {
    await catcher(
      async () => {
        await deleteUserFunction(user.id);
      },
      { setLoading: setLoading, success_msg: "Deleted successfully" }
    );
    setVisible(false);
  };
  return (
    <>
      <span type="ghost" onClick={() => setVisible(true)} danger style={{ fontSize: "20px" }}>
        <DeleteOutlined />
      </span>
      <Modal
        title="Delete User"
        okText={
          <>
            Delete <DeleteOutlined />
          </>
        }
        okButtonProps={{ loading }}
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={onOk}
      >
        <Alert type="warning" showIcon message="This action is irreversible"></Alert>
        <br />
        Do you confirm to delete{" "}
        <b>
          {user.firstName} {user.lastName}
        </b>
        ?
      </Modal>{" "}
    </>
  );
}
