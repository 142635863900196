import React, { useEffect, useState } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import { findSite } from "../../../helper/attribute";
import { genPut } from "../helper/create";

export const INPUTS = (data, setData, formname) => [
  {
    key: "date",
    elem: DateInput,
  },
  {
    key: "supplier_factors",
    elem: NumberInput,
    min: 0,
  },
  {
    key: "amount",
    elem: NumberInput,
  },
];

export default function Electricity({ data, setData }) {
  const formname = "electricity";
  const [SITEUPDATECOUNT, setSITEUPDATECOUNT] = useState(0);
  useEffect(() => {
    setData({
      ...data,
      q1: "Yes",
      supplier_factors:
        !SITEUPDATECOUNT && data.hasOwnProperty("supplier_factors")
          ? data["supplier_factors"]
          : findSite(data.siteId).supplier_factors ?? null,
    });
    setSITEUPDATECOUNT(SITEUPDATECOUNT + 1);
  }, [data.siteId]);

  return (
    <>
      {INPUTS(data, setData, formname).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </>
  );
}
