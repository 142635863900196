import React from "react";
import { Space, Table } from "antd";
import { findCompany, findUserById } from "../../../helper/attribute";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const GroupTable = ({ data, handleEdit, handleDelete }) => {
  // Columns configuration for the table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Admins",
      dataIndex: "admins",
      key: "admins",
      render: (admins) => (
        <div>
          {admins
            .map(
              (id) =>
                findUserById(id)?.firstName + " " + findUserById(id)?.lastName
            )
            .join(", ")}
        </div>
      ),
    },
    {
      title: "Companies",
      dataIndex: "companies",
      key: "companies",
      render: (companies) => (
        <div>{companies.map((c) => findCompany(c).title).join(", ")}</div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Options",
      key: "options",
      render: (_) => (
        <Space>
          <EditOutlined onClick={() => handleEdit(_.id)} />
          <DeleteOutlined onClick={() => handleDelete(_.id)} />
        </Space>
      ),
    },
  ];

  return <Table dataSource={data} columns={columns} />;
};

export default GroupTable;
