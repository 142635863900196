import { Button, Input, message, Space, Table } from "antd";
import { useState } from "react";
import ConfirmNTimesButton from "../../../components/confirm/ConfirmNTimesButton";
import Loading from "../../../components/loading";
import { updateConfig } from "../../../firebase/appconfig/update";

export const GHG_Categories = ({ data }) => {
  const categories = data.ghg_categories;
  const [loading, setLoading] = useState(false);
  const [orignalData, setOrignalData] = useState(
    JSON.parse(JSON.stringify(categories))
  );
  const [updatedData, setUpdatedData] = useState(
    JSON.parse(JSON.stringify(categories))
  );

  const handleTitleChange = (code, value) => {
    updatedData[code - 1].title = value;
    setUpdatedData(JSON.parse(JSON.stringify(updatedData)));
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateConfig(updatedData, "ghg_categories");
      setOrignalData(JSON.parse(JSON.stringify(updatedData)));
      message.info("Data Updated Successfully!");
    } catch (err) {
      if (err.message) message.error("Error!" + err.message);
      else message.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    setUpdatedData(JSON.parse(JSON.stringify(orignalData)));
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      width: "10%",
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleTitleChange(record.code, e.target.value)}
        />
      ),
    },
  ];
  if (loading) return <Loading title="Updating data!" />;

  return (
    <>
      <Table
        scroll={{ y: 500 }}
        title={() => {
          return (
            JSON.stringify(updatedData) != JSON.stringify(orignalData) && (
              <Space style={{ marginBottom: 10 }}>
                <ConfirmNTimesButton
                  onFinalClick={handleUpdate}
                  messages={[
                    "Update",
                    "Please confirm Update",
                    "The data will be changed for all application, Please confirm it again!",
                  ]}
                />
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            )
          );
        }}
        columns={columns}
        dataSource={updatedData}
        pagination={false}
      />
    </>
  );
};
