import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import * as XLSX from "xlsx";
import {
  matchLocalUpdateProp,
  setLocalUpdateProp,
} from "../../helper/attribute";
import { getCurrentTime } from "../../helper/time";
import { combineExcelProps, downloadFile } from "../../helper/wodash";
import { setEmissionFactors } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { updateConfig } from "../appconfig/update";
import { storage } from "../setup";
import { message } from "antd";

const localKey = "emission_factors";

const setFactors = (data) => {
  const workbook = XLSX.read(data, { type: "array" });

  const emissionFactors = {};
  workbook.SheetNames.forEach((sheetName) => {
    const sheet = workbook.Sheets[sheetName];
    const sheetData = combineExcelProps(
      XLSX.utils.sheet_to_json(sheet, {
        header: 0,
        defval: null,
        blankrows: false,
      }),
      "form"
    );

    emissionFactors[sheetName] = sheetData;
  });

  store.dispatch(setEmissionFactors(emissionFactors));
  localStorage.setItem(localKey, JSON.stringify(emissionFactors));
  setLocalUpdateProp(localKey);
};

export async function fetchEmissionFactors(
  path = "assets/Emissions Factors.xlsx"
) {
  const key = 'loading'; // Unique key for the loading message
  const factors = localStorage.getItem(localKey);
  
  if (matchLocalUpdateProp(localKey) && factors) {
    store.dispatch(setEmissionFactors(JSON.parse(factors)));
  } else {
    // message.loading({ content: 'Loading configurations...', key, duration: 0 });

    try {
      const url = await getDownloadURL(ref(storage, path));
      const response = await fetch(url);
      const data = await response.arrayBuffer();
      setFactors(data);
      // message.success({ content: 'Loaded!', key, duration: 2 });
    } catch (error) {
      console.error(error);
      message.error({ content: 'Failed to load configurations', key, duration: 2 });
    }
  }
}

export async function fetchEmissionCodes() {}

export async function uploadEmissionFactors(
  file,
  path = "assets/Emissions Factors.xlsx"
) {
  await uploadBytes(ref(storage, path), file);
  await updateConfig(
    {
      ...store.getState().config.updates,
      emission_factors: getCurrentTime(),
    },
    "updates"
  );
}

export async function downloadEmissionFactorsFile(
  path = "assets/Emissions Factors.xlsx"
) {
  const url = await getDownloadURL(ref(storage, path));
  downloadFile(url, "Emissions Factors.xlsx");
}

export const fetchFirstFile = async (url) => {
  const { items } = await listAll(ref(storage, url));
  const downloadUrl = await getDownloadURL(items[0]);
  downloadFile(downloadUrl, items[0].name);
};
