import { SettingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Table, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAffiliates } from "../../../firebase/affiliate/get";
import { catcher } from "../../../firebase/util";
import { createAffiliate } from "../../../firebase/affiliate/create";
import { useNavigate } from "react-router-dom";
import { routePath } from "../../../static/routes.static";

const Add = ({ fetch }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await catcher(async () => {
      const { title } = await form.validateFields();
      if (window.confirm("Do you want to create " + title + " as affiliate?")) {
        await createAffiliate({ title });
        form.resetFields(); // Reset the form fields
        setIsModalVisible(false); // Close the modal
        fetch();
      }
    }, {});
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add
      </Button>
      <Modal
        title="Enter Title"
        open={isModalVisible}
        onOk={async () => await handleOk()}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Please input the title!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default function Affiliates() {
  const [afs, setAfS] = useState(undefined);
  const navigate = useNavigate();
  const fetch = async () => {
    await catcher(async () => {
      const af = await fetchAffiliates();
      setAfS(af);
      console.log(af);
    }, {});
  };
  useEffect(() => {
    fetch();
  }, []);
  const handleSettings = (af) => {
    navigate(routePath.editAffiliate(af?.id));
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id", // This should match the key in your data source
      key: "id", // Unique key for identifying this column
    },
    {
      title: "Title",
      dataIndex: "title", // This should match the key in your data source
      key: "title", // Unique key for identifying this column
    },
    {
      title: "Created on",
      dataIndex: "createdAt", // This should match the key in your data source
      key: "createdAt", // Unique key for identifying this column
    },
    {
      title: "Settings",
      key: "title", // Unique key for identifying this column
      render: (_) => {
        return (
          <div>
            <SettingOutlined
              onClick={() => {
                handleSettings(_);
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Typography.Title level={3}>Affiliates</Typography.Title>
      <div>
        <Add fetch={fetch} />
        <Table loading={!afs} columns={columns} dataSource={afs}></Table>
      </div>
    </div>
  );
}
