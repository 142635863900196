import { Select } from "antd";
import { useRef } from "react";
import FakeLabel from "../input/select";
const { Option } = Select;

export default function SimpleSelect({ value, setValue, options, allowDefault, title, listHeight = 256, optionbg = null, dropdownStyle = {}, ...more }) {
  const defaultOption = allowDefault ? [{ label: "Default", value: null }] : [];
  const allOptions = [...defaultOption, ...options];

  const elem = (
    <Select listHeight={listHeight} dropdownStyle={dropdownStyle} value={value} onChange={(val) => setValue(val)} placeholder="Select an option" options={allOptions} dropdownMatchSelectWidth={true} />
  );

  if (more.elemOnly) return elem;
  return <FakeLabel {...more} title={title} elem={elem} />;
}
