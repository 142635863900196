import React from "react";
import { Radio, Input, Select, Switch, Tooltip } from "antd";
import { InfoCircleOutlined, QuestionCircleTwoTone } from "@ant-design/icons";

const { Option } = Select;

const Settings = ({ config, setConfig }) => {
  const handleConfigChange = (key, newValue) => {
    const updatedConfig = {
      ...config,
      [key]: { ...config[key], value: newValue },
    };
    setConfig(updatedConfig);
  };

  return (
    <div>
      {Object.entries(config).map(
        ([key, { value, type, options, title, info, ...more }]) => (
          <div key={key}>
            <span style={{ fontWeight: more.bold ? 600 : 500 }}>
              {title || key}{" "}
              <span>
                {info && (
                  <Tooltip title={info}>
                    <QuestionCircleTwoTone />
                  </Tooltip>
                )}
              </span>{" "}
            </span>
            {type === "boolean" ? (
              <Switch
                checked={value}
                onChange={(newValue) => handleConfigChange(key, newValue)}
              />
            ) : type === "array" ? (
              <Select
                value={value}
                onChange={(newValue) => handleConfigChange(key, newValue)}
              >
                {options.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            ) : (
              <Input
                value={value}
                onChange={(e) => handleConfigChange(key, e.target.value)}
              />
            )}
          </div>
        )
      )}
    </div>
  );
};

export default Settings;
