//helper for airport data
import airportData from "../assets/airportData.json";
import { extractYear } from "../pages/form/helper/getter";
import { filterFactors } from "./emissionfactors";

export const questions1 = ["Select Airports", "Enter Distances"];

const RADIANS = (x) => {
  return (Number(x) * Math.PI) / 180;
};

export const CalculateFlightResult = (data, returnCode = false, type = "simple" /*or wtt */) => {
  let level2 = "Flights";
  let row,
    distance = 0;
  if (type == "wtt") {
    level2 = "WTT- flights";
  } else {
    level2 = "Flights";
  }
  let year = extractYear(data);

  if (data.q1 === questions1[0]) {
    if (!data.departure_airport || !data.destination_airport) throw "Please input airports";

    row = filterFactors(
      {
        "Level 4": data.flight_class,
        "Level 3": FlightType(data),
        "Level 2": level2,
      },
      returnCode ? "ID" : year
    );
    distance = AirportsDistance(data);
  } else {
    if (!data.flight_class || !data.flight_type) throw "Please input Flight Class and Type";
    row = filterFactors(
      {
        "Level 4": data.flight_class,
        "Level 3": data.flight_type,
        "Level 2": level2,
      },
      returnCode ? "ID" : year
    );
    distance = data.distance;
  }
  if (returnCode) return row[0];
  const ghg = row[0];
  let result = ghg * distance * data.passengers;
  if (data.return_ticket.toLowerCase() == "yes") result *= 2;
  result /= 1000; //tonne conversion
  if (!result && data.flight_class !== flightClasses[0]) {
    // means the flight class is not present so use Average
    return CalculateFlightResult({ ...data, flight_class: flightClasses[0] }, returnCode, type);
  }
  return result;
};

export const convertIATA = (data) => {
  // if IATA (3-letter code is used), match the first code only

  if (data.departure_airport) data.departure_airport = airportData.find((a) => a["Search Name"]?.substring(0, 3) === data.departure_airport?.substring(0, 3))?.["Search Name"];
  if (data.destination_airport) data.destination_airport = airportData.find((a) => a["Search Name"]?.substring(0, 3) === data.destination_airport?.substring(0, 3))?.["Search Name"];
  if (data.via_airport) data.via_airport = airportData.find((a) => a["Search Name"]?.substring(0, 3) === data.via_airport?.substring(0, 3))?.["Search Name"];
};

export const FlightType = ({ departure_airport, destination_airport }) => {
  try {
    if (!departure_airport || !destination_airport) return;

    const distance = AirportsDistance({
      departure_airport,
      destination_airport,
    });

    const { Country: depCountry } = airportData.find((a) => a["Search Name"]?.substring(0, 3) === departure_airport?.substring(0, 3));
    const { Country: destCountry } = airportData.find((a) => a["Search Name"]?.substring(0, 3) === destination_airport?.substring(0, 3));

    if (depCountry == "UK" && destCountry == "UK") {
      return "Domestic, to/from UK";
    } else if (depCountry == "UK" || destCountry == "UK") {
      if (distance >= 3000) {
        return "Long-haul, to/from UK";
      } else {
        return "Short-haul, to/from UK";
      }
    } else {
      return "International, to/from non-UK";
    }
  } catch (err) {
    return;
  }
};

export const AirportsDistance = ({ departure_airport, destination_airport }) => {
  if (!departure_airport || !destination_airport) return 0;

  if (departure_airport === destination_airport) return 0;

  const f1 = airportData.find((a) => a["Search Name"]?.substring(0, 3) === departure_airport?.substring(0, 3));
  const f2 = airportData.find((a) => a["Search Name"]?.substring(0, 3) === destination_airport?.substring(0, 3));

  if (!f1 || !f2) {
    throw "Airport not found";
  }
  const { Latitude: C27, Longitude: D27 } = f1;
  const { Latitude: C29, Longitude: D29 } = f2;

  // Calculate the distances
  const totalDistance = Math.acos(Math.sin(RADIANS(C27)) * Math.sin(RADIANS(C29)) + Math.cos(RADIANS(C27)) * Math.cos(RADIANS(C29)) * Math.cos(RADIANS(D29 - D27))) * 6371;

  return totalDistance ?? 0;
};
export const findAirportCode = (name) => {
  return airportData.find((airport) => airport["Search Name"] === name)?.["IATA Code"];
};

export const flightTypes = ["Domestic, to/from UK", "Long-haul, to/from UK", "Short-haul, to/from UK", "International, to/from non-UK"];

export const flightClasses = ["Average passenger", "Economy class", "Premium economy class", "Business class", "First class"];
