import React, { useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { filterFactors } from "../../../helper/emissionfactors";
import { findForm } from "../../../static/formRoutes";
import { genPut } from "../helper/create";

export const INPUTS = (data, setData) => {
  const formname = data.name;
  return [
    {
      elem: DateInput,
      key: "date",
    },
    {
      elem: SelectSearch,
      key: "category",
      setValue: (category) =>
        setData({ ...data, category, type: null, uom: null, columntext: null }),
      options: filterFactors({ form: formname }, "Level 2"),
    },
    {
      elem: SelectSearch,
      key: "type",
      hidden: !data.category,
      setValue: (type) =>
        setData({ ...data, type, uom: null, columntext: null }),
      options: filterFactors(
        { "Level 2": data.category, form: formname },
        "Level 3"
      ),
    },
    {
      elem: SelectSearch,
      key: "columntext",
      hidden: !data.type,
      setValue: (columntext) => setData({ ...data, columntext, uom: null }),
      options: filterFactors(
        { "Level 2": data.category, "Level 3": data.type, form: formname },
        "Column Text"
      ),
    },
    {
      elem: SelectSearch,
      hidden: !data.columntext,
      key: "q1",
      options: ["Yes", "No"],
    },
    {
      elem: SelectSearch,
      key: "uom",
      hidden: !data.columntext,
      options: [
        ...filterFactors(
          { "Level 3": data.type, "Level 2": data.category, form: formname },
          "UOM"
        ),
        "kg",
        "g",
      ],
    },
    {
      elem: NumberInput,
      key: "amount",
    },
  ];
};

export default function BulkMaterials({ data, setData }) {
  const formname = data.name;
  const ids = findForm(formname).id_range();

  useEffect(() => {
    setData({ ...data, q1: data.q1 || "No" });
  }, []);

  return (
    <div>
      {INPUTS(data, setData).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </div>
  );
}
