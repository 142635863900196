import { createSlice } from "@reduxjs/toolkit";

export const superAdminSlice = createSlice({
  name: "user",
  initialState: {
    reportedBugs: undefined,
    reportedFeatures: undefined,
    consultants: undefined, // Added consultants state
  },
  reducers: {
    setReportedBugs: (state, action) => {
      state.reportedBugs = action.payload;
    },
    setReportedFeatures: (state, action) => {
      state.reportedFeatures = action.payload;
    },
    setConsultants: (state, action) => {
      // Reducer to manage consultants state
      state.consultants = action.payload;
    },
  },
});

// Export the new action creator
export const { setReportedBugs, setConsultants, setReportedFeatures } = superAdminSlice.actions;

export default superAdminSlice.reducer;
