import { Typography } from "antd";
import React from "react";
import { fixDecimals } from "../../../../helper/wodash";
import useSize from "../../../../hooks/useSize";
import { tco2e } from "../../../../static/texts.static";
const InLineCards = ({ dataArray }) => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  if (!Array.isArray(dataArray)) {
    return <p>Error: Invalid data format.</p>;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 16,
        overflowX: "auto",
        width: "100%",
      }}
    >
      {dataArray.map(
        (item, index) =>
          item.name !== "Total" &&
          item.result !== Infinity && (
            <div
              key={index}
              style={{
                flex: "1",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography.Text
                style={{ fontSize: isExtraSmall ? "13px" : "15px", margin: 0 }}
              >
                {item.name} ({tco2e})
              </Typography.Text>
              <Typography.Title
                style={{
                  fontSize: isExtraSmall ? "22px" : isSmall ? "26px" : "30px",
                  margin: 0,
                }}
              >
                {fixDecimals(item.result, 2, true) || 0}
              </Typography.Title>
            </div>
          )
      )}
    </div>
  );
};

export default InLineCards;
