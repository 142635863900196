import { Space } from "antd";
import { T1 } from "../../appconfig/texts";
import { fixDecimals } from "../../helper/wodash";
import { isUndefined, result } from "lodash";
import commaNumber from "comma-number";
import { planValue } from "../../helper/plans";

export default function formatEmissions(emissions, min2) {
  try {
    if (isUndefined(emissions)) return "0.00 ";
    if (isNaN(emissions)) return <div style={{ color: "red" }}></div>;
    if (emissions < 0.01 && emissions > 0) return "<0.01";
    try {
      if (min2) return fixDecimals(emissions, 2)?.toFixed(2);
      else return fixDecimals(emissions, 2);
    } catch (err) {
      return fixDecimals(emissions, 2);
    }
  } catch (err) {
    return err.message;
  }
}

export const totalEmissions = (currentDataSource = [], justTheNumbers = false) => {
  // Helper function to sum values with proper handling of non-numeric cases

  if (planValue("onlyDisplayInputsInCombinedResults")) return "";
  const sum = (type = "result") => {
    return currentDataSource.reduce((acc, e) => {
      const value = parseFloat(e[type]);
      return acc + (!isNaN(value) ? value : 0);
    }, 0);
  };

  // Helper function to safely format decimals
  const formatDecimal = (value, decimals = 2) => {
    return !isNaN(value) ? value.toFixed(decimals) : "0.00";
  };

  const result_emissions = formatDecimal(sum() + sum("tnd"));
  const wtt_emissions = formatDecimal(sum("wtt") + sum("wtt_tnd"));

  // Check if there's no WTT emission
  if (!parseFloat(sum("wtt"))) {
    if (justTheNumbers) return { total_emissions: 0, result_emissions: 0, wtt_emissions: 0 };
    return (
      <div>
        Total Emissions = <b>{result_emissions}</b>
      </div>
    );
  }

  // Market-based emissions check and calculation
  let marketbased_result = parseFloat(sum("marketbased_result"));
  if (marketbased_result) {
    const marketbased_tnd = sum("marketbased_tnd");
    const marketbased_wtt = sum("marketbased_wtt");
    const marketbased_wtt_tnd = sum("marketbased_wtt_tnd");

    const locationbased_result = sum("result");
    const locationbased_tnd = sum("tnd");
    const locationbased_wtt = sum("wtt");
    const locationbased_wtt_tnd = sum("wtt_tnd");

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="borderedb">
          <T1 pos="combined.emissionsTotal.combinedMarketEmissions"></T1>
          <b>{formatDecimal(marketbased_result + marketbased_tnd + marketbased_wtt + marketbased_wtt_tnd)}</b> =<T1 pos="combined.emissionsTotal.generationTnd"></T1>
          <b>{formatDecimal(marketbased_result + marketbased_tnd)}</b> +<T1 pos="combined.emissionsTotal.wttGenerationTnd"></T1>
          <b>{formatDecimal(marketbased_wtt + marketbased_wtt_tnd)}</b>
        </div>
        <div className="borderedb">
          <T1 pos="combined.emissionsTotal.combinedLocationEmissions"></T1>
          <b>{formatDecimal(locationbased_result + locationbased_tnd + locationbased_wtt + locationbased_wtt_tnd)}</b> =<T1 pos="combined.emissionsTotal.generationTnd"></T1>
          <b>{formatDecimal(locationbased_result + locationbased_tnd)}</b> +<T1 pos="combined.emissionsTotal.wttGenerationTnd"></T1>
          <b>{formatDecimal(locationbased_wtt + locationbased_wtt_tnd)}</b>
        </div>
      </div>
    );
  }

  if (justTheNumbers) {
    return {
      total_emissions: parseFloat(result_emissions) + parseFloat(wtt_emissions),
      result_emissions,
      wtt_emissions,
    };
  }

  // Default return for combined emissions
  return (
    <Space direction="vertical">
      <div className="borderedb">
        <T1 pos="combined.emissionsTotal.combinedEmissions"></T1>
        <b>{formatDecimal(parseFloat(result_emissions) + parseFloat(wtt_emissions))}</b> =<T1 pos="combined.emissionsTotal.emissions"></T1>
        <b>{result_emissions}</b> +<T1 pos="combined.emissionsTotal.wttEmissions"></T1>
        <b>{wtt_emissions}</b>
      </div>
    </Space>
  );
};
