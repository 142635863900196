import { deleteDoc, doc, getBatch, writeBatch } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { setResults } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db, storage } from "../setup";
import { bulkDeleteResultsOffline, deleteResultOffline } from "./offline/delete_offline";

export const deleteResult = async (id) => {
  if (store.getState().app.state === "trial") return await deleteResultOffline(id);
  try {
    // Delete the Firestore document
    await deleteDoc(doc(db, firestoreStatic.results, id));

    // Delete the corresponding file in Firebase Storage
    deleteObject(ref(storage, `formdata/${id}`));

    
  } catch (error) {
    console.error("Error deleting result:", error);
    throw error; // You can handle the error as needed
  }
};

export const bulkDeleteResults = async (ids) => {
  if (store.getState().app.state === "trial") return await bulkDeleteResultsOffline(ids);
  const fileDeletes = [];
  try {
    const batch = writeBatch(db);

    for (const id of ids) {
      const resultDocRef = doc(db, firestoreStatic.results, id);
      fileDeletes.push(deleteObject(ref(storage, `formdata/${id}`)));

      batch.delete(resultDocRef);
    }

    await batch.commit();
    await Promise.allSettled(fileDeletes);
    store.dispatch(
      setResults(
        store
          .getState()
          .app?.results?.filter((result) => !ids?.includes(result.id))
      )
    );

    
  } catch (error) {
    console.error("Error deleting results:", error);
    throw error; // You can handle the error as needed
  }
};
