import { openIndexedDB } from "./indexedDbUtils";
import { getCurrentDate } from "../../../helper/time";
// import { updateHistory } from "./updateHistory";

/**
 * Updates a record in IndexedDB.
 * @param {string} id - The unique ID of the record to update.
 * @param {object} data - The updated data for the record.
 * @param {boolean} overwrite - Whether to overwrite the entire record or just update specific fields.
 * @returns {Promise<void>} - Resolves when the update is complete.
 */
export async function updateFormDataOffline(id, data, overwrite = false) {
  if (!("result" in data)) throw "Emission result not present";
  data["updatedAt"] = getCurrentDate();

  const dbName = "FormDataDB";
  const storeName = "FormDataStore";

  try {
    const db = await openIndexedDB(dbName, storeName);
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);

    // Retrieve the existing record
    const existingRecord = await new Promise((resolve, reject) => {
      const request = store.get(id);
      request.onsuccess = () => resolve(request.result);
      request.onerror = (event) => reject(event.target.error);
    });

    if (!existingRecord) throw `Record with ID ${id} not found`;

    // Determine whether to overwrite or update fields
    const updatedData = overwrite ? { ...data, id } : { ...existingRecord, ...data };

    // Handle file addition
    const files = data.file;
    if (files && typeof files !== "string") {
      updatedData.file = files.map((file) => (typeof file === "string" ? file : file.name));

      // Add new files as blobs
      if (!updatedData.files) updatedData.files = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file && typeof file !== "string") {
          updatedData.files.push({ name: file.name, blob: file });
        }
      }
    }

    // Save updated data back to IndexedDB
    await new Promise((resolve, reject) => {
      const request = store.put(updatedData);
      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(event.target.error);
    });

    console.log(`Data with ID ${id} updated successfully.`);
    // await updateHistory(id, updatedData, "result_update");
  } catch (error) {
    console.error("Error updating form data:", error);
    throw error;
  }
}
