import React from "react";
import styled from "styled-components";
import { convertToTitleCase } from "../../helper/wodash";
import { Typography } from "antd";

const KeyValueGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const KeyValueItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
`;

const Key = styled.strong`
  min-width: 100px;
  margin-right: 5px;
  font-weight: bold;
`;

const Value = styled.span`
  margin-left: 5px;
`;

const KeyValueDisplay = ({ data, sortArray }) => {
  const sortedData = Object.entries(data).sort(
    ([keyA], [keyB]) => sortArray.indexOf(keyA) - sortArray.indexOf(keyB)
  );

  return (
    <KeyValueGrid>
      {sortedData.map(([key, value]) => (
        <KeyValueItem key={key}>
          <Typography.Text>
            <Key> {convertToTitleCase(key)}:</Key>
            <Value>{Array.isArray(value) ? value.join(", ") : value}</Value>
          </Typography.Text>
        </KeyValueItem>
      ))}
    </KeyValueGrid>
  );
};

export default KeyValueDisplay;
