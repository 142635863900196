import {
  collection,
  addDoc,
  updateDoc,
  doc,
  writeBatch,
} from "firebase/firestore";
import { uniqueId } from "lodash";
import { getCurrentDate } from "../../helper/time";
import { generateRandomString, objDontHave } from "../../helper/wodash";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { updateUser } from "../user/update";

//validations throws error or returns true
function validate(data) {
  //todo: check if the user is admin to access this
  const dontHv = objDontHave(data, ["name", "companies", "admins"]);
  if (dontHv) throw "Please add " + dontHv;
  if (data.companies.length < 2)
    throw "Length of companies must be greater than 2";
  if (data.name.length < 2) throw "Length of name must be greater than 2";
  return true;
}

export async function createGroup(data) {
  validate(data);
  data["createdAt"] = getCurrentDate();

  const batch = writeBatch(db);
  const id = generateRandomString(20);
  const groupRef = doc(collection(db, firestoreStatic.groups), id);
  // todo: check if batch name is different
  batch.set(groupRef, data); // Adding the new group document to the batch.

  for (const adminId of data.admins) {
    const userRef = doc(db, firestoreStatic.users, adminId);
    batch.update(userRef, { groupAdmin: id });
  }

  await batch.commit();

  return id;
}
