// TestResultFunctions.js
import {
  collection,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  getDoc,
  getDocs,
  query,
} from "firebase/firestore";
import { generateRandomString } from "../../helper/wodash";
import { db } from "../setup";
import { docsWithIds } from "../util";

// Create or Update function
export async function updateTestResult(data, docId = generateRandomString(20)) {
  const infoDocRef = doc(db, "TestResult", docId);
  await setDoc(infoDocRef, data, { merge: true });
  return docId;
}

// Delete function
export async function deleteTestResult(docId) {
  const infoDocRef = doc(db, "TestResult", docId);
  await deleteDoc(infoDocRef);
}

export async function getTestResult(docId) {
  const infoDocRef = doc(db, "TestResult", docId);

  try {
    const docSnapshot = await getDoc(infoDocRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      return null; // Document doesn't exist
    }
  } catch (error) {
    console.error("Error getting document:", error);
    throw error;
  }
}

export async function getTestResults() {
  const collectionRef = collection(db, "TestResult");
  const q = query(collectionRef);
  const docs = await getDocs(q);
  
  return docsWithIds(docs);
}
