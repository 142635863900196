import React, { useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { findFormText } from "../../../helper/attribute";
import { filterFactors } from "../../../helper/emissionfactors";
import { findForm } from "../../../static/formRoutes";

export const INPUTS = (data) => {
  const ids = findForm("hotel").id_range();

  return [
    {
      elem: DateInput,
      key: "date",
      title: "Date of Stay (First Night)",
    },
    {
      elem: SelectSearch,
      key: "country",
      options: filterFactors(undefined, "Level 3", true, ids),
    },
    {
      elem: NumberInput,
      key: "guests",
      title: "Number of Guest Nights",
    },
  ];
};

export default function Hotel({ data, setData, returnInputs = false }) {
  const formname = data.name;
  useEffect(() => {
    setData({ ...data, country: data.country || null });
  }, []);
  if (returnInputs) return INPUTS(data);
  return (
    <div>
      {INPUTS(data).map((input) =>
        React.createElement(input.elem, {
          value: data[input.key],
          setValue: (value) => setData({ ...data, [input.key]: value }),
          title: input.title || findFormText(formname, input.key),
          ...input,
        })
      )}
    </div>
  );
}
