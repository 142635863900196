import React from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { genPut } from "../helper/create";

export const HeatAndSteamTableConfig = {
  Onsite: {
    result: {
      //main key is "result"
      "Level 2": "Heat and steam",
      "Level 3": "Onsite heat and steam",
    },

    wtt: {
      "Level 2": "WTT- heat and steam",
      "Level 3": "Onsite heat and steam",
    },
  },
  District: {
    result: {
      "Level 2": "Heat and steam",
      "Level 3": "District heat and steam",
    },
    wtt: {
      "Level 2": "WTT- heat and steam",
      "Level 3": "District heat and steam",
    },
    tnd: {
      "Level 2": "Distribution - district heat & steam",
      "Level 3": "5% loss",
    },
    wtt_tnd: {
      "Level 2": "WTT- district heat & steam distribution",
      "Level 3": "5% loss",
    },
  },
};

export const INPUTS = (data, setData, formname) => [
  {
    key: "date",
    elem: DateInput,
  },
  {
    key: "q1",
    elem: SelectSearch,
    options: Object.keys(HeatAndSteamTableConfig),
  },
  {
    key: "amount",
    elem: NumberInput,
  },
];

export default function HeatAndSteam({ data, setData }) {
  const formname = "heatandsteam";

  return (
    <>
      {INPUTS(data, setData, formname).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </>
  );
}
