import React, { useState, useEffect } from "react";
import { Table, Button, AutoComplete, Input, Modal } from "antd";
import { getCacheOptions, updateCacheOptions } from "../../../firebase/cache/create";
import { PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const defaultConfig = {
  verifier: {
    suggestions: [
      "Evidence provided for data entered, with actual figures used.",
      "Evidence provided for data entered, with estimated figures used.",
      "No data available, estimated based on industry standards.",
      "Source provided for data, no evidence available.",
      "Estimated, based on typical business operation.",
      "Estimated.",
    ],
  },
  source: {
    suggestions: ["Test"],
  },
};

const SimpleEditableTable = ({ dataSource, setDataSource }) => {
  const [tempDataSource, setTempDataSource] = useState(dataSource || []); // Temporary data source
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal state
  const [newOption, setNewOption] = useState(""); // New option value
  const [columnToUpdate, setColumnToUpdate] = useState(""); // Column to update (source/verifier)
  const [selectedDataField, setSelectedDataField] = useState(""); // To store the current data field for adding new options
  const [optionsCache, setOptionsCache] = useState({}); // Cache for loaded options

  useEffect(() => {
    if (JSON.stringify(tempDataSource) !== JSON.stringify(dataSource)) {
      setDataSource(tempDataSource);
    }
  }, [tempDataSource, setDataSource]);

  console.log("Datasource", dataSource)

  // Function to load options for a specific data field from Firebase, with fallback to defaultConfig
  const loadOptionsForField = async (dataField) => {
    if (!optionsCache[`${dataField}_commenttable`]) {
      try {
        const cacheOptions = await getCacheOptions(dataField); // Fetch options from Firebase
        setOptionsCache((prevCache) => ({
          ...prevCache,
          [`${dataField}_commenttable`]: cacheOptions || defaultConfig,
        }));
        return cacheOptions || defaultConfig;
      } catch (error) {
        console.error(`Error fetching options for ${dataField}:`, error);
        return defaultConfig; // Return default config if fetching fails
      }
    }
    return optionsCache[`${dataField}_commenttable`] || defaultConfig;
  };

  // Use effect to preload options when component mounts for the first time
  useEffect(() => {
    async function preloadOptions() {
      const dataFields = tempDataSource.map((record) => record.data);
      for (const dataField of dataFields) {
        await loadOptionsForField(dataField);
      }
    }
    preloadOptions();
  }, [tempDataSource]);

  // Handle input changes locally in tempDataSource
  const handleInputChange = (value, recordKey, column) => {
    setTempDataSource((prevData) =>
      prevData.map((item) =>
        item.key === recordKey ? { ...item, [column]: value } : item
      )
    );
  };

  // Handle adding new option to the correct field and column
  const handleAddNewOption = async () => {
    if (newOption && columnToUpdate && selectedDataField) {
      const currentOptions = await loadOptionsForField(selectedDataField);
      const updatedOptions = {
        ...currentOptions,
        [columnToUpdate]: {
          ...currentOptions[columnToUpdate],
          suggestions: [
            ...(currentOptions[columnToUpdate]?.suggestions || []),
            newOption,
          ],
        },
      };
      await updateCacheOptions(selectedDataField, updatedOptions); // Save updated options to Firebase
      setOptionsCache((prevCache) => ({
        ...prevCache,
        [`${selectedDataField}_commenttable`]: updatedOptions,
      }));
      setIsModalVisible(false); // Close modal
      setNewOption(""); // Reset new option input
    }
  };

  // Show modal for adding a new option
  const showAddOptionModal = (column, dataField) => {
    setColumnToUpdate(column);
    setSelectedDataField(dataField);
    setIsModalVisible(true); // Open modal
  };

  // Custom filter function for AutoComplete
  const filterOption = (inputValue, option) =>
    option.value.toLowerCase().includes(inputValue.toLowerCase());

  // Table columns with autocomplete options
  const columns = [
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      render: (text) => <span>{text ?? "No data available"}</span>, // Fixed text with fallback for undefined
    },
    {
      title: "Source of data",
      dataIndex: "source",
      key: "source",
      render: (text, record) => {
        const currentOptions = optionsCache[`${record.data}_commenttable`] || defaultConfig; // Use cached options or defaultConfig
        return (
          <>
            <AutoComplete
              value={text || ""}
              options={currentOptions.source?.suggestions.map((option) => ({ value: option })) || []}
              onChange={(value) => handleInputChange(value, record.key, "source")}
              filterOption={filterOption} // Custom filtering
              style={{ width: "90%" }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Button
                    type="link"
                    style={{ display: 'flex', width: "100%", justifyContent: 'center', padding: '8px', cursor: 'pointer' }}
                    onClick={() => showAddOptionModal("source", record.data)}
                  >
                    <PlusOutlined /> Add option
                  </Button>
                </>
              )}
            />
          </>
        );
      },
    },
    {
      title: "Verifier comments",
      dataIndex: "verifier",
      key: "verifier",
      render: (text, record) => {
        const currentOptions = optionsCache[`${record.data}_commenttable`] || defaultConfig; // Use cached options or defaultConfig
        return (
          <>
            <AutoComplete
              value={text || ""}
              options={currentOptions.verifier?.suggestions.map((option) => ({ value: option })) || []}
              onChange={(value) => handleInputChange(value, record.key, "verifier")}
              filterOption={filterOption} // Custom filtering
              style={{ width: "100%" }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Button
                    type="link"
                    style={{ display: 'flex', width: "100%", justifyContent: 'center', padding: '8px', cursor: 'pointer' }}
                    onClick={() => showAddOptionModal("verifier", record.data)}
                  >
                    <PlusOutlined /> Add option
                  </Button>
                </>
              )}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table dataSource={tempDataSource} columns={columns} pagination={false} />

      {/* Modal for adding new options */}
      <Modal
        title={`Add New Option for ${selectedDataField}`}
        visible={isModalVisible}
        onOk={handleAddNewOption}
        onCancel={() => setIsModalVisible(false)}
        okButtonProps={{ disabled: !newOption }} // Disable OK button when newOption is empty
      >
        <Input
          placeholder="Enter new option"
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default SimpleEditableTable;
