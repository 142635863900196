import React, { useState } from "react";
import { Button, Divider, Image, Input, message, Modal, Tooltip, Typography } from "antd";
import html2canvas from "html2canvas";
import { BugOutlined, CameraOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { createBugReport } from "../../firebase/userhelp/reportbugs/create";
import SelectMultiple from "../input/SelectMultiple";
import SelectSearch from "../select/SearchSelect";
import ImageEditModal from "./comp/ImageDraw";
const SCREENSHOTTIME = 0.3;

const ReportBug = ({ modalVisible, setModalVisible }) => {
  const [data, setData] = useState({ tag: "Bug" });
  const [errs, setErrs] = useState({ text: null });
  const [drawModal, setDrawModal] = useState(false);

  const [loader, setLoader] = useState(false);
  useEffect(() => {}, []);

  const takeFullPageScreenshot = () => {
    setModalVisible(false); // Hide the modal
    message.loading({
      type: "loading",
      content: "Taking Screenshot",
      duration: SCREENSHOTTIME,
    });

    setTimeout(() => {
      const pageElement = document.documentElement;
      const options = {
        scrollX: 0,
        scrollY: -window.scrollY,
        scale: 1,
      };

      html2canvas(pageElement, options).then((canvas) => {
        const url = canvas.toDataURL();
        setModalVisible(true); // Show the modal after a slight delay
        setData({ ...data, screenshotUrl: url }); // Update the data state with the screenshotUrl
      });
    }, SCREENSHOTTIME * 1000);
  };

  const closeModal = () => {
    setModalVisible(false);
    setData({}); // Reset the screenshotUrl in the data state
    setErrs({});
  };
  const handleSendUpdate = async () => {
    setLoader(true);
    try {
      if (!data.text || data.text === "") {
        return setErrs({ ...errs, text: "Add some text" });
      }
      await createBugReport(data);
      closeModal();
      message.success("Bug has been reported!");
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <Modal
        title={
          <div>
            Report a bug <BugOutlined />
          </div>
        }
        open={modalVisible}
        onCancel={closeModal}
        okText={"Send"}
        onOk={handleSendUpdate}
        confirmLoading={loader}
      >
        {/* <SelectSearch
          title={"Type"}
          value={data.tag}
          setValue={(tag) => setData({ ...data, tag })}
          options={["Bug", "Feature Request"]}
          borderless
          bgless
          allowClear
        ></SelectSearch> */}
        <Input.TextArea
          value={data.text}
          onChange={(e) => setData({ ...data, text: e.target.value })}
          placeholder="Write detailed description about the bug."
          status={errs.text ? "error" : ""}
          style={{ height: "200px" }}
        ></Input.TextArea>
        <div style={{ color: "red" }}>{errs.text}</div>

        <Divider>Supporting screenshot</Divider>
        <div
          style={{
            display: "flex",
            gap: "10px",
            padding: "4px 0px",
          }}
        >
          {!data.screenshotUrl ? (
            <Button onClick={takeFullPageScreenshot}>
              <CameraOutlined /> <b>Add screenshot</b>
            </Button>
          ) : (
            <>
              <Button onClick={setDrawModal}>
                <EditOutlined /> Markup Screenshot
              </Button>
              <Button
                type="default"
                danger
                onClick={() => {
                  setData({ ...data, screenshotUrl: "" }); // Remove the screenshotUrl from the data state
                }}
              >
                <DeleteOutlined />
                Remove
              </Button>
            </>
          )}
        </div>
        {data.screenshotUrl && (
          <>
            <Image width={"80%"} src={data.screenshotUrl} alt="Full Page Screenshot" />
            <ImageEditModal visible={drawModal} setVisible={setDrawModal} imageUrl={data.screenshotUrl} setDataUrl={(screenshotUrl) => setData({ ...data, screenshotUrl })} />
          </>
        )}
      </Modal>
    </div>
  );
};

export default ReportBug;
