import { Skeleton, Typography } from "antd";
import React from "react";
import ObjectJS from "../../../components/Objectjs/Object.js";
import { updateConfig } from "../../../firebase/appconfig/update.js";
import store from "../../../redux/store.js";

export default function AppTexts() {
  const texts = JSON.parse(JSON.stringify(store.getState().config.texts));
  const setData = async (textsUpdated) => {
    await updateConfig(textsUpdated, "texts");
  };
  if (!texts) return <Skeleton />;
  return (
    <>
      <Typography.Title level={3}>AppTexts</Typography.Title>
      <div>
        <ObjectJS initialData={texts} setInitialData={setData} />
      </div>
    </>
  );
}
