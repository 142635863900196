import React, { useState } from "react";

export const BeautifyData = ({ data }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
  };

  const getType = (value) => {
    if (typeof value === "string") return "string";
    if (typeof value === "number") return "number";
    if (typeof value === "boolean") return "boolean";
    if (Array.isArray(value)) return "array";
    if (value instanceof Set) return "set";
    if (typeof value === "object" && value !== null) return "object";
    return "other";
  };

  const renderValue = (value) => {
    const type = getType(value);
    let textColor = "#000"; // Default color for other types

    switch (type) {
      case "number":
        textColor = "#28a745"; // Green for numbers
        break;
      case "boolean":
        textColor = "#dc3545"; // Red for booleans
        break;
      default:
        break;
    }

    return (
      <div>
        {typeof value === "object" && value !== null && (
          <div onClick={handleToggleCollapse} style={{ cursor: "pointer" }}>
            {isCollapsed ? "▶️" : "🔽"}{" "}
            {Array.isArray(value) ? "Array" : "Object"} (
            {Array.isArray(value) ? value.length : Object.keys(value).length})
          </div>
        )}
        {!isCollapsed && typeof value === "object" && value !== null && (
          <div style={{ marginLeft: "20px", borderLeft: "1px solid #ccc" }}>
            {Array.isArray(value)
              ? value.map((item, index) => (
                  <div
                    key={index}
                    style={{ marginBottom: "5px", display: "flex", gap: "2px" }}
                  >
                    <span style={{ color: "#666" }}>[{index}]</span>:{" "}
                    <BeautifyData data={item} />
                  </div>
                ))
              : Object.entries(value).map(([key, val]) => (
                  <div
                    key={key}
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      gap: "2px",
                      paddingLeft: "2px",
                    }}
                  >
                    <span style={{}}>
                      <b
                        style={{ fontWeight: 800, position: "sticky", top: 0 }}
                      >
                        {key}:
                      </b>{" "}
                    </span>
                    <BeautifyData data={val} />
                  </div>
                ))}
          </div>
        )}
        {typeof value !== "object" && (
          <span style={{ fontFamily: "monospace", color: textColor }}>
            {String(value)}
          </span>
        )}
      </div>
    );
  };

  return <div>{renderValue(data)}</div>;
};
