import { DownOutlined, MailOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Menu,
  Result,
  Select,
  Space,
  Typography,
  theme,
} from "antd";
import React, { Component, useEffect } from "react";
import { createBugReport } from "./firebase/userhelp/reportbugs/create";
import { catcher } from "./firebase/util";
import { routePath } from "./static/routes.static";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

function Only4Navigation() {
  const navigate = useNavigate();
  useEffect(() => {
    // navigate(routePath.Dashboard)
    window.location.href = routePath.Dashboard;
  }, []);
  return null;
}

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    loading: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false, error: null });
    }
  }

  componentDidCatch(error, info) {
    console.error(
      "Error caught by ErrorBoundary:",
      error,
      info,
      this.props.where
    );
  }

  handleRefresh = (clearCache) => {
    if (clearCache) {
      localStorage.clear(); // Clear localStorage
      window.location.reload(true); // Refresh with clearing cache
    } else {
      window.location.reload(false); // Simple refresh
    }
  };

  returnError = () => {
    try {
      return (
        this.state.error || this.state.error.message || "Not sure what's wrong."
      );
    } catch (err) {
      return JSON.stringify(this.state.error);
    }
  };

  handleGoBack = () => {
    // Implement your go back functionality here
    // window.history.back();
    window.location.href = "/";
  };

  handleMenuClick = ({ key }) => {
    if (key === "refresh") {
      this.handleRefresh(false);
    } else if (key === "clearCache") {
      this.handleRefresh(true);
    } else {
      this.setState({ ...this.state, navback: true });
    }
  };
  handleSendReport = () => {
    catcher(
      async () => {
        await createBugReport({
          text: "Path: " + window.location.href + this.state.error.stack,
        });
        this.handleGoBack();
      },
      {
        setLoading: (loading) => this.setState({ ...this.state, loading }),
      }
    );
  };

  render() {
    if (this.state.navback) return <Only4Navigation />;
    if (this.state.hasError) {
      if (this.props.component) return this.props.component;
      const items = [
        {
          key: "back2home",
          title: "Back to homepage",
        },
        {
          key: "refresh",
          title: "Refresh Page",
        },
        {
          key: "clearCache",
          title: "Refresh with Clearing Cache",
        },
      ];
      return (
        <Result
          status="error"
          style={{
            minHeight: "100vh",
          }}
          title={
            <Typography.Title level={3}>
              Oops! Something went wrong.{" "}
            </Typography.Title>
          }
          subTitle={this.state.error.message}
          extra={
            <div>
              Send us a report and we will look into it!
              <br />
              <br />
              <Space>
                <Button
                  type="primary"
                  loading={this.state.loading}
                  onClick={this.handleSendReport}
                >
                  Send Report <MailOutlined />
                </Button>
                {/* <Button type="primary" onClick={this.handleGoBack}>
                  Go Back <BackwardOutlined />
                </Button> */}
                or
                <Dropdown.Button
                  icon={<DownOutlined />}
                  onClick={() => this.handleMenuClick({ key: "back2home" })}
                  overlay={
                    <Menu onClick={this.handleMenuClick}>
                      {items.map((item) => (
                        <Menu.Item key={item.key}>{item.title}</Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  Back to home
                </Dropdown.Button>
              </Space>
            </div>
          }
        />
      );
    }

    // Your application components go here
    return this.props.children;
  }
}

export default ErrorBoundary;
