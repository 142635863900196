import { fetchAffiliateByDomainName } from "../firebase/affiliate/get";
import { getBaseUrl } from "../helper/loc";
import { setAffiliate } from "../redux/features/appSlice";
import store from "../redux/store";

function redirectToSustraxmx() {
  const currentUrl = new URL(window.location.href);
  let newHostname = currentUrl.hostname.replace("sustrax3", "sustraxmx");

  let newUrl =
    currentUrl.protocol +
    "//" +
    newHostname +
    currentUrl.pathname +
    currentUrl.search;

  window.location.href = newUrl;
}

export default async function affiliateChecks() {
  const base_url = getBaseUrl()?.replace("development.", "");
  console.log(base_url);
  if (base_url.includes("sustraxmx")) return;
  if (base_url.includes("sustrax3")) redirectToSustraxmx();

  try {
    // Check if affiliate
    const af = await fetchAffiliateByDomainName(base_url);
    console.log("affiliate", af);
    store.dispatch(setAffiliate(af));
  } catch (error) {
    console.error("Error fetching affiliate by domain name:", error);
  }
}
