import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import React from "react";
import InLineCards from "../pages/extras/testing/comp/inlineCards";

const label_elems = [<QuestionCircleTwoTone twoToneColor="#52c41a" />];

export const vspace = (height = 10) => <div style={{ height: `${height}px` }}></div>;

export const label = (title, elemi = 0, ...options) => (
  <Tooltip title={title} {...options}>
    {label_elems[elemi]}
  </Tooltip>
);

export const beautifulObjectNumValues = (object) => {
  return (
    <InLineCards
      dataArray={Object.entries(object)?.map(([key, value]) => ({
        name: key,
        result: value,
      }))}
    />
  );
};

export function generateDropDown({ title = "Select an item", items, onSelect }) {
  const menu = (
    <Menu onClick={onSelect}>
      {items.map((item) => (
        <Menu.Item key={item.id}>{item.text}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <span style={{ cursor: "pointer" }}>{title}</span>
    </Dropdown>
  );
}
export function cardsUI1(hasher, colors = [
  "rgba(179, 96, 150, 1)", // Soft pinkish-purple
  "rgba(85, 106, 203, 1)", // Soft bluish-purple
  "rgba(98, 98, 141, 1)",  // Muted lavender
  "rgba(120, 72, 128, 1)", // Dusky magenta
  "rgba(80, 95, 125, 1)",  // Deep slate blue
  "rgba(94, 77, 142, 1)",  // Rich plum
  "rgba(68, 84, 112, 1)",  // Subtle steel blue
  "rgba(99, 64, 117, 1)",  // Muted violet
  "rgba(75, 85, 132, 1)",  // Cool blue-gray
  "rgba(102, 51, 153, 1)", // Deep amethyst
]) {
  const hash =
    JSON.stringify(hasher)
      .split("")
      .reduce((h, c) => c.charCodeAt(0) + ((h << 5) - h), 0) & 0xffffffff;
  return {
    borderTop: `3px solid ${colors[hash % colors.length]}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  };
}
