import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  PhoneTwoTone,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Card, Popconfirm, Table, Typography, theme } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { LuToggleLeft } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { T1 } from "../../../appconfig/texts";
import TV from "../../../components/video/TV";
import { catcher } from "../../../firebase/util";
import { firstCompany } from "../../../helper/attribute";
import { getCurrentPlan, getCurrentPlanExpiry } from "../../../helper/plans";
import useSize from "../../../hooks/useSize";
import { setCompanies } from "../../../redux/features/appSlice";
import store from "../../../redux/store";
import mximg from "../../../static/logos/sustraxplansimage.png";
import { routePath } from "../../../static/routes.static";
import { Logo } from "../../../styles/core-components";
import ContactUsModal from "../../contact-us/ContactUs";
import { plansConfig } from "./Upgrade.config";
import "./style.css";
const { Link, Text, Title } = Typography;
const MX = () => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          marginTop: "20px",
          background: theme.useToken().token.colorBgContainer,
          padding: "2px 3px",
          display: "flex",
          width: "100%",
          flexDirection: isExtraSmall ? "column" : "row",
          justifyContent: "center",
          border: "1px solid darkgrey",
          borderRadius: "3px",
        }}
      >
        <div
          style={{ textAlign: "center", width: isExtraSmall ? "100%" : "70%" }}
        >
          <Title className="notranslate" level={isExtraSmall ? 3 : 2}>Sustrax MX</Title>
          <Title level={5}>
            Monthly, Multi-Year, Multi-Site, Multi-User tracking of emissions
            and reporting.
          </Title>

          <Title level={5}>
            Includes all features of the above plans, plus unlimited data
            entries, consultancy-led verification, and encompassing all scope 3
            categories, with inputs for both spend and activity data
          </Title>
          <img src={mximg} style={{ width: "100%", padding: "10px" }} />

          <div
            style={{
              marginTop: "30px",
              marginLeft: "20px",
              display: "flex",
              flexDirection: isExtraSmall ? "column" : "row",
              gap: "10px",
              justifySelf: "start",
              marginLeft: "20px",
              justifyContent: "start",
            }}
          >
            <strong style={{ fontWeight: 600, textDecoration: "underline" }}>
              For a demo please contact:
            </strong>
            <Link href="mailto:info@carbonfootprint.com" target="_blank" className="notranslate">
              info@carbonfootprint.com
            </Link>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: isExtraSmall ? "30px" : "18px",
            width: isExtraSmall ? "100%" : "40%",
          }}
        >
          <TV url={"https://www.youtube.com/embed/4UYHtoFlMQo"}></TV>
        </div>
      </div>
    </div>
  );
};

const planRanks = ["free", "basic", "premium", "ma", "mx"];

const Upgrade = () => {
  const currentPlan = getCurrentPlan();
  const expiryDate = getCurrentPlanExpiry();
  const { isSmall, isExtraSmall } = useSize();
  const [loading_renewal, set_loading_renewal] = useState(false);

  const [contactUs, setContactUs] = useState(false);
  const handleContactUs = () => {
    setContactUs({
      type: "Upgrade Plan",
      message: `Hi CarbonFootprint Team, \nI hope this message finds you well. I'm reaching out to inquire about your SustraxMX plan and its features.`,
    });
  };

  const navigate = useNavigate();
  const handlePlanClick = (planKey) => {
    navigate(routePath.Pay(planKey));
  };
  const handleToggleAutoRenewal = (planKey) => {
    catcher(
      async () => {
        const auto_renewal = firstCompany()?.auto_renewal;
        let updated_company;
        console.log(auto_renewal);
        if (auto_renewal) {
          const {
            data: { company },
          } = await axios.post(process.env.REACT_APP_BE + "/api/pay/cancel", {
            company_id: firstCompany()?.id,
          });
          updated_company = company; //good coding isnt it?
        } else {
          const {
            data: { company },
          } = await axios.post(process.env.REACT_APP_BE + "/api/pay/resub", {
            company_id: firstCompany()?.id,
          });
          updated_company = company;
        }
        console.log(updated_company);
        store.dispatch(setCompanies([updated_company])); //update company data, assuming it has only one company
      },
      { setLoading: set_loading_renewal }
    );
  };

  const commonColProps = (key) => ({
    render: (elem) => {
      return {
        children: (
          <div
            className={`td ${key}`}
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
            }}
          >
            {elem}
          </div>
        ),
      };
    },
  });
  return (
    <div style={{ padding: "0px" }}>
      <Typography.Title level={2} style={{ fontWeight: "500" }}>
        Current Plan
      </Typography.Title>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card
          style={{
            border: "1px solid darkgrey",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div>
              <Typography.Title
                level={1}
                className="notranslate"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Sustrax <T1 pos={`plans.${currentPlan}.title`} />
              </Typography.Title>
              {/* <T1 pos={`plans.${currentPlan}.bottomLine`} /> */}

              <div>
                <div>
                  {currentPlan !== "free" && (
                    <div>
                      {expiryDate && (
                        <div> Your subscription will renew on {expiryDate}</div>
                      )}
                      {firstCompany()?.auto_renewal ? (
                        <div>
                          <Popconfirm
                            title="Do you really want to turn auto-renewal off?"
                            onConfirm={handleToggleAutoRenewal}
                          >
                            <Button
                              loading={loading_renewal}
                              className=""
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <LuToggleLeft color="red" fontSize={"20px"} />{" "}
                              Turn auto-renewal off
                            </Button>
                          </Popconfirm>
                        </div>
                      ) : (
                        <Button
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={handleToggleAutoRenewal}
                          loading={loading_renewal}
                        >
                          <LuToggleLeft color="green" fontSize={"20px"} />
                          Turn auto-renewal on
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isExtraSmall && (
              <div style={{ marginRight: 30 }}>
                <Logo />
              </div>
            )}
          </div>
        </Card>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={2} style={{ fontWeight: "500" }}>
          Upgrade Account
        </Typography.Title>
        <div></div>
      </div>
      <InfoCircleOutlined /> All plans below are sold on an annual subscription
      basis. You can turn off auto-renewal anytime from this page once
      purchased.
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Table
          className="upgrade-table"
          style={{ width: "100%", zoom: isExtraSmall && 0.6 }}
          pagination={false}
          columns={[
            {
              title: (
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    fontSize: "20px",
                  }}
                >
                  Features
                </div>
              ),
              key: "Features",
              dataIndex: "Features",
              ...commonColProps("Features"),
            },
            ...plansConfig.plans.map((item) => ({
              key: item.key,
              width: 300,
              dataIndex: item.key,
              ...commonColProps(item.key),
              title: (
                <>
                  <div
                    className="th"
                    style={{
                      padding: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      color: "whitesmoke",
                    }}
                  >
                    <span className="notranslatea" style={{ fontSize: "20px" }}>
                      Sustrax <T1 pos={`plans.${item.key}.title`} />{" "}
                    </span>
                    <span style={{ fontSize: "18px" }}>{item.price}</span>
                  </div>
                  {planRanks.findIndex((e) => e === currentPlan) <
                    planRanks.findIndex((e) => e === item.key) && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={() => handlePlanClick(item.key)}
                        type="primary"
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "darkgreen",
                          border: "1px solid white",
                          background: "lightgrey",
                          fontSize: "20px",
                          cursor: "pointer",

                          padding: 2,
                        }}
                      >
                        Choose <RightOutlined />
                      </div>
                    </div>
                  )}
                  {currentPlan === item.key && (
                    <span
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        // border: "1px solid white",
                        // background: "lightgrey",
                      }}
                    >
                      <InfoCircleOutlined />
                      Current Plan
                    </span>
                  )}
                  {item.key === "pro" && currentPlan !== "pro" && (
                    <div
                      onClick={handleContactUs}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "darkgreen",
                        border: "1px solid white",
                        background: "lightgrey",
                        fontSize: "20px",
                        padding: 2,
                        cursor: "pointer",
                      }}
                    >
                      Contact us <PhoneTwoTone twoToneColor={"green"} />
                    </div>
                  )}
                </>
              ),
            })),
          ]}
          dataSource={plansConfig.items.map((item) => {
            const obj = {};
            item.status.map((keysObj) => {
              Object.keys(keysObj).forEach((key) => {
                const val = keysObj[key];
                if (val === 0)
                  return (obj[key] = (
                    <CloseOutlined style={{ color: "red" }} />
                  ));
                if (val === 1)
                  return (obj[key] = (
                    <CheckOutlined style={{ color: "darkgreen" }} />
                  ));
                obj[key] = item.statusFunc ? item.statusFunc(key, val) : val;
              });
            });
            return {
              Features: item.title,
              key: item,
              ...obj,
            };
          })}
        />
      </div>
      <MX />
      <ContactUsModal
        key={contactUs}
        visible={contactUs}
        onCancel={() => setContactUs()}
        data={contactUs}
      />
    </div>
  );
};
export default Upgrade;
