import { CaretDownFilled } from "@ant-design/icons";
import { Tabs } from "antd";
import { BeautifyData } from "../../../components/text/BeautifyData.js";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import SelectMultiple from "../../../components/input/SelectMultiple";
import Select1 from "../../../components/select/select1";

import {
  findAdminsOfCompany,
  findAdminsOfSite,
  findCompanies,
  findSitesByCompanyId,
  findUsersByCompanyId,
} from "../../../helper/attribute";
import "./index.css";
// Create the Ant Design Tabs component and place the Hierarchy component inside one of the tabs
const { TabPane } = Tabs;

const classColorList = [
  {
    classname: "company",
    color: {
      background: "#ffd700",
      border: "#ffd700",
    },
  },
  {
    classname: "site",
    color: {
      background: "pink",
      border: "pink",
    },
  },
  {
    classname: "company-admin",
    color: {
      background: "limegreen",
      border: "limegreen",
    },
  },
  {
    classname: "site-admin",
    color: {
      background: "lightgreen",
      border: "limegreen",
    },
  },
  {
    classname: "user",
    color: {
      background: "lavender",
      border: "lavender",
    },
  },
];

const Hierarchy = () => {
  const dollah = 1;
  const [orgChartData, setOrgChartData] = useState([]);
  const [settings, setSettings] = useState({
    showUsers: "no",
    showAdminsOnce: "yes",
    showColors: true,
    showCompanyAdmins: "no",
    showSiteAdmins: "no",
    companiesFilter: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const chartData = [["Name", "Manager", "ToolTip", { role: "color" }]];
      const companies = !settings.companiesFilter.length
        ? findCompanies()
        : findCompanies().filter((c) =>
            settings.companiesFilter.includes(c.id)
          );
      const alreadyshown = [];

      for (const company of companies) {
        // Add company node
        chartData.push([
          `<div class='company'>${company.title}</div>`,
          "",
          "",
          "",
        ]);

        // Fetch and add company admins
        const companyAdmins = await findAdminsOfCompany(company);
        if (companyAdmins.length > 0 && settings.showCompanyAdmins === "yes") {
          for (const admin of companyAdmins) {
            alreadyshown.push(admin.id);
            chartData.push([
              `<div class='company-admin'>${
                admin.firstName + " " + admin.lastName
              } `,
              `<div class='company'>${company.title}</div>`,
              "Company Admin",
              "#FF0000", // Red color for company admins
            ]);
          }
        }

        // Fetch and add sites
        const sites = await findSitesByCompanyId(company.id);

        if (sites.length > 0) {
          for (const site of sites) {
            chartData.push([
              `<div class='site'>${site.title}</div>`,
              `<div class='company'>${company.title}</div>`,
              "Site",
              "",
            ]);

            // Fetch and add site admins
            const siteAdmins = await findAdminsOfSite(site);
            if (siteAdmins.length > 0 && settings.showSiteAdmins === "yes") {
              for (const admin of siteAdmins) {
                alreadyshown.push(admin.id);
                chartData.push([
                  `<div class='site-admin'>${
                    admin?.firstName + " " + admin?.lastName
                  }`,
                  `<div class='site'>${site.title}</div>`,
                  "Site Admin",
                  "color= #00FF00", // Green color for site admins
                ]);
              }
            }
          }
        }

        if (settings.showUsers === "yes") {
          // Fetch and add company users
          const users = await findUsersByCompanyId(company.id).filter(
            (user) => !alreadyshown.includes(user.id)
          );
          if (users.length > 0) {
            for (const user of users) {
              chartData.push([
                `<div class='user'>${user.firstName} ${user.lastName}</div>`,
                `<div class='company'>${company.title}</div>`,
                "User",
                "",
              ]);
            }
          }
        }
      }

      setOrgChartData(chartData);
    };

    fetchData();
  }, [settings]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row-reverse",
          marginBottom: "100px",
        }}
      >
        <div className="colorbox">
          <u
            onClick={() =>
              setSettings({ ...settings, showColors: !settings.showColors })
            }
          >
            Colors <CaretDownFilled />
          </u>
          {settings.showColors &&
            classColorList.map((item) => (
              <div>
                <div className="label">{item.classname}</div>
                <div
                  key={item.classname}
                  className="box"
                  style={{
                    backgroundColor: item.color.background,
                    border: `2px solid ${item.color.border}`,
                  }}
                ></div>
              </div>
            ))}
        </div>
        <div>
          <SelectMultiple
            bgless
            borderless
            allowClear
            title={"Filter Companies"}
            value={settings.companiesFilter}
            handleChange={(companiesFilter) =>
              setSettings({ ...settings, companiesFilter })
            }
            options={findCompanies().map((c) => ({
              label: c.title,
              value: c.id,
            }))}
          />
          <Select1
            value={settings.showCompanyAdmins}
            setValue={(showCompanyAdmins) =>
              setSettings({ ...settings, showCompanyAdmins })
            }
            title={"Show company admins"}
            options={["yes", "no"]}
            minWidth="170px"
          />
          <Select1
            minWidth="170px"
            value={settings.showSiteAdmins}
            setValue={(showSiteAdmins) =>
              setSettings({ ...settings, showSiteAdmins })
            }
            title={"Show site admins"}
            options={["yes", "no"]}
          />{" "}
          <Select1
            minWidth="170px"
            value={settings.showUsers}
            setValue={(showUsers) => setSettings({ ...settings, showUsers })}
            title={"Show users"}
            options={["yes", "no"]}
          />
          {/* <Select1
        value={settings.showAdminsOnce}
        setValue={(showAdminsOnce) =>
          setSettings({ ...settings, showAdminsOnce })
        }
        title={"Show Admins Once"}
        options={["yes", "no"]}
      /> */}
        </div>
      </div>
      <div
        style={{
          overflow: "auto",
          width: "100%",
          height: "300px",
        }}
      >
        <Chart
          width={"100%"}
          chartType="OrgChart"
          loader={<div>Loading Chart</div>}
          data={orgChartData}
          options={{
            allowCollapse: true,
            allowHtml: true,
            compactRows: true,
          }}
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    </div>
  );
};

const Hierarchy2 = () => {
  const companies = [...findCompanies()];
  const data = companies.reduce((acc, company) => {
    const sites = findSitesByCompanyId(company.id);
    const users = findUsersByCompanyId(company.id);

    const companyWithUsersAndSites = {
      ...company,
      users: users,
      sites: sites,
    };

    acc[company.title] = companyWithUsersAndSites;
    return acc;
  }, {});

  return <BeautifyData data={data} />;
};

const HierarchyWithTabs = () => {
  return (
    <Tabs type="line" defaultActiveKey="1">
      <TabPane tab="Chart" key="1">
        <Hierarchy />
      </TabPane>
      <TabPane tab="Data" key="2">
        <Hierarchy2 />
      </TabPane>
    </Tabs>
  );
};

export default HierarchyWithTabs;
