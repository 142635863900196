import {
  Button,
  Form,
  InputNumber,
  message,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notifyreload } from "../../../components/alert/antdnotif";
import antdPrompt from "../../../components/prompts/antdPrompt";
import Select1 from "../../../components/select/select1";
import { updateConfig } from "../../../firebase/appconfig/update";
import { catcher } from "../../../firebase/util";
import {
  selectConfigData,
  setConfigData,
} from "../../../redux/features/configSlice";
import { plans as plansFixed } from "../../../helper/plans";

const { Option } = Select;

const Settings = ({ selectedPlan, plans, updatePlan, loading }) => {
  const [form] = Form.useForm();

  const handleUpdatePlan = () => {
    const updatedPlan = form.getFieldsValue();
    updatePlan(updatedPlan);
  };

  const columns = [
    {
      title: "Setting",
      dataIndex: "setting",
      key: "setting",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value, record) => (
        <Form.Item
          name={[selectedPlan, record.setting]}
          initialValue={value}
          key={record.setting}
        >
          <InputNumber />
        </Form.Item>
      ),
    },
  ];

  const data = Object.keys(plans[selectedPlan]).map((key) => ({
    key,
    setting: key,
    value: plans[selectedPlan][key],
  }));

  return (
    <div>
      <Form form={form} onFinish={handleUpdatePlan}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          showHeader={false}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const Plans = () => {
  const [selectedPlan, setSelectedPlan] = useState("free");
  const [loading, setLoading] = useState(false);
  const plans = useSelector(selectConfigData)?.plans;
  // const plans = plansFixed;
  const dispatch = useDispatch();

  const updatePlan = async (updatedPlan) => {
    catcher(
      async () => {
        const updatesPlansObject = {
          ...plans,
          [selectedPlan]: updatedPlan[selectedPlan],
        };
        await updateConfig(updatesPlansObject, "plans");
        dispatch(setConfigData({ plans: updatesPlansObject }));
        notifyreload();
      },
      { setLoading }
    );
  };
  const addPlan = async (planName) => {
    catcher(
      async () => {
        const updatesPlansObject = {
          ...plans,
          [planName]: { ...plans["free"] },
        };
        await updateConfig(updatesPlansObject, "plans");
        dispatch(setConfigData({ plans: updatesPlansObject }));
        
        notifyreload();
      },
      { setLoading }
    );
  };

  const addKey = async (key) => {
    catcher(
      async () => {
        const updatedPlans = JSON.parse(JSON.stringify(plans));
        Object.keys(updatedPlans).forEach((plankey) => {
          updatedPlans[plankey] = { [key]: 0, ...updatedPlans[plankey] };
        });
        await updateConfig(updatedPlans, "plans");
        dispatch(setConfigData({ plans: updatedPlans }));
        
        notifyreload();
      },
      { setLoading }
    );
  };

  const handlePlanChange = (value) => {
    setSelectedPlan(value);
    alert(value + " has been selected");
  };
  const handleClickAddPlan = () => {
    catcher(
      async () => {
        const plan_name = await antdPrompt(
          "PLAN NAME",
          "DONE",
          "PLEASE ADD THE PLAN NAME."
        );
        if (plan_name?.trim()?.length === 0 || !plan_name) {
          return message.error("PLEASE ADD NAME!!!");
        }
        await addPlan(plan_name);
      },
      { setLoading, success_msg: "" }
    );
  };
  const handleClickAddKey = () => {
    catcher(
      async () => {
        const key = await antdPrompt(
          "KEY NAME",
          "DONE",
          "PLEASE ADD THE KEY. It will be set as 0 for all available plans initially."
        );
        if (key?.trim()?.length === 0 || !key) {
          return message.error("PLEASE ADD KEY!!!");
        }
        await addKey(key);
      },
      { setLoading, success_msg: "" }
    );
  };
  return (
    <div>
      <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <Select1
          title="Select Plan"
          value={selectedPlan}
          setValue={handlePlanChange}
          options={Object.keys(plans).map((plan) => plan)}
        ></Select1>
        <Button onClick={handleClickAddPlan}>ADD PLAN</Button>
      </span>
      <span style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <b>Developer Options</b>
        <Button onClick={handleClickAddKey}>ADD NEW KEY</Button>
      </span>
      <Typography.Title level={4}>instructions</Typography.Title>
      <ul>
        <li>-1 means infinite</li>
        <li>0 means not allowed</li>
        <li>form max inputs will be equal to the number</li>
      </ul>
      <Settings
        selectedPlan={selectedPlan}
        plans={plans}
        updatePlan={updatePlan}
        loading={loading}
      />
    </div>
  );
};

export default Plans;
