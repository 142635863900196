export function sortObjectsByArray(arrayOfObjects, sortOrder, key) {
  return arrayOfObjects.sort(
    (a, b) => sortOrder.indexOf(a[key]) - sortOrder.indexOf(b[key])
  );
}
export function objectToArray(obj, keyName = 'key') {
  return Object.keys(obj).map(key => ({
      ...obj[key],
      [keyName]: key
  }));
}
