import { openIndexedDB } from "./indexedDbUtils";
import { findCompany, openSites } from "../../../helper/attribute";
import { setResults } from "../../../redux/features/appSlice";
import store from "../../../redux/store";
import { scope3overwrite } from "../../../helper/results";

/**
 * Fetches results from IndexedDB based on form name, batch size, and plan.
 * @param {string} formName - The name of the form to filter by (optional).
 * @param {number} batchSize - The size of each batch for processing.
 * @param {string} plan - The plan to filter by (optional).
 * @returns {Promise<void>} - Resolves when results are fetched and dispatched.
 */
export async function fetchResultsOffline(formName = undefined, batchSize = 30, plan = undefined) {
  console.log("Fetching data for formName, batchSize, plan", formName, batchSize, plan);

  // Fetch open sites
  let sites = openSites() || [];
  if (sites.length === 0) {
    store.dispatch(setResults([]));
    return;
  }

  if (plan) {
    // Filter sites by plan
    sites = sites.filter((site) => findCompany(site.companyId)?.plan === plan);
  }

  const dbName = "FormDataDB";
  const storeName = "FormDataStore";
  const results = [];

  try {
    const db = await openIndexedDB(dbName, storeName);
    const transaction = db.transaction(storeName, "readonly");
    const store_o = transaction.objectStore(storeName);

    // Process sites in batches
    for (let i = 0; i < sites.length; i += batchSize) {
      const batchSites = sites.slice(i, i + batchSize);
      const batchSiteIds = batchSites.map((site) => site.id);

      const siteResults = await new Promise((resolve, reject) => {
        const request = store_o.getAll();
        request.onsuccess = () => {
          const data = request.result.filter((item) => batchSiteIds.includes(item.siteId));
          resolve(data);
        };
        request.onerror = (event) => reject(event.target.error);
      });

      // If formName is specified, filter further by formName
      const filteredResults = formName ? siteResults.filter((item) => item.name === formName) : siteResults;

      results.push(...filteredResults);
    }

    // Dispatch the results to the store
    console.log(store,"store");
    store.dispatch(setResults(scope3overwrite(results)));
    console.log("Results fetched and dispatched successfully.");
  } catch (error) {
    console.error("Error fetching results:", error);
    throw error;
  }
}
