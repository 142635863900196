import React, { useState } from "react";
import SelectMultiple from "../../../components/input/SelectMultiple";

import { Button, Drawer, Input, Popconfirm, Select, Space } from "antd";
import {
  designations,
  findCompanies,
  findUsersByCompanyId,
  getCurrentUserDesignation,
} from "../../../helper/attribute";

import { createGroup } from "../../../firebase/group/create";
import { updateGroup } from "../../../firebase/group/update";
import { catcher } from "../../../firebase/util";
import { label } from "../../../helper/uimaker";
const { Option } = Select;
const AddGroup = ({ open, setOpen, editModeData }) => {
  let mode = "add";
  if (editModeData) mode = "edit";
  const designation = getCurrentUserDesignation();
  const [loading, setLoading] = useState(false);
  const companies = findCompanies();
  let users = [];
  const [data, setData] = useState({ ...editModeData });
  companies.forEach(
    (company) => (users = [...users, ...findUsersByCompanyId(company.id)])
  );

  const onClose = () => {
    setOpen(false);
  };
  const handleGroupAddorUpdate = async () => {
    await catcher(
      async () => {
        if (mode === "edit") {
          await updateGroup(data, editModeData);
        } else {
          await createGroup(data);
        }
      },
      {
        setLoading,
      }
    );
    setData({});
    setOpen(false);
  };
  return (
    <>
      <Drawer
        title={
          mode === "add" ? (
            "Create new Group"
          ) : (
            <>
              Editing{" "}
              <span style={{ color: "green", textDecoration: "underline" }}>
                {editModeData.name}
              </span>
            </>
          )
        }
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Popconfirm
              onConfirm={handleGroupAddorUpdate}
              title="Do you confirm?"
              okButtonProps={{ loading }}
            >
              <Button type="primary">Submit</Button>
            </Popconfirm>
          </Space>
        }
      >
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>Group Name</h3>
            <Input
              value={data.name}
              maxLength={80}
              placeholder="Enter Group Name"
              onChange={(e) => setData({ ...data, name: e.target.value })}
            ></Input>
          </div>

          {designation === designations.super_admin && (
            <>
              <h3>Select Companies</h3>
              <SelectMultiple
                nolabel
                value={data.companies}
                handleChange={(companies) => setData({ ...data, companies })}
                options={companies.map((c) => ({
                  label: c.title,
                  value: c.id,
                }))}
              />
              <h3>
                Select Admins{" "}
                {label(
                  "The role of selected user will be elevated to group admin. On dismissing he will go back to normal role."
                )}
              </h3>
              <SelectMultiple
                nolabel
                value={data.admins}
                handleChange={(admins) => setData({ ...data, admins })}
                options={users.map((user) => ({
                  value: user.id,
                  label: user.firstName + " " + user.lastName,
                }))}
              />
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};
export default AddGroup;
