import { ResponsiveContainer } from "recharts";
import { fixDecimals } from "../../../../helper/wodash";
import { useMediaQuery } from "react-responsive";
import useSize from "../../../../hooks/useSize";
export const combineData = (data) => {
  // Calculate the total emissions
  const totalEmissions = data.reduce((sum, entry) => sum + entry.result, 0);
  let othersKeys = [];

  // Filter data points contributing less than 5%
  const thresholdPercentage = 5;
  const filteredData = data.filter((entry) => {
    if ((entry.result / totalEmissions) * 100 >= thresholdPercentage) {
      return true;
    } else {
      othersKeys.push(entry.key);
    }
  });

  // Calculate the total emissions of the "Others" category
  const othersTotal =
    totalEmissions -
    filteredData.reduce((sum, entry) => {
      return sum + entry.result;
    }, 0);

  const combinedData = [
    ...filteredData,
    {
      key: "Other = " + othersKeys.join(", "),
      result: fixDecimals(othersTotal),
    },
  ];
  if (!othersKeys.length) return data;
  return combinedData;
};

export const XYLabels = ({ xLabel, yLabel }) => {
  return (
    <>
      <div
        className="x-axis"
        style={{
          position: "absolute",
          left: "50%", // Center horizontally
          bottom: "-3px", // Adjust as needed
          transform: "translateX(-50%)", // Center precisely horizontally
        }}
      >
        {xLabel}
      </div>
      <div
        className="y-axis"
        style={{
          position: "absolute",
          left: "14px", // Adjust as needed
          top: "50%", // Center vertically
          transform: "translateY(0%) rotate(270deg)",
          transformOrigin: "bottom left", // To set the rotation origin at the bottom left corner
        }}
      >
        {yLabel}
      </div>
    </>
  );
};

export const GraphWrap = ({
  children,
  xLabel = <div>Time</div>,
  showLabels = false,
}) => {
  const {isMobile} = useSize();
  const yLabel = (
    <div style={{ fontSize: isMobile ? "11px" : "14px" }}>
      Emissions (tCO<sub>2</sub>e)
    </div>
  );
  if (isMobile) showLabels = false;
  // Other media queries...

  return (
    <div style={{overflowX:isMobile?"auto":""}}>

    <div style={{ display: "relative", padding: 10, width:isMobile?"500px":"auto",  }}>
      {showLabels ? <XYLabels xLabel={xLabel} yLabel={yLabel} /> : ""}
      <div className="should-save-info">Apply options to view chart.</div>
      <ResponsiveContainer width="100%" height={400}>
        {children}
      </ResponsiveContainer>
    </div>
    </div>
  );
};

export const graph_colors = [
  "#1E88E5", // Bright Blue
  "#E53935", // Bright Red
  "#43A047", // Bright Green
  "#FB8C00", // Bright Orange
  "#8E24AA", // Bright Purple
  "#00ACC1", // Bright Teal
  "#5E35B1", // Bright Indigo
  "#D81B60", // Bright Pink
  "#FDD835", // Bright Yellow
  "#039BE5", // Bright Light Blue
  "#7CB342", // Bright Lime Green
  "#6D4C41", // Bright Brown
  "#546E7A", // Bright Blue Grey
  "#EC407A", // Bright Light Red
  "#66BB6A", // Bright Light Green
  "#29B6F6", // Bright Light Blue
  "#FFD54F", // Bright Light Yellow
  "#AB47BC", // Bright Light Purple
  "#26C6DA", // Bright Light Teal
];

export function combineAndSumProperties(objects) {
  // if (!objects || !Array.isArray(objects) || objects.length === 0) {
  //   return null; // Return null or handle the error appropriately
  // }

  const combinedObject = {};

  objects.forEach((obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop) && typeof obj[prop] === "number") {
        if (!combinedObject.hasOwnProperty(prop)) {
          combinedObject[prop] = 0;
        }
        combinedObject[prop] += obj[prop];
      }
    }
  });

  return combinedObject;
}

export function fixDecimalsOfObjects(objList) {
  return objList.map((obj) => {
    const newObj = {};
    for (const key in obj) {
      if (typeof obj[key] === "number") {
        newObj[key] = parseFloat(obj[key].toFixed(2));
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}
