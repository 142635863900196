import "./style.css";
import { Button, Divider, message, Typography, Input, DatePicker, Space, Table, Modal, Tag, Switch, Select, Tooltip, Tabs, Collapse } from "antd";
import React, { useState, useEffect } from "react";
import { catcher } from "../../../firebase/util";
import axios from "axios";
import { useAtom } from "jotai";
import { consultantAtom, formNamesAtom, reportDataAtom, tableDataSourceAtom } from "../atom/atoms";
import { downloadFileFromStorage } from "../../../firebase/storage/download";
import { getCurrentDate, getCurrentTime, getCurrentYear } from "../../../helper/time";
import { calculateTotals } from "../../extras/testing/other/banner-carbondi";
import store from "../../../redux/store";
import { getAnalysis } from "../../extras/testing/workers/year";
import { designations, findCompanies, firstCompany, getCurrentUserDesignation } from "../../../helper/attribute";
import { fixDecimals, getUniqueProps, removeDuplicates } from "../../../helper/wodash";
import { saveAs } from "file-saver";
import CbfReportDownload from "../../extras/testing/CbfReportDownload";
import mergeAndDownloadPDF from "./helper";
import { findForm, getForms } from "../../../static/formRoutes";
import CommentsTable from "./CommentsTable";
import { useSelector } from "react-redux";
import { selectResults, selectSites } from "../../../redux/features/appSlice";
import Loading from "../../../components/loading";
import { InfoCircleTwoTone, InfoOutlined, QuestionOutlined, UploadOutlined } from "@ant-design/icons";
import { updateUserClicks } from "../../../firebase/user/update";
import { getAllParamsFromLocation } from "../../../helper/loc";
import { findGHG, getGHGTitle } from "../../../static/ghg.static";
import { arrayToEnglishString, removeLastLine } from "../../../helper/string";
import { get_consultants } from "../../../firebase/super_admins/get_consultants";
import SelectSearch from "../../../components/select/SearchSelect";
import { getCacheOptions, updateCacheOptions } from "../../../firebase/cache/create";
import { objectToArray } from "../../../helper/arr";
import Permissions from "../../super-admin/SuperAdminSectionPages/Permissions";
import Companies from "../../admin/companies";
const { TabPane } = Tabs;
function convertValuesToStrings(list) {
  var groupedByScope = {};
  var grandTotals = {};

  // Group objects by scope
  list.forEach(function (obj) {
    var scope = obj["Scope"];
    if (!groupedByScope[scope]) {
      groupedByScope[scope] = [];
    }
    groupedByScope[scope].push(obj);
  });

  var finalList = [];

  // Process each scope group
  for (var scope in groupedByScope) {
    if (groupedByScope.hasOwnProperty(scope)) {
      var scopeObjects = groupedByScope[scope];
      var scopeTotals = {};

      // Process each object within the scope group
      scopeObjects.forEach(function (obj) {
        var newObj = {};

        // Process each key in the object
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
            var value = obj[key];

            // Treat "Scope" key first
            if (key === "Scope") {
              newObj[key] = String(value);
            } else {
              // Convert numeric values to 2 decimal places and calculate totals
              if (!isNaN(value) && value !== "") {
                value = parseFloat(value).toFixed(2);
                // Accumulate totals for numeric values within the scope
                if (!scopeTotals[key]) {
                  scopeTotals[key] = 0;
                }
                scopeTotals[key] += parseFloat(value);

                // Accumulate grand totals across all scopes
                if (!grandTotals[key]) {
                  grandTotals[key] = 0;
                }
                grandTotals[key] += parseFloat(value);
              }
              newObj[key] = String(value);
            }
          }
        }
        finalList.push(newObj);
      });

      // Add a summary object for the scope
      var summaryObj = { Scope: "Scope " + scope + " Total" };

      // Add totals or empty strings for non-numeric fields
      for (var key in scopeTotals) {
        if (scopeTotals.hasOwnProperty(key)) {
          summaryObj[key] = fixDecimals(scopeTotals[key], 2, true);
        }
      }

      // Ensure that non-numeric fields are represented by empty strings in the total object
      for (var objKey in scopeObjects[0]) {
        if (!scopeTotals.hasOwnProperty(objKey) && objKey !== "Scope") {
          summaryObj[objKey] = "";
        }
      }

      finalList.push(summaryObj);
    }
  }

  // Add a grand total at the end
  var grandTotalObj = { Scope: "Total (Scope 1, 2 and 3)" };

  // Populate grand total values or empty strings for non-numeric fields
  for (var key in grandTotals) {
    if (grandTotals.hasOwnProperty(key)) {
      grandTotalObj[key] = fixDecimals(grandTotals[key], 2, true);
    }
  }

  // Ensure that non-numeric fields are represented by empty strings in the grand total object
  for (var key in list[0]) {
    if (!grandTotals.hasOwnProperty(key) && key !== "Scope") {
      grandTotalObj[key] = "";
    }
  }

  finalList.push(grandTotalObj);

  return finalList;
}

function convertListToColumns(list) {
  return list.map((item) => {
    return {
      Data: item.data,
      "Source of data": item.source || "",
      "Verifier comments": item.verifier || "",
    };
  });
}
function singleLineString(str) {
  return str.replace(/\s+/g, " ").trim();
}

function combineChildrenghg(data, year) {
  let combinedChildrenghg = [];

  // Iterate through each item in dataSource
  data.dataSource.forEach((scope) => {
    if (scope.childrenghg && Array.isArray(scope.childrenghg)) {
      // Filter based on the year provided in the params
      const filteredGHG = scope.childrenghg.filter((item) => item[year] !== undefined);
      combinedChildrenghg = combinedChildrenghg.concat(filteredGHG);
    }
  });

  return combinedChildrenghg;
}

const fieldConfig = {
  date: { type: "text", info: "The date of the report (DD/MM/YYYY format)" },
  fullName: { type: "text", info: "Full name of user." },
  marketBasedEmission: { type: "text", info: "Market based emission value" },
  locationBasedEmission: { type: "text", info: "Location based emission value" },
  companyName: { type: "text", info: "Name of the company" },
  startDate: { type: "text", info: "Follow DD/MM/YYYY format" },
  endDate: { type: "text", info: "Follow DD/MM/YYYY format" },
  accuracyComments: { type: "textarea", info: "Comments on the accuracy of the report" },
  recommendations: { type: "textarea", info: "Write recommendations separated by a new line." },
  consultant_signature: { type: "text", info: "Consultant's signature URL (signature to be used in report)" },
  finalComments: { type: "textarea", info: "Sign off comments" },

  consultant_details: { type: "textarea", info: "Details about the consultant" },
  consultant_summary: { type: "textarea", info: "Consultant summary" },
  // reviewer
  reviewer_details_without_phone: { type: "textarea", info: "Reviewer details" },
  reviewer_summary: { type: "textarea", info: "Reviewer summary" },
  // authorizer
  authorizer_details_without_phone: { type: "textarea", info: "Authorizer details" },
  authorizer_summary: { type: "textarea", info: "Authorizer summary" },
};

const Verification = () => {
  const results = useSelector(selectResults);
  const [consultant, setConsultant] = useAtom(consultantAtom);
  const [formNames, setFormNames] = useState(formNamesAtom);
  const [tableDataSource, setTableDataSource] = useState(tableDataSourceAtom);
  const [reportData, setReportData] = useAtom(reportDataAtom);
  const [year, setYear] = useState(getCurrentYear());
  const [useWtt, setUseWtt] = useState(true);
  const [reviewer, setReviewer] = useState();
  const [authorizer, setAuthorizer] = useState();
  const sites = useSelector(selectSites);
  const consultants = useSelector((state) => state.superAdminData.consultants);
  const [cacheCompany, setCacheCompany] = useState(undefined);

  const getReportData = async (consultant) => {
    const company = firstCompany();
    const options = {
      wtt: useWtt ? "Included" : "Excluded",
      type: "Location",
      reporting: "All",
      sites: getCurrentUserDesignation() !== designations.group_admin ? [findCompanies()[0]?.id] : findCompanies()?.map((c) => c.id),
    };

    const locationAnalysis = getAnalysis(store.getState().app.results, options);
    const marketAnalysis = getAnalysis(store.getState().app.results, {
      ...options,
      type: "Market",
    });

    const locGhg = combineChildrenghg(locationAnalysis, year);
    const mktGhg = combineChildrenghg(marketAnalysis, year);
    const locationTotals = calculateTotals(locationAnalysis.dataSource, year, true);
    const marketTotals = calculateTotals(marketAnalysis.dataSource, year, true);
    console.log("loc:", locationAnalysis);

    console.log(locGhg, mktGhg);

    const emissionTable = [];
    locGhg.forEach((item, index) => {
      const { scope, name } = item;
      const mktItem = mktGhg.find((m) => m.scope === scope && m.name === name);
      emissionTable.push({
        Scope: scope,
        "Emission Source": getGHGTitle(name),
        "Location-Based (tCO₂e)": fixDecimals(item[year], 2, true),
        "Market-Based (tCO₂e)": fixDecimals(mktItem?.[year], 2, true),
      });
    });

    const findc = (fullName) => {
      const x = consultants?.find((c) => c.firstName + " " + c.lastName === fullName);
      console.log(x, fullName, consultants);
      return x;
    };

    return {
      date: getCurrentDate(),
      fullName: store.getState().user.doc.firstName + " " + store.getState().user.doc.lastName,
      firstName: store.getState().user.doc.firstName,
      marketBasedEmission: fixDecimals(marketTotals),
      locationBasedEmission: fixDecimals(locationTotals),
      companyName: findCompanies()?.[0]?.title,
      startDate: "31/12/" + (Number(year) - 1),
      endDate: "31/12/" + year,
      accuracyComments: "",
      recommendations: "",
      finalComments: "",
      // consultant
      consultantEmail: consultant.email,
      //--details
      consultant_details: consultant.details_for_template,
      consultant_details_inline: singleLineString(removeLastLine(consultant.details_for_template)),
      consultant_details_without_phone: removeLastLine(consultant.details_for_template),
      consultant_signature: await downloadFileFromStorage(consultant.signature, "s.png", true),
      consultant_summary: consultant.summary_for_template,
      // reviwer
      reviewer_details_without_phone: removeLastLine(findc(reviewer)?.details_for_template, 2),
      reviewer_summary: findc(reviewer)?.summary_for_template,

      //authorizer
      authorizer_details_without_phone: removeLastLine(findc(authorizer)?.details_for_template, 2),
      authorizer_summary: findc(authorizer)?.summary_for_template,

      formNames,
      formCommentsTable: [],
      year,
      // pro
      companyAbout: `${company.title} is ${company.about}`,
      emissionTable: convertValuesToStrings(emissionTable),
      companyCountries: arrayToEnglishString(removeDuplicates(sites.map((site) => site.country))),
    };
  };
  useEffect(() => {
    if (consultant) {
      getReportData(consultant).then(setReportData);
    }
  }, [consultant, year, useWtt, reviewer, authorizer]);

  useEffect(() => {
    catcher(async () => {
      const { auth_key } = getAllParamsFromLocation();
      if (auth_key) {
        const { data } = await axios.post(`${process.env.REACT_APP_BE}/api/user/login/consultant`, { direct_using_uid: auth_key });
        if (!data.consultant) return message.error("Error getting consultant information");
        setConsultant(data);
        get_consultants(); //todo: make more secure
      }
    }, {});
  }, []);

  useEffect(() => {
    if (results) {
      const uniqueFormNames = getUniqueProps(results, "name");
      const formNames = uniqueFormNames.map((name) => findForm(name)?.title);
      setFormNames(formNames);
      setTableDataSource(formNames.map((name) => ({ data: name, key: name })));
    }
  }, [results]);

  useEffect(() => {
    catcher(async () => {
      const cache = await getCacheOptions(firstCompany()?.title, "");
      setCacheCompany(cache);
    }, {});
  }, []);

  if (!results) return <Loading />;

  const handleGetConsultant = async () => {
    await catcher(
      async () => {
        const email = prompt("Email");
        const password = prompt("Password");
        if (!email || !password) return message.error("Email or password cannot be empty");

        const { data } = await axios.post(`${process.env.REACT_APP_BE}/api/user/login/consultant`, { username: email, password });
        if (!data.consultant) return message.error("Error performing action");
        setConsultant(data);
      },
      { loading_msg: "Starting secure consultant session..." }
    );
  };

  const handleDownloadReport = async (type = 1) => {
    // 1 2 type, 2 is for pro.
    catcher(
      async () => {
        let inputFile;
        if (type == 1) {
          await updateUserClicks("download_pdf_report");
          inputFile = await downloadFileFromStorage("assets/verification_template.docx", "template.docx", true);
        } else {
          inputFile = await downloadFileFromStorage("assets/verification_template_pro.docx", "template.docx", true);
        }
        const dataToSend = {
          inputFile,
          context: {
            ...reportData,
            recommendations: reportData.recommendations.split("\n"),
            formCommentsTable: convertListToColumns(tableDataSource),
          },
        };
        console.log(dataToSend);
        const result = await axios.post("https://testing-wasilislam.pythonanywhere.com/docx/template", dataToSend, { responseType: "blob" });
        saveAs(result.data, `${findCompanies()?.[0]?.title} Verification Report.docx`);
        // save data for reuse.
        updateCacheOptions(
          firstCompany()?.title,
          {
            [`${getCurrentTime()}_verification`]: JSON.stringify({
              tableDataSource,
              reportData,
            }),
          },
          ""
        );
      },
      { loading_msg: "Generating file, please wait" }
    );
  };

  const handleChange = (key, value) => {
    setReportData((prevData) => ({ ...prevData, [key]: value }));
  };

  const renderField = (key, fieldConfigItem) => {
    const label = key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .replaceAll("_", " ");
    const commonProps = { key, className: "report-data-field" };
    const inputProps = {
      value: reportData[key],
      onChange: (e) => handleChange(key, e.target.value),
      size: "large",
    };
    const infoElement = (
      <Tooltip title={<>{fieldConfigItem.info}</>}>
        <QuestionOutlined />
      </Tooltip>
    );
    const labelElement = (
      <label className="report-data-label" style={{ fontWeight: "bold" }}>
        {label}:
      </label>
    );

    switch (fieldConfigItem.type) {
      case "text":
        return (
          <div {...commonProps}>
            <div className="flexer">
              {labelElement}
              <Input {...inputProps} status={(!reportData[key]?.length || !reportData[key] === "string") && "warning"} className="report-data-input" />
            </div>
          </div>
        );
      case "textarea":
        return (
          <div {...commonProps}>
            <div className="flexer">
              {labelElement}
              <Input.TextArea autoSize {...inputProps} className="report-data-textarea" />
            </div>
          </div>
        );
      case "date":
        return (
          <div {...commonProps}>
            {labelElement}
            <DatePicker value={reportData[key]} onChange={(date) => handleChange(key, date)} className="report-data-datepicker" />
          </div>
        );
      default:
        return null;
    }
  };

  const renderReportDataFields = () => Object.keys(reportData).map((key) => fieldConfig[key] && renderField(key, fieldConfig[key]));

  const downloadDiv = (
    <div>
      <p style={{ marginBottom: "20px", fontSize: "18px", fontWeight: "500", color: "#333" }}>Step 1: Select and download your preferred report.</p>
      <div style={{ display: "flex", gap: "15px", marginBottom: "25px" }}>
        <button
          onClick={() => handleDownloadReport(1)}
          style={{
            backgroundColor: "#0050b3",
            color: "#fff",
            fontWeight: "bold",
            padding: "15px 30px",
            fontSize: "16px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
          }}
        >
          📄 Download Report
        </button>
        <button
          onClick={() => handleDownloadReport(2)}
          style={{
            backgroundColor: "#0050b3",
            color: "#fff",
            fontWeight: "bold",
            padding: "15px 30px",
            fontSize: "16px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
          }}
        >
          📑 Download MX Report
        </button>
      </div>
      <p style={{ marginBottom: "25px", fontSize: "16px", lineHeight: "1.6", color: "#555" }}>
        After downloading, verify the data and formatting of the PDF. Once confirmed, merge it with the analysis report.
      </p>
      <CbfReportDownload
        renderThisInstead={
          <button
            style={{
              backgroundColor: "#28a745",
              color: "#fff",
              fontWeight: "bold",
              padding: "15px 30px",
              fontSize: "16px",
              borderRadius: "8px",
              border: "none",
              cursor: "pointer",
            }}
          >
            📤 Upload for Converting to PDF
          </button>
        }
        callFunctionInsteadOfDownload={mergeAndDownloadPDF}
        year={2024}
        results={store.getState().app.results}
      />{" "}
    </div>
  );
  const consultantSettings = (
    <div style={{ padding: "20px", maxWidth: "300px" }}>
      <SelectSearch bgless borderless title="Use WTT" value={useWtt ? "Yes" : "No"} setValue={(val) => setUseWtt(val === "Yes" ? true : false)} options={["Yes", "No"].map((y) => y)} />
      <SelectSearch bgless borderless title="Year" value={year} setValue={setYear} options={[2019, 2020, 2021, 2022, 2023, 2024].map((y) => y)} />
      <SelectSearch bgless borderless title="Reviewer" value={reviewer} setValue={setReviewer} options={consultants?.map((c) => `${c.firstName} ${c.lastName}`)} />
      <SelectSearch bgless borderless title="Authorizer" value={authorizer} setValue={setAuthorizer} options={consultants?.map((c) => `${c.firstName} ${c.lastName}`)} />

      <Divider />
    </div>
  );
  const reportOptions = (
    <div style={{ width: "100%", margin: "auto" }}>
      <Tabs
        tabPosition="top" // Positioning tabs on the right
        defaultActiveKey="1" // Set the default tab
        type="card"
      >
        {/* Tab for report data fields */}
        <TabPane tab="Report Data Fields" key="1">
          {renderReportDataFields()}
        </TabPane>

        {/* Tab for comments table */}
        <TabPane tab="Comments Table" key="2">
          <CommentsTable dataSource={tableDataSource} setDataSource={setTableDataSource} />
        </TabPane>
      </Tabs>
    </div>
  );

  const renderReportData = () => (
    <>
      <Divider style={{ fontWeight: "bold", fontSize: "20px" }}>Analysis Verification Report</Divider>
      <div style={{ display: "flex", flexDirection: "column", flex: "1 1" }} className="main-verification">
        <Typography.Title level={3}> 1. Report settings.</Typography.Title>
        <Divider></Divider>
        {consultantSettings}
        <Typography.Title level={3}> 2. Review data</Typography.Title>
        <Divider></Divider>
        {cacheCompany && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ width: "400px", float: "right" }}>
              <SelectSearch
                title="History"
                borderless
                bgless
                setValue={(key) => {
                  const data = JSON.parse(cacheCompany[key]);
                  console.log("data", data);
                  setTableDataSource(data?.tableDataSource);
                  setReportData(data?.reportData);
                }}
                options={Object.keys(cacheCompany)
                  ?.filter((key) => key?.includes("_verification"))
                  .map((x) => x.replace("_verification", ""))}
              ></SelectSearch>
            </div>
          </div>
        )}
        {reportOptions}
        <Typography.Title level={3}> 3. Download Reports</Typography.Title>
        <Divider></Divider>

        {downloadDiv}
      </div>
    </>
  );

  if (!consultant)
    return (
      <Button onClick={handleGetConsultant} style={{ backgroundColor: "#1890ff", color: "white", fontWeight: "bold" }}>
        Start
      </Button>
    );

  return (
    <div className="verification-wrapper">
      <Typography.Title
        level={3}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#0050b3",
          fontWeight: 600,
        }}
      >
        Hi {consultant.firstName} {consultant.lastName}!
        <Button
          onClick={() => setConsultant(null)}
          style={{
            backgroundColor: "#ff4d4f",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "4px",
          }}
        >
          Logout Session
        </Button>
      </Typography.Title>

      <Typography.Paragraph style={{ fontSize: "16px", fontWeight: 500 }}>You can use this page to perform user actions and download reports.</Typography.Paragraph>

      <Collapse style={{ marginTop: "20px" }} bordered={false} defaultActiveKey={["0"]}>
        <Collapse.Panel header="Personal Details" key="1">
          <pre>
            <b>
              <u>Details</u>
            </b>
            <br />
            {consultant.details_for_template}
          </pre>
          <pre>
            <b>
              <u>Summary</u>
            </b>
            <br />
            {consultant.summary_for_template}
          </pre>
          <Button type="link" onClick={() => downloadFileFromStorage(consultant.signature, "your_signature.png")} style={{ fontWeight: 600, color: "#0050b3" }}>
            Download Signature
          </Button>
        </Collapse.Panel>
      </Collapse>

      {renderReportData()}
    </div>
  );
};
export default function VerificationWrapper() {
  const results = useSelector(selectResults);
  if (!results) return <Loading />;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '20px' }}>
      <Tabs
        defaultActiveKey="3"
        tabPosition="left"
        style={{ height: '100%' }}
        type="line" // You can use 'line' or 'card' for different styles
      >
        <TabPane tab="Permissions" key="1">
          <Permissions />
        </TabPane>
        <TabPane tab="Edit Plan" key="2">
          <Companies is_consultant_page={true} />
        </TabPane>
        <TabPane tab="Verification" key="3">
          <Verification />
        </TabPane>
      </Tabs>
    </div>
  );
}