import {
  ArrowRightOutlined
} from "@ant-design/icons";
import { Input, Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { T1 } from "../../../appconfig/texts";
import { createCompany } from "../../../firebase/company/create";
import { except } from "../../../firebase/util";
import { routePath } from "../../../static/routes.static";

export default function AddCompanyModal({ visible, setVisible }) {
  const [status, setStatus] = useState({});
  const [data, setData] = useState({ title: "" });
  const navigate = useNavigate();
  const onOk = async () => {
    except(async () => {
      const id = await createCompany(data);
      navigate(routePath.CompanyDetails(id));
      setVisible(false);
    });
  };
  return (
    <Modal
      title={<T1 pos="company.add.title" />}
      okText={
        <span>
          Create <ArrowRightOutlined />{" "}
        </span>
      }
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={onOk}
    >
      <p>
        <T1 pos="company.add.desc" />
      </p>
      <Input
        value={data.title}
        onChange={(e) => setData({ ...data, title: e.target.value })}
        status={status.title === "error" && "error"}
        addonBefore={<T1 pos="company.add.form.title" />}
        placeholder="New Company Name"
      />
    </Modal>
  );
}
