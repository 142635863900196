import { findCompany, findSite, openSites } from "../../../../helper/attribute";
import { subtractMonths } from "../../../../helper/time";
import { transformKeys } from "../../../../helper/wodash";
import vars from "../../../../static/attributes.static";
import { dataForms } from "../../../../static/formRoutes";
import { analysisnames } from "../config";
import { commonFilters, formatName, genRows } from "./config";

export function getAnalysis(results, filters, rough) {
  const rows = genRows(filters);
  // Cols will contain seperators eg years, months, datatags etc
  const cols = new Set();
  const finalResults = {
    1: { name: "Scope 1", children: [], childrenghg: [] },
    2: { name: "Scope 2", children: [], childrenghg: [] },
    3: { name: "Scope 3", children: [], childrenghg: [] },
    total: { name: "Total" },
  };
  const sitesData = openSites();
  let scopechart = {};
  let ukkwh = { name: "Uk kWh" },
    globalkwh = { name: "Global kWh" };

  // applying filters
  const ghgData = {};
  const calculatedKwhMemo = {};

  for (let resultx of results) {
    const result = JSON.parse(JSON.stringify(resultx));
    const name = result.name;
    const { title: site, country: siteCountry } = sitesData.find((site) => result.siteId === site.id);
    const sitetags = sitesData.find((site) => result.siteId === site.id).tags;
    if (sitetags)
      for (let col of sitetags) {
        cols.add(col); //DATE SETTING BASED ON RYSM
        result.date = subtractMonths(result.date, findCompany(findSite(result?.siteId)?.companyId)?.reporting_year_starting_month);

        for (let key of Object.keys(rows[name])) {
          //FILTERS
          if (filters.wtt !== "Included" && key.includes("wtt")) {
            continue;
          }
          //--FILTERS
          let ghg;
          const obj = rows[name][key];
          if (!obj.results) obj.results = {};
          let resultresult = 0;
          // GHG Selection
          if (typeof obj.ghgSelector === "function") {
            ghg = obj.ghgSelector(result); // finds the ghg category
          } else if (key.includes("wtt")) {
            ghg = result.ghgwtt;
          } else {
            ghg = result.ghg;
          }
          // Result Selection
          if (typeof obj.selector === "function") {
            resultresult = obj.selector(result);
          } else {
            if (key === "simple") {
              resultresult = result.result;
            }
            if (key === "wtt" && result.wtt) {
              resultresult = result.wtt;
            }
          }
          let scope = obj.scope;
          let scope2 = scope;
          if (result[vars.result["Overwritten GHG"]]) {
          }
          if ([1, 2, 3, 4].includes(ghg)) {
            scope2 = 1;
          } else if ([5].includes(ghg)) {
            scope2 = 2;
          } else {
            scope2 = 3;
          }
          if (!commonFilters(result, filters, obj)) continue;
          scopechart[col] = scopechart[col] || { col };
          scopechart[col][scope2] = scopechart[col][scope2] || 0;
          const formattedkey = formatName(key + "-" + name);
          scopechart[col][formattedkey] = scopechart[col][formattedkey] || 0;
          //making of chart
          scopechart[col][scope2] += resultresult;
          scopechart[col][formattedkey] += resultresult;
          obj.results[col] = resultresult + (obj.results[col] || 0);
          //Approach 2: Setting directly
          //Taking care of scope
          // if (!resultresult) continue;

          const scope_children = finalResults[scope2]["children"];
          const ghg_children = finalResults[scope2]["childrenghg"];
          let old_result_row = scope_children.find((row) => row["name"] === formatName(key + "-" + name) && row["scope"] === scope2);
          let old_ghg_row = ghg_children.find((row) => row["name"] == ghg && row["scope"] === scope2);
          if (!old_result_row) {
            scope_children.push({
              [col]: resultresult,
              scope: scope2,
              name: formatName(key + "-" + name),
            });
          } else {
            //scope and name will be same now
            old_result_row[col] = (old_result_row[col] || 0) + resultresult;
          }
          if (!old_ghg_row) {
            ghg_children.push({
              [col]: resultresult,
              scope: scope2,
              name: ghg,
            });
          } else {
            old_ghg_row[col] = (old_ghg_row[col] || 0) + resultresult;
          }
          //GHG STUFF
          console.log("Each Result", result, resultresult, JSON.parse(JSON.stringify(finalResults)));

          if (typeof ghg === "number" && result.result) {
            if (scope2 === scope2) {
              if (!ghgData[ghg]) ghgData[ghg] = {};
              ghgData[ghg][col] = resultresult + (ghgData[ghg][col] || 0);
            }
          }
          //~GHG STUFF

          console.log("starting to add secr hours**", result.kwh);
          if (filters.reporting === "SECR" && !calculatedKwhMemo.hasOwnProperty(result.id)) {
            calculatedKwhMemo[result.id] = true;
            if (siteCountry === "United Kingdom") {
              console.log("adding secr**", result.kwh);
              ukkwh[col] = parseFloat(ukkwh[col] || 0) + parseFloat(result.kwh || 0);
            } else {
              console.log("adding secr**", result.kwh);
              globalkwh[col] = parseFloat(globalkwh[col] || 0) + parseFloat(result.kwh || 0);
            }
          }
        }
      }
  }
  let dataSource = [];
  scopechart = transformKeys(Object.values(scopechart), (name) => {
    if (["col", "1", "2", "3"].includes(name)) return name;
    const form = dataForms.find((form) => name.includes(form.name));
    return `${name.includes("wtt") ? "WTT" : ""} ${analysisnames[name] || form?.title}`;
  });

  dataSource = Object.values(finalResults);
  if (filters.reporting === "SECR") {
    dataSource.push(ukkwh);
    dataSource.push(globalkwh);
  }

  if (filters.reportType === "GHG") {
    // Sorting: Currently GHG is based on numbers and they must be sorted in each scope. So, a simple sort function has been used.
    dataSource = dataSource.map((row) => ({
      children: row?.childrenghg?.sort((a, b) => a.name - b.name),
      name: String(row.name),
    }));
  }
  const toReturn = {
    dataSource,
    moreInfo: { cols: [...cols], scopechart, ukkwh, globalkwh },
  };

  return toReturn;
  postMessage(toReturn);
}
