import { EditOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import { routePath } from "../../static/routes.static";
import Loading from "../loading";

export const commonChartOptions = {
  width: "100%",
  height: "400px",
  loader: <Loading title="Creating Chart" />,
  errorElement: <div>Ok</div>,
};

export const ChartWrapper = ({ children }) => {};

export const EmptyChart = () => {
  const navigate = useNavigate();
  return (
    <Empty
      style={{
        height: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      description="Not enough data to display a chart."
      children={
        <div>
          <Button
            onClick={() => {
              navigate(routePath.Form(""));
            }}
          >
            Add data <EditOutlined />{" "}
          </Button>
        </div>
      }
    />
  );
};
