import { Affix, Button, Space } from "antd";
import React, { useState } from "react";
import texts from "../../appconfig/texts";
import { catcher } from "../../firebase/util";

const Adder = ({ handleAdd }) => {
  const [showAdder, setShowAdder] = useState(false);
  const onClick = () => {
    setShowAdder(true);
  };
  if (showAdder) {
    return (
      <>
        <select onClick={(e) => e.stopPropagation()}>
          {["", "array", "string", "number", "boolean", "object"].map((o) => (
            <option value={o}>{o}</option>
          ))}
        </select>
      </>
    );
  } else {
    return (
      <button
        style={{ background: "transparent", border: "none", cursor: "pointer" }}
        onClick={(event) => {
          onClick();
          event.stopPropagation();
        }}
      >
        +
      </button>
    );
  }
};

const Editor = ({ value, setValue, textColor, pathReference }) => {
  const [editorMode, setEditorMode] = useState(false);
  const [v, setV] = useState(value);
  function handleDoubleClick() {
    setEditorMode(true);
  }
  const handleDone = () => {
    setValue(pathReference, v);
    setEditorMode(false);
  };
  const handleCancel = () => {
    setEditorMode(false);
  };
  if (editorMode) {
    return (
      <span style={{ display: "flex" }}>
        <textarea
          style={{ width: "fit-content" }}
          value={v}
          onChange={(e) => setV(e.target.value)}
        />
        <button onClick={handleDone}>✔️</button>
        <button onClick={handleCancel}>✖️</button>
      </span>
    );
  }
  return (
    <span
      onDoubleClick={handleDoubleClick}
      style={{ fontFamily: "monospace", color: textColor }}
    >
      {String(value)}
    </span>
  );
};

const ObjectJS = ({ data, path = [], onUpdateData }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
  };
  const getType = (value) => {
    if (typeof value === "string") return "string";
    if (typeof value === "number") return "number";
    if (typeof value === "boolean") return "boolean";
    if (Array.isArray(value)) return "array";
    if (value instanceof Set) return "set";
    if (typeof value === "object" && value !== null) return "object";
    return "other";
  };

  const renderValue = (value, currentPath) => {
    const type = getType(value);
    let textColor = "#000"; // Default color for other types

    switch (type) {
      case "number":
        textColor = "#28a745"; // Green for numbers
        break;
      case "boolean":
        textColor = "#dc3545"; // Red for booleans
        break;
      default:
        break;
    }
    const handleAdd = () => {
      return;
    };

    const elementPath = [...currentPath]; // Clone the current path
    const pathReference = elementPath.join("."); // Create a dot-separated path reference

    return (
      <div>
        {typeof value === "object" && value !== null && (
          <div onClick={handleToggleCollapse} style={{ cursor: "pointer" }}>
            {isCollapsed ? "▶️" : "🔽"}{" "}
            {Array.isArray(value) ? "Array" : "Object"} (
            {Array.isArray(value) ? value.length : Object.keys(value).length})
            <Adder handleAdd={handleAdd} />
          </div>
        )}
        {!isCollapsed && typeof value === "object" && value !== null && (
          <div style={{ marginLeft: "20px", borderLeft: "1px solid #ccc" }}>
            {Array.isArray(value)
              ? value.map((item, index) => (
                  <div
                    key={index}
                    style={{ marginBottom: "5px", display: "flex", gap: "2px" }}
                  >
                    <span style={{ color: "#666" }}>[{index}]</span>:{" "}
                    <ObjectJS
                      data={item}
                      path={[...elementPath, index]}
                      onUpdateData={onUpdateData} // Pass onUpdateData
                    />
                  </div>
                ))
              : Object.entries(value).map(([key, val]) => (
                  <div
                    key={key}
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      gap: "2px",
                      paddingLeft: "2px",
                    }}
                  >
                    <span style={{}}>
                      <b
                        style={{ fontWeight: 800, position: "sticky", top: 0 }}
                      >
                        {key}:
                      </b>{" "}
                    </span>
                    <ObjectJS
                      data={val}
                      path={[...elementPath, key]}
                      onUpdateData={onUpdateData} // Pass onUpdateData
                    />
                  </div>
                ))}
          </div>
        )}
        {typeof value !== "object" && (
          <Editor
            value={value}
            setValue={onUpdateData} // Pass onUpdateData
            textColor={textColor}
            pathReference={pathReference} // Pass the path reference
          />
        )}
      </div>
    );
  };

  return <div>{renderValue(data, path)}</div>;
};

const AppObjectJS = ({ initialData, setInitialData, updateData }) => {
  const [data, setData] = useState(JSON.parse(JSON.stringify(initialData)));
  const isDifferent = JSON.stringify(data) !== JSON.stringify(initialData);
  const [loading, setLoading] = useState(false);
  // Callback function to update the data
  const onUpdateData = (pathReference, editedValue) => {
    // Clone the current data object
    const newData = { ...data };

    // Use a reference to navigate and update the data
    let current = newData;
    const pathElements = pathReference.split(".");
    for (let i = 0; i < pathElements.length - 1; i++) {
      current = current[pathElements[i]];
    }

    // Update the value at the specified path
    current[pathElements[pathElements.length - 1]] = editedValue;

    // Set the updated data
    setData(newData);
  };
  const handleCancel = () => {
    setData(JSON.parse(JSON.stringify(initialData)));
  };
  const handleUpdate = async () => {
    catcher(async () => {
      await setInitialData(data);
    }, {});
  };

  return (
    <div>
      <h1>Editable Object Viewer</h1>
      {isDifferent && (
        <Affix offsetTop={0}>
          {
            <Space
              style={{
                padding: 10,
                position: "sticky",
                top: 0,
                background: "white",
                width: "100%",
              }}
            >
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" onClick={handleUpdate}>
                Update
              </Button>
            </Space>
          }
        </Affix>
      )}
      <ObjectJS data={data} onUpdateData={onUpdateData} />
    </div>
  );
};

export default AppObjectJS;
