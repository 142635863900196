import { getFactors } from "./emissionfactors";

// fuzzy checks and stuff
function fuzzyCheck(word1, word2) {
  if (typeof word1 !== "string" || typeof word2 !== "string") return false;
  const distance = levenshteinDistance(
    word1?.toLowerCase(),
    word2?.toLowerCase()
  );
  const maxLength = Math.max(word1.length, word2.length);
  const similarity = 1 - distance / maxLength;

  // Adjust the similarity threshold as needed
  const similarityThreshold = 0.8;

  return similarity >= similarityThreshold;
}

function levenshteinDistance(word1, word2) {
  const m = word1.length;
  const n = word2.length;

  const dp = [];

  for (let i = 0; i <= m; i++) {
    dp.push([]);
    dp[i][0] = i;
  }

  for (let j = 1; j <= n; j++) {
    dp[0][j] = j;
  }

  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      const cost = word1[i - 1] === word2[j - 1] ? 0 : 1;
      dp[i][j] = Math.min(
        dp[i - 1][j] + 1, // deletion
        dp[i][j - 1] + 1, // insertion
        dp[i - 1][j - 1] + cost // substitution
      );
    }
  }

  return dp[m][n];
}
export function fuzzyPropsReplace(object, array = getFactors()) {
  try {
    const matchedObject = { ...object };

    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const propValue = object[key];

        let bestMatch = null;
        let bestSimilarity = 0;

        for (const item of array) {
          for (const itemKey in item) {
            if (item.hasOwnProperty(itemKey)) {
              const itemValue = item[itemKey];
              const similarity = fuzzyCheck(propValue, itemValue);

              if (similarity > bestSimilarity) {
                bestMatch = item;
                bestSimilarity = similarity;
              }
            }
          }
        }

        if (bestMatch) {
          matchedObject[key] = bestMatch[key];
        }
      }
    }

    return matchedObject;
  } catch (err) {
    return object;
  }
}
