import { Select } from "antd";
import FakeLabel from "../input/select";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selector .ant-select-selection-placeholder {
    font-size: ${(props) => (props.isExtraSmall ? "12px" : "inherit")};
  }

  .ant-select-dropdown {
    width: ${(props) => (props.isExtraSmall ? "80%" : "auto")};
  }
`;

function rearrangeArray(arr) {
  const grossIndex = arr.indexOf("kWh (Gross CV)");
  const netIndex = arr.indexOf("kWh (Net CV)");
  if (grossIndex !== -1 && netIndex !== -1) {
    arr.splice(grossIndex, 1);
    arr.splice(netIndex, 0, "kWh (Gross CV)");
    return arr.reverse();
  }
  return arr;
}

export default function SelectSearch({
  value,
  setValue,
  options,
  fullWidth = false,
  hideIfSingle = false,
  nolabel = false,
  filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  },
  ...more
}) {
  const isExtraSmall = useMediaQuery({ query: "(max-width: 599px)" });

  console.log(more.title, options);

  if (!Array.isArray(options)) return null;

  if (more.displayAs) {
  }
  options = options?.filter((o) => o !== "" && o);
  if (options.length === 0) return null;
  if (!options?.every((x) => x != null)) {
    return null;
  }
  if (!options.includes(value) && value !== null && value !== undefined) {
    more.error_status = value + " not found";
  }
  if (options.length === 0) return null;
  //
  const element = (
    <StyledSelect
      showSearch
      allowClear={more.allowClear}
      value={value}
      style={{
        width: fullWidth ? "100%" : "10px",
      }}
      onChange={setValue}
      placeholder="Select an option"
      filterOption={filterOption}
      isExtraSmall={isExtraSmall}
    >
      {rearrangeArray(options).map((option) => (
        <Option key={option}>{option}</Option>
      ))}
    </StyledSelect>
  );
  if (nolabel) return element;
  return <FakeLabel {...more} elem={element} />;
}
