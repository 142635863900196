// AppInfoFunctions.js
import {
  collection,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { generateRandomString } from "../../helper/wodash";
import { db } from "../setup";
import store from "../../redux/store";
import { setOther } from "../../redux/features/appSlice";

// Create or Update function
export async function updateAppInfo(data, docId = generateRandomString(20)) {
  const infoDocRef = doc(db, "AppInfo", docId);
  await setDoc(infoDocRef, data, { merge: true });
  store.dispatch(setOther({ key: docId, value: data }));
  return docId;
}

// Delete function
export async function deleteAppInfo(docId) {
  const infoDocRef = doc(db, "AppInfo", docId);
  await deleteDoc(infoDocRef);
}

export async function getAppInfo(docId) {
  const storedValue = store.getState().app.other?.[docId];
  if (storedValue) return storedValue;
  const infoDocRef = doc(db, "AppInfo", docId);

  try {
    const docSnapshot = await getDoc(infoDocRef);
    if (docSnapshot.exists()) {
      store.dispatch(setOther({ key: docId, value: docSnapshot.data() }));
      return docSnapshot.data();
    } else {
      return null; // Document doesn't exist
    }
  } catch (error) {
    console.error("Error getting document:", error);
    throw error;
  }
}
