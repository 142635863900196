import { Modal, Input, Button } from "antd";

const antdPrompt = async (
  promptMessage = "Enter your answer",
  buttonText = "Ok",
  text = "",
  onOk,
  defaultValue = ""
) => {
  return new Promise((resolve, reject) => {
    let userAnswer = defaultValue;

    const handleOk = async () => {
      if (typeof onOk === "function") {
        try {
          await onOk(userAnswer);
          resolve(userAnswer);
        } catch (error) {
          console.error("Error occurred in onOk:", error);
          resolve(null); // Resolve with null if there was an error in onOk.
        }
      } else {
        resolve(userAnswer);
      }
    };

    const handleCancel = () => {
      resolve(null); // Resolve with null if the user cancels the prompt.
    };

    const handleInputChange = (event) => {
      userAnswer = event.target.value;
    };

    Modal.confirm({
      title: promptMessage,
      content: (
        <div>
          {text}
          {defaultValue !== -1 && (
            <Input
              defaultValue={defaultValue}
              placeholder="Type your answer here..."
              onChange={handleInputChange}
            />
          )}
        </div>
      ),
      onOk: handleOk,
      onCancel: handleCancel,
      okText: buttonText,
    });
  });
};

export default antdPrompt;
