import {
  DownloadOutlined,
  InfoCircleTwoTone,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { Button, Input, Skeleton, Tag, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ErrorBoundary from "../../ErrorBoundary";
import AntDrop from "../../components/input/antddropdown";
import FileInput from "../../components/input/datafile";
import { getAppInfo } from "../../firebase/appinfo/create";
import { catcher } from "../../firebase/util";
import { findFormTexts } from "../../helper/attribute";
import { findForm } from "../../static/formRoutes";
import ExportButtonsBulkUpload from "./config";
import { downloadBulkUploadTemplate } from "./helper";
import Select1 from "../../components/select/select1";
import { FaFileExcel, FaQuestionCircle, FaRegFileExcel } from "react-icons/fa";

export default function InputData({ data, setData, settings, setSettings }) {
  const form = findForm(data.name);
  const [fileOptions, setFileOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getOptions = async () => {
      catcher(
        async () => {
          const formObj = await getAppInfo("bulk-" + form.name);
          if (formObj?.hasFile) {
            setFileOptions(formObj?.files);
          }
        },
        { setLoading, success_msg: "" }
      );
    };
    getOptions();
  }, [form]);
  if (loading) return <Skeleton active />;
  const getInstructions = () => {
    return (
      <Typography.Text>
        <Typography.Title level={5} style={{ fontWeight: 600 }}>
          Instructions
        </Typography.Title>
        <ol style={{ marginTop: '10px', paddingLeft: '20px' }}>
          <li>Download the template, fill it accordingly, and upload.</li>
          <li>
            Required columns are:{" "}
            {findFormTexts(data.name, "forms", true).map((x) => (
              <Tag key={x.title} style={{ marginBottom: '5px' }}>
                {x.title}{" "}
                {x.desc && (
                  <Tooltip title={x.desc}>
                    <FaQuestionCircle
                      twoToneColor={"grey"}
                    />
                  </Tooltip>
                )}
              </Tag>
            ))}
          </li>
          <li>Please follow the Excel date format.</li>
        </ol>
      </Typography.Text>
    );
  };
    
  const getDownloadButton = () => {
    // Check if there is a specific export button for the form
    const config = { ...settings };
    if (ExportButtonsBulkUpload[form.name]) {
      // Clone the element and pass necessary props
      return React.cloneElement(ExportButtonsBulkUpload[form.name], {
        form: form,
        downloadTemplate: downloadBulkUploadTemplate,
      });
    } else if (fileOptions?.length) {
      // If there are file options, display a dropdown with download options
      return (
        <AntDrop
          options={fileOptions}
          onChange={(filename) =>
            downloadBulkUploadTemplate(form, undefined, filename, config)
          }
        >
          <Button type="primary">
            Download Template <DownloadOutlined />
          </Button>
        </AntDrop>
      );
    } else {
      // If no specific export button or file options, display a simple download button
      return (
        <Button
          type="primary"
          onClick={async () =>
            await downloadBulkUploadTemplate(
              form,
              undefined,
              form.title + "-template",
              config
            )
          }
        >
          Download Template <DownloadOutlined />
        </Button>
      );
    }
  };
  const getSettings = () => {
    return null; //todo: temporary fix
    return (
      <div>
        <Typography.Title level={5}>Settings</Typography.Title>
        <Select1
          value={settings.type}
          setValue={(type) => setSettings({ ...settings, type })}
          title="Type"
          options={["Monthly", "Default"]}
        />
      </div>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Title level={3}>{form.title}</Typography.Title>
      </div>
      <Typography.Text className="instructions">
        Save time by importing data in bulk using the Excel sheet below. Download the template,
        fill it accordingly and upload.
      </Typography.Text>
      {getSettings()}
      {getInstructions()}
      <div
        style={{
          display: "",
          justifyContent: "left",
          flexDirection: "column",
          gap: 20,
          alignItems: "center",
        }}
      >
        <Typography.Title level={5}>Template</Typography.Title>
        
        {getDownloadButton()}
      </div>
        <Typography.Title level={5}>Import filled </Typography.Title>
      <ErrorBoundary>
        <div style={{ height: "200px", display: "flex" }}>
          <FileInput
            file={data.file}
            setFile={(file) => setData({ ...data, file })}
            accept=".xlsx,.xls"
          />
        </div>
      </ErrorBoundary>
    </div>
  );
}
