import React, { useEffect, useState } from "react";
import { getAllParamsFromLocation } from "../../../../helper/loc";
import { catcher } from "../../../../firebase/util";
import { fetchAffiliate } from "../../../../firebase/affiliate/get";
import Loading from "../../../../components/loading";
import { SimplePair } from "../../../results";
import { Button, Divider, Typography } from "antd";
import {
  findUserById,
  findUsersByCompanyId,
} from "../../../../helper/attribute";
import SelectMultiple from "../../../../components/input/SelectMultiple";
import { matchObjects } from "../../../../helper/wodash";
import { updateAffiliate } from "../../../../firebase/affiliate/update";
import { useNavigate } from "react-router-dom";
import { routePath } from "../../../../static/routes.static";

let catched_af = undefined;
export default function EditAffiliates() {
  const [id] = useState(() => getAllParamsFromLocation()?.id);
  const users_to_add = findUsersByCompanyId("iXPTGkQvc9mSN3TyBVEx"); //Affiliates company id
  const [af, setAf] = useState(undefined);
  const navigate = useNavigate();
  useEffect(() => {
    catcher(async () => {
      const af = await fetchAffiliate(id);
      setAf(af);
      catched_af = { ...af };
    }, {});
  }, []);
  const handleCancel = () => {
    setAf({ ...catched_af });
  };
  const handleSave = () => {
    catcher(async () => {
      await updateAffiliate(id, af);
      catched_af = { ...af };
      setAf({ ...af });
    }, {});
  };
  const showAfSettings = () => {
    navigate(routePath.adminAffiliate(id));
  };
  if (!id) return <div>404</div>;
  if (!af) return <Loading title="Loading affiliates" />;
  return (
    <>
      <div>
        {!matchObjects(catched_af, af) && (
          <>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </>
        )}

        <SimplePair title={"Title"} value={af.title} />
        <SimplePair title={"Created At"} value={af.createdAt} />
        <Button
          style={{ float: "right" }}
          type="primary"
          onClick={showAfSettings}
        >
          Settings
        </Button>
      </div>
      <div>
        <Divider />
        <Typography.Title level={3}>Admins</Typography.Title>
        <div>
          <SelectMultiple
            title={"Add/Remove"}
            value={af.admins}
            handleChange={(admins) => setAf({ ...af, admins })}
            options={users_to_add.map((user) => ({
              label: user?.firstName + user?.lastName,
              value: user?.id,
            }))}
          />
          <Typography.Title level={4}>Current Admins</Typography.Title>
          {af.admins?.map((id) => findUserById(id)?.email)}
        </div>
      </div>
    </>
  );
}
