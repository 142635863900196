import axios from "axios";
import { updateUser } from "../../firebase/user/update";
import { setUserDoc } from "../../redux/features/userSlice";
import store from "../../redux/store";

const BOT_THREAD_ID = "bot_thread_id"
const NEW_THREAD_URL = "https://saboorislam.pythonanywhere.com/thread/create"
const DELETE_THREAD_URL = "https://saboorislam.pythonanywhere.com/thread/delete"


function formatMessage(text) {
  // Pattern to match anything within [] or 【】 brackets
  const pattern = /(\[[^\]]*\])|(【[^】]*】)/g;
  return text?.replace(pattern, '');
}

 export default formatMessage;

 const updateThreadID = async(currentUser,threadID,del=false)=>{
  if(del == true && threadID.trim() !== ""){
    await axios.delete(
      DELETE_THREAD_URL,
      {
        headers: { "Content-Type": "application/json" },
        data: { "threadID": threadID }
      }
    );
    
  }
  if(threadID.trim() === "" || del == true){
    const response = await axios.post(
      NEW_THREAD_URL, 
      {},
      {headers: { "Content-Type": "application/json" }}
    );
    const threadObject = response.data;
    threadID = threadObject?.id
  }

  const updatedData={[BOT_THREAD_ID]:threadID};
  await updateUser(currentUser?.id,updatedData)
  store.dispatch(setUserDoc({...currentUser, ...updatedData}));
 }

 export {formatMessage,updateThreadID}