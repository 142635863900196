import { message } from "antd";
import store from "../redux/store";
import { att_excel_vs_forms } from "../static/formRoutes";
import { removePropertiesByValues } from "./jyes";
import formatEmissions from "../components/text/resultEmissions";
import { fixDecimals } from "./wodash";

export function getFactors(type = "MAIN") {
  try {
    if (!type) return [];
    return store.getState().app.emissionFactors[type];
  } catch (err) {
    message.error(JSON.stringify(err));
    return [];
  }
}

export function filterFactors(filters, value, addEmptyFactors = true, ids_range = undefined) {
  try {
    filters = removePropertiesByValues(filters, [null, undefined]);
    const filters_updated = filters && JSON.parse(JSON.stringify(filters));
    let emissionFactors = JSON.parse(JSON.stringify(getFactors()));
    if (Array.isArray(ids_range)) {
      emissionFactors = emissionFactors.filter((e) => ids_range?.includes(e.ID));
    }
    let result = emissionFactors;
    if (typeof filters_updated === "object") {
      if (filters_updated.hasOwnProperty("form")) {
        //
        emissionFactors = emissionFactors.filter((e) => e.form?.includes(filters_updated.form));
        delete filters_updated.form;
        //
      }
      result = emissionFactors.filter((item) =>
        Object.entries(filters_updated).every(([key, value]) => {
          if (typeof item[key] === "string" && typeof value === "string") {
            return item[key].toLowerCase() === value.toLowerCase();
          }
          return item[key] === value;
        })
      );
    }
    const set = new Set();
    //
    result.forEach((x) => {
      if (addEmptyFactors === false && x["2022"] === 0) {
      } else {
        set.add(x[value]);
      }
    });
    return [...set];
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export function propNotPresentCheck(filterObject, fieldTitles = {}) {
  const objects = getFactors();
  const obj = {};
  let filteredArray = objects;

  for (let prop in filterObject) {
    filteredArray = filteredArray.filter((obj) => obj[prop] === filterObject[prop]);

    if (filteredArray.length === 0) {
      obj.notFound = prop;
      break;
    }
  }
  const notFoundProperty = obj.notFound;
  if (notFoundProperty) {
    if (notFoundProperty in att_excel_vs_forms) {
      if (att_excel_vs_forms[notFoundProperty] in fieldTitles) throw filterObject[notFoundProperty] + " is not a valid " + fieldTitles[att_excel_vs_forms[notFoundProperty]];
      else throw filterObject[notFoundProperty] + " is not a valid " + att_excel_vs_forms[notFoundProperty];
    }
    throw filterObject[notFoundProperty] + " is not a valid " + notFoundProperty;
  }

  return { notFound: false };
}

export function emissionFactorsYears() {
  //return years included in emissionFactorsYears
  if (getFactors()[0])
    return Object.keys(getFactors()[0])
      .filter((x) => !isNaN(parseInt(x)))
      .map((x) => parseInt(x));
  return [];
}
export const combineEmissions = (emissionsData, threshold = 0.05, includeDetails = false) => {
  // used in graphs, gets an array of objects, combines those which are lesser than threshold 5% in default case and then returns back
  return emissionsData.map((entry) => {
    let total = 0;
    for (const key in entry) {
      if (entry.hasOwnProperty(key) && key !== "col") {
        total += entry[key];
      }
    }

    const limit = threshold * total;
    let other = 0;
    const result = {};
    const otherDetails = [];

    Object.entries(entry).forEach(([key, value]) => {
      if (value < limit) {
        other += value;
        if (includeDetails) {
          otherDetails.push(` ${key}`);
        }
      } else {
        result[key] = Math.round(value);
      }
    });

    if (other > 0) {
      if (includeDetails) {
        result[`Other`] = other;
      } else {
        result["Other"] = other;
      }
    }

    return result;
  });
};
