import { collection, getDocs, query } from "firebase/firestore";
import { setReportedBugs } from "../../../redux/features/superAdminSlice";
import store from "../../../redux/store";
import { db } from "../../setup";
import { docsWithIds } from "../../util";

export async function fetchReportBugs() {
  let docs = [];
  const collectionRef = collection(db, "bugs");
  const q = query(collectionRef);
  docs = await getDocs(q);
  const formsdata = docsWithIds(docs);
  store.dispatch(setReportedBugs(formsdata));
}
