import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { filterFactors } from "../../../helper/emissionfactors";
import { filterStringsBySubstrings } from "../../../helper/wodash";
import { genPut } from "../helper/create";

export const INPUTS = (data, setData) => {
  const formname = "other_travels";
  const findUOM = () => {
    const options = filterFactors(
      { "Level 2": data.category, "Level 3": data.type },
      "UOM"
    );
    if (options[0] === "passenger.km" && !options.includes("passenger.mile")) {
      // if only one options
      options.unshift("passenger.mile");
    }
    return ["mile", "km"]; //must to rename back to passenger.km and passenger.mile
  };
  return [
    {
      elem: DateInput,
      key: "date",
      value: data.date,
      setValue: (date) => setData({ ...data, date }),
    },
    {
      elem: SelectSearch,
      key: "category",
      value: data.category,
      setValue: (category) => {
        setData({
          ...data,
          category,
          type: null,
          uom:
            category === "Rail" || category === "Bus" ? "passenger.km" : null,
          passengers: 1,
        });
      },
      options: filterStringsBySubstrings(
        [
          ...filterFactors({ "Level 1": "Business travel- land" }, "Level 2"),
          ...filterFactors({ "Level 1": "Business travel- sea" }, "Level 2"),
        ],
        ["Car", "bike"]
      ),
    },
    {
      elem: SelectSearch,
      key: "type",
      value: data.type,
      setValue: (type) => setData({ ...data, type, uom: null }),
      options: filterStringsBySubstrings(
        filterFactors({ "Level 2": data.category }, "Level 3"),
        ["Freight"]
      ),
      hidden: !data.category,

      // hidden: data.category === "Rail" || data.category === "Bus",
    },
    {
      elem: NumberInput,
      key: "passengers",
      value: data.passengers,
      setValue: (passengers) => setData({ ...data, passengers }),
      // hidden: data.category !== "Rail" && data.category !== "Bus",
    },
    {
      elem: SelectSearch,
      key: "uom",
      value: data.uom,
      setValue: (uom) => setData({ ...data, uom }),
      options: findUOM(),
      hidden: !data.type,
      // hidden: data.category === "Rail" || data.category === "Bus",
    },
    {
      elem: NumberInput,
      key: "amount",
      hidden: !data.type,
      value: data.amount,
      setValue: (amount) => setData({ ...data, amount }),
    },
    // Optional: rail and bus => ask for the number of passengers
  ];
};

export default function OtherTravels({ data, setData }) {
  const formname = "other_travels";

  return (
    <>
      {INPUTS(data, setData).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </>
  );
}
