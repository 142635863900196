import { Typography } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import useSize from "../../../../hooks/useSize";

export default function Heading({ type }) {
  const {isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge} = useSize();
  return (
    <Typography.Title
      style={{ fontSize: isExtraSmall ? "24px" : isSmall ? "26px" : "30px" }}
    >
      <b style={{ textDecoration: "underline" }}>{type}</b>
    </Typography.Title>
  );
}
