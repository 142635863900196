import React from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { filterFactors } from "../../../helper/emissionfactors";
import { genPut } from "../helper/create";

export const INPUTS = ({ data, setData }) => {
  if (!data) data = {};
//   const categoryOptions = filterFactors({ form: "fuel" }, "Level 2");
//   const typeOptions = filterFactors({ "Level 2": data?.category, form: "fuel" }, "Level 3");
  const uomOptions = filterFactors({ "Level 3": "Natural gas", "Level 2": "Gaseous fuels", form: "fuel" }, "UOM").sort();



  return [
    {
      elem: DateInput,
      key: "date",
      value: data?.date,
    },
    // default to natural gas
    // {
    //   elem: SelectSearch,
    //   key: "category",
    //   value: data?.category,
    //   setValue: (category) => setData({ ...data, category, type: null, uom: null }),
    //   options: categoryOptions,
    // },
    // {
    //   elem: SelectSearch,
    //   key: "type",
    //   value: data?.type,
    //   hidden: !data?.category,
    //   options: typeOptions,
    //   set: { uom: null },
    // },
    {
      elem: SelectSearch,
      key: "uom",
      value: data?.uom,
      options: uomOptions,
    },
    {
      elem: NumberInput,
      key: "amount",
      value: data?.amount,
    },
  ];
};

export default function NaturalGas({ data, setData }) {
  const formname = "naturalgas";

  console.log(data);

  const inputs = INPUTS({ data, setData });

  return <div>{inputs.map((input) => genPut(input, formname, data, setData))}</div>;
}
