import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom"; // Import the necessary dependency
import FourOhFour from "../../components/404";
import {
  designations,
  getCurrentUserDesignation,
} from "../../helper/attribute";
import {
  addOrReplaceQueryParam,
  getAllParamsFromLocation,
} from "../../helper/loc";
import RoughWork from "../extras/RoughWork/RoughWork";
import ConfigPage from "./AppConfig";
import Bugs from "./Bugs";
import GroupAdmins from "./GroupAdmins";
import Affiliates from "./SuperAdminSectionPages/Affiliates";
import Consultants from "./SuperAdminSectionPages/Consultants";
import Hierarchy from "./SuperAdminSectionPages/Hierarchy";
import OtherSuperAdminSettings from "./SuperAdminSectionPages/Other";
import Permissions from "./SuperAdminSectionPages/Permissions";
import Plans from "./SuperAdminSectionPages/Plans";
import SuperAdmins from "./SuperAdminSectionPages/SuperAdmins";
import Testing from "./SuperAdminSectionPages/Testing";
import Info from "./SuperAdminSectionPages/Info/Info";
import Features from "./RequestFeatures";

const urlTabKey = "tab";

const SuperAdminSection = () => {
  const location = useLocation(); // Use the useLocation hook to get the current location
  const bugs = useSelector((state) =>
    state.superAdminData.reportedBugs?.filter((bug) => bug.status !== "closed")
  );
  const [currentTab, setCurrentTab] = useState(
    getAllParamsFromLocation()[urlTabKey]
  );

  // Extract the tab query parameter from the location object
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(urlTabKey);
    if (tabParam) {
      setCurrentTab(tabParam);
    }
  }, [location.search]);

  const onChange = (key) => {
    addOrReplaceQueryParam(urlTabKey, key);
    setCurrentTab(key);
  };

  const items = [
    {
      key: "info",
      label: "Info",
      children: <Info />,
    },
    {
      key: "App Config",
      label: `App Config`,
      children: <ConfigPage />,
    },
    {
      key: "Permissions",
      label: <div>Permissions</div>,
      children: <Permissions />,
    },
    {
      key: "Group Admins",
      label: <div>Groups</div>,
      children: <GroupAdmins />,
    },
    {
      key: "bugs",
      label: <div>BUGS ({bugs?.length})</div>,
      children: <Bugs />,
    },
    {
      key: "features",
      label: <div>Requests</div>,
      children: <Features />,
    },
    {
      key: "3",
      label: `Hierarchy`,
      children: <Hierarchy />,
    },
    {
      key: "testing",
      label: `Testing Results`,
      children: <Testing />,
    },
    {
      key: "roughwork",
      label: `Rough Work`,
      children: <RoughWork />,
    },
    {
      key: "plans",
      label: "Plans",
      children: <Plans />,
    },
    {
      key: "super-admins",
      label: "Super Admins",
      children: <SuperAdmins />,
    },
    {
      key: "consultants",
      label: "Consultants",
      children: <Consultants />,
    },
    {
      key: "affiliates",
      label: "Affiliates",
      children: <Affiliates />,
    },
    {
      key: "more",
      label: "More",
      children: <OtherSuperAdminSettings />,
    },
  ];

  // extra frontend check
  if (getCurrentUserDesignation() !== designations.super_admin)
    return <FourOhFour />;

  return (
    <Tabs
      type="card"
      defaultActiveKey={currentTab}
      activeKey={currentTab} // Set the activeKey to the currentTab state
      items={items}
      addIcon
      onChange={onChange}
    />
  );
};

export default SuperAdminSection;
