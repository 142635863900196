import { notification } from "antd";
import moment from "moment";
import { timeFormat } from "./time";
const conversionCache = {}; // Object to store cached conversions

function formatDate(dateString) {
  return moment(dateString, timeFormat).format("YYYY-MM-DD");
}

export async function convertCurrency(date, fromCurrency, toCurrency) {
  // If fromCurrency and toCurrency are the same, return 1
  if (fromCurrency === toCurrency) {
    return 1;
  }

  const host = "api.frankfurter.app";
  const cacheKey = `${date}_${fromCurrency}_${toCurrency}`;

  // Check if the conversion exists in the cache
  if (cacheKey in conversionCache) {
    return conversionCache[cacheKey];
  }

  const url = `https://${host}/${formatDate(
    date
  )}?from=${fromCurrency}&to=${toCurrency}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    const convertedAmount = data.rates[toCurrency];
    // notification.info({ message: `Currency conversion date = ${data.date}` });
    // Cache the converted value for future use
    conversionCache[cacheKey] = convertedAmount;

    return convertedAmount;
  } catch (error) {
    console.error("Error converting currencies:", error);
    throw error;
  }
}
