import { Button, Divider, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/wrapper";
import { getAppInfo, updateAppInfo } from "../../../firebase/appinfo/create";
import { catcher } from "../../../firebase/util";

export default function AIStuff() {
  const [constData, setConstData] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    catcher(async () => {
      const data = await getAppInfo("ai");
      setData({ ...data });
      setConstData(data);
    }, {});
  }, []);

  const handleUpdatePrompt = async () => {
    catcher(
      async () => {
        await updateAppInfo(data, "ai");
      },
      { setLoading }
    );
  };

  return (
    <Wrapper title="AI" loading={!data}>
      <Divider />
      <Typography.Title level={4}>Context</Typography.Title>
      <Input.TextArea
        style={{ marginBottom: 10 }}
        value={data?.prompt}
        onChange={(e) => setData({ ...data, prompt: e.target.value })}
      ></Input.TextArea>
      <Typography.Title level={4}>Follow up questions context</Typography.Title>
      <Input.TextArea
        style={{ marginBottom: 10 }}
        value={data?.followUpPrompt}
        onChange={(e) => setData({ ...data, followUpPrompt: e.target.value })}
      ></Input.TextArea>
      <Button loading={loading} onClick={handleUpdatePrompt}>
        UpdatePrompt
      </Button>
    </Wrapper>
  );
}
