// how to display data to the super admin

import { Modal } from "antd";
import React, { useState } from "react";
import SelectMultiple from "../../../components/input/SelectMultiple";
import {
  designations,
  getCurrentUserDesignation,
} from "../../../helper/attribute";
import { selectCompanies } from "../../../redux/features/appSlice";
import store from "../../../redux/store";

export default function DisplaySettings({
  refetchData,
  modalVisible,
  setModalVisible,
}) {
  const [companies, setCompanies] = useState([]);
  const handleCancel = () => {
    setModalVisible(false);
  };
  const handleOk = () => {
    refetchData(companies);
  };
  if (getCurrentUserDesignation() === designations.super_admin)
    return (
      <Modal
        title="Display settings (super admins)"
        open={modalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        destroyOnClose={true}
      >
        <h4>Companies</h4>
        <SelectMultiple
          description={
            "This list of companies can be shortened. Reloading the page will reset it."
          }
          title={"Companies Range"}
          value={companies}
          handleChange={setCompanies}
          options={selectCompanies(store.getState()).map((c) => ({
            value: c.id,
            label: c.title,
          }))}
        />
      </Modal>
    );
}
