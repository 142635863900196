export function sumProps(obj, props) {
  // Use the reduce function to accumulate the sum.
  // For each property, add its value to the accumulator.
  // If the property doesn't exist in the object, add 0 instead.
  return props.reduce((acc, prop) => acc + (obj[prop] || 0), 0);
}

export function removePropsFromList(
  listOfObjects,
  valuesToRemove = [null, undefined]
) {
  listOfObjects.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (valuesToRemove.includes(obj[key])) {
        delete obj[key];
      }
    });
  });
}
export function removePropsFromObject(
  object,
  valuesToRemove = [null, undefined]
) {
  removePropsFromList([object], valuesToRemove);
}

export function arrayToObjects(data) {
  const header = data[0];
  const result = [];

  for (let i = 1; i < data.length; i++) {
    const obj = {};
    for (let j = 0; j < header.length; j++) {
      obj[header[j]] = data[i][j];
    }
    result.push(obj);
  }

  return result;
}

export function filterListByKeys(
  listOfObjects,
  keysToRemove = [],
  keysToRemain = undefined
) {
  return listOfObjects.map((obj) => {
    const newObj = {};

    // Iterate over the keys in the object
    Object.keys(obj).forEach((key) => {
      const shouldRemove = keysToRemove && keysToRemove.includes(key);
      const shouldRemain = !keysToRemain || keysToRemain.includes(key);

      // Add key to newObj if it should not be removed and should remain (if keysToRemain is specified)
      if (!shouldRemove && shouldRemain) {
        newObj[key] = obj[key];
      }
    });

    return newObj;
  });
}
