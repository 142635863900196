import { message } from "antd";

export function copyToClipboard(text, onCopy) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      if (onCopy) message.info(onCopy);
    })
    .catch((err) => {
      message.error(err);
    });
}
