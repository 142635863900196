export function scrollToElementWithId(id) {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

export function replaceElement(oldElementSelector, newParentSelector) {
  try {
    // Select the old element using the provided selector
    const oldElement = document.getElementsByClassName(oldElementSelector)[0];
    if (!oldElement) {
      throw new Error(`Element with selector "${oldElementSelector}" not found.`);
    }

    // Select the new parent element using the provided selector
    const newParent = document.getElementsByClassName(newParentSelector)[0];
    if (!newParent) {
      throw new Error(`Parent element with selector "${newParentSelector}" not found.`);
    }

    // Remove the old element from its current parent and append it to the new parent
    oldElement.parentNode.removeChild(oldElement);
    newParent.appendChild(oldElement);

    console.log("Element successfully moved to new parent.", oldElement, newParentSelector);
  } catch (error) {
    console.error("Error replacing element:", error.message);
  }
}

export function injectScript(scriptContent, srcUrl = null) {
  try {
    // Create a new script element
    const script = document.createElement("script");

    if (srcUrl) {
      // If a source URL is provided, set it as the script's src attribute
      script.src = srcUrl;
      script.defer = true; // Optional: defer loading until HTML is fully parsed
    } else if (scriptContent) {
      // If inline script content is provided, add it to the script element
      script.textContent = scriptContent;
    } else {
      throw new Error("Either script content or source URL must be provided.");
    }

    // Append the script element to the document's head or body
    document.head.appendChild(script);

    console.log("Script injected successfully.");
  } catch (error) {
    console.error("Error injecting script:", error.message);
  }
}
