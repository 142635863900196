import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  matchLocalUpdateProp,
  setLocalUpdateProp,
} from "../../helper/attribute";
import { setConfigData } from "../../redux/features/configSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsObjects } from "../util";

async function getConfigDoc(type) {
  const updateDoc = await getDoc(doc(db, firestoreStatic.config, type));
  return JSON.parse(updateDoc.data().data);
}

export async function fetchAppConfig() {
  const updates = await getConfigDoc("updates");
  store.dispatch(setConfigData({ updates }));
  const docsToGet = [];
  let formsdata = {};
  for (let prop in updates) {
    if (prop === "emission_factors") continue;

    if (!matchLocalUpdateProp(prop)) {
      docsToGet.push(prop);
    } else {
      try {
        formsdata[prop] = JSON.parse(localStorage.getItem(prop));
      } catch (err) {
        docsToGet.push(prop);
      }
    }
  }
  if (docsToGet.length) {
    let docs = [];
    const collectionRef = collection(db, firestoreStatic.config);
    const q = query(collectionRef, where("__name__", "in", docsToGet));
    docs = await getDocs(q);
    formsdata = { ...formsdata, ...docsObjects(docs) };
  }

  store.dispatch(setConfigData(formsdata));
  // finally setting it up in the local storage
  Object.keys(formsdata).forEach((prop) => {
    setLocalUpdateProp(prop);
    localStorage.setItem(prop, JSON.stringify(formsdata[prop]));
  });
}
