import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../setup";
import { firestoreStatic } from "../../static/firestore.static";
import { setConsultants } from "../../redux/features/superAdminSlice";
import { docsWithIds, formatUsers } from "../util";
import store from "../../redux/store";

export const get_consultants = async () => {
  if (store.getState()?.superAdminData?.consultants) return;
  const collectionRef = collection(db, firestoreStatic.users);
  let consultants = [];

  // Construct a Firestore query to fetch users who are marked as consultants
  const q = query(
    collectionRef,
    where("consultant", "==", true) // Query to fetch users with consultant set to true
  );

  const docs = await getDocs(q);
  consultants = formatUsers(docsWithIds(docs));

  console.log(consultants, docs);

  store.dispatch(setConsultants(consultants));
};
