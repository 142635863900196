import React from "react";
import { useSelector } from "react-redux";
import { selectCompanies, selectUsers } from "../../../../redux/features/appSlice";
import { BeautifyData } from "../../../../components/text/BeautifyData";
import { Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import Wexel from "../../../../helper/excel";
import { downloadXLSX } from "../../../../helper/wodash";
import { catcher } from "../../../../firebase/util";
import { findCompany, getDesignation } from "../../../../helper/attribute";
import { getCurrentPlan, getPlanTitles } from "../../../../helper/plans";
import { T1 } from "../../../../appconfig/texts";
import { timeFormat } from "../../../../helper/time";
import moment from "moment";

const companiesInfo = (companies) => {
  const planCounts = {};
  if (companies) {
    companies.forEach((company) => {
      console.log(company, planCounts);
      if (planCounts[company?.plan]) {
        planCounts[company?.plan] = planCounts[company?.plan] + 1;
      } else {
        planCounts[company?.plan] = 1;
      }
    });
  }
  return planCounts;
};

export default function Info() {
  const companies = useSelector(selectCompanies);
  const users = useSelector(selectUsers);
  const mapping = companiesInfo(companies);
  const downloadCompanies = () => {
    catcher(() => {
      downloadXLSX("companies_raw", companies);
    }, {});
  };
  const downloadUsers = () => {
    catcher(() => {
      const users_mapped_with_companies = users.map((userx) => {
        const user = JSON.parse(JSON.stringify(userx));
        delete user["password"];
        const company = findCompany(user.companyId);
        user["user-role"] = getDesignation(user, false);

        // Check for timestamps and convert to UK date format
        Object.keys(user).forEach((key) => {
          if (typeof user[key] === "number" && user[key].toString().length === 13) {
            // Assuming it's a timestamp in milliseconds
            user[key] = moment(user[key]).format(timeFormat);
          }
        });

        if (company) {
          Object.keys(company).forEach((key) => {
            user[`Company-${key}`] = company[key];
          });
        }

        return user;
      });
      downloadXLSX("users_raw", users_mapped_with_companies);
    }, {});
  };

  return (
    <>
      <div>
        <Typography.Title level={3}>
          Company Accounts <DownloadOutlined onClick={downloadCompanies} />
        </Typography.Title>
        {Object.keys(mapping).map((key) => {
          return (
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <h4>
                <T1 pos={`plans.${key}.title`} /> ({key})
              </h4>{" "}
              = {mapping[key]}
            </div>
          );
        })}
      </div>
      <Typography.Title level={3}>
        Users Full Info <DownloadOutlined onClick={downloadUsers} />
      </Typography.Title>
    </>
  );
}
