import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import { StyledWithIcon } from "./styles";

export function ArrowText({ text, onClick, main = false, ...more }) {
  return (
    <StyledWithIcon
    className="notranslate"
      style={{ color: main && "darkgreen", fontWeight: "500" }}
      onClick={() => onClick(text)}
      {...more}
    >
      {text}
      <span className="icon">
        <ArrowRightOutlined />
      </span>
    </StyledWithIcon>
  );
}
