import React, { useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { getGHGTitle, GHG_CATEGORIES_VALUES } from "../../../static/ghg.static";
import { genPut } from "../helper/create";
const INPUTS = (data, setData) => {
  return [
    {
      elem: DateInput,
      key: "date",
    },
    {
      elem: NumberInput,
      key: "result",
    },
    {
      elem: SelectSearch,
      key: "q1",
      options: ["Yes", "No"],
    },
    {
      elem: NumberInput,
      key: "wtt",
      hidden: data.q1 !== "Yes",
    },
    {
      elem: SelectSearch,
      key: "ghg",
      options: GHG_CATEGORIES_VALUES(),
    },
  ];
};

export default function Additional({ data, setData }) {
  const formname = data.name;
  useEffect(() => {
    setData({
      ...data,
      ghg: data.ghg ? getGHGTitle(data.ghg) : null,
      q1: data.q1 || "Yes",
    });
  }, []);

  return (
    <div>
      {INPUTS(data, setData).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </div>
  );
}
export { INPUTS };
