import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Layout, Row, Col } from "antd";
import { AlertOutlined } from "@ant-design/icons";
import { routePath } from "../../../static/routes.static";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const PaymentCancellationPage = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    // Navigate to payment page or implement retry payment logic
    navigate(routePath.Upgrade);
  };

  const handleReturnHome = () => {
    navigate(routePath.Dashboard);
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Content style={{ padding: "5px", textAlign: "center" }}>
        <Row justify="center">
          <Col xs={24} sm={18} md={12} lg={18}>
            <Title level={2}>
              <AlertOutlined />
              Payment Incomplete
            </Title>
            <Paragraph>
              Unfortunately, your payment process was not completed. Please try
              again or contact support if you continue to experience issues.
            </Paragraph>
            <Button
              type="primary"
              onClick={handleRetry}
              style={{ marginRight: "10px" }}
            >
              Retry Payment
            </Button>
            <Button onClick={handleReturnHome}>Return to Home</Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default PaymentCancellationPage;
