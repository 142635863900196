import { doc, updateDoc } from "firebase/firestore";
import { getCurrentDate } from "../../helper/time";
import { objDontHave, valuesNotNull } from "../../helper/wodash";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { fetchUsers } from "./get";
import validator from "validator";
import store from "../../redux/store";
import { setUsers } from "../../redux/features/appSlice";
import { notifyIfOffline } from "../../offline";
import { setUserDoc } from "../../redux/features/userSlice";
const beforeuserUpdate = (data) => {
  notifyIfOffline();
  const dontHv = valuesNotNull(data, ["firstName", "lastName"]);
  if (dontHv) throw "Please add " + dontHv;
  // if (data.phone && !validator.isMobilePhone(data.phone)) throw "Not a valid phone number";
  data.updatedAt = getCurrentDate();
  return data;
};

export const updateUser = async (id, data) => {
  console.log("Updating user with id", id, "and data", data);

  if (!id || !typeof data === "object") return "no";
  data = beforeuserUpdate(data);
  await updateDoc(doc(db, firestoreStatic.users, id), data);
  store.dispatch(
    setUsers(
      store.getState().app.users.map((user) => {
        if (user.id !== id) return user;
        else return { ...user, ...data };
      })
    )
  );
};

export const updateUserClicks = async (
  click_str = "",
  user_objx,
  updateGlobalStorage = false
) => {
  let user_obj = user_objx;
  if (!user_obj) user_obj = store.getState().user.doc;
  if (!click_str) return;
  click_str = "count_" + click_str;
  const click_count =
    typeof user_obj[click_str] === "number" ? user_obj[click_str] + 1 : 1;

  const data = {
    [click_str]: click_count,
    [click_str + "last_click_on"]: new Date().getTime(),
  };
  await updateDoc(doc(db, firestoreStatic.users, user_obj.id), data);
  if (updateGlobalStorage)
    if (user_objx) {
      store.dispatch(
        setUsers(
          store.getState().app.users.map((user) => {
            if (user.id !== user_obj.id) return user;
            else return { ...user, ...data };
          })
        )
      );
    } else {
      // is current user
      store.dispatch(setUserDoc({ ...user_obj, ...data }));
    }
};
