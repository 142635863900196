import { CloudUploadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Space, Upload } from "antd";
import React from "react";
import {
  downloadEmissionFactorsFile,
  uploadEmissionFactors,
} from "../../firebase/storage/assets";
import { except } from "../../firebase/util";
export default function EmissionFactors() {
  const handleUpload = ({ file }) => {
    Modal.confirm({
      title: "Upload Emission Factors",
      onOk: () =>
        except(async () => {
          if (!file.name.includes(".xls")) throw "File type error";
          await uploadEmissionFactors(file);
        }),
      content: (
        <>
          ⚠️Warning: You are going to upload emission factors used in the
          application. <br />
          <InfoCircleOutlined /> <b>File:</b>
          {file?.name}
        </>
      ),
    });
  };
  const handleDownloadEmissionFactors = () => {
    except(async () => {
      await downloadEmissionFactorsFile();
    });
  };
  return (
    <div style={{ height: "550px", padding: "10px" }}>
      <Divider />
      <Space direction="">
        <Button onClick={handleDownloadEmissionFactors} type="primary">
          Download Current Emission Factors
        </Button>
        <Upload customRequest={handleUpload} showUploadList={false}>
          <Button type="link">
            <CloudUploadOutlined />
            Upload New Emission Factors
            <CloudUploadOutlined />
          </Button>
        </Upload>
      </Space>
    </div>
  );
}
