import {
    doc,
    writeBatch,
    getDoc,
    deleteDoc
  } from "firebase/firestore";
  import { firestoreStatic } from "../../static/firestore.static";
  import { db } from "../setup";
  
  function validateDelete(data) {
    if (!data.id) throw "Group ID is required for deletion";
    // if (!data.admins || data.admins.length === 0) throw "Admins list is required";
    // Additional validation checks can be added here
    return true;
  }
  
  export async function deleteGroup(data) {
    validateDelete(data);
  
    const batch = writeBatch(db);
    const groupRef = doc(db, firestoreStatic.groups, data.id);
  
    // Check if the group exists before attempting to delete
    const groupDoc = await getDoc(groupRef);
    if (!groupDoc.exists()) {
      throw "Group not found";
    }
  
    // Delete the group document
    batch.delete(groupRef);
  
    // Update admins: Set their group reference to null
    for (const adminId of data.admins) {
      const userRef = doc(db, firestoreStatic.users, adminId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        batch.update(userRef, { groupAdmin: null }); // Setting groupAdmin to null
      }
    }
  
    // Commit the batch
    await batch.commit();
  }
  