import { addDoc, collection } from "firebase/firestore";
import { getCurrentDate } from "../../helper/time";
import { objDontHave } from "../../helper/wodash";
import { firestoreStatic } from "../../static/firestore.static";
import { dataForms } from "../../static/formRoutes";
import { db } from "../setup";
import { fetchAffiliates } from "./get";

//validations throws error or returns true
function validateAffiliateData(data) {
  //todo: check if the user is admin to access this
  const dontHv = objDontHave(data, ["title"]);
  if (dontHv) throw "Please add " + dontHv;
  //adding other things
  return true;
}

export async function createAffiliate(data, shouldFetch = true) {
  validateAffiliateData(data);
  data["createdAt"] = getCurrentDate();
  const { id } = await addDoc(collection(db, firestoreStatic.affiliates), data);
  if (shouldFetch) await fetchAffiliates();
  return id;
}
