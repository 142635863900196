import React, { useState } from "react";
import { Card, Typography, Row, Col, Form, Input, Button, Modal } from "antd";
import validator from "validator";
import { getAuth, updatePassword } from "firebase/auth";
import { useMediaQuery } from "react-responsive";
import useSize from "../../hooks/useSize";

const ChangePassword = () => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  const [data, setData] = useState({ password: "" });
  const [isButtonHidden, setIsButtonHidden] = useState(true);

  const handleUpdatePassword = async () => {
    Modal.confirm({
      title: "Confirm change password",
      onOk: async () => {
        try {
          if (!validator.isLength(data.password, { min: 6 })) {
            throw "Password min length is 6";
          }
          // Add your password complexity checks here if needed

          await updatePassword(getAuth().currentUser, data.password);
          setData({});
        } catch (error) {
          // Handle password validation errors here
          Modal.error({
            title: "Timeout Error",
            content:
              "To change your password, please make sure you've recently logged in.",
          });
        }
      },
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setData({ password: newPassword });
    setIsButtonHidden(!validator.isLength(newPassword, { min: 6 }));
  };

  return (
    <Card>
      <Typography.Title
        style={{ fontSize: isExtraSmall ? "18px" : "24px" }}
        className="changePassword"
      >
        Change Password
      </Typography.Title>
      <br />
      {
        <ul
          className="password-instructions"
          style={{
            marginTop: isExtraSmall ? "0px" : "",
            paddingLeft: isExtraSmall ? "14px" : "",
          }}
        >
          <li>New password must be at least 6 characters long.</li>
          <li>
            To change your password, please make sure you've recently logged in.
          </li>
        </ul>
      }
      <Row gutter={[16]}>
        <Col md={12} sm={24}>
          <Form.Item>
            <Input
              placeholder="New Password"
              value={data.password}
              onChange={handlePasswordChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        onClick={handleUpdatePassword}
        disabled={isButtonHidden}
      >
        Update Password
      </Button>
    </Card>
  );
};

export default ChangePassword;
