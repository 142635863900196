import React, { useEffect } from "react";
import InternetChecker from "./InternetChecker";
import FloatingButtons from "./FloatingButtons";
import Popups from "./Popups";
import UserTour from "../tour";
import { useSelector } from "react-redux";
import { selectCompanies } from "../../redux/features/appSlice";
import { getCurrentPlan } from "../../helper/plans";

export default function AllApp({ refetchData }) {
  // setting up clarity code

  const companies = useSelector(selectCompanies);
  useEffect(() => {
    // SET CLARITY TAG
    if (Array.isArray(companies) && companies.length > 0) {
      const plan = getCurrentPlan();
      window.setClarityTag("plan", plan);
    }
  }, [companies]);
  return (
    <>
      {/* <Popups /> */}
      <InternetChecker />
      <FloatingButtons refetchData={refetchData} />
      <UserTour />
    </>
  );
}
