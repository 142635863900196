// cache.js

function Cache() {
  this.data = {};
}

Cache.prototype.set = function (key, value) {
  this.data[key] = value;
};

Cache.prototype.get = function (key) {
  return this.data[key];
};

Cache.prototype.has = function (key) {
  return key in this.data;
};

Cache.prototype.remove = function (key) {
  delete this.data[key];
};

Cache.prototype.clear = function () {
  this.data = {};
};
const cache = new Cache();
export default cache;
export const createCache = () => {
  return new Cache();
};
