import React from "react";
import ErrorBoundary from "../../ErrorBoundary";
import GHGAnalysis from "../extras/testing/ghg.analysis";
export default function GHGCategory() {
  return (
    <ErrorBoundary>
      <GHGAnalysis />
    </ErrorBoundary>
  );
}
