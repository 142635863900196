import { Typography } from "antd";
import React from "react";
import Loading from "./loading";

export default function Wrapper({
  children,
  loading = false,
  title = "",
  titleLoading = `Loading ${title}`,
}) {
  return (
    <div>
      <Typography.Title>{title}</Typography.Title>
      <div>{loading ? <Loading title={titleLoading} /> : children}</div>
    </div>
  );
}
