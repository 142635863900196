import { Input } from "antd";
import React, { useState } from "react";
import FakeLabel from "./select";

export default function TextInput({
  value,
  type = "input",//textarea means render text area
  setValue,
  title = "Write Text",
  placeholder = "",
  extra = {},
  min = 0,
  max = 100,
  addonAfter,
  nolabel = false,
  uom,
  displayOnly,
  numeric = false,
  ...more
}) {
  const [editing, setEditing] = useState(false);
  const elemOptions = {
    bordered: !displayOnly,
    disabled: displayOnly,
    maxLength: max,
    minLength: min,
    value: (!editing && uom && value) ? value + " " + uom : value,
    onChange: (e) => setValue(e.target.value),
    addonAfter: addonAfter,
    onBlur: () => setEditing(false),
    onFocus: () => setEditing(true),
    placeholder: placeholder
  };
  let elem = (
    <Input
      type={numeric && "number"}
      {...elemOptions}
    />
  );
  if (type === "textarea") elem = <Input.TextArea autoSize {...elemOptions} />
  if (nolabel) return elem;
  return (
    <>
      <FakeLabel {...more} title={title} elem={elem} {...extra} />
    </>
  );
}
