import { RobotOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import axios from "axios";
import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";
import { useSelector } from "react-redux";
import { selectUserDoc } from "../../redux/features/userSlice";
import store from "../../redux/store";
import formatMessage, { updateThreadID } from "./chatbothelper";

const API_URL = `https://saboorislam.pythonanywhere.com/message/list`;

const loadChat = async () => {
  try {
    const currentUser = store.getState().user.doc;
    const threadID = currentUser?.["bot_thread_id"] ? currentUser?.["bot_thread_id"] : null;
    // const threadID = localStorage.getItem("threadID")? JSON.parse(localStorage.getItem("threadID")):null;

    const response = await axios.post(API_URL, { threadID: threadID }, { headers: { "Content-Type": "application/json" } });
    const messages = response.data;
    console.log("MESSGAWS"+messages)
    if ("error" in messages){
      updateThreadID(currentUser,messages.newThreadID,false)
      console.log("ThreadID updated")
      return {}
    }
    // return data
    const formattedMessages = [];
    for (let i = messages?.["assistant"]?.length + messages?.["user"]?.length - 1; i >= 0; i--) {
      // console.log(i)
      if (i % 2 != 0) {
        // console.log(messages.user[i/2])
        formattedMessages.push(createClientMessage(messages.user[(i - 1) / 2]));
      } else {
        // console.log(messages.assistant[(i-1)/2])
        formattedMessages.push(createChatBotMessage(formatMessage(messages.assistant[i / 2])));
      }
    }
    return formattedMessages ?? {};
  } catch (err) {
    console.log(err);
  }
};

const config = {
  customComponents: {
    botAvatar: (props) => (
      <Avatar
        style={{
          transform: "translateX(-10px)",
        }}
        size={"large"}
        icon={"S"}
      />
    ),
  },
  initialMessages: [createChatBotMessage("Hello! How can I help you?")],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#4CAF50", // Green color for bot message box
    },
    chatButton: {
      backgroundColor: "#81C784", // Lighter green color for chat button
    },
    chatContainer: {
      width: "1600px",
    },
  },
};

export default config;
export { loadChat as LoadMessages };
