import { Radio } from "antd";
import React from "react";
import FakeLabel from "../input/select";

export default function RadioSelector({ value, setValue, options, title }) {
  return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {title && (
          <div
            style={{
              minWidth: "100px",
              fontWeight: "600",
              marginRight: "10px",
            }}
          >
            {title + " "}
          </div>
        )}
        <Radio.Group value={value} onChange={(e) => setValue(e.target.value)}>
          {options.map((o) => (
            <Radio.Button
              style={{ minWidth: "100px", textAlign: "center" }}
              value={typeof o === "object" ? o.value : o}
            >
              {typeof o === "object" ? o.label : o}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
  );
}
