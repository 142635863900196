import { InputNumber } from "antd";
import React, { useState } from "react";
import { ToWords } from "to-words";
import FakeLabel from "./select";

const toWords = new ToWords({
  localeCode: "en-GB",
});

export default function NumberInput({
  value,
  setValue,
  title = "Select Number",
  extra = {},
  min = 0,
  max = 10000000000,
  addonAfter,
  description,
  nolabel = false,
  uom,
  disabled,
  ...more
}) {
  const [editing, setEditing] = useState(false);

  const elem = (
    <InputNumber
      max={max}
      min={min}
      disabled={disabled}
      value={!editing && uom && value ? value + " " + uom : value}
      onChange={setValue}
      addonAfter={addonAfter}
      onBlur={() => setEditing(false)}
      onFocus={() => {
        setEditing(true);
      }}
    />
  );
  if (nolabel) return elem;
  return (
    <>
      <FakeLabel
        {...more}
        title={title}
        elem={elem}
        {...extra}
        description={description}
        info={
          !isNaN(value) && value != null ? (
            <div>{toWords.convert(value)}</div>
          ) : null
        }
      />
    </>
  );
}
