import { Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import TextInput from "../../../../components/input/TextInput";
import {
  fetchAffiliate,
  fetchMyAffiliate,
} from "../../../../firebase/affiliate/get";
import { catcher } from "../../../../firebase/util";
import Loading from "../../../../components/loading";
import { updateAffiliate } from "../../../../firebase/affiliate/update";
import { getAllParamsFromLocation } from "../../../../helper/loc";
import SelectSearch from "../../../../components/select/SearchSelect";
const Tt = Typography.Title;

const configBox = [
  {
    id: "domain_name",
    title: "Domain Name",
    type: "text",
    info: "Please write correct domain name. Small case. So that these settings can be applied on the affiliate domain name.",
  },
  { id: "app_name", title: "App Name", type: "text" },
  {
    id: "primary_color",
    title: "Primary Color",
    type: "text",
    placeholder: "HEX Code",
    info: "Enter HEX code",
  },
  {
    id: "logo",
    title: "Logo",
    type: "text",
    info: (
      <p>
        base64 for logo (
        <a target="_blank" href="https://base64.guru/converter/encode/image">
          Convertor
        </a>
        )
      </p>
    ),
  },
  {
    id: "allow_signup",
    title: "Allow Signup",
    type: "select",
    info: "Yes to allow signup, no otherwise.",
    options: ["Yes", "No"],
  },
];
let catched_af = { c: {} };
export default function Admin() {
  const [id] = useState(() => getAllParamsFromLocation()?.id);

  const [af, setAf] = useState(undefined);
  useEffect(() => {
    catcher(async () => {
      const af = await fetchAffiliate(id);
      setAf(af);
      catched_af = { ...af };
    }, {});
  }, []);
  const handleSave = async () => {
    catcher(async () => {
      await updateAffiliate(af.id, af);
    }, {});
  };
  if (!af) return <Loading />;

  return (
    <div>
      <Tt level={3}>Admin Section</Tt>
      <div>
        <Tt level={4}>
          Visual Settings
          <Button onClick={handleSave}>Save</Button>
        </Tt>
      </div>

      <div style={{ width: "50%" }}>
        {configBox.map((item) => {
          if (item.type === "text") {
            return (
              <TextInput
                max={1000000}
                {...item}
                value={af["c"][item.id]}
                setValue={(val) =>
                  setAf({ ...af, c: { ...af.c, [item.id]: val } })
                }
              />
            );
          } else if (item.type === "select") {
            return (
              <SelectSearch
                {...item}
                value={af["c"][item.id]}
                options={item.options}
                setValue={(val) =>
                  setAf({ ...af, c: { ...af.c, [item.id]: val } })
                }
              />
            );
          }
        })}
      </div>
    </div>
  );
}
