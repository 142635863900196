import { addDoc, collection, doc, writeBatch } from "firebase/firestore";
import { getCurrentDate } from "../../helper/time";
import { objDontHave } from "../../helper/wodash";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { fetchSites, getSitesCount } from "./get";

//validations throws error or returns true
function validateSiteData(data) {
  //todo: check if the user is admin to access this
  const dontHv = objDontHave(data, ["title", "companyId", "country"]);
  if (dontHv) throw "Please add " + dontHv;
  //adding other things
  return true;
}

export async function createSite(data, shouldFetch = true) {
  validateSiteData(data);
  data["createdAt"] = getCurrentDate();
  data["index"] = (await getSitesCount()) + 1;
  const { id } = await addDoc(collection(db, firestoreStatic.sites), data);
  if (shouldFetch) await fetchSites();
  return id;
}

export async function createSitesInBulk(sitesData, shouldFetch = true) {
  const batch = writeBatch(db);
  let currentIndex = await getSitesCount(); // Get the starting index

  for (const data of sitesData) {
    validateSiteData(data); // Validate each site data
    data["createdAt"] = getCurrentDate();
    data["index"] = ++currentIndex; // Increment for each site

    const siteRef = doc(collection(db, firestoreStatic.sites)); // Get a new document reference
    batch.set(siteRef, data); // Add to batch
  }

  await batch.commit(); // Commit the batch

  if (shouldFetch) await fetchSites(); // Optionally fetch sites after updating

  return "Sites created successfully";
}
