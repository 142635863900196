import React from "react";
import { useSelector } from "react-redux";
import { selectResults } from "../../../redux/features/appSlice";

const RoughWork = () => {
  const results = useSelector(selectResults);
  if (!results) return "calculating";
  const kwh = results.map((r) => (Number(r.kwh) > 0 ? Number(r.kwh) : 0)).reduce((a, b) => a + b, 0);
  return (
    <div>
      <h2>results total length</h2>
      {results.length}

      <h2>kwh total</h2>
      {kwh}
    </div>
  );
};

export default RoughWork;
