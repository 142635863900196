import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../setup";

export async function createFile(filePath, fileData) {
  const fileRef = ref(storage, filePath);

  await uploadBytes(fileRef, fileData);

  const downloadURL = await getDownloadURL(fileRef);
  return downloadURL;
}
