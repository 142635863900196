import axios from "axios";
import { Workbook } from "exceljs";
import * as fs from "file-saver";

function numberToLetters(num) {
  let letters = "";
  while (num >= 0) {
    letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
}

export default function Wexel() {
  let workbook = new Workbook();
  let dropdown_sheet_name = "wexel____dropdowns_____wexel";
  let dropdown_sheet = workbook.addWorksheet(dropdown_sheet_name, {
    state: "hidden",
  });
  const default_styles = {
    header: {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF228B22" },
      },
      font: {
        color: { argb: "FFFFFFFF" },
      },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    },
  };

  this.removeColumn = (columnIndex, sheetName = "Inputdata", deleteContentOnly = true) => {
    let worksheet = workbook.getWorksheet(sheetName);

    if (deleteContentOnly) {
      // Clear the content of the column
      worksheet.eachRow({ includeEmpty: true }, function (row) {
        row.getCell(columnIndex).value = null;
      });

      // Set the column width to a very low value
      worksheet.getColumn(columnIndex).width = 0;
    } else {
      // Remove the entire column
      worksheet.columns.splice(columnIndex - 1, 1);

      // Shift the remaining columns to the left
      worksheet.eachRow({ includeEmpty: true }, function (row) {
        row.splice(columnIndex, 1);
      });
    }
  };

  this.loadDataFromUrl = async function (url, sheetName = "Inputdata") {
    const response = await axios.get(url, { responseType: "arraybuffer" });
    const data = response.data;
    await workbook.xlsx.load(data);
    dropdown_sheet_name = "wexel____site_data";
    dropdown_sheet = workbook.addWorksheet(dropdown_sheet_name, {
      state: "hidden",
    });

    // OPTIONAL: Extracting headers as arr

    let inputdataSheet = workbook.getWorksheet(sheetName);
    if (!inputdataSheet) {
      // Handle case where "Inputdata" sheet is not found
      throw new Error('Sheet "Inputdata" not found');
    }

    // Extract headers or the first row as an array
    const headers = [];
    inputdataSheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      headers.push(cell.value);
    });

    return headers;
  };
  this.addSheet = function (name, properties = { state: "visible" }) {
    workbook.addWorksheet(name, properties);
  };
  this.addConfig = function (obj) {
    if (typeof obj !== "object") return;
    this.addSheet("Config", { state: "visible" });
    let index = 1;
    for (let [key, value] of Object.entries(obj)) {
      workbook.getWorksheet("Config").getCell(`A${index}`).value = key;
      workbook.getWorksheet("Config").getCell(`B${index}`).value = value;
      index++;
    }
  };
  this.hideAllSheets = function (except) {
    if (except?.length < 1) return;
    workbook.worksheets.forEach((sheet) => {
      if (!except.includes(sheet.name)) {
        sheet.state = "veryHidden";
      }
    });
  };
  this.addDropdownRow = function (options) {
    const row = dropdown_sheet.addRow(options);
    return `=${dropdown_sheet_name}!$A$${row.number}:${numberToLetters(options.length - 1)}$${row.number}`;
  };
  this.addHeader = function ({ headers, sheet_ref = 1, style = default_styles.header }) {
    const worksheet = workbook.getWorksheet(sheet_ref);

    // Get the first row
    const firstRow = worksheet.getRow(1);

    // Clear existing values and styles in the first row
    firstRow.eachCell({ includeEmpty: true }, (cell) => {
      cell.value = null;
      Object.keys(style).forEach((key) => (cell[key] = null));
    });

    // Set the new headers and apply styles
    headers.forEach((header, index) => {
      const cell = firstRow.getCell(index + 1);
      cell.value = header;
      Object.keys(style).forEach((key) => (cell[key] = style[key]));
    });

    // Commit the changes to the workbook
  };
  this.applyColFormula = function (sheet_name, col_index, formulae = {}, length = 200) {
    const worksheet = workbook.getWorksheet(sheet_name);
    for (let rowIndex = 2; rowIndex <= length; rowIndex++) {
      worksheet.getCell(numberToLetters(col_index) + rowIndex).dataValidation = {
        showErrorMessage: true,
        ...formulae,
        // allowBlank: true,
      };
    }
  };
  this.createNamedRange = function (name, options) {
    const sheet = workbook.getWorksheet(dropdown_sheet_name);
    const x = sheet.addRow(options);

    workbook.definedNames.add("");
  };
  this.download = async function (name) {
    workbook.worksheets.forEach((sheet) => {
      let maxLength = 0;
      sheet.columns?.forEach((column, i) => {
        column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = column.width === 0 ? 0 : maxLength < 10 ? 10 : maxLength;
      });
    });
    try {
      const data = await workbook.xlsx.writeBuffer();
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Using FileSaver.js for efficient file saving
      fs.saveAs(blob, name + ".xlsx");
    } catch (error) {
      console.error("Error during file creation and download:", error);
    }
  };
}

export function excelJsListFormula(options) {
  // options = options.slice(0, 7);
  return {
    type: "list",
    formulae: ["=$A$1:$E$1"],
  };
}
