import React from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { filterFactors } from "../../../helper/emissionfactors";
import { genPut } from "../helper/create";

export const INPUTS = (data, setData) => {
  return [
    {
      elem: DateInput,
      key: "date",
      value: data.date,
      setValue: (date) => setData({ ...data, date }),
    },
    {
      elem: SelectSearch,
      key: "type",
      value: data.type,
      setValue: (type) => setData({ ...data, type }),
      options: filterFactors({ form: "refrigerator" }, "Level 3"),
    },
    {
      elem: NumberInput,
      key: "amount",
      value: data.amount,
      setValue: (amount) => setData({ ...data, amount }),
    },
  ];
};

export default function Refrigerator({ data, setData }) {
  return (
    <>
      {INPUTS(data, setData).map((input, index) =>
        genPut(input, "refrigerator", data, setData)
      )}
    </>
  );
}
