import React, { Suspense } from "react";
import ErrorBoundary from "../../ErrorBoundary";
const CombinedTable = React.lazy(() => import("./CombinedTable"));
export default function Combined() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ErrorBoundary>
        <CombinedTable />
      </ErrorBoundary>
    </Suspense>
  );
}
