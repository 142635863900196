export function convertToNumbers(input) {
  if (Array.isArray(input)) {
    return input.map(convertToNumbers);
  } else if (typeof input === "object" && input !== null) {
    return Object.keys(input).reduce((acc, key) => {
      acc[key] = convertToNumbers(input[key]);
      return acc;
    }, {});
  } else if (
    typeof input === "string" &&
    !isNaN(input) &&
    !isNaN(parseFloat(input))
  ) {
    return parseFloat(input);
  } else {
    return input;
  }
}
