import moment from "moment";
import { monthsArray } from "../comp/options";
const timeFormat = "DD/MM/YYYY";

export const commonFilters = (result, filters, resultconfig) => {
  const momentDate = moment(result.date, timeFormat);
  if (!result.result && !result.wtt) return false;
  if (filters.year) {
    if (filters.year === "custom" && (momentDate.isBefore(moment(filters.startdate, timeFormat)) || momentDate.isAfter(moment(filters.enddate, timeFormat)))) {
      return false;
    }
    if (filters.year !== "custom" && momentDate.format("YYYY") != filters.year) return false;
  }
  if (filters.monthRange) {
    const range = monthsArray.find((obj) => obj.label === filters.monthRange).value;
    const month = momentDate.format("M");
    if (!range.includes(parseInt(month))) {
      return false;
    }
  }
  //todo: select ghg or wttghg
  if (filters.reporting === "SECR") {
    if (result.ghg === 11) return true;
    if (resultconfig.scope === 1 || resultconfig.scope === 2) return true;
    if (result.name === "freighting" && result.ownership === "Owned") return true;
    return false
  }
  if (filters.reporting === "PPN 06/21") {
    if ([11, 12, 10, 14, 9].includes(result.ghg)) return true;
    if (resultconfig.scope === 1 || resultconfig.scope === 2) return true;
    return false;
  }
  return true;
};
export const genRows = (filters) => {
  return {
    fuel: { simple: { scope: 1 }, wtt: { scope: 3 } },
    refrigerator: {
      simple: { scope: 1 },
    },
    cars: {
      company: {
        scope: 1,
        selector: (result) => {
          if (result.form === "Company Owned") return result.result;
          else return 0;
        },
      },
      wttcompany: {
        scope: 3,
        selector: (result) => {
          if (result.form === "Company Owned") return result.wtt;
          else return 0;
        },
      },
      employee: {
        scope: 3,
        selector: (result) => {
          if (result.form === "Employee Owned") return result.result;
          else return 0;
        },
      },
      wttemployee: {
        scope: 3,
        selector: (result) => {
          if (result.form === "Employee Owned") return result.wtt;
          else return 0;
        },
      },
      hire: {
        scope: 3,
        selector: (result) => {
          if (result.form === "Hire Car") return result.result;
          else return 0;
        },
      },
      wtthire: {
        scope: 3,
        selector: (result) => {
          if (result.form === "Hire Car") return result.wtt;
          else return 0;
        },
      },
    },
    electricity: {
      simple: {
        scope: 2,
        selector: (result) => {
          return filters.type === "Location" ? result.result : result.marketbased_result;

          const q1 = filters.type === "Location" ? "Yes" : "No";
          if (result.q1 === q1) return result.result;
          else return 0;
        },
      },
      wtt: {
        scope: 3,
        selector: (result) => {
          return filters.type === "Location" ? result.wtt : result.marketbased_wtt;
          const q1 = filters.type === "Location" ? "Yes" : "No";
          if (result.q1 === q1) return result.wtt;
          else return 0;
        },
      },
      tnd: {
        scope: 3,
        selector: (result) => {
          return filters.type === "Location" ? result.tnd : result.marketbased_tnd;
          const q1 = filters.type === "Location" ? "Yes" : "No";
          if (result.q1 === q1) return result.tnd;
          else return 0;
        },
        ghgSelector: (result) => {
          return result.tndghg;
        },
      },
      wtt_tnd: {
        scope: 3,
        selector: (result) => {
          return filters.type === "Location" ? result.wtt_tnd : result.marketbased_wtt_tnd;
          const q1 = filters.type === "Location" ? "Yes" : "No";
          if (result.q1 === q1) return result.wtt_tnd;
          else return 0;
        },
        ghgSelector: (result) => {
          return result.wtt_tnd_ghg;
        },
      },
    },
    heatandsteam: {
      simple: {
        scope: 2,
        selector: (result) => {
          return result.result;
        },
      },
      wtt: {
        scope: 3,
        selector: (result) => {
          return result.wtt;
        },
      },
      tnd: {
        scope: 3,
        selector: (result) => {
          return result.tnd;
        },
        ghgSelector: (result) => {
          return result.tndghg;
        },
      },
      wtt_tnd: {
        scope: 3,
        selector: (result) => {
          return result.wtt_tnd;
        },
        ghgSelector: (result) => {
          return result.wtt_tnd_ghg;
        },
      },
    },
    flight: {
      simple: { scope: 3 },
      wtt: { scope: 3 },
    },
    waste: {
      simple: { scope: 3 },
    },
    other_travels: {
      simple: { scope: 3 },
      wtt: { scope: 3 },
    },
    freighting: {
      simple: { scope: 3 },
      wtt: { scope: 3 },
    },
    bulk_materials: {
      simple: { scope: 3 },
    },
    commuting: {
      simple: { scope: 3 },
      wtt: { scope: 3 },
    },
    hotel: {
      simple: { scope: 3 },
    },
    water: {
      waste: {
        scope: 3,
        selector: (result) => result.waste_result,
        ghgSelector: (result) => result.waste_ghg,
      },
      supply: {
        scope: 3,
        selector: (result) => result.supply_result,
        ghgSelector: (result) => result.waste_ghg,
      },
    },
    paper: {
      simple: { scope: 3 },
    },
    home_workers: {
      simple: { scope: 3 },
    },
    spending: {
      simple: { scope: 3 },
    },
    additional: {
      simple: { scope: 3 },
      wtt: { scope: 3 },
    },
    product: {
      em_emb: {
        scope: 3,
        selector: (result) => result.em_emb_result,
        ghgSelector: (result) => result.emb_ghg,
      },
      em_dist: {
        scope: 3,
        selector: (result) => result.em_dist_result,
        ghgSelector: (result) => result.dist_ghg,
      },
      em_manf: {
        scope: 3,
        selector: (result) => result.em_manf_result,
        ghgSelector: (result) => result.manf_ghg,
      },
      em_dist_cust: {
        scope: 3,
        selector: (result) => result.em_dist_cust_result,
        ghgSelector: (result) => result.dist_cust_ghg,
      },
      em_use: {
        scope: 3,
        selector: (result) => result.em_use_result,
        ghgSelector: (result) => result.use_ghg,
      },
      em_waste: {
        scope: 3,
        selector: (result) => result.em_waste_result,
        ghgSelector: (result) => result.waste_ghg,
      },
    },
    // added on 25/10/24
    ingredients: {
      simple:{scope: 3}
    },
    naturalgas: {
      simple: { scope: 1 }, wtt: { scope: 3 }
    }
  };
};

export const formatName = (name) => {
  name = name.replace("simple", "");
  name = name.replace("-", " ");
  return name;
};

function sumObjectValues(obj) {
  return Object.values(obj).reduce((acc, val) => acc + val, 0);
}

function removeZeroProperties(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== 0));
}

export function configChartData(chartArr, property = "col") {
  return chartArr
    .slice()
    .sort((a, b) => a[property] - b[property])
    .map((line) => removeZeroProperties(line));
}

export const configSeperations = (seperations) => {
  if (!seperations || !sumObjectValues(seperations)) return undefined;
  else return removeZeroProperties(seperations);
};

export const addPercentages = (ukkwh, globalkwh) => {};

export function createPercentageObject(obj1, obj2) {
  const resultObject = {};

  return resultObject;
}
