import { notification } from "antd";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { setGroups } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsWithIds } from "../util";

export async function fetchGroups(forced = false) {
  try {
    store.dispatch(setGroups(undefined));
    const user = store.getState().user.doc;
    if (user.super_admin) {
      const groupsCollectionRef = collection(db, firestoreStatic.groups);
      const groups = docsWithIds(await getDocs(groupsCollectionRef));
      store.dispatch(setGroups(groups));
    } else if (user.groupAdmin) {
      const groupsCollectionRef = doc(
        db,
        firestoreStatic.groups,
        user.groupAdmin
      );
      const docSnap = await getDoc(groupsCollectionRef);
      if (!docSnap.exists())
        throw "Your group is deleted! Contact Super Admins";
      store.dispatch(setGroups([{ ...docSnap.data(), id: docSnap.id }]));
    }
  } catch (error) {
    // Handle any errors that might occur during the fetch operation.
    console.error("Error fetching groups collection:", error);
    throw error;
  }
}
