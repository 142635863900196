import React from "react";
import YearAnalysis from "../testing/year.analysis";
import { Typography } from "antd";
import { FaLeaf } from "react-icons/fa";

export default function Offset() {
  return (
    <div>
      <Typography.Title>
        Carbon Offsetting
      </Typography.Title>
      <YearAnalysis offsetPage={true} />
    </div>
  );
}
