import { DeleteOutlined, DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Table, Spin, Tooltip, Typography, Space } from "antd";
import React, { useEffect, useState, useCallback, memo, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectFilteredResults, selectSites, setResults } from "../../redux/features/appSlice";
import { deleteResult } from "../../firebase/data/delete";
import Loading from "../../components/loading";
import { NewTabLink, SitePageLink } from "../../components/Link/Link";
import { getAllParamsFromLocation } from "../../helper/loc";
import { antdtablefilters } from "../../static/combinedtable.static";
import { sortByDateCustom } from "../../helper/wodash";
import { findFormTexts } from "../../helper/attribute";
import formatEmissions from "../../components/text/resultEmissions";
import { isNumber } from "lodash";
import More from "./More";
import { DefaultUserFilters, handleFileDownload, TableOptions } from ".";
import { findForm } from "../../static/formRoutes";
import useSize from "../../hooks/useSize";
import { EmptyChart } from "../../components/charts/charts.config";
import ErrorBoundary from "../../ErrorBoundary";
import BarReChart2 from "../../components/charts/BarRechart2";
import { timeFormat } from "../../helper/time";

const ResultTable = ({ name, renderTableOnly }) => {
  const { isExtraSmall } = useSize();
  const dispatch = useDispatch();
  const sites = useSelector(selectSites);
  const form = findForm(name);
  const [loading, setLoading] = useState(true);
  const results = useSelector((state) => selectFilteredResults(state, name));
  const [userFilters, setUserFilters] = useState({ ...DefaultUserFilters });

  const [spreadFilter, setSpreadFilter] = useState(false);

  if (spreadFilter) {
    results = results.filter((result) => {
      return result.spreadtag === spreadFilter;
    });
  }

  // Efficient loading simulation
  useEffect(() => {
    const loadData = setTimeout(() => setLoading(false), 200);
    return () => clearTimeout(loadData);
  }, []);

  const columns = [
    {
      title: "Created/ Updated On",
      dataIndex: "createdOrUpdatedAt",
      sorter: (a, b) => sortByDateCustom(a, b, "createdOrUpdatedAt"),
      defaultSortOrder: "descend",
      width: 150,
    },
    {
      title: "Site",
      render: (record) => <SitePageLink id={record.siteId} />,
      filters: sites.map((s) => ({ text: s.title, value: s.id })),
      onFilter: (siteId, record) => siteId === record.siteId,
      width: 150,
    },
    ...findFormTexts(name, "tables", !renderTableOnly).map((x) => ({
      title: x.title,
      dataIndex: x.name,
      width: 110 + x.title.length * 5,
      render: (val) => {
        if (isNumber(val)) return formatEmissions(val);
        else return val;
      },
    })),
    {
      title: "Comment",
      dataIndex: "comment",
      width: 200,
      render: (comment) => <div style={{ fontSize: 13 }}>{comment}</div>,
    },
    {
      title: "Data Tag",
      dataIndex: "resulttag",
      width: 120,
      render: (tag) => <div style={{ fontSize: 13 }}>{tag}</div>,
      ...antdtablefilters.select(results, "resulttag"),
    },
    {
      title: "Record details",
      fixed: isExtraSmall ? "" : "right",
      width: 150,
      render: (result) => {
        return (
          <Space
            style={{
              justifyContent: "flex-end",
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            <TableOptions result={result} form={form} type="details" />
            <More handleFileDownload={handleFileDownload} result={result} form={form} setSpreadFilter={setSpreadFilter} />
          </Space>
        );
      },
    },
    {
      title: "Actions",
      fixed: isExtraSmall ? "" : "right",
      width: 170,
      render: (result) => {
        return (
          <div
            style={{
              fontSize: "1.4em",
              overflowX: "auto",
              display: "flex",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            {!renderTableOnly && (
              <>
                <TableOptions result={result} form={form} type="actions" />
                {/** */}
                {!result.spreadtag && !["spending"].includes(result.name) ? (
                  <Tooltip title="Spread data into multiple months">
                    {" "}
                    <span style={{ color: "darkgrey" }}>|</span>
                    <More
                      result={result}
                      openSpread={true}
                      form={form}
                      handleFileDownload={handleFileDownload}
                      setSpreadFilter={setSpreadFilter}
                      icon={
                        <Button
                          type="link"
                          style={{
                            fontSize: "14px",
                            padding: 2,
                            color: "darkgreen",
                          }}
                        >
                          Spread
                        </Button>
                      }
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const Chart = () => {
    if (results?.length)
      return (
        <ErrorBoundary>
            <div>aloooooooooooooooooooooooooooooooooooooooooo</div>
          <BarReChart2 form={form} results={results ?? []} timeFormat={timeFormat} />
        </ErrorBoundary>
      );
    else return <EmptyChart />;
  };
  return (
    <Spin spinning={loading}>
      <Chart />
      <Table columns={columns} dataSource={results} pagination={{ defaultPageSize: 10, pageSizeOptions: [10, 100, 1000] }} scroll={{ y: 400 }} rowKey="id" />
    </Spin>
  );
};

const Result = ({ name }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Typography.Title level={3}>Results for {name}</Typography.Title>
      <ResultTable key={getAllParamsFromLocation().name} name={name} />
      <Button onClick={() => navigate("/add-new")} type="primary" icon={<PlusOutlined />}>
        Add New Result
      </Button>
    </div>
  );
};

export default Result;
