import { useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import Select1 from "../../../components/select/select1";
import { filterFactors } from "../../../helper/emissionfactors";
import { findForm } from "../../../static/formRoutes";
import { genPut } from "../helper/create";
import { isSustraxAnnual, isSustraxLite } from "../../../helper/plans";

export const INPUTS = (data, setData) => {
  if(!data)data={}
  const formname = data.name;
  const methodNames = findForm(formname)?.methodNames||[];
  const findUOM = () => {
    let options = filterFactors(
      { form: "commuting", "Level 2": data.category, "Level 3": data.type },
      "UOM"
    );
    if (options.includes("passenger.km")) {
      options = ["km", "mile"]; // we will have to use these but display them as km and miles as well.
    }
    return options;
  };
  return [
    {
      elem: DateInput,
      key: "date",
      value: data.date,
    },
    {
      elem: SelectSearch,
      key: "category",
      value: data.category,
      set: {
        type: null,
        columntext: null,
        uom: null,
        passengers: 1,
      },
      options: filterFactors({ form: "commuting" }, "Level 2"),
    },
    {
      elem: SelectSearch,
      key: "type",
      value: data.type,
      set: {
        columntext: null,
        uom: null,
      },
      options: filterFactors(
        { form: "commuting", "Level 2": data.category },
        "Level 3"
      ),
      // hidden: data.category === "Rail" || data.category === "Bus",
    },
    {
      elem: SelectSearch,
      key: "columntext",
      value: data.columntext,
      options: filterFactors(
        { form: "commuting", "Level 2": data.category, "Level 3": data.type },
        "Column Text"
      ),
      hidden: !data.type,
    },
    {
      elem: SelectSearch,
      key: "uom",
      value: data.uom,
      options: findUOM(),
      displayAs: { "passenger.km": "km", "passenger.mile": "mile" },
      // hidden: data.category === "Rail" || data.category === "Bus",
    },
    {
      elem: Select1,
      key: "q1",
      value: data.q1,
      set: {
        amount: null,
        amount2: null,
        days: null,
      },
      options: [
        {
          key: isSustraxAnnual() ? "Annual Distance" : "Monthly Distance",
          value: methodNames[0],
        },
        {
          key: "One Way Distance",
          value: methodNames[1],
        },
      ],
    },
    {
      elem: NumberInput,
      key: "amount",
      value: data.amount,
      hidden: data.q1 !== methodNames[0],
    },
    {
      elem: NumberInput,
      key: "amount2",
      value: data.amount2,
      hidden: data.q1 === methodNames[0],
    },
    {
      elem: NumberInput,
      key: "days",
      value: data.days,
      hidden: data.q1 === methodNames[0],
    },
    {
      elem: NumberInput,
      key: "passengers",
      value: data.passengers,
      hidden: ["Ferry", "Bus", "Taxis", "Rail"].includes(data?.category)&&false,
    },
  ];
};

export default function Commuting({ data, setData }) {
  const formname = data.name;
  const methodNames = findForm(formname).methodNames;

  useEffect(() => {
    setData({ ...data, q1: data.q1 || methodNames[0] });
  }, []);

  return (
    <>
      {INPUTS(data, setData).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </>
  );
}
