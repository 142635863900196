import { useMediaQuery } from "react-responsive";

const useSize = () => {
  const isExtraSmall = useMediaQuery({ query: "(max-width: 599px)" });
  const isSmall = useMediaQuery({
    query: "(min-width: 600px) and (max-width: 959px)",
  });
  const isMedium = useMediaQuery({
    query: "(min-width: 960px) and (max-width: 1279px)",
  });
  const isLarge = useMediaQuery({
    query: "(min-width: 1280px) and (max-width: 1919px)",
  });
  const isExtraLarge = useMediaQuery({ query: "(min-width: 1920px)" });

  return {
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isMobile: isSmall || isExtraSmall,
  };
};

export default useSize;
