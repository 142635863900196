import { EditOutlined } from "@ant-design/icons";
import { Alert, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import { getUpdatesProp } from "../../helper/attribute";
import { selectTheme } from "../../redux/features/appSlice";
import { selectConfigData } from "../../redux/features/configSlice";
import "../../styles/tabs.antd.css";
import FormConfig from "./ConfigPages/FormConfig";
import { GHG_Categories } from "./ConfigPages/GHG_Categories";
import EmissionFactors from "./EmissionFactors";
import AppTexts from "./SuperAdminSectionPages/AppTexts";
import BulkUploadTemplates from "./SuperAdminSectionPages/BulkUploadTemplates";
import Faqconfig from "./SuperAdminSectionPages/Faqconfig";
import AIStuff from "./SuperAdminSectionPages/AIStuff";
import UpdateVidForForms from "./SuperAdminSectionPages/formVidLinks";

const ConfigPage = () => {
  const configData = useSelector(selectConfigData);
  const [activeKey, setActiveKey] = useState(0);
  const theme = useSelector(selectTheme);

  useEffect(() => {
    setData({ ...configData });
  }, [configData]);
  const [data, setData] = useState({});
  const handleCancel = (type) => {
    setData(JSON.parse(JSON.stringify(data)));
  };
  const items = [
    ...[
      { name: "forms", title: "Forms" },
      { name: "tables", title: "Table" },
      { name: "reviewpages", title: "Review Page" },
    ].map((x) => ({
      key: x.name,
      label: (
        <>
          <EditOutlined /> {x.title}
        </>
      ),
      children: (
        <FormConfig
          name={x.name}
          key={JSON.stringify(data)}
          data={data}
          handleCancel={() => handleCancel(x.name)}
        />
      ),
    })),
    {
      key: "ghg_categories",
      label: (
        <>
          <EditOutlined /> GHG Categories
        </>
      ),
      children: <GHG_Categories data={data} />,
    },
    {
      key: "emission_factors",
      label: (
        <>
          <EditOutlined /> Emission Factors
        </>
      ),
      children: <EmissionFactors />,
    },
    {
      key: "bulk_uploads",
      label: (
        <>
          <EditOutlined /> Bulk Upload
        </>
      ),
      children: <BulkUploadTemplates />,
    },
    {
      key: "faq",
      label: (
        <>
          <EditOutlined /> FAQ
        </>
      ),
      children: <Faqconfig />,
    },
    {
      key: "App Texts",
      label: (
        <>
          <EditOutlined /> App Texts
        </>
      ),
      children: <AppTexts />,
    },
    {
      key: "AI",
      label: (
        <>
          <EditOutlined /> AI
        </>
      ),
      children: <AIStuff />,
    },
    {
      key: "vid-linsk",
      label: (
        <>
          <EditOutlined /> Form Videos
        </>
      ),
      children: <UpdateVidForForms />,
    },
  ];
  if (!configData) return <Loading />;
  return (
    <div>
      <Alert
        style={{ marginBottom: "10px" }}
        message={
          <div>
            {items[activeKey].label}{" "}
            {`Last Updated at ${getUpdatesProp(
              items[parseInt(activeKey)]?.key
            )}`}
          </div>
        }
      ></Alert>
      <Tabs
        className={`vertical-tabs ${
          !theme.isDarkMode ? "bg-white" : "bg-black"
        }`}
        defaultActiveKey={activeKey}
        onChange={(key) => setActiveKey(items.findIndex((x) => x.key === key))}
        tabPosition="left"
        centered
        items={items}
      />
    </div>
  );
};

export default ConfigPage;
