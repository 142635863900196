import React, { useEffect, useState } from "react";
import { Card, Row, Col, Typography, Spin, Button } from "antd";
import { PlayCircleOutlined, PlaySquareOutlined } from "@ant-design/icons";
import Loading from "../../../components/loading";
import { getAppInfo } from "../../../firebase/appinfo/create";
import { catcher } from "../../../firebase/util";
import { addOrReplaceQueryParam, getAllParamsFromLocation } from "../../../helper/loc";
import { getFormName } from "../testing/config";
import { Link } from 'react-router-dom';
import { FaArrowRight, FaChalkboardTeacher } from 'react-icons/fa';
import { getCurrentPlan } from "../../../helper/plans";
import { routePath } from "../../../static/routes.static";
import { TbChalkboard } from "react-icons/tb";

const Learn = () => {
  const [videoData, setVideoData] = useState({ forms: {}, other: {} });
  const [currentPlan] = useState(getCurrentPlan);
  const [loading, setLoading] = useState(true);
  const [currentVideo, setCurrentVideo] = useState(getAllParamsFromLocation()?.id);

  console.log(currentVideo, getFormName(currentVideo), "current plan");

  useEffect(() => {
    catcher(
      async () => {
        let data_full = await getAppInfo("form_vid_links");
        let data = { forms: {}, other: {} };

        // Categorize form videos
        Object.keys(data_full).forEach((key) => {
          if (key.includes("-" + currentPlan) && data_full[key] && data_full[key] !== "") {
            data.forms[key] = data_full[key];
          }
        });

        // Add other videos to the "other" section
        data.other["Spread"] = "https://www.youtube.com/watch?v=tB6vouD_hUg";
        data.other["Scale"] = "https://www.youtube.com/watch?v=5_5Y7TkfmVY";

        const convertedData = convertVideoLinks(data);
        setVideoData(convertedData);
        setLoading(false); // Stop loading once data is set
      },
      { success_msg: "", setLoading }
    );
  }, [currentPlan]);

  const handleSetCurrentVideo = (formName) => {
    addOrReplaceQueryParam("id", formName);
    setCurrentVideo(formName);
    window.scroll({ top: 0 });
  };

  return (
    <div style={{ padding: "30px" }}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {currentVideo && (
            <>
              <Typography.Title level={3} style={{ marginTop: 0, lineHeight: 0, marginBottom: 50 }}>
                {![undefined, "undefined", " undefined", null].includes(getFormName(currentVideo)) ? getFormName(currentVideo) + " input" : currentVideo} tutorial
              </Typography.Title>
              <Spin spinning={loading}>
                <iframe
                  title={currentVideo}
                  width="100%"
                  height="700px"
                  src={videoData.forms[currentVideo] || videoData.other[currentVideo]}
                  frameborder="0"
                  allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  style={{
                    marginBottom: "20px",
                    border: "1px solid black",
                    borderRadius: "10px",
                  }}
                />
              </Spin>
            </>
          )}

          <Typography.Title level={3}>Form Tutorials</Typography.Title>
          <Row gutter={[16, 16]}>
            {Object.entries(videoData.forms).map(([key, embedUrl]) => (
              <Col xs={24} sm={12} md={6} lg={6} xl={6} key={key}>
                <Card
                  hoverable
                  style={{ position: "relative" }}
                  cover={
                    <>
                      <img alt={key} src={`https://img.youtube.com/vi/${extractYoutubeId(embedUrl)}/0.jpg`} style={{ width: "100%" }} />
                      <PlayCircleOutlined
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -100%)",
                          fontSize: "100px",
                          color: "white",
                        }}
                      />
                    </>
                  }
                  onClick={() => handleSetCurrentVideo(key)}
                >
                  <Card.Meta title={`${getFormName(key)} tutorial`} description="Click to watch the video" />
                </Card>
              </Col>
            ))}
          </Row>

          <Typography.Title level={3}>Other Tutorials</Typography.Title>
          <Row gutter={[16, 16]}>
            {Object.entries(videoData.other).map(([key, embedUrl]) => (
              <Col xs={24} sm={12} md={6} lg={6} xl={6} key={key}>
                <Card
                  hoverable
                  style={{ position: "relative" }}
                  cover={
                    <>
                      <img alt={key} src={`https://img.youtube.com/vi/${extractYoutubeId(embedUrl)}/0.jpg`} style={{ width: "100%" }} />
                      <PlayCircleOutlined
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -100%)",
                          fontSize: "100px",
                          color: "white",
                        }}
                      />
                    </>
                  }
                  onClick={() => handleSetCurrentVideo(key)}
                >
                  <Card.Meta title={`${key} tutorial`} description="Click to watch the video" />
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

function convertVideoLinks(data) {
  const convertedData = { forms: {}, other: {} };

  for (const key in data.forms) {
    const videoId = extractYoutubeId(data.forms[key]);
    convertedData.forms[key] = `https://www.youtube.com/embed/${videoId}?autoplay=1&allowfullscreen=1&playlist=${videoId}&loop=1&rel=0`;
  }

  for (const key in data.other) {
    const videoId = extractYoutubeId(data.other[key]);
    convertedData.other[key] = `https://www.youtube.com/embed/${videoId}?autoplay=1&allowfullscreen=1&playlist=${videoId}&loop=1&rel=0`;
  }

  return convertedData;
}

function extractYoutubeId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
}



const LearnMore = ({path}) => {
  return (
    <div className="learn-more-container" style={{float:"right"}}>
      <Link to={routePath.learn(path)} className="learn-more-button">
        <Button type="primary" style={{ }} className="btn" icon={<PlaySquareOutlined style={{fontSize:"larger"}}   />}>
         Watch {path} tutorial 
        </Button>
      </Link>
    </div>
  );
};

export  {LearnMore};


export default Learn;
