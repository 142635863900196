//consultants can go to users profile and see their data.

import { Button, Divider, Empty, Image, Input, message, Table, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Wrapper from "../../../components/wrapper";
import { catcher } from "../../../firebase/util";
import { get_consultants } from "../../../firebase/super_admins/get_consultants";
import { fetchUserByEmail } from "../../../firebase/user/get";
import { updateUser } from "../../../firebase/user/update";
import store from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setConsultants } from "../../../redux/features/superAdminSlice";
import { getFile } from "../../../firebase/storage/get";
import { createFile } from "../../../firebase/storage/create";
import { downloadFileFromStorage } from "../../../firebase/storage/download";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";

const UserDetails = ({ user }) => {
  const [details, setDetails] = useState(user.details_for_template || "");
  const [summary, setSummary] = useState(user.summary_for_template || "");

  const handleUpdate = async () => {
    await catcher(async () => {
      if (details !== user.details_for_template || summary !== user.summary_for_template) {
        await updateUser(user.id, {
          details_for_template: details,
          summary_for_template: summary,
        });
      }
    });
  };

  return (
    <>
      <Input.TextArea rows={4} value={details} onChange={(e) => setDetails(e.target.value)} placeholder="Enter details" />
      <Input.TextArea rows={2} value={summary} onChange={(e) => setSummary(e.target.value)} placeholder="Enter summary" />
      <Button onClick={handleUpdate}>Update</Button>
    </>
  );
};

const UserSignature = ({ user }) => {
  const id = user?.id;
  const signature = user?.signature;
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleUpload = async (event) => {
    await catcher(async () => {
      const file = event.target.files[0];
      if (file) {
        const filePath = `user/${id}/signature.png`;
        const downloadURL = await createFile(filePath, file);
        // You can update the user signature here if needed
        await updateUser(id, { signature: filePath });
        message.info("File uploaded successfully: " + downloadURL);
        dispatch(
          setConsultants(
            store.getState().superAdminData.consultants.map((consultant) => {
              if (consultant.id === user.id) {
                return { ...consultant, signature: filePath };
              } else return consultant;
            })
          )
        );
      }
    });
  };

  const handleDownload = async () => {
    try {
      if (signature) {
        await downloadFileFromStorage(signature, "signature.png");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <Button onClick={triggerFileInput}>
        Upload {signature && "again"}
        <UploadOutlined />
      </Button>
      {signature && (
        <div>
          <Divider />
          <Button size="small" onClick={handleDownload}>
            Download Signature
          </Button>
          <Button
            size="small"
            onClick={async () => {
              await updateUser(id, { signature: null });
              message.info("done, will be visible after refresh.");
              dispatch(
                setConsultants(
                  store.getState().superAdminData.consultants.map((consultant) => {
                    if (consultant.id === user.id) {
                      return { ...consultant, signature: null };
                    } else return consultant;
                  })
                )
              );
            }}
          >
            Remove <CloseOutlined />
          </Button>
        </div>
      )}
      <input type="file" style={{ display: "none" }} onChange={handleUpload} accept="image/*" ref={fileInputRef} />
    </div>
  );
};

export default function Consultants() {
  const consultants = useSelector((state) => state.superAdminData.consultants);
  console.log(consultants);
  useEffect(() => {
    catcher(
      async () => {
        await get_consultants();
      },
      { success_msg: "" }
    );
  }, []);
  const handleAddConsultant = async () => {
    catcher(async () => {
      const consultant_mail = prompt("Consultant Email?");
      if (!consultant_mail) return;
      const user = await fetchUserByEmail(consultant_mail);
      if (!user) throw "User does not exist";
      if (window.confirm(`Confirm to add ${user.firstName} ${user.lastName} ${user.email} as consultant`)) {
        await updateUser(user.id, { consultant: true });
        store.dispatch(setConsultants([...consultants, user]));
      }
    }, {});
  };
  const handleRemoveConsultant = async (consultant) => {
    catcher(async () => {
      await updateUser(consultant?.id, { consultant: false });
      store.dispatch(setConsultants(consultants.filter((c) => c.email !== consultant.email)));
    }, {});
  };
  return (
    <Wrapper title="Consultants" loading={!consultants}>
      <Table
        pagination={false}
        dataSource={consultants}
        title={() => (
          <div>
            <Button onClick={handleAddConsultant}>+ add consultant</Button>
          </div>
        )}
        columns={[
          { dataIndex: "email", title: "Email" },
          { dataIndex: "firstName", title: "First Name" },
          { dataIndex: "lastName", title: "Last Name" },
          {
            title: "Sign",
            render: (user) => <UserSignature user={user} />,
          },
          {
            title: "Phone",
            dataIndex: "phone",
            // render: (user) => <UserSignature user={user} />,
          },
          {
            title: "Details (For templates)",
            render: (user) => <UserDetails user={user} />,
          },
          {
            title: "Options",
            render: (consultant) => {
              return (
                <Button
                  danger
                  // style={{ background: "grey", color: "white" }}
                  onClick={() => handleRemoveConsultant(consultant)}
                >
                  Remove
                </Button>
              );
            },
          },
        ]}
      />
    </Wrapper>
  );
}
