import { atom } from "jotai";
import {atomWithStorage} from "jotai/utils";

export const consultantAtom = atom(undefined);
export const formNamesAtom = atom([]);
export const reportDataAtom = atom({
  date: "",
  marketBasedEmission: "",
  locationBasedEmission: "",
  companyName: "",
  startDate: "",
  endDate: "",
  accuracyComments: "",
  recommendations: "",
  consultant_signature: "",
  consultant_details: "",
});
export const tableDataSourceAtom = atom([]);

//---------other atoms

export const tableCollapsedCompanyDetailsAtom = atomWithStorage("tableCollapseCompanyDetails", false);
