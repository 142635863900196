import { message, Modal, notification, Skeleton, Spin } from "antd";
import { findCompany, findSite } from "../../../helper/attribute";
import { planValue } from "../../../helper/plans";
import { getYearFromDateFormatWithMonth } from "../../../helper/wodash";
import store from "../../../redux/store";
import { extractYears } from "../../results/resultsCalcs";

export const extractYear = (resultData, returnActualYear = false) => {
  try {
    // get date based on the data
    // const company = findCompany(findSite(data.siteId).companyId);
    // const year = getYearFromDateFormatWithMonth(
    //   data.date,
    //   company.reporting_year_starting_month
    // );
    const year = extractYears([resultData])?.[0];

    if (!returnActualYear && year > 2024) return 2024;
    return year;
  } catch (err) {
    // alert("YEAR NOT FOUND IN GETTER.JS! CRITICAL ERROR!");
    return 2024;
  }
};

export const inputAllowed = (data) => {
  const maxInputsAllowed = planValue("form_" + data.name);
  const currentInputsLength = store
    .getState()
    .app.results?.filter((r) => r?.name === data.name)?.length;

  if (maxInputsAllowed !== -1 && currentInputsLength >= maxInputsAllowed)
    return false;
  return true;
};
export const inputsDoneSentence = (data) => {
  const maxInputsAllowed = planValue("form_" + data.name);
  const currentInputsLength = store
    .getState()
    .app.results?.filter((r) => r?.name === data.name)?.length;

  if (currentInputsLength === undefined) return <Spin size="small"></Spin>;

  return `(${currentInputsLength} of ${maxInputsAllowed} entries)`;
};
