import React, { useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { filterFactors } from "../../../helper/emissionfactors";
import { findFormText } from "../../../helper/attribute";
import { genPut } from "../helper/create";

export const INPUTS = (data, setData) => {
  const formname = "waste";

  return [
    {
      elem: DateInput,
      key: "date",
      title: findFormText(formname, "date"),
      description: findFormText(formname, "date", "desc"),
      value: data.date,
      setValue: (date) => setData({ ...data, date }),
    },
    {
      elem: SelectSearch,
      key: "category",
      title: findFormText(formname, "category"),
      description: findFormText(formname, "category", "desc"),
      value: data.category,
      setValue: (category) =>
        setData({
          ...data,
          category,
          type: null,
          uom: null,
          columntext: null,
        }),
      options: filterFactors({ "Level 1": "Waste disposal" }, "Level 2", false),
    },
    {
      elem: SelectSearch,
      key: "type",
      title: findFormText(formname, "type"),
      description: findFormText(formname, "type", "desc"),
      hidden: !data.category,
      value: data.type,
      setValue: (type) =>
        setData({ ...data, type, uom: null, columntext: null }),
      options: filterFactors(
        { "Level 1": "Waste disposal", "Level 2": data.category },
        "Level 3",
        false
      ),
    },
    {
      elem: SelectSearch,
      key: "columntext",
      title: findFormText(formname, "columntext"),
      hidden: !data.type,
      description: findFormText(formname, "columntext", "desc"),
      value: data.columntext,
      setValue: (columntext) => setData({ ...data, columntext, uom: null }),
      options: [
        ...filterFactors(
          {
            "Level 1": "Waste disposal",
            "Level 2": data.category,
            "Level 3": data.type,
          },
          "Column Text",
          false
        ).filter((name) => name !== "Open-loop"),
        "Re-use",
      ],
    },
    {
      elem: SelectSearch,
      key: "uom",
      title: findFormText(formname, "uom"),
      description: findFormText(formname, "uom", "desc"),
      hidden: !data.columntext,
      value: data.uom,
      setValue: (uom) => setData({ ...data, uom }),
      options: [
        ...filterFactors(
          {
            "Level 1": "Waste disposal",
            "Level 3": data.type,
            "Level 2": data.category,
          },
          "UOM"
        ),
        "kg",
        "g"
      ],
    },
    {
      elem: NumberInput,
      key: "amount",
      title: findFormText(formname, "amount"),
      description: findFormText(formname, "amount", "desc"),
      value: data.amount,
      setValue: (amount) => setData({ ...data, amount }),
    },
  ];
};

export default function Waste({ data, setData }) {
  const formname = "waste";

  useEffect(() => {
    setData({ ...data, q1: data.q1 || "No" });
  }, []);

  return (
    <div>
      {INPUTS(data, setData).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </div>
  );
}
