import React from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";

const AntDrop = ({
  options,
  onChange,
  children = (
    <>
      Select an option <DownOutlined />
    </>
  ),
}) => {
  if (options.length === 1) {
    return <div onClick={() => onChange(options[0])}>{children}</div>;
  }
  const handleMenuClick = (e) => {
    // Call the onChange function with the selected option
    onChange(options[e.key]);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {options.map((option, index) => (
        <Menu.Item key={index.toString()}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  return <Dropdown overlay={menu}>{children}</Dropdown>;
};

export default AntDrop;
