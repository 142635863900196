import { ExportOutlined } from "@ant-design/icons";
import React from "react";
import { T1 } from "../../../../appconfig/texts";
import { downloadXLSX, transformKeys } from "../../../../helper/wodash";
import useSize from "../../../../hooks/useSize";
import { configDataSource, getFormName } from "../config";
import { firstCompany } from "../../../../helper/attribute";

export const configDataSourceForDownload = (dataSource, cols, options) => {
  dataSource = configDataSource(dataSource, cols);
  let data = [];
  for (let i = 0; i < dataSource.length; i++) {
    //header data
    const headerRow = { Group: dataSource[i].name };
    for (let col of cols) {
      headerRow[col] = dataSource[i][col];
    }
    data.push(headerRow);
    if (dataSource[i]["children"]) {
      const toAdd = [...dataSource[i]["children"]];
      toAdd.forEach(
        (row) => (row.name = getFormName(row.name, options.reportType))
      );
      data = data.concat(toAdd);
    }
  }
  return data;
};

export default function ExportButton({
  dataSource,
  fileName,
  cols,
  options = {},
}) {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  const handleDownload = () => {
    try {
      const data = configDataSourceForDownload(dataSource, cols, options);
      downloadXLSX(
        `${fileName}`,
        transformKeys(data, (key) => {
          if (key === "name") return "Name";
          return key;
        }),
        null,
        null,
        [],
        ["Group", "Name", ...cols]
      );
    } catch (err) {}
  };
  return (
    <>
      {/* {JSON.stringify(dataSource)} */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 10,
          cursor: "pointer",
        }}
      >
        <div
          className="button-primary-1"
          style={{
            padding: "2px 10px",
            fontSize: isExtraSmall ? "12px" : "14px",
          }}
          onClick={handleDownload}
        >
          <T1 pos={"app.export"} /> <ExportOutlined />
        </div>
      </div>
    </>
  );
}
