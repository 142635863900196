import { ApartmentOutlined, CalculatorOutlined, CrownOutlined, DeleteOutlined, FileOutlined, HomeOutlined, LeftOutlined, RightOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Divider, Input, Modal, Select, Space, Tabs, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import texts, { T1 } from "../../../appconfig/texts";
import TextInput from "../../../components/input/TextInput";
import NumberInput from "../../../components/input/number";
import FakeLabel from "../../../components/input/select";
import FormContext from "../../../contexts/FormContext";
import { deleteCompany, forceDeleteCompany } from "../../../firebase/company/delete";
import { fetchCompanies } from "../../../firebase/company/get";
import { updateCompany } from "../../../firebase/company/update";
import { except } from "../../../firebase/util";
import { designations, findCompany, getAllCompanyData, getCurrentUserDesignation, printCompanyStats } from "../../../helper/attribute";
import { addOrReplaceQueryParam, getAllParamsFromLocation } from "../../../helper/loc";
import { selectTheme } from "../../../redux/features/appSlice";
import { tco2e } from "../../../static/texts.static";
import CompanyAdmins from "./admins";
import IntensityMetrics from "./comp/metrics";
import { fixDecimals } from "../../../helper/wodash";
import AdditionalDataMetrics from "./comp/additionalmetrics_input";
import { BeautifyData } from "../../../components/text/BeautifyData";
import CompanyUsers from "./company_users";
import FileSelectCompany from "./others/file";
import { FaSitemap } from "react-icons/fa";
import CompanySites from "./company_sites";
import useSize from "../../../hooks/useSize";
import { planValue } from "../../../helper/plans";
import { TbTargetArrow } from "react-icons/tb";
import Targets from "./comp/targets_wrap";
import { useAtom } from "jotai";
import { tableCollapsedCompanyDetailsAtom } from "../../consultants/atom/atoms";
const CompanyProfile = ({ _: companyData, canEditCompany }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ ...companyData });
  const [oldData, setOldData] = useState({ ...companyData });
  const { isMobile } = useSize();
  const handleUpdate = () => {
    except(async () => {
      setLoading(true);
      await updateCompany(companyData.id, data);
      setOldData(data);
      setLoading(false);
    });
  };
  const handleCancel = () => {
    setData({ ...oldData });
  };
  const isUpdated = JSON.stringify(data) !== JSON.stringify(oldData);
  return (
    <Card>
      <>
        <Typography.Title level={3}>{<T1 pos="company.profile.text" />}</Typography.Title>
        <Divider style={{ margin: 0 }} />

        <TextInput displayOnly value={data.createdAt + "🗓️"} title={<T1 pos="company.profile.madeOn" />} />
        <TextInput value={data.title} setValue={(e) => setData({ ...data, title: e })} title={<T1 pos="company.profile.name" />} />
        <FakeLabel
          title={<T1 pos="company.profile.about" />}
          elem={
            <Input.TextArea
              rows={5}
              value={data.about}
              onChange={(e) => setData({ ...data, about: e.target.value })}
              addonBefore={<T1 pos="company.profile.about" />}
              placeholder={texts.company.profile.aboutPlaceHolder}
            />
          }
        />
        <FakeLabel
          title={<T1 pos="company.profile.startingFrom" />}
          info={<T1 pos="company.profile.startingFromInfo" />}
          elem={
            <Select style={{ width: 300 }} value={data.reporting_year_starting_month} onChange={(val) => setData({ ...data, reporting_year_starting_month: val })}>
              {moment.months().map((m) => (
                <Select.Option key={m}>{m}</Select.Option>
              ))}
            </Select>
          }
        ></FakeLabel>
        <Typography.Title level={3}>Data</Typography.Title>
        <Divider style={{ margin: 0 }} />
        <FakeLabel
          title={"Data Tags"}
          info={"Use data tags to seperate the results. You can view them under data tags analysis page, once set."}
          elem={
            <div>
              <Select
                notFoundContent={<>Enter tags separated by commas</>}
                placeholder={texts.site.profile.tagsPlaceHolder}
                mode="tags"
                value={data.resulttags}
                onChange={(resulttags) => {
                  // Filter out empty values and trim spaces from each tag
                  const processedTags = resulttags.map((tag) => tag.trim()).filter((tag) => tag !== "");
                  setData({ ...data, resulttags: processedTags });
                }}
                style={{
                  width: isMobile ? "100%" : "400px",
                }}
                tokenSeparators={[","]}
                rootClassName="notranslate"
              />
            </div>
          }
        />
        <AdditionalDataMetrics data={JSON.parse(JSON.stringify(data))} setData={setData} />
        {!planValue("hide_targets_company_page") ? (
          <div>
            <Typography.Title level={3}>Targets</Typography.Title>
            <Divider style={{ margin: 0 }} />

            <NumberInput
              title="Annual Target"
              value={data.annualTarget}
              addonAfter={tco2e}
              setValue={(e) => {
                setData({
                  ...data,
                  annualTarget: e,
                  monthlyTarget: e / 12,
                });
              }}
            />

            <NumberInput
              title="Monthly Target"
              disabled={true}
              value={fixDecimals(data.monthlyTarget)}
              addonAfter={tco2e}
              setValue={(e) => {
                setData({
                  ...data,
                  monthlyTarget: e,
                });
              }}
            />
          </div>
        ) : (
          ""
        )}

        {canEditCompany && (
          <Space>
            <Button loading={loading} onClick={handleUpdate} type="primary" disabled={!isUpdated}>
              Update
            </Button>
            {isUpdated && <Button onClick={handleCancel}>Cancel</Button>}
          </Space>
        )}
      </>
    </Card>
  );
};

const Admins = ({ _ }) => {
  return (
    <Card>
      <CompanyAdmins _={_} />
    </Card>
  );
};

const Settings = ({ _ }) => {
  const { isMobile } = useSize();

  const navigate = useNavigate();
  const handleDeleteCompany = () => {
    except(async () => {
      await deleteCompany(_.id);
      navigate("/dashboard");
      fetchCompanies();
    });
  };
  const handleForceDeleteCompany = () => {
    Modal.confirm({
      width: 1300,
      onOk: async () => await forceDeleteCompany(_.id),
      content: (
        <div>
          <Typography.Title>Please review company details before deleting.</Typography.Title> <BeautifyData data={getAllCompanyData(_.id)}></BeautifyData>
        </div>
      ),
    });
  };
  if (getCurrentUserDesignation() !== designations.super_admin) return false;
  return (
    <Card>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: isMobile ? "10px" : "30px",
        }}
      >
        <Typography.Title level={3}>{<T1 pos="company.settings" />}</Typography.Title>
      </div>
      <>
        <Alert
          message={"Delete Company"}
          description={"Requires company has no assosiated data."}
          action={
            <Button onClick={handleDeleteCompany} style={{ background: "darkred", color: "white" }}>
              Delete
              <DeleteOutlined />
            </Button>
          }
        ></Alert>
        <Divider orientation="left">Dangerous Actions Below</Divider>
        <Alert
          message={"Force Delete Company"}
          description={"Deletes all assossiated users, sites, and other data."}
          action={
            <Button onClick={handleForceDeleteCompany} style={{ background: "darkred", color: "white" }}>
              Force delete
              <DeleteOutlined />
            </Button>
          }
        ></Alert>
      </>
    </Card>
  );
};

export default function CompanyDetails() {
  const { isMobile } = useSize();
  const { id } = useParams();
  const [tabsCollapsed, setTabsCollapsed] = useAtom(tableCollapsedCompanyDetailsAtom);
  const canEditCompany = [designations.company_admin, designations.group_admin, designations.super_admin, designations.affiliate].includes(getCurrentUserDesignation());
  const theme = useSelector(selectTheme);
  const company = findCompany(id);

  useEffect(() => {
    if (isMobile) {
      setTabsCollapsed(true);
    }
  }, [isMobile]);

  const Collapsor = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        marginBottom:10,
      }}
    >
      <Typography.Title level={5} >
        <Button type="link" icon={tabsCollapsed ? <RightOutlined /> : <LeftOutlined />} onClick={() => setTabsCollapsed(!tabsCollapsed)} style={{ fontSize: "16px", cursor: "pointer" }}>
          {/* {tabsCollapsed ? "Expand Tabs" : "Collapse Tabs"} */}
        </Button>
        {company.title}
      </Typography.Title>
    </div>
  );

  const items = [
    {
      key: "profile",
      label: (
        <>
          <HomeOutlined /> {!tabsCollapsed && <T1 pos="company.profile.text" />}
        </>
      ),
      children: (
        <>
          
          <CompanyProfile _={findCompany(id)} canEditCompany={canEditCompany} />
        </>
      ),
      filter: true,
    },
    {
      key: "users",
      label: (
        <>
          <UserOutlined /> {!tabsCollapsed && "Users"}
        </>
      ),
      children: (
        <>
          
          <CompanyUsers companyId={id} />
        </>
      ),
      filter: true,
    },
    {
      key: "sites",
      label: (
        <>
          <ApartmentOutlined /> {!tabsCollapsed && "Sites"}
        </>
      ),
      children: (
        <>
          
          <CompanySites companyId={id} />
        </>
      ),
      filter: true,
    },
    {
      key: "admins",
      label: (
        <>
          <CrownOutlined /> {!tabsCollapsed && <T1 pos="company.companyAdmins.title" />}
        </>
      ),
      children: (
        <>
          
          <Admins _={findCompany(id)} />
        </>
      ),
      filter: canEditCompany,
    },
    {
      key: "metrics",
      label: (
        <>
          <CalculatorOutlined /> {!tabsCollapsed && <T1 pos="company.intensityMetrics.text" />}
        </>
      ),
      children: (
        <>
          
          <IntensityMetrics _={findCompany(id)} />
        </>
      ),
      filter: true,
    },
    {
      key: "file",
      label: (
        <>
          <FileOutlined /> {!tabsCollapsed && <T1 pos="site.files.text" />}
        </>
      ),
      children: (
        <>
          
          <FileSelectCompany id={id} />
        </>
      ),
      filter: true,
    },
    // {
    //   key: "targets",
    //   label: (
    //     <>
    //       <TbTargetArrow /> {!tabsCollapsed && "Targets"}
    //     </>
    //   ),
    //   children: (
    //     <>
          
    //       <Targets _={findCompany(id)} />
    //     </>
    //   ),
    //   filter: true,
    // },
    {
      key: "settings",
      label: (
        <>
          <SettingOutlined /> {!tabsCollapsed && <T1 pos="company.settings" />}
        </>
      ),
      children: (
        <>
          
          <Settings _={findCompany(id)} />
        </>
      ),
      filter: getCurrentUserDesignation() === designations.super_admin,
    },
  ];

  if (!findCompany(id).id) {
    return "COMPANY NOT FOUND";
  }

  return (
    <div style={{ position: "relative", background: theme.isDarkMode ? "black" : "#f9f9f9" }}>
      <Collapsor/>
      <FormContext.Provider
        value={{
          fixedWidth: isMobile ? "100%" : "500px",
          bgless: true,
          borderless: true,
          minWidthElem: isMobile ? "100%" : "500px",
          maxWidthElem: isMobile ? "100%" : "500px",
          minWidthLabel: "150px",
          disabled: !canEditCompany,
        }}
      >
        <Tabs
          tabPosition={isMobile ? "top" : "left"}
          className={theme.isDarkMode ? "" : "bg-white"}
          defaultActiveKey={getAllParamsFromLocation()?.["tab"] || "profile"}
          onChange={(key) => {
            addOrReplaceQueryParam("tab", key);
          }}
          items={items.filter((item) => item.filter)}
          tabBarStyle={{
            minWidth: tabsCollapsed ? "30px" : "220px",
            padding: "0px",
            margin: 0,
          }}
        />
      </FormContext.Provider>
    </div>
  );
}
