import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./features/appSlice";
import userReducer from "./features/userSlice";
import configReducer from "./features/configSlice";
import superAdminReducer from "./features/superAdminSlice";

export default configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    config: configReducer,
    superAdminData: superAdminReducer,
  },
});
