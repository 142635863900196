import { doc, updateDoc } from "firebase/firestore";
import { getCurrentTime } from "../../helper/time";
import { setConfigData } from "../../redux/features/configSlice";
import store from "../../redux/store";
import { db } from "../setup"; // Assuming you have initialized your Firebase app and exported the Firestore instance as 'db'

export const updateConfig = async (updatedData, type) => {
  const docRef = doc(db, "appconfig", type);
  const updateDocRef = doc(db, "appconfig", "updates");
  await updateDoc(updateDocRef, {
    data: JSON.stringify({
      ...store.getState().config.updates,
      [type]: getCurrentTime(),
    }),
  });
  await updateDoc(docRef, {
    data: JSON.stringify(updatedData),
  });
  store.dispatch(
    setConfigData({ ...store.getState().config, [type]: updatedData })
  );
};
