import { collection, deleteDoc, doc } from "firebase/firestore";
import {
  designations,
  filterResultsBySite,
  findAdminsOfSite,
  getCurrentUserDesignation,
} from "../../helper/attribute";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";

function beforeAll() {
  if (getCurrentUserDesignation() === designations.super_admin) {
  } else
    throw "You don't have the permission to delete this site. Please contact support.";
}

export const deleteSite = async (_, force = false) => {
  if (force === false) {
    beforeAll();
    if (filterResultsBySite(_.id).length)
      throw "You cannot delete the site, becuase it has active result data.";
  }
  await deleteDoc(doc(db, firestoreStatic.sites, _.id));
  //todo: ask to delete site data or not
};
