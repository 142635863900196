import { CopyOutlined, GlobalOutlined, InfoOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Card, Empty, Image, Input, Modal, Popconfirm, Select, Space, Table, Typography } from "antd";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc, writeBatch } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import SimpleSelect from "../../components/select/simpleselect";
import { getMoreInfo, updateMoreInfo } from "../../firebase/MoreInfo/create";
import EllipsisText from "../../components/text/EllipsisText";
import { getAuth } from "firebase/auth";
import { app, db, storage } from "../../firebase/setup";
import { catcher } from "../../firebase/util";
import { copyToClipboard } from "../../helper/copypaste";
import { getCurrentDate } from "../../helper/time";
import { setReportedFeatures } from "../../redux/features/superAdminSlice";
import store from "../../redux/store";
import { dateSorter } from "../../components/table/tableProps.js";
import { useNavigate } from "react-router-dom";
import { fetchFeatures } from "../../firebase/userhelp/requestfeature/get.js";
import { downloadFile } from "../../helper/wodash.js";

const FeatureStatusList = [
  { label: "New", value: "new", color: "#FF5733" },
  { label: "Pending", value: "pending", color: "#FFC300" },
  { label: "In Progress", value: "in_progress", color: "#36A64F" },
  { label: "Resolved", value: "resolved", color: "#4D90FE" },
  { label: "Reopened", value: "reopened", color: "#FF5733" },
  { label: "Closed", value: "closed", color: "#888888" },
];

function FeatureMoreInfo({ data }) {
  const comments = data?.comments ?? [];
  if (!data) return <Empty />;
  return (
    <>
      <Typography.Title level={4}>Comments:</Typography.Title>
      {comments.map(({ comment, by, date }) => (
        <Card key={date}>
          <b>{by}: </b> {comment}
          <small style={{ color: "grey" }}> - {date}</small>
        </Card>
      ))}
    </>
  );
}

function FeatureModal({ feature, visible, setVisible }) {
  let [moredata, setMoreData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const more_token = "feature_" + feature.id;

  const fetchMoreData = async () => {
    setMoreData(undefined);
    const data = await getMoreInfo(more_token);
    setMoreData(data);
  };

  useEffect(() => {
    if (visible) fetchMoreData();
  }, [feature]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleAddComment = async () => {
    if (!moredata) moredata = {};
    if (!moredata.comments) moredata.comments = [];
    catcher(
      async () => {
        const by = getAuth(app).currentUser.email;
        const comments = [...moredata.comments, { comment, by, date: getCurrentDate() }];
        const updatedFeature = { comments };
        await updateMoreInfo(updatedFeature, more_token);
        await updateDoc(doc(db, "features", feature.id), {
          comments: moredata.comments.length + 1,
          info_msg: "Last comment made by " + by + " on " + getCurrentDate(),
        });
        setComment("");
        setMoreData({ ...moredata, comments });
      },
      { setLoading }
    );
  };

  return (
    <Modal destroyOnClose title="Feature Details" open={visible} onCancel={handleCancel} width={1000} footer={null}>
      <Typography.Title level={4}>TEXT: {feature?.text}</Typography.Title>
      <div style={{ display: "flex" }}>
        <Input placeholder="Add a comment" value={comment} onChange={(e) => setComment(e.target.value)} />
        <Button type="primary" onClick={handleAddComment} loading={loading}>
          Add Comment
        </Button>
      </div>
      {moredata === undefined ? <Loading title="Fetching more information" /> : <FeatureMoreInfo data={moredata} />}
    </Modal>
  );
}

function getFileExtension(filename) {
  if (filename?.includes(".")) {
    return filename.split(".").pop();
  }
  return "";
}

export default function Features() {
  const features = useSelector((state) => state.superAdminData.reportedFeatures);
  const [urls, setUrls] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [modals, setModals] = useState({ feature: false });
  const [filter, setFilter] = useState("new");

  const navigate = useNavigate();

  console.log(features, "Features");

  useEffect(() => {
    const fetchUrls = async () => {
      for (const obj of features) {
        if (!obj.file) continue;

        const imageRef = ref(storage, `userhelp/features/${obj.id}_file.${getFileExtension(obj.fileName)}`);
        console.log(`userhelp/features/${obj.id}_file.${getFileExtension(obj.fileName)}`);
        try {
          getDownloadURL(imageRef).then((url) => {
            setUrls((prevUrls) => ({
              ...prevUrls,
              [obj.id]: url,
            }));
          });
        } catch (error) {
          console.error(`Error fetching image for object with ID ${obj.id}:`, error);
        }
      }
    };
    const fetchImageUrls = async () => {
      for (const obj of features) {
        if (!obj.ss) continue;
        const imageRef = ref(storage, `userhelp/features/${obj.id}.png`);

        try {
          getDownloadURL(imageRef).then((url) => {
            setImageUrls((prevUrls) => ({
              ...prevUrls,
              [obj.id]: url,
            }));
          });
        } catch (error) {
          console.error(`Error fetching image for object with ID ${obj.id}:`, error);
        }
      }
    };

    fetchImageUrls();
    fetchUrls();
  }, [features]);
  useEffect(() => {
    fetchFeatures();
  }, []);

  if (!features) return <Loading title="Loading Features list"></Loading>;

  const onDelete = async () => {
    for (const row of selectedRows) {
      const featureRef = doc(collection(db, "features"), row.id);
      const featureMoreRef = doc(collection(db, "MoreInfo"), "feature_" + row.id);

      try {
        await deleteDoc(featureRef);
        if (featureMoreRef) {
          await deleteDoc(featureMoreRef);
        }
        if (row.fileName) {
          const fileRef = ref(storage, `userhelp/features/${row.id}_file.${getFileExtension(row.fileName)}`);
          await deleteObject(fileRef);
        }
        if (row.ss) {
          try {
            const imageRef = ref(storage, `userhelp/features/${row.id}.png`);
            deleteObject(imageRef); // Delete from Storage
          } catch (err) {}
        }
      } catch (err) {
        console.error(err);
      }
    }

    store.dispatch(setReportedFeatures(features.filter((feature) => !selectedRows.map((row) => row.id).includes(feature.id))));
    setSelectedRows([]);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  const handleUpdateFeature = (id, data) => {
    catcher(async () => {
      await updateDoc(doc(db, "features", id), data);
      fetchFeatures();
    }, {});
  };

  const columns = [
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: dateSorter("createdAt"),
    },
    {
      title: "Requested By",
      dataIndex: "requested_by",
      key: "requested_by",
    },
    {
      title: "Text",
      dataIndex: "text",
      render: (text) => <EllipsisText text={text} maxLength={10000} />,
    },
    {
      title: "Supporting file",
      dataIndex: "file",
      key: "file",
      render: (_, record) =>
        record.file ? (
          urls[record.id] ? (
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                downloadFile(urls[record.id], record.fileName);
              }}
            >
              {record.fileName}
            </Button>
          ) : (
            "Loading..."
          )
        ) : (
          "Not Provided"
        ),
    },
    {
      title: "Screenshot",
      dataIndex: "screenshotUrl",
      key: "screenshotUrl",
      render: (_, record) => (record.ss ? imageUrls[record.id] ? <Image src={imageUrls[record.id]} alt="Screenshot" width={80} height={60} /> : "Loading..." : "Not Provided"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 120,
      render: (status, feature) => {
        return (
          <SimpleSelect
            value={status || "new"}
            setValue={(status) => handleUpdateFeature(feature.id, { status })}
            bgless={true}
            borderless={true}
            maxWidthElem={0}
            minWidthElem={100}
            minWidthLabel={0}
            maxWidthLabel={0}
            options={FeatureStatusList}
            optionbg={FeatureStatusList.find((feature) => feature.value === status)?.color}
          />
        );
      },
    },
    {
      title: "Comments",
      render: (_, __, i) => {
        return (
          <>
            <Button
              type={_.comments ? "primary" : "ghost"}
              style={{ cursor: "pointer", display: "flex" }}
              onClick={() => {
                setModals({ ...modals, feature: _ });
              }}
            >
              {_.comments || "No"} Comments
            </Button>
            <div style={{ fontSize: "10px", lineHeight: "-10px" }}>{_.info_msg}</div>
          </>
        );
      },
    },
    {
      title: <MenuOutlined />,
      render: (feature) => (
        <Space style={{ color: "grey" }}>
          <CopyOutlined onClick={() => copyToClipboard(feature.id, "Id copied to clipboard.")} />
          {feature.url && <GlobalOutlined onClick={() => handleGoToUrl(feature)} />}
        </Space>
      ),
    },
  ];

  const handleGoToUrl = (feature) => {
    const url = new URL(feature.url);
    const path = url.pathname + url.search;
    navigate(path);
  };

  const featuresToShow = features
    .filter((feature) => {
      if (!filter) return feature;
      if (!feature.status && filter === "new") return feature;
      if (feature.status === filter) return feature;
      return null;
    })
    .map((feature) => ({ ...feature, key: feature.id }));

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Popconfirm title="Are you sure you want to delete the selected rows?" onConfirm={onDelete} okText="Yes" cancelText="No" disabled={selectedRows.length === 0}>
          <a>Delete Selected</a>
        </Popconfirm>
        <div style={{ padding: "10px" }}>
          <Select allowClear onClear={setFilter} style={{ width: "100px" }} options={FeatureStatusList} value={filter} onSelect={setFilter} />
        </div>
      </div>
      <Typography.Title level={3}>{`Viewing ${filter} features (${featuresToShow.length})`}</Typography.Title>
      <Table
        pagination={false}
        dataSource={featuresToShow}
        columns={columns}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
      <FeatureModal
        visible={modals.feature}
        feature={modals.feature}
        setVisible={(feature) => {
          setModals({ ...modals, feature: false });
        }}
      />
    </>
  );
}
