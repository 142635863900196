import { message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { T1 } from "../../appconfig/texts";
import { routePath } from "../../static/routes.static";
import SelectDataForms from "./dataforms";

export default function SelectForm({
  data,
  setData,
  type = "form",
  title = <T1 pos="form.q" />,
  editId,
}) {
  //editId: if enabled it is not going to change the data
  //todo: check if form is found
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <SelectDataForms
        title={title}
        value={data.name}
        setValue={(name) => {
          if (editId)
            return message.info("Cannot change form type in edit mode.");
          navigate(
            type === "form" ? routePath.Form(name) : routePath.BulkUpload(name)
          );
        }}
      />
    </div>
  );
}
