import { getStorage, ref, listAll, getDownloadURL, uploadBytesResumable } from "firebase/storage";

/**
 * Copies a folder and its contents from source path to destination path in Firebase Storage.
 * @param {string} sourcePath - The path of the source folder in Firebase Storage.
 * @param {string} destinationPath - The path of the destination folder in Firebase Storage.
 */
export async function copyFolder(sourcePath, destinationPath) {
    const storage = getStorage();
    const sourceRef = ref(storage, sourcePath);

    // List all files and subfolders in the source folder
    const listAllFiles = async (folderRef) => {
        const fileList = [];
        const folderContents = await listAll(folderRef);

        for (const itemRef of folderContents.items) {
            fileList.push(itemRef);
        }

        for (const prefixRef of folderContents.prefixes) {
            fileList.push(...await listAllFiles(prefixRef));
        }

        return fileList;
    };

    // Copy a single file
    const copyFile = async (fileRef, destinationFolder) => {
        const fileURL = await getDownloadURL(fileRef);
        const response = await fetch(fileURL);
        const blob = await response.blob();

        const fileName = fileRef.name;
        const destinationFileRef = ref(storage, `${destinationFolder}/${fileName}`);
        await uploadBytesResumable(destinationFileRef, blob);
    };

    const filesToCopy = await listAllFiles(sourceRef);

    for (const fileRef of filesToCopy) {
        const relativePath = fileRef.fullPath.replace(sourcePath, '');
        const destinationFolder = destinationPath + relativePath.substring(0, relativePath.lastIndexOf('/'));
        await copyFile(fileRef, destinationFolder);
    }

    console.log(`Folder copied from ${sourcePath} to ${destinationPath}`);
}
