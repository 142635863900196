import { Avatar } from "antd";
import React from "react";

export default function UserAvatar({ _ }) {
  return (
    <Avatar size={"large"}>
      <span translate="no">{_?.firstName[0]}</span>
    </Avatar>
  );
}
