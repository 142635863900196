import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../setup"; // Import Firestore setup

// Save options to Firestore under "Cache" collection with unique name
export async function updateCacheOptions(dataField, options, appendRight="_commenttable") {
  const cacheDocRef = doc(db, "Cache", `${dataField}${appendRight}`);
  await setDoc(cacheDocRef, options, { merge: true });
}

// Get options from Firestore for a specific data field with unique name
export async function getCacheOptions(dataField, appendRight="_commenttable") {
  const cacheDocRef = doc(db, "Cache", `${dataField}${appendRight}`);
  try {
    const docSnapshot = await getDoc(cacheDocRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data(); // Return the stored options
    } else {
      return null; // No options found for this data field
    }
  } catch (error) {
    console.error("Error getting document:", error);
    return null;
  }
}
