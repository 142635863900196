import { LinkOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Divider, Typography } from "antd";
import isMobile from "is-mobile";
import moment from "moment";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Select1 from "../../../components/select/select1";
import SimpleSelect from "../../../components/select/simpleselect";
import formatEmissions from "../../../components/text/resultEmissions";
import { removePropertiesByValues } from "../../../helper/jyes";
import { getCurrentPlan, isSustraxLite, planValue } from "../../../helper/plans";
import useSize from "../../../hooks/useSize";
import { dataForms } from "../../../static/formRoutes";
import { getGHGTitle } from "../../../static/ghg.static";
import co2Neutral from "../../../static/logos/cfpneutral.png";
import co2assessed from "../../../static/logos/co2assessed.png";
import windImage from "../../../static/logos/wind.jpg";
import { routePath } from "../../../static/routes.static";
import { tco2e } from "../../../static/texts.static";
import "../../../styles/responsive.css";
import CbfReportDownload from "./CbfReportDownload";
import Boxed from "./comp/boxed";
import Graph from "./comp/graph";
import commaNumber from "comma-number";
import store from "../../../redux/store";
import { getNextTwelveMonths, timeFormat } from "../../../helper/time";
import { extractYear } from "../../form/helper/getter";
import BannerCarbonDi from "./other/banner-carbondi";
import { fixDecimals } from "../../../helper/wodash";
import { updateUserClicks } from "../../../firebase/user/update";
const isMobileDevice = isMobile();
export function ghgData2Rqrd(data) {
  const result = [];

  const scope1Keys = ["1", "2", "3", "4"];
  const scope2Keys = ["5"];
  const scope3Keys = Object.keys(data).filter((key) => !scope1Keys.includes(key) && !scope2Keys.includes(key));

  // Scope 1
  const scope1 = {
    name: "Scope 1",
    children: scope1Keys?.map((key) => ({
      name: key,
      scope: 1,
      ...data[key],
    })),
  };
  result.push(scope1);

  // Scope 2
  const scope2 = {
    name: "Scope 2",
    children: scope2Keys?.map((key) => ({
      name: key,
      scope: 2,
      ...data[key],
    })),
  };
  result.push(scope2);

  // Scope 3
  const scope3 = {
    name: "Scope 3",
    children: scope3Keys?.map((key) => ({
      name: key,
      scope: 3,
      ...data[key],
    })),
  };
  result.push(scope3);

  // Total (if needed)
  // You can calculate the total here if necessary
  result.push({ name: "Total" });
  return result;
}

export const analysisnames = {
  "waste water": "Waste Water",
  "supply water": "Supply Water",
  "wttcompany cars": "Company Cars",
  "company cars": "Company Cars",
  "employee cars": "Employee Cars",
  "wttemployee cars": "Employee Cars",
  "hire cars": "Hire Cars",
  "wtthire cars": "Hire Cars",
  "tnd electricity": "Electricity T&D",
  "wtt_tnd electricity": "Electricity T&D",
  "tnd heatandsteam": "Heat and Steam T&D",
  "wtt_tnd heatandsteam": "Heat and Steam T&D",
  "em_emb product": "Embodied Emissions (Product)",
  "em_dist product": "Transport Emissions (Product)",
  "em_manf product": "Manufacturing Emissions (Product)",
  "em_dist_cust product": "'Distribution Emissions (Product)",
  "em_use product": "Usage Emissions (Product)",
  "em_waste product": "Disposal Emissions (Product)",
};

export const cellStyles = (name) => {
  return {
    fontWeight: name.includes("Scope") || name.includes("Total") ? 600 : "normal",
    // width: "100px",
  };
};

export const configDataSource = (dataSource, cols, options) => {
  if (!dataSource) return undefined;

  dataSource = JSON.parse(JSON.stringify(dataSource));

  let source = dataSource.map((row) => {
    if (row?.name.includes("Scope")) {
      for (let col of cols) {
        try {
          col = String(col);
        } catch (err) {
          // Consider handling the error or removing this catch if not needed
          console.error(err);
        }

        row[col] = 0;

        if (row.children) {
          for (let child of row.children) {
            row[col] += child[col] || 0;

            let totalRow = dataSource.find((row) => row?.name === "Total");
            if (totalRow) {
              // Ensure totalRow exists before attempting to update it
              totalRow[col] = (totalRow[col] || 0) + (child[col] || 0);
            }
          }
        }
      }
    }

    // Assuming removePropertiesByValues is a function you've defined elsewhere
    let cleanedRow = removePropertiesByValues(row, [0]);
    return cleanedRow;
  });

  return source;
};

function isObjectValuesExcluded(obj, keysToExclude = ["name", "scope", "key"], valuesToCheck = [null, undefined, 0]) {
  return Object.keys(obj).every((key) => {
    // Skip the excluded keys
    if (keysToExclude.includes(key)) {
      return true;
    }

    // Check if the value is in the specified array of values to check
    const value = obj[key];
    return valuesToCheck.includes(value);
  });
}

export const tableProps = (dataSource, results, columns, cols, options = {}) => {
  console.log(dataSource, "dataSource-1");
  dataSource = configDataSource(dataSource, cols, options);
  console.log(dataSource, "dataSource-0");
  return {
    bordered: true,
    pagination: false,
    loading: !dataSource || !results,
    dataSource: dataSource?.map((row, ri) => ({
      ...row,
      key: ri + "cc",
      children: row.children
        ?.filter((child) => !isObjectValuesExcluded(child))
        ?.map((childrow, ci) => ({
          ...childrow,
          key: ri + "-" + ci,
        })),
    })),
    key: "id",
    columns: columns.map((c, i) => {
      return { ...c, align: i !== 0 ? "right" : "left" };
    }),
  };
};

export const createGraphComponent = (scopeChart, graphFilters, setGraphFilters, cols, typeOfChart, chartOptions) => {
  function completeAndSortMonths(data) {
    // this is used to display all months
    const rysm = chartOptions?.reporting_year_starting_month,
      year = chartOptions?.year;
    console.log(rysm, year);
    if (data.length === 0) return [];

    // GET NEXT 12 MONTHS

    const fullData = getNextTwelveMonths(rysm, year).map(({ month, year }) => {
      const monthYear = `${month} ${year}`;
      return data.find((entry) => entry.col === monthYear) || { col: monthYear };
    });
    return fullData.sort((a, b) => moment(a.col, "MMM YY") - moment(b.col, "MMM YY"));
  }
  if (chartOptions.showAllMonths) scopeChart = completeAndSortMonths(scopeChart);
  const graphComponent = (
    <Graph
      dataSource={scopeChart}
      hidden={["bar"]}
      showDownloadAsPNG
      typeOfChart={typeOfChart}
      scopePieChartFilters={graphFilters}
      scopePieChartElem={getCurrentPlan() !== "free" && <Select1 title="Range" options={cols} value={graphFilters.col} setValue={(col) => setGraphFilters({ ...graphFilters, col })} />}
      chartOptions={chartOptions}
    />
  );
  return graphComponent;
};

export const getFormName = (name, reportType) => {
  if (reportType === "GHG") {
    console.log("getting ghg name", name);
    return getGHGTitle(name);
  }
  name = String(name);
  const form = dataForms.find((form) => name.includes(form?.name));
  return `${name.includes("wtt") ? "WTT" : ""} ${analysisnames[name] || form?.title}`;
};

export const createcols = (cols, dataSource, options = {}) => {
  const columns = [
    {
      title: "Name",
      // dataIndex: "name",
      key: "name",
      width: 1200,
      render: (data) => {
        const name = String(data?.name);
        if (name.includes("Total") || name.includes("Scope") || name.includes("kWh")) return <div style={cellStyles(name)}>{name}</div>;
        const form = dataForms.find((form) => name.includes(form?.name));
        if (options.reportType === "GHG") {
          console.log("getting ghg name", data, getGHGTitle(name));
          return getGHGTitle(name);
        } else return isSustraxLite() ? getFormName(name) : <Link to={routePath.Result(form?.name)}>{getFormName(name)}</Link>;
      },
    },
    ...cols?.map((col) => ({
      title: (
        <div>
          {col} ({tco2e})
        </div>
      ),
      key: col,
      width: 300,

      render: (row) => {
        return commaNumber(formatEmissions(row[col], 2));
      },
    })),
  ];

  return columns;
};
export const GetScopeOffsetBox = (scopes, navigate, offsetPage, years = [2019, 2020, 2021, 2022, 2023, 2024], scopeYear, setScopeYear) => {
  const isMediumSmall = useMediaQuery({
    query: "(min-width:375px) and (max-width : 599px)",
  });
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  if (!planValue("canViewAnalysisOffset")) return;
  if (!scopes) return null;
  const totalToOffset = Math.ceil(scopes.find((scope) => scope.name === "Total")?.result);
  const totalKey = "Total to offset";
  scopes.splice(-1, 0, {
    name: "Total",
    result: scopes.find((scope) => scope.name === "Total")?.result,
  });
  if (isExtraSmall) {
    scopes?.pop();
  } else {
    scopes[scopes.length - 1].name = totalKey;
  }

  const content = (
    <div className="boxs" style={{ display: "flex", flexDirection: "column" }}>
      <Typography.Title className="yearSelector-title" style={{ padding: "10px 10px 0px 10px" }}>
        Year{" "}
        <span style={{ color: "green" }}>
          <span style={{ fontSize: "0px" }} className="selector1">
            <SimpleSelect
              elemOnly
              value={scopeYear}
              setValue={setScopeYear}
              options={years.map((value) => ({
                label: value,
                value,
              }))}
            />
          </span>
        </span>
      </Typography.Title>
      <div
        className="yearlyAnalysis-offsetting-wrapper"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isExtraSmall ? " column " : isSmall ? "row" : "row",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            padding: "10px",
            width: "100%", // This ensures the container takes full width
          }}
        >
          <Typography.Title style={{ fontSize: isExtraSmall ? "20px" : "24px" }}></Typography.Title>
          <div
            style={{
              borderLeft: "4px solid green",
              paddingLeft: "10px",
            }}
          >
            {!totalToOffset && (
              <div>
                Nothing here yet. Please add data in the input{" "}
                <Link to={routePath.Form("")}>
                  <LinkOutlined />
                </Link>{" "}
                tabs to see offsetting.
              </div>
            )}
            {scopes.map(
              (scope, index) =>
                scope.result && (
                  <div
                    key={index} // Always use keys when rendering arrays for better performance
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start", // Aligns text to the left within the centered div
                      width: "fit-content", // This ensures each child div takes full width
                      padding: "5px 10px", // A little padding, adjust as needed
                      border: scope.name === totalKey ? "2px solid green" : "",
                      borderRight: scope.name === totalKey ? "2px solid green" : "",
                      color: scope.name === totalKey ? "green !important" : "black",
                      boxSizing: "border-box", // Ensures padding doesn't affect the overall width
                    }}
                  >
                    <Typography.Title
                      style={{
                        margin: 0,
                        fontSize: isExtraSmall ? "16px" : "20px",
                      }} // Override margins here if needed
                    >
                      {scope.name} =
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: "18px" }}>
                      {scope.name === totalKey ? (
                        <span>
                          {commaNumber(totalToOffset)} {tco2e}{" "}
                        </span>
                      ) : (
                        <>
                          {fixDecimals(scope.result, 2, true)} {tco2e}
                        </>
                      )}{" "}
                      {scope.name === totalKey && (
                        <Button
                          type="primary"
                          style={{
                            borderRadius: "0px",
                            color: "white",
                            fontSize: isExtraSmall ? "13px" : "14px",
                          }}
                          onClick={() => {
                            updateUserClicks("offset_btn");
                            window.open(`https://www.carbonfootprint.com/offset.aspx?o=${totalToOffset}&r=SustraxMX`, "_blank");
                          }}
                        >
                          Offset Now
                        </Button>
                      )}
                    </Typography.Text>
                  </div>
                )
            )}
          </div>
          <div style={{ margin: "10px 0px" }}>
            <CbfReportDownload
              key={"report" + scopeYear}
              year={scopeYear}
              results={store.getState().app.results.filter((result) => {
                return true; //todo: change if the data needs to be seperated by years, unlikely!
                return extractYear(result, true) - 1 != scopeYear;
              })}
            />
          </div>
          <BannerCarbonDi />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: isExtraSmall ? "center" : "none", // Center the content vertically
            justifyContent: "center", // Center the content horizontally
            // padding: isExtraSmall ? "0 0 20px 0" : "10px 20px 0 0",
            height: "100%",
            objectFit: "contain",
            marginTop: isMobile ? "50px" : "25px",
            // marginBottom: isExtraSmall ? "20px" : 0,
          }}
        >
          <img
            src={windImage}
            alt="Turbine pic"
            style={{
              display: isMobile ? "none" : "",
              width: "95%", // Make the image responsive by setting its width to 100%
              height: "100%", // Set the height to 100% of the container's height
              objectFit: "cover", // Maintain aspect ratio and cover the container
            }}
          />{" "}
        </div>
      </div>

      <Divider></Divider>

      <Typography.Text
        style={{
          fontSize: isExtraSmall ? "14px" : "16px",
          padding: "20px",
          margin: "10px 0",
          borderRadius: "4px",
          display: !totalToOffset && "none",
        }}
      >
        <i>
          <span style={{ fontSize: 20 }}>Y</span>ou can now get your carbon calculations verified to achieve the Carbon Footprint Standard.{" "}
          <a
            onClick={() => {
              updateUserClicks("contact_us_analysis_page");
            }}
            href="mailto:info@carbonfootprint.com?subject=Carbon%20Footprint%20Sustrax%20Lite%20Verification"
          >
            Contact us to find out more. <PhoneOutlined />
          </a>{" "}
          <br />
          When you achieve the Carbon Footprint Standard you will receive the branding to prove your credentials to your stakeholders and customers. This will extend to carbon neutrality (if you have
          also offset all emissions with certified projects).
        </i>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "20px",
            flexDirection: isExtraSmall ? "column" : "row",
          }}
        >
          <img
            style={{
              width: "100%",
              maxWidth: isExtraSmall ? "300px" : isSmall ? "320px" : "400px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "10px 0",
            }}
            src={co2assessed}
            alt="Carbon Footprint"
          />
          <img
            style={{
              width: "100%",
              maxWidth: isExtraSmall ? "300px" : isSmall ? "320px" : "400px",
              display: "block",
              marginLeft: isExtraSmall ? "auto" : "50px",
              marginRight: "auto",
              padding: "10px 0",
            }}
            src={co2Neutral}
            alt="Carbon Footprint"
          />
        </div>
        <br />
        {/* If you would like to achieve the Carbon Footprint Standard then
    please{" "}
    <a
      href="mailto:info@carbonfootprint.com"
      style={{
        color: "#007bff",
        textDecoration: "underline",
      }}
    >
      contact our team
    </a>
    . <br /> This will enable you to use the Carbon Footprint Standard
    branding to demonstrate to your clients and stakeholders your robust
    commitment to environmental management. */}
      </Typography.Text>
    </div>
  );
  if (offsetPage) return content;
  return (
    <div id="offset-box">
      <Boxed title={<div>Emissions Summary</div>}>{content}</Boxed>
    </div>
  );
};
