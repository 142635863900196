import { collection, getDocs, query, where } from "firebase/firestore";
import { findCompany, findSite, openSites } from "../../helper/attribute";
import { setResults } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsWithIds } from "../util";
import { scope3overwrite } from "../../helper/results";
import { fetchResultsOffline } from "./offline/get_offline";

export async function fetchResults(
  formName = undefined,
  batchSize = 30,
  plan = undefined
) {
  if (store.getState().app.state === "trial")
    return await fetchResultsOffline(formName, batchSize, plan);
  console.log(
    "fetching data for formname, batchsize, plan",
    formName,
    batchSize,
    plan
  );
  let sites = openSites() || [];
  if (sites.length === 0) {
    store.dispatch(setResults([]));
    return;
  }

  if (plan) {
    //setting plan key
    sites = sites.filter((site) => {
      return findCompany(site.companyId)?.plan === plan;
    });
  }

  const collectionRef = collection(db, firestoreStatic.results);
  let results = [];

  // Iterate through the sites in batches
  for (let i = 0; i < sites.length; i += batchSize) {
    const batchSites = sites.slice(i, i + batchSize);

    let q = query(
      collectionRef,
      where(
        "siteId",
        "in",
        batchSites.map((c) => c.id)
      )
      // limit(batchSize) // Limit the number of results per batch
    );
    if (formName) {
      q = query(
        collectionRef,
        where(
          "siteId",
          "in",
          batchSites.map((c) => c.id)
        ),
        where("name", "==", formName)
        // limit(batchSize) // Limit the number of results per batch
      );
    }

    const docs = await getDocs(q);
    const batchResults = docsWithIds(docs);
    results.push(...batchResults);
  }
  // await bulkDeleteResults(results.map((result) => result.id));
  // const oldResults= store.getState().app.results;// gotta merge with this before
  // store.dispatch(setResults(scope3overwrite(results)));

  // merging based on id
  const oldResults = store.getState().app.results ?? []; // Existing results array
  const newResults = scope3overwrite(results); // New results array

  // Merge based on 'id'
  const mergedResults = [
    ...oldResults.filter(
      (oldItem) => !newResults.some((newItem) => newItem.id === oldItem.id)
    ),
    ...newResults,
  ];

  // Dispatch the merged results
  store.dispatch(setResults(mergedResults));
}
