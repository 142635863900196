import { CopyOutlined, GlobalOutlined, InfoOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Empty, Image, Input, Modal, Popconfirm, Select, Space, Table, Typography } from "antd";
import { BeautifyData } from "../../components/text/BeautifyData.js";
import { getAuth } from "firebase/auth";
import { collection, doc, writeBatch } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import SimpleSelect from "../../components/select/simpleselect";
import EllipsisText from "../../components/text/EllipsisText";
import { getMoreInfo, updateMoreInfo } from "../../firebase/MoreInfo/create";
import { app, db, storage } from "../../firebase/setup";
import { updateBug } from "../../firebase/userhelp/reportbugs/update";
import { catcher } from "../../firebase/util";
import { copyToClipboard } from "../../helper/copypaste";
import { getCurrentDate } from "../../helper/time";
import { setReportedBugs } from "../../redux/features/superAdminSlice";
import store from "../../redux/store";
import { dateSorter } from "../../components/table/tableProps.js";
import { Link, useNavigate } from "react-router-dom";
import { findUserByEmail, findUserById } from "../../helper/attribute.js";

const BugStatusList = [
  { label: "New", value: "new", color: "#FF5733" }, // A newly reported bug
  { label: "Pending", value: "pending", color: "#FFC300" }, // Bug is awaiting review or further action
  { label: "In Progress", value: "in_progress", color: "#36A64F" }, // Bug is being actively worked on
  { label: "Resolved", value: "resolved", color: "#4D90FE" }, // Bug has been fixed and verified
  { label: "Reopened", value: "reopened", color: "#FF5733" }, // Bug was thought to be resolved but reappeared
  { label: "Closed", value: "closed", color: "#888888" }, // Bug is considered closed, no further action needed
];

function BugMoreInfo({ data }) {
  const comments = data?.comments ?? [];
  if (!data) return <Empty description="No comments yet" />;
  return (
    <div style={{ marginBottom: "16px" }}>
      {comments.map(({ comment, by, date }, index) => (
        <Card key={index} style={{ marginBottom: "8px", borderRadius: "8px", border: "none", backgroundColor: "#f0f2f5", padding:0 }}>
          <Typography.Text strong style={{fontSize:"smaller", color:"darkgrey"}}>{by}</Typography.Text>
          <Typography.Paragraph strong style={{ margin: "4px 0", fontSize:"larger" }}>{comment}</Typography.Paragraph>
          <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
            {date}
          </Typography.Text>
        </Card>
      ))}
    </div>
  );
}

function BugModal({ bug, visible, setVisible }) {
  const [moredata, setMoreData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const more_token = "bug_" + bug.id;

  const fetchMoreData = async () => {
    setMoreData(undefined);
    const data = await getMoreInfo(more_token);
    setMoreData(data);
  };

  useEffect(() => {
    if (visible) fetchMoreData();
  }, [bug]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleAddComment = async () => {
    if (!moredata) moredata = {};
    if (!moredata.comments) moredata.comments = [];
    catcher(
      async () => {
        const by = getAuth(app).currentUser.email;
        const date = getCurrentDate();
        const comments = [...moredata.comments, { comment, by, date }];
        const updatedBug = { comments };
        await updateMoreInfo(updatedBug, more_token);
        await updateBug(bug.id, {
          comments: moredata.comments.length + 1,
          info_msg: `Last comment made by ${by} on ${date}`,
        });
        setComment("");
        setMoreData({ ...moredata, comments });
      },
      { setLoading }
    );
  };

  return (
    <Modal destroyOnClose title="Bug Details" open={visible} onCancel={handleCancel} width={600} footer={null}>
      <Typography.Title level={5}>Details:</Typography.Title>
      <Typography.Paragraph>{bug?.text}</Typography.Paragraph>

      {moredata === undefined ? <div style={{ textAlign: "center", padding: "20px" }}>Loading comments...</div> : <BugMoreInfo data={moredata} />}

      <div style={{ display: "flex", marginTop: "16px" }}>
        <Input.TextArea placeholder="Write a comment..." value={comment} onChange={(e) => setComment(e.target.value)} rows={2} style={{ flex: 1, borderRadius: "8px", marginRight: "8px" }} />
        <Button type="primary" onClick={handleAddComment} loading={loading}>
          Comment
        </Button>
      </div>
    </Modal>
  );
}
export default function Bugs() {
  const bugs = useSelector((state) => state.superAdminData.reportedBugs);
  const [imageUrls, setImageUrls] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [modals, setModals] = useState({ bug: false });
  const [filter, setFilter] = useState("new");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImageUrls = async () => {
      for (const obj of bugs) {
        if (!obj.ss) continue;
        const imageRef = ref(storage, `userhelp/bugs/${obj.id}.png`);

        try {
          getDownloadURL(imageRef).then((url) => {
            setImageUrls((prevUrls) => ({
              ...prevUrls,
              [obj.id]: url,
            }));
          });
        } catch (error) {
          console.error(`Error fetching image for object with ID ${obj.id}:`, error);
        }
      }
    };

    fetchImageUrls();
  }, [bugs]);

  if (!bugs) return <Loading title="Loading Bugs list"></Loading>;

  const onDelete = async () => {
    const batch = writeBatch(db);
    for (const row of selectedRows) {
      const bugRef = doc(collection(db, "bugs"), row.id);
      batch.delete(bugRef); // Delete from Firestore
      const bugMoreRef = doc(collection(db, "MoreInfo"), "bug_" + row.id);
      batch.delete(bugMoreRef);
      if (row.ss) {
        try {
          const imageRef = ref(storage, `userhelp/bugs/${row.id}.png`);
          deleteObject(imageRef); // Delete from Storage
        } catch (err) {}
      }
    }

    await batch.commit();

    store.dispatch(setReportedBugs(bugs.filter((bug) => !selectedRows.map((row) => row.id).includes(bug.id))));
    setSelectedRows([]);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };
  const handleUpdateBug = (id, data) => {
    catcher(async () => {
      await updateBug(id, data);
    }, {});
  };

  const columns = [
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: dateSorter("createdAt"),
    },
    {
      title: "Reported By",
      dataIndex: "reported_by",
      key: "reported_by",
    },
    {
      title: "Screenshot",
      dataIndex: "screenshotUrl",
      key: "screenshotUrl",
      render: (_, record) => (record.ss ? imageUrls[record.id] ? <Image src={imageUrls[record.id]} alt="Screenshot" width={80} height={60} /> : "Loading..." : "Not Provided"),
    },
    {
      title: "Text",
      dataIndex: "text",
      render: (text) => (
        <>
          <EllipsisText text={text} maxLength={10000} />
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status, bug) => {
        return (
          <SimpleSelect
            value={status || "new"}
            setValue={(status) => handleUpdateBug(bug.id, { status })}
            bgless={true}
            borderless={true}
            maxWidthElem={0}
            minWidthElem={100}
            minWidthLabel={0}
            maxWidthLabel={0}
            options={BugStatusList}
            optionbg={BugStatusList.find((bug) => bug.value === status)?.color}
          />
        );
      },
    },
    {
      title: "Comments",
      render: (_, __, i) => {
        return (
          <>
            <Button
              type={_.comments ? "primary" : "ghost"}
              style={{ cursor: "pointer", display: "flex" }}
              onClick={() => {
                setModals({ ...modals, bug: _ });
              }}
            >
              {_.comments || "No"} Comments
            </Button>
            <div style={{ fontSize: "10px", lineHeight: "-10px" }}>{_.info_msg}</div>
          </>
        );
      },
    },
    {
      title: <MenuOutlined />,
      render: (bug) => (
        <Space style={{ color: "grey" }}>
          <CopyOutlined onClick={() => copyToClipboard(bug.id, "Id copied to clipboard.")} />
          {bug.url && <GlobalOutlined onClick={() => handleGoToUrl(bug)}></GlobalOutlined>}
        </Space>
      ),
    },
  ];
  const handleDisplayComments = async (i) => {
    Modal.info({
      title: bugs[i].text,
      width: 1100,
      content: (
        <div>
          <Input placeholder="Add Comment"></Input>
        </div>
      ),
    });
  };
  const handleGoToUrl = (bug) => {
    const url = new URL(bug.url);
    const path = url.pathname + url.search; // Get the path and the query string
    console.log(path);
    navigate(path); // Navigate to the path on the current host
  };
  const bugsToShow = bugs
    .filter((bug) => {
      if (!filter) return bug;
      if (!bug.status && filter === "new") return bug;
      if (bug.status === filter) return bug;
    })
    .map((bug) => ({ ...bug, key: bug.id }));
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Popconfirm title="Are you sure you want to delete the selected rows?" onConfirm={onDelete} okText="Yes" cancelText="No" disabled={selectedRows.length === 0}>
          <a>Delete Selected</a>
        </Popconfirm>
        <div style={{ padding: "10px" }}>
          <Select allowClear onClear={setFilter} style={{ width: "100px" }} options={BugStatusList} value={filter} onSelect={setFilter}></Select>
        </div>
      </div>
      <Typography.Title level={3}>{`Viewing ${filter} bugs (${bugsToShow.length})`}</Typography.Title>
      <Table
        pagination={false}
        dataSource={bugsToShow}
        columns={columns}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
      <BugModal
        visible={modals.bug}
        bug={modals.bug}
        setVisible={(bug) => {
          setModals({ ...modals, bug: false });
        }}
      ></BugModal>
    </>
  );
}
