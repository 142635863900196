import { doc, updateDoc, writeBatch } from "firebase/firestore";
import { getCurrentDate } from "../../helper/time";
import { db } from "../setup";
import { firestoreStatic } from "../../static/firestore.static";

const beforeuserUpdate = (data) => {
  data.updatedAt = getCurrentDate();
  return data;
};
export const updateAffiliate = async (id, data) => {
  // Process data before update
  data = beforeuserUpdate(data);

  // Update the existing affiliate document
  const affiliateRef = doc(db, firestoreStatic.affiliates, id); // Adjust "affiliates" if needed
  await updateDoc(affiliateRef, data);

  // Start a batch for updating user documents
  const batch = writeBatch(db);

  // Update user documents if admins are provided
  if (data?.admins) {
    data.admins.forEach((adminId) => {
      const userRef = doc(db, firestoreStatic.users, adminId); // Adjust "users" if needed
      batch.update(userRef, { af_id: id }); // Use the passed-in affiliate ID
    });
  }

  // Commit the batch
  await batch.commit();
  return id; // Return the updated affiliate's ID
};
