import React, { useState } from "react";
import { Modal, InputNumber, Typography, Checkbox, Table, Button, Space } from "antd";
import { fixDecimals, generateRandomString } from "../../helper/wodash";
import RadioSelector from "../../components/select/radioselector";
import { findFormText } from "../../helper/attribute";
import { findForm } from "../../static/formRoutes";
import { handleSpreadResult } from "./More";

const BulkSpread = ({ dataRows = [], open, setOpen }) => {
  const [spreadMonths, setSpreadMonths] = useState(2); // Number of months to spread data
  const [spreadPos, setSpreadPos] = useState("previous"); // previous or later months
  const [spreadMetrics, setSpreadMetrics] = useState(true);
  const [statuses, setStatuses] = useState({}); // To track the status of each result

  const results = dataRows.filter((result) => !result.spreadtag);

  const handleUpdate = async () => {
    Modal.confirm({
      title: "Confirm Bulk Spread?",
      content: "The results will be spread into multiple months. Spread results will be treated as individual results.",
      onOk: async () => {
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          setStatuses((prev) => ({ ...prev, [result.id]: "In Progress" }));

          await handleSpreadResult(
            {
              ...result,
              spreadMonths,
              spreadPos,
              spread_metrics: spreadMetrics,
              spreadtag: spreadPos + generateRandomString(9),
            },
            result
          );

          setStatuses((prev) => ({ ...prev, [result.id]: "Done✅" }));
        }
        //finally
        setOpen(false)
      },
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => findForm(name)?.title,
    },
    {
      title: "Spread Over (Months)",
      dataIndex: "spreadMonths",
      key: "spreadMonths",
      render: () => spreadMonths,
    },
    {
      title: "Spread Position",
      dataIndex: "spreadPos",
      key: "spreadPos",
      render: () => spreadPos,
    },
    {
      title: "Data Spread",
      key: "data",
      render: (text, record) => (
        <ul>
          {findForm(record.name)
            ?.dataFields(record, true)
            .map((field, index) => {
              const value = fixDecimals(record[field] / spreadMonths);
              return (
                <li key={index}>
                  {findFormText(record.name, field)}: {isNaN(value) ? "Not present" : fixDecimals(record[field]) + " => " + value}
                </li>
              );
            })}
        </ul>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => statuses[record.id] || "Pending",
    },
  ];

  return (
    <div>
      <Modal
        width="50%"
        title="Spread Data Over Multiple Results"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleUpdate}
        okText="Spread All Results"
      >
        <Typography.Text strong>(Already spread results are ignored.)</Typography.Text>

        <div style={{ margin: "1rem 0rem" }}>
          <Typography.Text>Would you like to spread data across previous or upcoming months?</Typography.Text>
          <RadioSelector options={["previous", "later"]} value={spreadPos} setValue={setSpreadPos} />
        </div>

        <div style={{ margin: "1rem 0rem" }}>
          <Typography.Text>How many months would you like to spread the data over?</Typography.Text>
          <br />
          <Space>
            <InputNumber style={{ width: "100px" }} min={2} max={12} defaultValue={spreadMonths} onChange={(value) => setSpreadMonths(value)} />
          </Space>
        </div>

        <div style={{ margin: "1rem 0rem" }}>
          <Checkbox checked={spreadMetrics} onChange={(val) => setSpreadMetrics(val.target.checked)}>
            Spread Metrics
          </Checkbox>
        </div>

        {/* Display the spread results for all records in a minimalistic table */}
        <Table
          dataSource={results}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={false}
          scroll={{ y: 300 }} // Add scroll for better handling of large data
        />
      </Modal>
    </div>
  );
};

export default BulkSpread;
