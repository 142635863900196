import { doc, updateDoc, setDoc, getDoc } from "firebase/firestore";
import { getCurrentDate, getCurrentTime } from "../../helper/time";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { message } from "antd";

export async function updateHistory(id, updatedObject, type = "result_update") {
  if (!["result_update", "result_create", "result_spread"].includes(type)) {
    return message.error("could not update history.");
  }

  const currentTime = getCurrentTime();
  const historyData = {
    [currentTime]: {
      type,
      value: JSON.stringify(updatedObject),
    },
  };

  const docRef = doc(db, firestoreStatic.resultshistory, id);

  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // If the document exists, update it
      await updateDoc(docRef, {
        ...historyData,
      });
    } else {
      // If the document does not exist, create it with the initial data
      await setDoc(docRef, {
        ...historyData,
      });
    }

    console.log("History updated successfully");
  } catch (error) {
    console.error("Error updating history:", error);
  }
}


export async function getHistory(id) {
    const docRef = doc(db, firestoreStatic.resultshistory, id);
  
    try {
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        // Document exists, return the data
        return docSnap.data();
      } else {
        // Document does not exist
        return null;
      }
    } catch (error) {
      console.error("Error retrieving history:", error);
      throw new Error("Failed to retrieve history.");
    }
  }