import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getCurrentUserDesignation } from "../../helper/attribute";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsWithIds } from "../util";

export async function fetchAffiliates() {
  const userdoc = store.getState().user.doc;
  const designation = getCurrentUserDesignation();
  // store.dispatch(setAffiliates(undefined));
  let docs = [];
  const collectionRef = collection(db, firestoreStatic.affiliates);
  const q = query(
    collectionRef
    // where(documentId(), "in", [userdoc?.affiliateId])
  );
  docs = await getDocs(q);
  return docsWithIds(docs);
}

export async function fetchMyAffiliate() {
  const userdoc = store.getState().user.doc;
  const designation = getCurrentUserDesignation();
  // store.dispatch(setAffiliates(undefined));
  let docs = [];
  const collectionRef = collection(db, firestoreStatic.affiliates);
  const q = query(collectionRef, where("admins", "array-contains", userdoc.id));
  docs = await getDocs(q);
  return { c: {}, admins: [], ...docsWithIds(docs)[0] };
}

export async function fetchAffiliate(id) {
  const collectionRef = collection(db, firestoreStatic.affiliates);
  const docRef = doc(collectionRef, id);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (!data?.admins) {
        data.admins = [];
      }
      if (!data.c) {
        data.c = {};
      }
      return data;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    return null;
  }
}


export async function fetchAffiliateByDomainName(domainName) {
  const collectionRef = collection(db, firestoreStatic.affiliates);
  const q = query(collectionRef, where("c.domain_name", "==", domainName));

  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // Handling the first matching document. You could loop through all matches if needed.
      const docSnap = querySnapshot.docs[0];
      let data = docSnap.data();
      if (!data?.admins) {
        data.admins = [];
      }
      if (!data.c) {
        data.c = {};
      }
      return data;
    } else {
      console.log("No matching document found!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document by domain name: ", error);
    return null;
  }
}