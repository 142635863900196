import { useEffect, useState } from "react";
import { registerAllModules } from "handsontable/registry";

import { HotTable } from "@handsontable/react";

import "./targets.css";
import "handsontable/dist/handsontable.full.min.css";
import { Button, Card, Col, Dropdown, Menu, Row, Select, Typography } from "antd";

import { extractTotals, getAnalysis } from "../../../extras/testing/workers/year";
import Loading from "../../../../components/loading";
import { updateCompany } from "../../../../firebase/company/update";
import { TextAreaWithSaveCancel } from "antd-elements";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectResults, selectTheme } from "../../../../redux/features/appSlice";
import TargetsCharts from "./targets_charts";
import { TbTargetArrow } from "react-icons/tb";
import { cardsUI1 } from "../../../../helper/uimaker";
import useSize from "../../../../hooks/useSize";
import RainbowText from "../../../../components/text/RainbowText";
import { graph_colors } from "../../../extras/testing/charts/config";
import DownloadDomImage from "../../../../components/canvas/downloadDomImage";
import { firstCompany } from "../../../../helper/attribute";
registerAllModules();

let totalsNumCache = [];
function convertObjToArray(obj, baselineYear, endYear = 2050) {
  const result = [];
  const totalYears = endYear - baselineYear + 1; // Calculate the total number of years

  Object.entries(obj).forEach(([title, yearMap]) => {
    const values = new Array(totalYears).fill(undefined);

    Object.entries(yearMap).forEach(([year, value]) => {
      const index = year - baselineYear;
      values[index] = value;
    });

    result.push([title, ...totalsNumCache, ...values.slice(totalsNumCache.length)]);
  });

  return result;
}

const Targets = ({ _ }) => {
  const companyId = _.id;
  const currentYear = new Date().getFullYear();
  const { isMobile } = useSize();
  let [baselineYear, setBaselineYear] = useState();
  const [dataYears, setDataYears] = useState([]);
  const [analysisData, setAnalysisData] = useState(undefined);
  const [savingTable, setSavingTable] = useState(false);
  const results = useSelector(selectResults);
  const theme = useSelector(selectTheme);
  const [rows, setRows] = useState([]);
  const [analysisOptions, setAnalysisOptions] = useState({
    wtt: "Included",
    type: "Market",
    reporting: "All",
    sites: [companyId],
  });

  const create = (recalculateWithSave = false) => {
    if (Array.isArray(results)) {
      let includeYearsOnly;
      if (baselineYear) {
        includeYearsOnly = dataYears.filter((y) => y >= baselineYear);
      }
      const locationAnalysis = getAnalysis(results, analysisOptions, includeYearsOnly);
      const totals = extractTotals(locationAnalysis);
      totalsNumCache = Object.values(totals).map((x) => x.total);
      console.log(locationAnalysis, "locationAnalysis");
      setAnalysisData(locationAnalysis);
      if (!baselineYear) {
        const years = locationAnalysis.moreInfo.cols.map(Number);
        baselineYear = years[0]; //imp, used in the future
        setBaselineYear(baselineYear);
        setDataYears(years);
      }
      const last_row = ["Actual Data", ...totalsNumCache];
      if (recalculateWithSave) {
        const rows = [["Passive Target", ...generateYearValues(baselineYear)], ["Active Target", ...generateYearValues(baselineYear)], last_row];
        setRows(rows);
        updateRowsBE(rows);
        return;
      }
      if (_.target_rows_str) {
        setRows(convertObjToArray(JSON.parse(_.target_rows_str), baselineYear));
      } else {
        setRows([["Passive Target", ...generateYearValues(baselineYear)], ["Active Target", ...generateYearValues(baselineYear)], last_row]);
      }
    }
  };
  useEffect(() => {
    create();
  }, [results, analysisOptions, baselineYear]);

  const updateRowsBE = async (rows) => {
    if (rows.length) {
      setSavingTable(true);
      function convertArrayToObj(array, baselineYear) {
        const result = {};

        array.forEach((item) => {
          const [title, ...values] = item; // Extract title and values
          const yearMap = {};

          values.forEach((value, index) => {
            // if (value !== undefined) { // Only map defined values
            const year = baselineYear + index;
            yearMap[year] = value;
            // }
          });

          result[title] = yearMap;
        });

        return result;
      }

      let rowsU = convertArrayToObj(rows, baselineYear);
      console.log(rowsU, "rowsU");

      await updateCompany(companyId, { target_rows_str: JSON.stringify(rowsU), target_rows_last_updated: new Date().getTime() });
      setSavingTable(false);
    }
  };

  function generateYearValues(baseline, totalsNum = totalsNumCache) {
    const data = [];

    for (let year = baseline, i = 0; year <= 2050; year++, i++) {
      let value = totalsNum[i];
      data.push(value);
    }

    return data;
  }

  const handleAddRow = () => {
    const newRow = [`Row ${rows.length}`, ...generateYearValues(baselineYear)];
    const updatedRows = [...rows.slice(0, -1), newRow, rows[rows.length - 1]];
    setRows(updatedRows);
    updateRowsBE(updatedRows);
  };

  const handleBaselineChange = (value) => {
    setBaselineYear(value);
    // updateRowsBE(updatedRows);
  };

  const years = Array.from({ length: 2050 - baselineYear + 1 }, (_, i) => baselineYear + i);
  const headers = ["Row Title", ...years];

  if (analysisData === undefined) return <Loading />;

  const DataTable = () => (
    <div className="data-table">
      <div>
        <Typography.Text>
          You can copy and paste into the table, but you <u>cannot</u> edit disabled cells.
        </Typography.Text>
      </div>
      <div className={theme.isDarkMode ? "invert" : ""}>
        <HotTable
          data={rows}
          cells={(row, col) => {
            // disable starting rows as well till current year
            if (years[col - 1] <= currentYear) {
              return { readOnly: true, disableVisualSelection: true, editor: false };
            }

            const isLastRow = row === rows.length - 1;
            if (isLastRow) {
              return { readOnly: true, disableVisualSelection: true, editor: false }; // Disable editing for the last row
            }
          }}
          colHeaders={headers}
          rowHeaders={true}
          height="auto"
          autoWrapRow={true}
          autoWrapCol={true}
          licenseKey="non-commercial-and-evaluation"
          contextMenu={true}
          afterChange={(changes) => {
            if (changes) {
              const updatedRows = [...rows];
              changes.forEach(([row, column, oldValue, newValue]) => {
                if (newValue === null || newValue === undefined) {
                  updatedRows[row][column] = newValue;
                } else if (row >= 0 && column >= 1) {
                  updatedRows[row][column] = parseFloat(newValue) || 0;
                } else if (row >= 0 && column === 0) {
                  updatedRows[row][column] = newValue;
                }
              });
              setRows(updatedRows);
              updateRowsBE(updatedRows);
            }
          }}
        />
      </div>
    </div>
  );
  return (
    <div className="targets">
      {savingTable && <div className="saving-tag">saving...</div>}

      {/* Main Section */}
      <Row gutter={[20, isMobile ? 10 : 20]}>
        {/* Targets Section */}
        <Col span={isMobile ? 24 : 8}>
          <Card
            bordered
            bodyStyle={{ padding: isMobile ? "10px" : "20px" }}
            style={{
              ...cardsUI1(4),
              minHeight: "320px",
              marginBottom: isMobile ? "20px" : "0",
            }}
          >
            <Typography.Title level={isMobile ? 3 : 2}>
              <TbTargetArrow />
              Targets
              {/* <RainbowText text={"Targets"} colors={graph_colors} /> */}
            </Typography.Title>
            <Typography.Title level={5}>Starting Year</Typography.Title>
            <Select title="Baseline Year" value={baselineYear} onChange={handleBaselineChange} style={{ width: "100%" }} options={dataYears.map((year) => ({ label: year, value: year }))} />
            <Typography.Title level={5}>Type</Typography.Title>
            <Select
              title="Baseline Year"
              value={analysisOptions.type}
              onChange={(type) => setAnalysisOptions({ ...analysisOptions, type })}
              style={{ width: "100%" }}
              options={[
                { label: "Location", value: "Location" },
                { label: "Market", value: "Market" },
              ]}
            ></Select>
          </Card>
        </Col>

        {/* Target Summary Section */}
        <Col span={isMobile ? 24 : 16}>
          <Card
            bordered
            bodyStyle={{ padding: isMobile ? "10px" : "20px" }}
            style={{
              ...cardsUI1(1),
              minHeight: "320px",
            }}
          >
            <Typography.Title level={3} style={{ marginBottom: "10px" }}>
              Notes
            </Typography.Title>
            <Typography.Text type="secondary">Write about your target notes here.</Typography.Text>
            <TextAreaWithSaveCancel
              initialValue={_.target_comments}
              onUpdate={async (target_comments) => {
                setSavingTable(true);
                await updateCompany(companyId, { target_comments });
                setSavingTable(false);
              }}
            />
          </Card>
        </Col>
      </Row>

      {/* Data Table Section */}
      <Card
        bordered
        bodyStyle={{ padding: isMobile ? "10px" : "20px" }}
        style={{
          ...cardsUI1(2),
          marginTop: isMobile ? "10px" : "20px",
          minHeight: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={3}>
          Data Table{" "}
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: "Clear data",
                  onClick: () => create(true),
                },
              ],
            }}
            trigger={["hover"]}
          >
            <Button type="link" icon={<SettingOutlined />}></Button>
          </Dropdown>
        </Typography.Title>
        <div>
          <DataTable />
          <Button icon={<PlusOutlined />} onClick={handleAddRow} style={{ marginTop: "16px" }}>
            Add New Row
          </Button>
        </div>
      </Card>

      {/* Charts Section */}
      <Card
        bordered
        bodyStyle={{ padding: isMobile ? "10px" : "20px" }}
        style={{
          ...cardsUI1(3),
          marginTop: isMobile ? "10px" : "20px",
          minHeight: "300px",
        }}
      >
        <DownloadDomImage fileName={`Chart Export ${_.title}.png`} makeAbsolute elementId={"targets-charts-1"} text={"Graph created by Sustrax MX from carbonfootprint.com"} />
        <div style={{ padding: 10 }}>
          <TargetsCharts years={years} rows={rows} />
        </div>
      </Card>
    </div>
  );
};

export default Targets;
