import moment from "moment";
import { timeFormat } from "../../../../helper/time";

function getGHGTitle(ghgs, code) {
  const foundCategory = ghgs.find(
    (category) => category.code === parseInt(code)
  );
  return foundCategory ? foundCategory.title : undefined;
}

function convertObjectToArray(inputObject) {
  return Object.keys(inputObject).map((key) => ({
    id: key,
    ...inputObject[key],
  }));
}

export function getGHGAnalysis(results, ghgCategories, filters) {
  // apply filters
  const filterOut = (result) => {
    if (filters.year) {
      const momentDate = moment(result.date, timeFormat);
      if (
        filters.year === "custom" &&
        (momentDate.isBefore(moment(filters.startdate, timeFormat)) ||
          momentDate.isAfter(moment(filters.enddate, timeFormat)))
      ) {
        return false;
      }
      if (
        filters.year !== "custom" &&
        momentDate.format("YYYY") != filters.year
      )
        return false;
    }
    return true;
  };
  // generating dataSourceSkeleton
  let dataSource = ghgCategories.map((ghg) => ({
    key: ghg.title,
    children: {},
    result: 0,
    ...ghg,
  }));
  // generatingTable
  for (let result of results) {
    if (!filterOut(result)) continue;
    const name = result.name;
    const siteId = result.siteId;

    if (name === "product") {
      const keys = ["emb", "dist", "manf", "dist_cust", "use", "waste"];
      keys.forEach((key) => {
        const ghgTitle = getGHGTitle(ghgCategories, result[`${key}_ghg`]);
        const index = ghgCategories.findIndex((cat) => cat.title === ghgTitle); // cam improve
        let subTable = dataSource[index].children;
        if (!subTable.hasOwnProperty(name)) subTable[name] = {};
        subTable[name][siteId] =
          result[`em_${key}`] + (subTable[name][siteId] || 0);
        dataSource[index].result += result[`em_${key}`];
      });
    } else if (getGHGTitle(ghgCategories, result.ghg)) {
      const ghgTitle = getGHGTitle(ghgCategories, result.ghg);
      const index = ghgCategories.findIndex((cat) => cat.title === ghgTitle); // cam improve
      let subTable = dataSource[index].children;
      if (!subTable.hasOwnProperty(name)) subTable[name] = {};
      subTable[name][siteId] = result.result + (subTable[name][siteId] || 0);
      dataSource[index].result += result.result;

      // checking for wtt ghg
      const ghgWttTitle = getGHGTitle(ghgCategories, result.ghgwtt);
      if (filters.wtt === "Included" && ghgWttTitle && result.wtt) {
        const name = "wtt-" + result.name;
        const index = ghgCategories.findIndex(
          (cat) => cat.title === ghgWttTitle
        ); // cam improve
        let subTable = dataSource[index].children;
        if (!subTable.hasOwnProperty(name)) subTable[name] = {};
        subTable[name][siteId] = result.wtt + (subTable[name][siteId] || 0);
        dataSource[index].result += result.wtt;
      }
    }
  }

  for (let row of dataSource) {
    row.children = convertObjectToArray(row.children);
    row.result = parseFloat(row.result.toFixed(2));
  }

  //filtering zero
  dataSource = dataSource.filter((row) => row.result);
  return { dataSource };
  postMessage({ dataSource });
}
