/**
 * Removes object properties whose values match any value in the specified array.
 *
 * @param {object} obj - The object from which properties will be removed.
 * @param {array} valuesToRemove - An array of values to match for property removal.
 */
export function removePropertiesByValues(obj, valuesToRemove) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && valuesToRemove.includes(obj[key])) {
      delete obj[key];
    }
  }
  return obj;
}
