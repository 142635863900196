// Checks before login

import { ArrowRightOutlined, TranslationOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import {
  ChangeLanguage,
  findLanguageByCode,
} from "../helper/translations.google";

function languageCheck() {
  if (!navigator.language.includes("en")) {
    if (window.location.href.includes("goog")) return;
    const lang = findLanguageByCode(navigator.language);
    notification.info({
      message: (
        <div>
          English
          <ArrowRightOutlined />
          {lang}
        </div>
      ),
      btn: (
        <Button onClick={async () => await ChangeLanguage(lang)}>
          <TranslationOutlined />
          Translate
        </Button>
      ),
    });
  }
}

export default async function checksBeforeLogin() {
  languageCheck();
}
