import React, { useState } from "react";
import { Form, Select, Slider, Button } from "antd";

const { Option } = Select;

export const LineOptionsForm = ({ lineOptions, setLineOptions }) => {
  const lineTypes = ["basis", "basisClosed", "basisOpen", "linear", "linearClosed", "natural", "monotoneX", "monotoneY", "monotone", "step", "stepBefore", "stepAfter"];

  const handleUpdate = (changedValues) => {
    setLineOptions((prevOptions) => ({
      ...prevOptions,
      ...changedValues,
    }));
  };

  return (
    <Form layout="vertical" initialValues={lineOptions} onValuesChange={handleUpdate}>
      <Form.Item label="Line Type" name="type">
        <Select>
          {lineTypes.map((type) => (
            <Option key={type} value={type}>
              <span style={{ textTransform: "capitalize" }}>{type}</span>
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Stroke Width" name="strokeWidth">
        <Slider min={0} max={10} />
      </Form.Item>

      <Form.Item label="Active Dot Radius" name="activeDotRadius">
        <Slider min={0} max={10} />
      </Form.Item>
    </Form>
  );
};

export const BarOptionsForm = ({ barOptions, setBarOptions }) => {
  const handleUpdate = (changedValues) => {
    setBarOptions((prevOptions) => ({
      ...prevOptions,
      ...changedValues,
    }));
    console.log("Updated Options:", { ...barOptions, ...changedValues });
  };

  return (
    <Form layout="vertical" initialValues={barOptions} onValuesChange={handleUpdate}>
      {/* Bar Width */}
      <Form.Item label="Bar Width" name="barWidth">
        <Slider min={1} max={50} step={1} />
      </Form.Item>

      {/* Bar Opacity */}
      <Form.Item label="Bar Opacity" name="barOpacity">
        <Slider min={0} max={1} step={0.1} />
      </Form.Item>
    </Form>
  );
};
