import { doc, updateDoc, writeBatch } from "firebase/firestore";
import { openSites } from "../../helper/attribute";
import { getCurrentDate } from "../../helper/time";
import { objDontHave } from "../../helper/wodash";
import { setSites } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { fetchSites } from "./get";
import { fetchResults } from "../data/get";

const beforeuserUpdate = (data) => {
  const dontHv = objDontHave(data, ["title", "country"]);

  if (dontHv) throw "Please add " + dontHv;
  data.updatedAt = getCurrentDate();
  return data;
};

export const updateSite = async (id, data) => {
  await updateDoc(doc(db, firestoreStatic.sites, id), data);
  store.dispatch(setSites(store.getState().app.sites.map((site) => (site.id !== id ? site : { ...site, ...data }))));
  if (data._overwrite_ghg) fetchResults();
};
export const batchUpdateSites = async (sites_sorted) => {
  const batch = writeBatch(db);
  const sites = openSites();
  const diff = [];

  for (let i = 0, len = sites.length; i < len; i++) {
    if (sites_sorted[i].index !== sites[i].index) {
      const docRef = doc(db, firestoreStatic.sites, sites_sorted[i].id);
      batch.update(docRef, { index: sites[i].index });
      diff.push(sites_sorted[i]);
    }
  }

  // Commit the batch write operation
  await batch.commit();
  fetchSites();
};
