import {
  EditOutlined,
  InfoCircleOutlined,
  InfoOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, notification, Tag, TreeSelect, Typography } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { findCompanies, openSites } from "../../helper/attribute";
import { selectWarnings } from "../../redux/features/appSlice";
import FakeLabel from "../input/select";
const { SHOW_ALL } = TreeSelect;

function filterSites(companyId) {
  const sites = openSites();
  return sites.filter((site) => site.companyId === companyId);
}

function optionsSelectSites() {
  const companies = findCompanies();

  const treeOptions = companies.map((company) => {
    return {
      label: company.title,
      value: company.id,
      children: filterSites(company.id).map((site) => ({
        label: site.title,
        value: site.id,
      })),
    };
  });

  return treeOptions;
}

const SiteMultipleChecker = ({ sites, setSites, ...more }) => {
  const [value, setValue] = useState(sites);
  const [loading, setLoading] = useState(false);
  const { siteLengthMismatch } = useSelector(selectWarnings);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const handleOkClick = () => {
    setLoading(true);
    setSites(value);
  };
  const handleCancel = () => {
    setValue(sites);
  };

  const handleBlur = (e) => {
    if (e == false) setValue(sites);
  };

  const tProps = {
    treeData: optionsSelectSites(),
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    placeholder: "Please select",
    style: {
      width: "100%",
    },
  };

  if (!sites) sites = [];
  const tagColor = (x) =>
    openSites()
      .map((site) => site.id)
      .includes(x.value)
      ? "darkorange"
      : siteLengthMismatch
      ? "yellowgreen"
      : "black";
  return (
    <div
      style={{
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <FakeLabel
        bgless
        borderless
        maxWidthElem="400px"
        shouldOptionTranslate="no"
        padding="0px"
        justifyContent="flex-start"
        title={more.title || "Sites"}
        {...more}
        elem={
          <TreeSelect
            suffixIcon={
              <EditOutlined style={{ zoom: 1.4, pointerEvents: "none" }} />
            }
            autoClearSearchValue
            showSearch={false}
            treeDefaultExpandAll
            onDropdownVisibleChange={handleBlur}
            multiple={false}
            tagRender={(x) => (
              <div
                className="button-primary-1"
                style={{
                  marginRight: 3,
                  color: tagColor(x),
                  textAlign: "center",
                  padding: "4px 3px",
                  backgroundColor:
                    tagColor(x) == "darkorange" ? "white" : "#a5d6a7",
                  minWidth: "100px",
                  borderColor: "green",
                  borderRadius: 0,
                }}
              >
                {x.label}
              </div>
            )}
            {...tProps}
            dropdownRender={(options) => (
              <div className="notranslate">
                {options}
                {JSON.stringify(sites) !== JSON.stringify(value) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                      flexDirection: "row",
                      borderTop: "2px solid grey",
                      paddingTop: "10px",
                    }}
                  >
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" onClick={handleOkClick}>
                      Update
                    </Button>
                  </div>
                )}
              </div>
            )}
          ></TreeSelect>
        }
      ></FakeLabel>
      {siteLengthMismatch && (
        <div
          onClick={() =>
            notification.info({
              description:
                "Data from other sites (which you don't have access to) is omitted from the results presented.",
            })
          }
        >
          <Typography.Text style={{ color: "royalblue" }}>
            <InfoCircleOutlined /> Data omitted.
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default SiteMultipleChecker;
