import { Button, message, Select, Space, Switch, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import * as XLSX from "xlsx";
import { returnExcelOptions } from "./helper2";
import { findFormText, findFormTexts, firstSite, openSites } from "../../helper/attribute";
import Fuse from "fuse.js";
import { EditFilled, EditOutlined, WarningTwoTone } from "@ant-design/icons";
import ReviewPage from "./reviewpage";
import { exceldateformat } from "../../helper/time";

const MAXROWS = 2000;
const { OptGroup, Option } = Select;

// Fuse.js configuration for fuzzy matching
const fuseOptions = {
  keys: ["label"],
  threshold: 0.3,
  findAllMatches: true,
};

// Function to perform fuzzy matching
export const getFuzzyMatchedOptions = (val, options) => {
  try {
    if (!val) return [];
    const fuse = new Fuse(
      options.map((option) => ({
        value: option,
        label: option,
      })),
      fuseOptions
    );
    const results = fuse.search(val);
    return results.map((result) => result.item);
  } catch (err) {
    message.info("error")
    return [];
  }
};

// Function to convert arrays to objects using headers as keys
const arraysToObjects = (keysArray, dataArrays) =>
  dataArrays.map((dataArray) =>
    keysArray.reduce((obj, key, index) => {
      obj[key] = dataArray[index];
      return obj;
    }, {})
  );

function IntermediatePage({ form, data, setData, settings = { type: "Default" } }) {
  const [excelOptionsArr, setExcelOptionsArr] = useState([]);
  const [wrongEdits, setWrongEdits] = useState({}); // Track fields that were edited after being incorrect
  const [filterEnabled, setFilterEnabled] = useState(true); // Control filter state

  // Initialize data and parse Excel file
  useEffect(() => {
    const InitProcedure = () => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(data.file);
      reader.onload = async (event) => {
        const rows = event.target.result;
        const workbook = XLSX.read(rows, { type: "array" });
        const sheetName = settings.sheetName || workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        let parsedRows = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          defval: null,
          blankrows: false,
        });

        if (parsedRows.length > MAXROWS) {
          parsedRows = parsedRows.slice(0, MAXROWS + 1);
        }

        const headers = parsedRows[0];
        const dataRows = parsedRows.slice(1);
        const formattedRows = arraysToObjects(headers, dataRows).map((row, index) => ({
          ...row,
          key: index + 1,
        }));

        const mappedRows = formattedRows.map((row) => {
          const newRow = {};
          Object.keys(row).forEach((title) => {
            const obj = findFormTexts(data.name, "forms")?.find((obj) => obj.title === title);
            newRow[obj ? obj.name : title] = row[title];
          });
          return newRow;
        });

        const optionsArr = await Promise.all(mappedRows.map((row) => returnExcelOptions(form, { ...settings, ...row })));

        setExcelOptionsArr(optionsArr);
        setData({
          ...data,
          rows: mappedRows.map((row) => {
            const siteId = openSites().find((site) => site.title === row["Site"])?.id || firstSite().id;
            const date = exceldateformat(row?.date);
            const newRow = { siteId, date };
            return { ...row, ...newRow };
          }),
        });
      };
    };

    InitProcedure();
  }, [data.file, form, settings, setData]);

  // Handle value change
  const handleValueChange = async (newValue, key, rowIndex) => {
    const updatedRows = [...data.rows];
    updatedRows[rowIndex][key] = newValue;

    setData({ ...data, rows: updatedRows });

    const updatedOptions = await returnExcelOptions(form, { ...settings, ...updatedRows[rowIndex] });
    const newExcelOptionsArr = [...excelOptionsArr];
    newExcelOptionsArr[rowIndex] = updatedOptions;
    setExcelOptionsArr(newExcelOptionsArr);

    // Mark this field as edited (track wrong edits)
    setWrongEdits((prev) => ({
      ...prev,
      [`${rowIndex}-${key}`]: true,
    }));
  };

  // Automatically select suggested options for all rows
  const autoSelectSuggested = () => {
    const updatedRows = data.rows.map((row, rowIndex) => {
      const updatedRow = { ...row };

      Object.keys(row).forEach((key) => {
        const excelOptions = excelOptionsArr[rowIndex]?.find((x) => x.key === key)?.excel_options;
        if (excelOptions) {
          const fuzzyMatchedOptions = getFuzzyMatchedOptions(row[key], excelOptions);
          if (fuzzyMatchedOptions.length > 0) {
            updatedRow[key] = fuzzyMatchedOptions[0].value; // Auto-select first suggested option
          }
        }
      });

      return updatedRow;
    });

    setData({ ...data, rows: updatedRows });
  };

  // Columns definition with filtering and edit conditions
  const columns = useMemo(() => {
    return Object.keys(data.rows?.[0] || {})
      .filter((key) => {
        if (!filterEnabled) return true;
        return excelOptionsArr.some((x) => x.some((option) => option.key === key));
      })
      .map((key) => ({
        title: findFormText(data.name, key),
        dataIndex: key,
        width: 300,
        key,
        render: (val, row, rowIndex) => {
          const options = excelOptionsArr[rowIndex]?.find((x) => x.key === key)?.excel_options;
          const isEditable = wrongEdits[`${rowIndex}-${key}`]; // Only allow editing if it was wrong and edited

          // Check if value is already "OK" and should not be editable
          const isValueOk = options && options.includes(val);

          if (!options || (!isEditable && isValueOk)) {
            // Don't show the edit icon for correct fields
            return <span>{val}</span>;
          }

          if (isEditable) {
            return (
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <EditFilled />
                <Select showSearch style={{ width: 200 }} defaultValue={val} onChange={(newValue) => handleValueChange(newValue, key, rowIndex)}>
                  {options.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
            );
          }

          const fuzzyMatchedOptions = getFuzzyMatchedOptions(val, options || []);
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Tooltip title="No matches found">
                <WarningTwoTone twoToneColor={"red"} />
              </Tooltip>
              <Select showSearch style={{ width: 200 }} defaultValue={val} onChange={(newValue) => handleValueChange(newValue, key, rowIndex)}>
                {fuzzyMatchedOptions.length > 0 && (
                  <OptGroup label="Suggested">
                    {fuzzyMatchedOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </OptGroup>
                )}
                <OptGroup label="All Options">
                  {options?.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
          );
        },
      }));
  }, [data.rows, excelOptionsArr, wrongEdits, filterEnabled]);

  return (
    <div>
      <Button onClick={autoSelectSuggested} type="primary" style={{ marginBottom: 16 }}>
        Automatically Select Suggested Options
      </Button>
      <Button onClick={() => setFilterEnabled(!filterEnabled)} style={{ marginBottom: 16 }}>
        {filterEnabled ? "Disable" : "Enable"} Filter
      </Button>
      <Typography.Title level={3}>Edit data table</Typography.Title>
      {data.rows && <Table key={JSON.stringify(excelOptionsArr)} rowKey="key" dataSource={data.rows} columns={columns} pagination={false} scroll={{ x: 1200 }} />}
    </div>
  );
}

export default function FinalPage(props) {
  const [isIntermediateVisible, setIsIntermediateVisible] = useState(true);

  const handleToggle = (checked) => {
    setIsIntermediateVisible(checked);
  };

  return <ReviewPage {...props} />;
  return (
    <div>
      <Switch checked={isIntermediateVisible} onChange={handleToggle} checkedChildren="Intermediate" unCheckedChildren="Review" />
      <div style={{ display: isIntermediateVisible ? "none" : "block" }}>
        <ReviewPage {...props} />
      </div>
      <div style={{ display: isIntermediateVisible ? "block" : "none" }}>
        <IntermediatePage {...props} />
      </div>
    </div>
  );
}
