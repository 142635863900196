import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Rectangle,
} from "recharts";

const generateSkeletonData = (length) =>
  Array.from({ length }).map((m) => ({
    name: m,
    value: Math.random() * 100,
  }));

const SkeletonBarChart = ({ bars }) => {
  const [data, setData] = useState(generateSkeletonData(bars));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setData(generateSkeletonData(bars)); 
    }, 900); 

    return () => clearInterval(intervalId);
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
        <XAxis dataKey="name" tickLine={false} tick={false} axisLine={false} />
        <YAxis
          tickLine={false}
          tick={false}
          axisLine={false}
          domain={[0, "dataMax + 100"]}
        />
        <Bar dataKey="value" fill="#ccc" animationDuration={400} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SkeletonBarChart;
