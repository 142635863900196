import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, notification, Space, Table, Tooltip } from "antd";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import ConfirmNTimesButton from "../../../components/confirm/ConfirmNTimesButton";
import antdPrompt from "../../../components/prompts/antdPrompt";
import RadioSelector from "../../../components/select/radioselector";
import DNDTable from "../../../components/table/dndtable";
import { updateConfig } from "../../../firebase/appconfig/update";
import { except } from "../../../firebase/util";
import { convertToTitleCase, removePropertyKeyNested } from "../../../helper/wodash";

export default function FormConfig({ name, data }) {
  const [forms, setForms] = useState(() => {
    try {
      return JSON.parse(JSON.stringify(data[name]));
    } catch (err) {
      return [];
    }
  });
  const [dndtableKey, setDndTableKey] = useState(() => uniqueId());
  const [indexes, setIndexes] = useState({});
  if (!data[name]) return "Not Present";
  const keys = Object.keys(data[name]);

  const propertyInput = (property, index, type = "input") => {
    const props = {
      value: forms[keys[index]][property],
      onChange: (e) => {
        forms[keys[index]][property] = e.target.value;
        setForms({ ...forms });
      },
    };
    if (type === "textarea") return <Input.TextArea {...props} />;
    return <Input {...props} />;
  };
  const columns = [
    {
      title: "Name",
      render: (f, _, i) => keys[i],
    },
    {
      title: "Title",
      render: (record, _, index) => {
        return propertyInput("title", index);
      },
    },
    {
      title: "Instructions",
      render: (record, _, index) => {
        return propertyInput("ins", index, "textarea");
      },
    },
  ];
  const textPropertyInput = (property, index, i, type = "input") => {
    const props = {
      value: forms[keys[i]].texts[index][property],
      onChange: (e) => {
        forms[keys[i]].texts[index][property] = e.target.value;
        setForms({ ...forms });
      },
    };

    if (type === "textarea") return <Input.TextArea style={{ height: "50px" }} {...props} />;
    return <Input {...props} />;
  };
  const textPropertyCheckbox = (property, index, i) => {
    const props = {
      checked: forms[keys[i]].texts[index][property],
      onChange: (e) => {
        forms[keys[i]].texts[index][property] = e.target.checked;
        setForms({ ...forms });
      },
    };
    return <Checkbox {...props}></Checkbox>;
  };
  const handleUpdate = async () => {
    except(async () => {
      const newforms = JSON.parse(JSON.stringify(forms));
      removePropertyKeyNested(newforms);
      await updateConfig(JSON.parse(JSON.stringify(newforms)), name);
      notification.info({
        message: "The changes have been saved and forms are updated. However you may reload the application so they are reflected everywhere.",
        btn: (
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
        ),
      });
    });
  };
  const handleCancel = () => {
    setForms(JSON.parse(JSON.stringify(data[name])));
    setDndTableKey(uniqueId());
  };
  const isDirty = () => {
    return JSON.stringify(data[name]) !== JSON.stringify(forms);
  };
  const innerTableOptions = [
    {
      label: "Texts",
      value: "s",
      columns: (i) => {
        let obj = [
          {
            title: "Key",
            dataIndex: "name",
            fixed: "left",
            width: 170,
            fixed: "right",
          },
          {
            title: "Title",
            render: (record, _, index) => textPropertyInput("title", index, i),
          },
          {
            title: "Description",
            render: (record, _, index) => textPropertyInput("desc", index, i, "textarea"),
          },
          {
            title: <small>Display</small>,
            width: 90,
            render: (record, _, index) => textPropertyCheckbox("s", index, i, "textarea"),
          },
          {
            title: <small>Display extra</small>,
            width: 90,
            render: (record, _, index) => textPropertyCheckbox("e", index, i, "textarea"),
          },
          {
            title: <small>Graph Divider</small>,
            width: 90,
            render: (record, _, index) => textPropertyCheckbox("gd", index, i, "textarea"),
          },
        ];
        if (name !== "tables") {
          obj.pop();
          return obj;
        }
        return obj;
      },
    },
  ];
  const handleAddProperty = async (form_name) => {
    const info = (
      <div>
        <h3 style={{ textDecoration: "underline" }}>
          <WarningOutlined /> Sensitive action
        </h3>
        Add property to form {form_name} for UI {name}?
      </div>
    );
    //-------
    try {
      const property_name = await antdPrompt("Property Name", "Add", info);
      forms[form_name].texts.push({
        title: convertToTitleCase(property_name),
        name: property_name,
      });

      // setForms(JSON.parse(JSON.stringify(forms)));
      setDndTableKey(uniqueId("dnddaf"));
    } catch (err) {
      console.error(err);
    }
  };
  const handleRemoveProperty = async (form_name) => {
    const info = (
      <div>
        <h3 style={{ textDecoration: "underline" }}>
          <WarningOutlined /> Sensitive action
        </h3>
        Remove property form {form_name} for UI {name}?
      </div>
    );
    //-------
    try {
      const property_to_delete = await antdPrompt("Property Name", "Add", info);
      forms[form_name].texts = forms[form_name].texts.filter((text) => text.name !== property_to_delete);

      // setForms(JSON.parse(JSON.stringify(forms)));
      setDndTableKey(uniqueId("dnddaf"));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Table
      scroll={{ x: 1000, y: 650 }}
      pagination={false}
      expandable={{
        expandedRowRender: (record, i) => (
          <DNDTable
            style={{ border: "3px solid darkgreen" }}
            key={dndtableKey + i}
            title={() => {
              return (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <b>{record.title}</b>
                  </div>
                  <div style={{ display: "flex", gap:3 }}>
                    <RadioSelector
                      value={indexes[record.key] ? indexes[record.key] : innerTableOptions[0].value}
                      setValue={(v) => setIndexes({ ...indexes, [record.key]: v })}
                      options={innerTableOptions}
                    />
                    <Tooltip title="Add new property">
                      <Button type="default" onClick={() => handleAddProperty(record.key)}>+</Button>
                    </Tooltip>
                    <Tooltip title="Delete Properties">
                      <Button danger onClick={() => handleRemoveProperty(record.key)}>
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              );
            }}
            onUpdate={() => {}}
            showUpdateButton={false}
            onDirectUpdate={(val) => {
              forms[keys[i]].texts = JSON.parse(JSON.stringify(val));
              setForms({ ...forms });
            }}
            dataSource={record.texts.map((text) => ({
              ...text,
              key: JSON.stringify(text),
            }))}
            columns={indexes[record.key] ? innerTableOptions.find((x) => x.value === indexes[record.key]).columns(i) : innerTableOptions[0].columns(i)}
          />
        ),
      }}
      title={() => (
        <div>
          {isDirty() && (
            <Space>
              <ConfirmNTimesButton onFinalClick={handleUpdate} messages={["Confirm", "Please confirm two times!", "Please confirm last time!"]} />
              <Button onClick={handleCancel}>Cancel</Button>
            </Space>
          )}
        </div>
      )}
      columns={columns}
      dataSource={Object.values(forms).map((form, i) => ({
        ...form,
        key: keys[i],
      }))}
    ></Table>
  );
}
