import { Alert } from "antd";
import React from "react";

export default function makeAlert({ msgs, type = "info" }) {
  if (msgs && msgs.length)
    return (
      <Alert
        style={{ marginBottom: 20 }}
        type={type}
        closable
        showIcon
        description={
          <>
            {msgs.map((msg) => (
              <>
                {msg}
                <br />
              </>
            ))}
          </>
        }
      />
    );
}
