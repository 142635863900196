// some users will be offline, depending upon state value in redux=trial

import { ArrowRightOutlined } from "@ant-design/icons";
import store from "./redux/store";
import { Button, notification } from "antd";

//here are some functions that define that

export const isOfflineUser = () => {
  if (store.getState().app.state === "trial") return true;
  return false;
};

export const notifyIfOffline = () => {
  if (isOfflineUser()) {
    notification.open({
      placement: "top",
      message:"You need to have an account to do that.",
      description: (
        <div style={{ textAlign: "center" }}>
          <a href="https://sustraxmx.com/login" target="_blank" rel="noopener noreferrer">
            <Button type="primary" style={{ fontWeight: "bold", backgroundColor: "#228B22", borderColor: "#228B22" }}>
              Create ID <ArrowRightOutlined />
            </Button>
          </a>
        </div>
      ),
    });
    throw new Error("Offline user."); // Throw an error for offline users.
  }
};
