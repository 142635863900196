import React, { useState, useEffect } from "react";
import { message } from "antd";

const App = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [messageKey, setMessageKey] = useState("x");

  useEffect(() => {
    // Add event listeners to check online/offline status changes
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  const handleConnectionChange = () => {
    messageApi.destroy(messageKey);
    const isOnlineNow = navigator.onLine;

    if (isOnlineNow) {
      messageApi.open({
        key: messageKey,
        type: "info",
        content: "Connection restored!",
        duration: 2,
      });
    } else {
      messageApi.open({
        key: messageKey,
        type: "warning",
        content: "You are offline. Please check your internet connection.",
        duration: 0,
      });
    }
  };

  return <>{contextHolder}</>;
};

export default App;
