import { Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { filterFactors } from "../../../helper/emissionfactors";
import { selectFactors } from "../../../redux/features/appSlice";
import FakeLabel from "../select";

export default function FrieghtCategory({ value, setValue, title, ...more }) {
  const factors = filterFactors({ "Level 1": "Freighting goods" }, "Level 2");
  return (
    <FakeLabel
      {...more}
      title={title}
      elem={
        <Select value={value} onChange={setValue}>
          {factors.map((v) => (
            <Select.Option key={v}>{v}</Select.Option>
          ))}
        </Select>
      }
    />
  );
}
