import React from "react";

import { Card, Table, Collapse, Row, Col, Typography } from "antd";
import CbfReportDownload from "../CbfReportDownload";
import store from "../../../../redux/store";
const { Title } = Typography;

const { Panel } = Collapse;
// Utility function for formatting numbers
const fixDecimalsAndAddCommas = (num) =>
  num !== undefined
    ? Number(num)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "0.00";

export const DisplayResultsTableData = ({ data }) => {
  if (!data) return "";

  const generateEmissionDetails = (emissionParams) => {
    const dynamicData = [];

    Object.keys(emissionParams).forEach((type) => {
      const emDetails = emissionParams[type];
      dynamicData.push({
        title: type.trim(),
        emissions: emDetails,
      });
    });

    return dynamicData;
  };

  const dynamicData = generateEmissionDetails(data);

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: "10%",
      render: (_, __, index) => (_ ? "" : index + 1),
    },
    {
      title: "Tonnes of CO₂e",
      dataIndex: "result",
      key: "result",
      width: "20%",
      render: (text, record) => fixDecimalsAndAddCommas(record.result),
    },
    {
      title: "WTT",
      dataIndex: "resultwtt",
      key: "resultwtt",
      width: "20%",
      render: (text, record) => fixDecimalsAndAddCommas(record.resultwtt),
    },
    {
      title: "Details",
      dataIndex: "text",
      key: "text",
      width: "50%",
      render: (text) => text || "-",
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      {dynamicData.map((emission) => {
        // Calculate Totals
        const totalCO2e = emission.emissions.reduce(
          (acc, e) => acc + (e.result ?? 0),
          0
        );
        const totalWTT = emission.emissions.reduce(
          (acc, e) => acc + (e.resultwtt ?? 0),
          0
        );

        // Add totals row
        const dataSourceWithTotals = [
          ...emission.emissions.map((e, idx) => ({
            ...e,
            key: idx,
          })),
          {
            key: "totals",
            index: "Total",
            result: totalCO2e,
            resultwtt: totalWTT,
            text: `Total emissions for ${emission.title}`,
            isTotalRow: true,
          },
        ];

        return (
          <div key={emission.title} style={{ marginBottom: "30px" }}>
            <Title level={3} style={{ marginBottom: "10px" }}>
              {emission.title}
            </Title>
            <Table
              dataSource={dataSourceWithTotals}
              columns={columns.map((col) =>
                col.dataIndex === "text"
                  ? {
                      ...col,
                      render: (text, record) => (
                        <span
                          style={
                            record.isTotalRow
                              ? { fontWeight: "bold" }
                              : undefined
                          }
                        >
                          {text}
                        </span>
                      ),
                    }
                  : col.dataIndex === "result" || col.dataIndex === "resultwtt"
                  ? {
                      ...col,
                      render: (value, record) => (
                        <span
                          style={
                            record.isTotalRow
                              ? { fontWeight: "bold" }
                              : undefined
                          }
                        >
                          {fixDecimalsAndAddCommas(value)}
                        </span>
                      ),
                    }
                  : col
              )}
              pagination={false}
              bordered={false}
              style={{ padding: "10px 0" }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default function ResultsTableDescription() {
  return (
    <div>
      <CbfReportDownload
        year={2024}
        results={store.getState().app.results}
        renderResultsEmissionsOnly
      />
    </div>
  );
}
