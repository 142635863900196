import React, { useEffect } from "react";
import paper_weights from "../../../assets/paper_weights.json";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { filterFactors } from "../../../helper/emissionfactors";
import { findForm } from "../../../static/formRoutes";
import { genPut } from "../helper/create";

export const INPUTS = (data, setData) => {
  const form = findForm(data.name);
  return [
    {
      elem: DateInput,
      key: "date",
    },
    {
      elem: NumberInput,
      key: "amount",
    },
    {
      elem: SelectSearch,
      key: "uom",
      options: form.uoms,
    },
    {
      elem: SelectSearch,
      key: "columntext",
      options: filterFactors({ form: "paper" }, "Column Text"),
    },
    {
      elem: SelectSearch,
      key: "size",
      options: Object.keys(paper_weights[100]),
    },
    {
      elem: SelectSearch,
      key: "gsm",
      options: Object.keys(paper_weights),
    },
  ];
};

export default function Paper({ data, setData }) {
  const formname = data.name;
  useEffect(() => {
    setData({ ...data });
  }, []);

  return (
    <div>
      {INPUTS(data, setData).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </div>
  );
}
