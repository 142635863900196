import { CheckCircleTwoTone, CheckSquareTwoTone, FileTwoTone } from "@ant-design/icons";
import { Button, Typography, Upload } from "antd";
import React, { useState } from "react";
import { fixDecimals } from "../../helper/wodash";
import "./datafile.css";
import { LuFileCheck } from "react-icons/lu";

function FileInput({ file, setFile, fileSize = 1, accept = "*", minHeight, extraWord = "file" }) {
  const [error, setError] = useState("");

  const beforeUpload = (file) => {
    if (fileSize !== -1 && file.size > fileSize * 1024 * 1024) {
      setError(`File size must be less than ${fileSize}MB`);
      return false;
    }
    setError("");
    setFile(file);
    return false;
  };

  const handleRemove = () => {
    setFile(null);
  };

  const props = {
    beforeUpload,
    showUploadList: false,
    accept,
  };
  const createURL = () => {
    try {
      return URL.createObjectURL(file);
    } catch (err) {
      return "#";
    }
  };
  return (
    <div className="file-input" style={{ minHeight }}>
      {file ? (
        <div className="file-preview">
          <Typography.Text>
            <p>
              <LuFileCheck color="darkgreen" twoToneColor={"forestgreen"} style={{ fontSize: 60 }} />
              <br />
              <div style={{ fontSize: "15px", margin: "10px" }}>
                <a href={createURL()} download={file.name}>
                  {" "}
                  {file.name || file || "File Name Error"}
                </a>
                {file.size && <span> ({fixDecimals(file.size / 1000)} Kb)</span>}
              </div>
            </p>
            <Button danger type="default" onClick={handleRemove}>
              Remove File
            </Button>
          </Typography.Text>
        </div>
      ) : (
        <div className="file-dragndrop" style={{ fontSize: 18 }}>
          <Upload {...props} style={{ width: "1000px !important" }}>
            <FileTwoTone twoToneColor={"forestgreen"} style={{ fontSize: 60 }} />
            <br />
            Drag & drop {extraWord} <br /> or <span style={{ color: "green", textDecoration: "underline" }}>browse</span>
            {error && <p className="error">{error}</p>}
            {accept !== "*" && <div style={{ color: "darkgrey", fontSize: "smaller" }}>Supported: {accept}</div>}
          </Upload>
        </div>
      )}
    </div>
  );
}

export default FileInput;
