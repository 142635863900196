import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Card, Result, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/loading";
import { fetchCompany } from "../../../firebase/company/get";
import { catcher } from "../../../firebase/util";
import { firstCompany } from "../../../helper/attribute";
import { getAllParamsFromLocation } from "../../../helper/loc";
import { routePath } from "../../../static/routes.static";

const { Title, Text } = Typography;

function PaymentSucceeded() {
  const [planName, setPlanName] = useState(getAllParamsFromLocation().plan);
  const [allOk, setAllOk] = useState(-1); //-1 means waiting
  const navigate = useNavigate();
  const back2dashboard = () => {
    navigate(routePath.Dashboard);
  };
  useEffect(() => {
    const intervalDuration = 2000 + Math.random() * 1000; // Randomly between 2000ms (2s) and 3000ms (3s)
    const twoMinutes = 120000; // Two minutes in milliseconds
    let tries = 0;

    const interval = setInterval(async () => {
      catcher(
        async () => {
          tries++;
          const company = await fetchCompany(firstCompany()?.id);
          console.log(company);
          if (company?.plan && company.plan === planName) {
            if (tries !== 1) window.location.reload();
            setAllOk(true);
            clearInterval(interval); // Stop the interval when condition is met
          }
        },
        { success_msg: "", setLoading: () => {} }
      );
    }, intervalDuration);

    // Set a timeout to clear the interval after two minutes
    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, twoMinutes);

    // Clean up the interval and timeout when the component unmounts
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  if (allOk === -1) return <Loading title="Confirming payments..." />;
  else if (!allOk)
    return (
      <Result title="Payment validation failed!">
        Payment validation has been failed. Please check in an hour or contact
        carbonfootprint team!
      </Result>
    );
  else
    return (
      <Card>
        <Title level={2}>
          <CheckCircleOutlined style={{ color: "green", fontSize: "50px" }} />{" "}
          Payment Successful!
        </Title>
        <Text>You have successfully upgraded to the {planName} plan.</Text>

        <Button type="primary" onClick={back2dashboard}>
          Go to Dashboard
        </Button>
      </Card>
    );
}

export default PaymentSucceeded;
