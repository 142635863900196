import React, { useState } from "react";
import YearAnalysis from "../../extras/testing/year.analysis";
import { Card, Select, Typography } from "antd";
import { planValue } from "../../../helper/plans";
import { routePath } from "../../../static/routes.static";
import { firstCompany } from "../../../helper/attribute";
import { Link } from "react-router-dom";

const InsightAlternate = () => {
  const cardStyle = {
    marginBottom:"3px"
  };

  const linkStyle = {
    color: 'forestgreen',
    textDecoration: 'none',
    fontWeight: '600',
  };

  return (
    <div>
      <div style={cardStyle}>
        <Link to={routePath.Profile} style={linkStyle}>Edit Profile</Link>
      </div>
      <div style={cardStyle}>
        <Link to={routePath.CompanyDetails(firstCompany()?.id)} style={linkStyle}>Company Details</Link>
      </div>
      <div style={cardStyle}>
        <Link to={routePath.combined_data_table} style={linkStyle}>Your Inputs</Link>
      </div>
      <div style={cardStyle}>
        <Link to={routePath.FAQ} style={linkStyle}>View FAQs</Link>
      </div>
    </div>
  );
};

const InsightResults = ({ years }) => {
  console.log("x", years);
  const [year, setYear] = useState(Array.isArray(years) ? years[years.length - 1] : undefined);

  if (!year) return "Loading insights 💡";

  // Transforming years to options format required by Select
  const yearOptions = years.map((y) => ({ value: y, label: y }));

  if (!planValue("displayDashboardInsights")) return <InsightAlternate />;
  return (
    <div>
      <Typography.Title level={5}>
        Insights for year <Select options={yearOptions} onChange={setYear} value={year} style={{ width: 120 }} />
      </Typography.Title>
      <div>
        <YearAnalysis scopeResultsOnly={{ year }} />
      </div>
    </div>
  );
};

export default InsightResults;
