import moment from "moment";

const timeFormat = "DD/MM/YYYY";
export { timeFormat };
export function getCurrentDate() {
  return moment().format(timeFormat);
}

export function compareDates(date1, date2) {
  if (!date1 || !date2) return 1;
  const momentDate1 = moment(date1, timeFormat);
  const momentDate2 = moment(date2, timeFormat);

  if (momentDate1.isAfter(momentDate2)) {
    return 1; // date1 is later than date2
  } else if (momentDate1.isBefore(momentDate2)) {
    return -1; // date1 is earlier than date2
  } else {
    return 0; // dates are the same
  }
}
export function getCurrentDatePlusNDays(n) {
  return moment().add(n, "days").format(timeFormat);
}
export function getCurrentTime() {
  return moment().format();
}
export function getCurrentYear() {
  // Returns the current year in integer form
  return parseInt(moment().format("YYYY"));
}

export function exceldateformat(excelDate, checkOtherFormatsAsWell = true) {
  try {
    // Convert Excel date to JavaScript date
    const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
    const momentDate = moment.utc(jsDate.valueOf()); // Convert to moment.js object

    // Return formatted date if valid
    if (momentDate.isValid()) {
      return momentDate.format(timeFormat);
    }
  } catch (error) {
    console.error("Error parsing Excel date:", error);
  }

  // If checkOtherFormatsAsWell is true, check other formats
  if (checkOtherFormatsAsWell) {
    const formatsToCheck = [
      "DD-MM-YYYY",
      "DD-MM-YY",
      "YYYY-MM-DD",
      "DD/MM/YYYY",
      "DD/MM/YY",
      "DD/MM/YYYY HH:mm:ss",
      "DD-MM-YYYY HH:mm:ss",
      "DD.MM.YYYY",
      "DD.MM.YY",
      "YYYY.MM.DD",
      "DD.MM.YYYY HH:mm:ss",
    ];

    for (let format of formatsToCheck) {
      const parsedDate = moment(excelDate, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format(timeFormat); // Return the correctly formatted date
      }
    }
  }

  // Return null if no valid date found
  return null;
}

export function reverseExcelDateFormat(dateString, timeFormat = timeFormat) {
  // Parse the date string using the provided format
  const momentDate = moment.utc(dateString, timeFormat);

  // Convert the moment.js date to a JavaScript date
  const jsDate = momentDate.toDate();

  // Convert the JavaScript date to an Excel date
  const excelDate = jsDate.getTime() / 86400000 + 25569;

  return excelDate;
}

export function subtractMonths(dateString, monthName) {
  // Parse the input date string into a Moment.js object
  const date = moment(dateString, "DD/MM/YYYY");
  const monthNumber = moment().month(monthName).format("M") - 1;

  // Subtract the specified month using Moment.js
  date.subtract(monthNumber, "months");

  // Format the result as DD/MM/YYYY
  const formattedDate = date.format("DD/MM/YYYY");

  return formattedDate;
}

export function addMonths(currentMonthName, addMonthValue) {
  // Parse the currentMonthName to create a moment object
  const currentMonth = moment(currentMonthName, "MMM YY");

  // Add the specified number of months
  currentMonth.add(addMonthValue, "months");

  // Format the result back to MMM YY format
  const result = currentMonth.format("MMM YY");

  return result;
}

export function getMonthNumber(monthName) {
  try {
    return moment().month(monthName).format("M") - 1;
  } catch (err) {
    console.error(err);
    return 0;
  }
}

export function getNextTwelveMonths(startMonth, startYear, yearFormat = "YY", monthFormat = "MMM") {
  let months = [];
  let date = moment(`${startYear}-${startMonth}`, "YYYY-MMM");

  for (let i = 0; i < 12; i++) {
    months.push({
      month: date.format(monthFormat),
      year: date.format(yearFormat),
    });
    date.add(1, "months");
  }

  return months;
}
