import { AlertOutlined } from "@ant-design/icons";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Typography } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/loading";
import { catcher } from "../../../firebase/util";
import { firstCompany } from "../../../helper/attribute";
import { getAllParamsFromLocation } from "../../../helper/loc";
import { products } from "./Pay.config";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const api_url = process.env.REACT_APP_BE;

const PayForm = () => {
    console.log("REACT_APP_STRIPE_PK", process.env.REACT_APP_STRIPE_PK);
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [planKey, setPlanKey] = useState(getAllParamsFromLocation().planKey);

  const handleCheckout = async () => {
    catcher(
      async () => {
        const companyId = firstCompany()?.id;
        if (!companyId) {
          throw new Error("No company ID available");
        }

        const response = await axios.post(api_url + "/api/pay", {
          company_id: companyId,
          price: products[planKey],
          plan: planKey,
        });

        const session = response.data;

        if (stripe && session?.id) {
          stripe.redirectToCheckout({ sessionId: session.id });
        } else {
          console.log(stripe, session);
          setErr("Error");
          throw new Error("session could not be created!");
        }
      },
      { setLoading }
    );
  };
  useEffect(() => {
    if (stripe) handleCheckout();
  }, [stripe]);
  if (loading || !err)
    return <Loading title="Redirecting to Stripe..."></Loading>;
  if (err)
    return (
      <div>
        <Typography.Title level={5} style={{ color: "red" }}>
          <AlertOutlined style={{ fontSize: 50 }} />
          Something bad happened! Please contact{" "}
          <a href="mailto:info@carbonfootprint.com" target="_blank">
            {" "}
            info@carbonfootprint.com!
          </a>
        </Typography.Title>
      </div>
    );
};

const StripeCheckout = () => {
  return (
    <Elements stripe={stripePromise}>
      <PayForm />
    </Elements>
  );
};

export default StripeCheckout;
