import { Modal, Select, Space } from "antd";
import React, { useState } from "react";
import { updateUser } from "../../../firebase/user/update";
import { except } from "../../../firebase/util";
import { findNotAdminsOfCompany } from "../../../helper/attribute";
import useSize from "../../../hooks/useSize";
import { Link } from "react-router-dom";
import { routePath } from "../../../static/routes.static";
import { InfoCircleFilled } from "@ant-design/icons";
export default function AdminAddCompany({ visible, setVisible, _ }) {
  const { isExtraSmall } = useSize();
  const users = findNotAdminsOfCompany(_);
  const [data, setData] = useState({});
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = () => {
    except(async () => {
      let admins = users.find((e) => e.id === data.adminId).admin_of_companies;
      if (!admins) admins = [];

      await updateUser(data.adminId, { admin_of_companies: [...admins, _.id] });
      setVisible(false);
    });
  };
  return (
    <Modal
      title="Add new Admin"
      open={visible}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Space
        style={{
          paddingTop: 30,
          display: isExtraSmall ? "flex" : "",
          flexDirection: isExtraSmall ? "column" : "",
          alignItems: isExtraSmall ? "flex-start" : "",
        }}
      >
        <span>Select User</span>
        <Select
          value={data.adminId}
          onChange={(adminId) => setData({ ...data, adminId })}
          style={{ width: isExtraSmall ? 350 : 300 }}
        >
          {users.map((user) => (
            <Select.Option value={user.id}>
              {user?.firstName} {user?.lastName}
            </Select.Option>
          ))}
        </Select>
      </Space>
        <div style={{marginTop:"10px"}}><InfoCircleFilled/> To create users go to this <Link to={routePath.Users}>Link.</Link></div>
    </Modal>
  );
}
