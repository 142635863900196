import { Button, Space } from "antd";
import React, { useState } from "react";

export default function ConfirmNTimesButton({
  messages = ["Confirm"],
  onFinalClick,
}) {
  const [currMsgInd, setCurrMsgInd] = useState(0);
  const handleClick = () => {
    if (currMsgInd + 1 === messages.length) {
      return onFinalClick();
    }
    setCurrMsgInd(currMsgInd + 1);
  };
  const handleCancel = () => {
    setCurrMsgInd(0);
  };
  return (
    <Space>
      <Button type="primary" onClick={handleClick}>
        {messages[currMsgInd]}
      </Button>
    </Space>
  );
}
