import { addDoc, collection } from "firebase/firestore";
import { addDate, getCurrentDate } from "../../helper/time";
import { objDontHave } from "../../helper/wodash";
import { firestoreStatic } from "../../static/firestore.static";
import { dataForms } from "../../static/formRoutes";
import { db } from "../setup";
import { except } from "../util";
import { fetchCompanies } from "./get";
import {
  designations,
  getCurrentUserDesignation,
} from "../../helper/attribute";
import { fetchMyAffiliate } from "../affiliate/get";

//validations throws error or returns true
function validateCompanyData(data) {
  //todo: check if the user is admin to access this
  const dontHv = objDontHave(data, ["title", "reporting_year_starting_month"]);
  if (dontHv) throw "Please add " + dontHv;
  //adding other things
  return true;
}

export async function createCompany(data, shouldFetch = true) {
  data.reporting_year_starting_month = "January";
  data.form_permissions = dataForms.map((form) => form.name);
  validateCompanyData(data);
  data["createdAt"] = getCurrentDate();
  //if affiliate
  if (getCurrentUserDesignation() === designations.affiliate) {
    const af = await fetchMyAffiliate();
    data.af_id = af?.id;
  }
  const { id } = await addDoc(collection(db, firestoreStatic.companies), data);
  if (shouldFetch) await fetchCompanies();
  return id;
}
