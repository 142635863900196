/*Contains the links components */

import { useNavigate } from "react-router-dom";
import { findCompany, findSite, userCanEditSite } from "../../helper/attribute";
import { routePath } from "../../static/routes.static";
import { ArrowText } from "../text/text";

export const CompanyPageLink = ({ id, main = false, link = true }) => {
  const navigate = useNavigate();
  if (!link) return findCompany(id).title;

  return (
    <ArrowText
      main={main}
      text={findCompany(id).title}
      onClick={() => {
        navigate(routePath.CompanyDetails(id));
      }}
    />
  );
};

export const SitePageLink = ({
  id,
  main = false,
  disabled = !userCanEditSite(findSite(id)),
}) => {
  const navigate = useNavigate();
  const site = findSite(id);
  let title = site.title;
  if (site.closed) {
    title += " (closed🔒)";
  }
  if (disabled) return title;
  return (
    <ArrowText
      translate="no"
      main={main}
      text={title}
      onClick={() => {
        navigate(routePath.SiteDetails(id));
      }}
    />
  );
};
export const NewTabLink = ({ link, title }) => {
  if (!title) title = link;
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        window.open(link, "_blank");
      }}
    >
      {title}
    </a>
  );
};
