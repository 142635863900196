import { ref, getDownloadURL } from "firebase/storage";
import { downloadFile } from "../../helper/wodash";
import { storage } from "../setup";

export async function downloadFileFromStorage(
  filePath,
  filename,
  returnUrlOnly
) {
  const fileRef = ref(storage, filePath);

  const downloadURL = await getDownloadURL(fileRef);
  if (returnUrlOnly) return downloadURL;
  console.log("downloading, locationurl:", window.location.href);
  if (window.location.href.includes("bulk-upload?name=fuel")) {
    //temporary fix
    window.open(downloadURL, "_blank", "width=600,height=400");
    return;
  }
  downloadFile(downloadURL, filename);
  return downloadURL;
}
