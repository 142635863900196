import { getAuth } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { getCurrentDate } from "../../../helper/time";
import { updateMoreInfo } from "../../MoreInfo/create";
import { db, storage } from "../../setup";

function getFileExtension(filename) {
  if (filename.includes(".")) {
    return filename.split(".").pop();
  }
  return "";
}

// Create an object in the "Userhelp/features" collection
export const createFeatureRequest = async (data) => {
  try {
    const featuresRef = collection(db, "features");
    const dataToAdd = {
      text: data.text,
      requested_by: getAuth().currentUser.email,
      createdAt: getCurrentDate(),
      file: data.file ? true : false,
      ss: data.screenshotUrl ? true : false,
      fileName: data.file?.name,
      url: window.location.href,
      tag: data.tag,
    };
    if (!dataToAdd.fileName) delete dataToAdd.fileName;
    const newFeatureRef = await addDoc(featuresRef, dataToAdd);
    await updateMoreInfo({ date: getCurrentDate() }, "feature_" + newFeatureRef.id);

    if (data.file) {
      // Obtain the file object from the featureData
      const file = data.file;

      // Create a reference to the Firebase Storage location
      const storageRef = ref(storage, `userhelp/features/${newFeatureRef.id}_file.${getFileExtension(data.file.name)}`); // Specify the desired path and file name

      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, file);
    }

    if (data.screenshotUrl) {
      // Step 1: Obtain the base64-encoded image data
      const imageUrl = data.screenshotUrl;

      // Step 2: Convert base64 to Blob
      const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      };

      const imageBlob = dataURItoBlob(imageUrl);

      // Step 3: Create a reference to the Firebase Storage location
      const storageRef = ref(storage, `userhelp/features/${newFeatureRef.id}.png`); // Specify the desired path and file name

      // Step 4: Upload the Blob to Firebase Storage
      await uploadBytes(storageRef, imageBlob);
    }

    return newFeatureRef.id;
  } catch (error) {
    console.error("Error creating feature request: ", error);
  }
};
