export const adminApi = {
  createUser:
    "https://us-central1-carboncalculator-bf757.cloudfunctions.net/adminCreateUser",
  deleteUser:
    "https://us-central1-carboncalculator-bf757.cloudfunctions.net/adminDeleteUser",
  twofa: "https://us-central1-carboncalculator-bf757.cloudfunctions.net/twofa",
  sendPasswordResetMail:
    "https://us-central1-carboncalculator-bf757.cloudfunctions.net/sendPasswordResetMail",
  generatePdfPieChart:
    "https://us-central1-carboncalculator-bf757.cloudfunctions.net/generatePdfPieChart",
  generatePdfBarChart:
    "https://us-central1-carboncalculator-bf757.cloudfunctions.net/generatePdfBarChart",
};
