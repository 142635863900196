import { Checkbox, Popconfirm, Switch, Table } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateUser } from "../../../firebase/user/update";
import { catcher } from "../../../firebase/util";
import { selectUsers } from "../../../redux/features/appSlice";

export default function SuperAdmins() {
  const users = useSelector(selectUsers);
  const [loading, setLoading] = useState(false);
  const toggleSuperAdmin = async (email) => {
    await catcher(
      async () => {
        const { id, super_admin } = users.find((user) => user.email === email);
        await updateUser(id, { super_admin: !super_admin });
      },
      { setLoading }
    );
  };
  const columns = [
    { title: "Email", dataIndex: "email" },
    {
      title: "Super Admin",
      dataIndex: "super_admin",
      render: (super_admin, user) => {
        return (
          <Popconfirm
            okText={"YES I CONFIRM!"}
            okButtonProps={{ loading }}
            title={`Do you confirm to ${!super_admin ? "add" : "remove"} ${
              user?.firstName + " " + user?.lastName
            } as SUPER ADMIN?`}
            onConfirm={async () => await toggleSuperAdmin(user.email)}
          >
            <Checkbox checked={super_admin}></Checkbox>
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <Table pagination={false} columns={columns} dataSource={users}></Table>
  );
}
