import {
  CaretDownFilled,
  CaretDownOutlined,
  CaretUpFilled,
} from "@ant-design/icons";
import { theme, Typography } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import useSize from "../../../../hooks/useSize";

export default function Boxed({
  title,
  children,
  bg,
  initialDownState = true,
  className,
}) {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  const [down, setDown] = useState(initialDownState);
  return (
    <div
      className={"boxed " + className}
      style={{ backgroundColor: theme.useToken().token.colorBgContainer }}
    >
      <Typography.Title
        className="heading"
        style={{ fontSize: isExtraSmall ? "18px" : "24px" }}
      >
        <div
          onClick={() => setDown(!down)}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {title}
          <div style={{ color: "darkgrey", cursor: "pointer" }}>
            {down ? <CaretDownOutlined /> : <CaretUpFilled />}
          </div>
        </div>
      </Typography.Title>
      {down && children}
    </div>
  );
}
