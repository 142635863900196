import { EllipsisOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useState } from "react";

const EllipsisText = ({ text, maxLength, link }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  if (typeof text === "number") text = String(text);
  if (text === null || text === undefined) text = "";

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLinkClick = (url, event) => {
    event.preventDefault();
    window.open(url, '_blank');
  };

  if (text.length <= maxLength || isExpanded) {
    if (link) {
      return (
        <a onClick={(event) => handleLinkClick(text, event)}>
          {text}{" "}
        </a>
      );
    }
    return <div onClick={handleToggle}>{text} </div>;
  }

  const truncatedText = text.slice(0, maxLength);

  return (
    <Tooltip title={text}>
      <div onClick={handleToggle}>
        {!link ? (
          truncatedText
        ) : (
          <a onClick={(event) => handleLinkClick(text, event)}>
            {truncatedText}
          </a>
        )}
        <EllipsisOutlined />
      </div>
    </Tooltip>
  );
};

export default EllipsisText;
