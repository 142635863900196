import { Typography } from "antd";
import React from "react";

export default function Conn() {
  return (
    <div>
      <Typography.Title level={3}>Affiliate Dashboard </Typography.Title>
    </div>
  );
}
