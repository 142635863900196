export async function openIndexedDB(dbName, storeName) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1);
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: "id" });
        }
      };
      request.onsuccess = (event) => resolve(event.target.result);
      request.onerror = (event) => reject(event.target.error);
    });
  }
  
  export async function addDataToIndexedDB(dbName, storeName, data) {
    const db = await openIndexedDB(dbName, storeName);
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
  
    return new Promise((resolve, reject) => {
      const request = store.add(data);
      request.onsuccess = () => resolve(data.id);
      request.onerror = (event) => reject(event.target.error);
    });
  }
  
  export async function fetchAllDataFromIndexedDB(dbName, storeName) {
    const db = await openIndexedDB(dbName, storeName);
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
  
    return new Promise((resolve, reject) => {
      const request = store.getAll();
      request.onsuccess = () => resolve(request.result);
      request.onerror = (event) => reject(event.target.error);
    });
  }
  
  export async function deleteDataFromIndexedDB(dbName, storeName, id) {
    const db = await openIndexedDB(dbName, storeName);
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
  
    return new Promise((resolve, reject) => {
      const request = store.delete(id);
      request.onsuccess = () => resolve(`Data with ID ${id} deleted successfully`);
      request.onerror = (event) => reject(event.target.error);
    });
  }
  