import React, { useState } from "react";

import { Button, Divider, Image, Input, message, Modal, Tooltip, Typography, notification } from "antd";
import ImageEditModal from "./comp/ImageDraw";
import { BugOutlined, CameraOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import { createFeatureRequest } from "../../firebase/userhelp/requestfeature/create";
import FileInput from "../input/datafile";
import { getCurrentPlan, isSustrax, isSustraxAnnual } from "../../helper/plans";

const SCREENSHOTTIME = 0.3;

const RequestFeature = ({ modalVisible, setModalVisible }) => {
  const [data, setData] = useState({ tag: "Feature Request" });
  const [errs, setErrs] = useState({ text: null });
  const [drawModal, setDrawModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
    setData({ tag: "Feature Request" }); // Reset the data state
    setErrs({});
  };

  const takeFullPageScreenshot = () => {
    setModalVisible(false); // Hide the modal
    message.loading({
      type: "loading",
      content: "Taking Screenshot",
      duration: SCREENSHOTTIME,
    });

    setTimeout(() => {
      const pageElement = document.documentElement;
      const options = {
        scrollX: 0,
        scrollY: -window.scrollY,
        scale: 1,
      };

      html2canvas(pageElement, options).then((canvas) => {
        const url = canvas.toDataURL();
        setModalVisible(true); // Show the modal after a slight delay
        setData({ ...data, screenshotUrl: url }); // Update the data state with the screenshotUrl
      });
    }, SCREENSHOTTIME * 1000);
  };

  const handleSendUpdate = async () => {
    if (!data.text || data.text === "") {
      return setErrs({ ...errs, text: "Add some text" });
    }
    setLoader(true);
    try {
      await createFeatureRequest(data);
      closeModal();
      notification.success({
        message: "Feature Request Submitted",
        description: "Request submitted successfully! We will review your feature request and get back to you soon.",
        placement: "top",
      });
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoader(false);
    }
  };

  const handleFileChange = (file) => {
    if (true) {
      setData({ ...data, file: file });
      message.success(`${file.name} file uploaded successfully`);
    }
  };

  const removeFile = () => {
    setData({ ...data, file: "" });
  };

  return (
    <div>
      <Modal title={<div>Request a Feature</div>} open={modalVisible} onCancel={closeModal} okText={"Send"} onOk={handleSendUpdate} confirmLoading={loader}>
        <Input.TextArea
          value={data.text}
          onChange={(e) => {
            setData({ ...data, text: e.target.value });
            if (errs.text) setErrs({ ...errs, text: null });
          }}
          placeholder="Describe the feature you would like to request."
          status={errs.text ? "error" : ""}
          style={{ height: "200px" }}
        />
        <div style={{ color: "red" }}>{errs.text}</div>

        {/*SUPPORTING SCREENSHOT */}

        <Divider>Supporting screenshot</Divider>
        <div
          style={{
            display: "flex",
            gap: "10px",
            padding: "4px 0px",
          }}
        >
          {!data.screenshotUrl ? (
            <Button onClick={takeFullPageScreenshot}>
              <CameraOutlined /> <b>Add screenshot</b>
            </Button>
          ) : (
            <>
              <Image width={"50%"} src={data.screenshotUrl} alt="Full Page Screenshot" />
              <Button onClick={setDrawModal}>
                <EditOutlined /> Markup Screenshot
              </Button>
              <Button
                type="default"
                danger
                onClick={() => {
                  setData({ ...data, screenshotUrl: "" }); // Remove the screenshotUrl from the data state
                }}
              >
                <DeleteOutlined />
                Remove
              </Button>
            </>
          )}
        </div>
        {data.screenshotUrl && (
          <>
            <ImageEditModal visible={drawModal} setVisible={setDrawModal} imageUrl={data.screenshotUrl} setDataUrl={(screenshotUrl) => setData({ ...data, screenshotUrl })} />
          </>
        )}

        {/*SUPPORTING FILE */}
        {!isSustrax("free") && (
          <>
            <Divider>Supporting file</Divider>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                padding: "4px 0px",
              }}
            >
              {!data.file ? (
                <FileInput setFile={handleFileChange} />
              ) : (
                <>
                  <div>
                    <Button type="link"><b>File: </b>{data.file.name}</Button>
                  </div>
                  <Button type="default" danger onClick={removeFile}>
                    <DeleteOutlined /> Remove
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default RequestFeature;
