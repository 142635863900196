import { ArrowRightOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Input,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import FileInput from "../../../components/input/datafile";
import FakeLabel from "../../../components/input/select";
import { beforeUserCreate, createUser } from "../../../firebase/user/create";
import { catcher } from "../../../firebase/util";
import {
  designations,
  firstCompany,
  getCurrentUserDesignation,
} from "../../../helper/attribute";
import Wexel from "../../../helper/excel";
import { arrayToObjects } from "../../../helper/obj";
import { selectCompanies } from "../../../redux/features/appSlice";
import ErrorBoundary from "../../../ErrorBoundary";
import useSize from "../../../hooks/useSize";
const bulkUploadSheetName = "User Data";

function AddUserDrawer({ visible, setVisible, openEditUser }) {
  let companies = useSelector(selectCompanies);
  const [bulkUsers, setBulkUsers] = useState(null);
  const [bulkUsersCompanyId, setBulkUsersCompanyId] = useState(null);
  const [status, setStatus] = useState({});
  const [tab, setTab] = useState("form");
  const [data, setData] = useState({
    companyId:
      getCurrentUserDesignation() === designations.super_admin
        ? null
        : companies[0] && companies[0].id,
  });
  const [loading, setLoading] = useState(false);
  console.log(bulkUsers);
  const onClose = () => {
    setVisible(false);
  };
  const onOk = async () => {
    await catcher(
      async () => {
        if (tab === "form") {
          const newUserDoc = await createUser(data);
          setVisible(false);
          if (openEditUser) openEditUser(newUserDoc.id);
        } else {
          if (!bulkUsers)
            return message.error("Please add a filled template file first.");
          for (let i = 0; i < bulkUsers.length; i++) {
            if (!bulkUsers[i]?.err) {
              try {
                await createUser(bulkUsers[i]);
              } catch (err) {
                bulkUsers[i].err = err?.message || JSON.stringify(err);
                setBulkUsers(JSON.parse(JSON.stringify(bulkUsers)));
              }
            }
          }
        }
        setVisible(false);
      },
      { setLoading }
    );
  };

  const { TabPane } = Tabs;

  const handleBulkUploadUser = (file) => {
    try {
      if (!bulkUsersCompanyId)
        return message.error("Please select Company first");
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = async (event) => {
        try {
          const rows = event.target.result;
          const workbook = XLSX.read(rows, { type: "array" });
          const sheet = workbook.Sheets[bulkUploadSheetName];
          const data = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            raw: true,
          });

          setBulkUsers(
            arrayToObjects(data).map((user) => {
              user.companyId = bulkUsersCompanyId;
              try {
                return beforeUserCreate(user);
              } catch (err) {
                return { ...user, err: JSON.stringify(err) };
              }
            })
          );
        } catch (err) {}
      };
    } catch (err) {
      console.log(err);
      message.info("Something bad happened!");
    }
  };
  const handleBulkUploadTemplateDownload = () => {
    const excel = new Wexel();
    excel.addSheet(bulkUploadSheetName);
    excel.addHeader({
      headers: ["email", "password", "firstName", "lastName", "phone"],
      sheet_ref: bulkUploadSheetName,
    });
    excel.hideAllSheets([bulkUploadSheetName]);
    excel.download(`${firstCompany()?.title}-Bulk User Upload.xlsx`);
  };
  const handleTabChange = (newTab) => {
    setTab(newTab);
  };
  const companyInput = (
    <FakeLabel
      bgColor="#fafafa"
      title={"Company"}
      round="7px"
      elem={
        <Select
          showSearch
          style={{ width: "100%" }}
          onChange={(companyId) => setData({ ...data, companyId })}
          value={data.companyId}
          dropdownMatchSelectWidth={200}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {companies.map((company) => (
            <Select.Option
              key={_.uniqueId("select_option_")}
              value={company.id}
            >
              {company.title}
            </Select.Option>
          ))}
        </Select>
      }
    />
  );
  const { isExtraSmall } = useSize();
  return (
    <Drawer
      title={<>Add New User</>}
      placement="left"
      closable={true}
      onClose={onClose}
      open={visible}
      width={isExtraSmall ? 400 : tab === "form" ? 700 : 1000}
      footer={
        <Space style={{ justifyContent: "flex-end" }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={onOk} loading={loading}>
            {tab === "form" ? "Add" : "Add Multiple Users"}{" "}
            <ArrowRightOutlined />
          </Button>
        </Space>
      }
    >
      <Tabs defaultActiveKey="form" onChange={handleTabChange}>
        <TabPane tab="Single User" key="form">
          <div style={{ display: "" }}>
            <Space
              style={{
                display: "initial",
                flex: 1,
              }}
            >
              <Typography.Title level={4}>Add New User</Typography.Title>
              <p>
                Please provide the following information to create a new user
                profile.
              </p>
            </Space>
            <Space
              direction="vertical"
              style={{
                borderLeft: "1px solid darkgreen",
                padding: 20,
                flex: 3,
              }}
            >
              {companyInput}
              <Divider style={{ margin: isExtraSmall ? "10px" : "" }} />
              <Input
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                status={status.email === "error" && "error"}
                addonBefore={"Email"}
                placeholder={"someone@" + "something.com"}
              />
              <Input
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
                status={status.password === "error" && "error"}
                addonBefore={"Password"}
                placeholder="*********"
              />
              <Divider style={{ margin: isExtraSmall ? "10px" : "" }} />
              {isExtraSmall ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Input
                    value={data.firstName}
                    onChange={(e) =>
                      setData({ ...data, firstName: e.target.value })
                    }
                    status={status.title === "error" && "error"}
                    addonBefore={"First Name"}
                  />
                  <Input
                    value={data.lastName}
                    onChange={(e) =>
                      setData({ ...data, lastName: e.target.value })
                    }
                    status={status.title === "error" && "error"}
                    addonBefore={"Last Name"}
                  />
                </div>
              ) : (
                <Space>
                  <Input
                    value={data.firstName}
                    onChange={(e) =>
                      setData({ ...data, firstName: e.target.value })
                    }
                    status={status.title === "error" && "error"}
                    addonBefore={"First Name"}
                  />
                  <Input
                    value={data.lastName}
                    onChange={(e) =>
                      setData({ ...data, lastName: e.target.value })
                    }
                    status={status.title === "error" && "error"}
                    addonBefore={"Last Name"}
                  />
                </Space>
              )}

              <Input
                value={data.phone}
                onChange={(e) => setData({ ...data, phone: e.target.value })}
                status={status.phone === "error" && "error"}
                addonBefore={"Phone"}
                placeholder="+123456"
              />
            </Space>
          </div>
        </TabPane>
        <TabPane tab="Multiple Users" key="bulk-upload">
          <Typography.Title level={4}>
            Add multiple users at once <Tag>Beta</Tag>
          </Typography.Title>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              Please
              <Button
                style={{ padding: 3 }}
                type="link"
                onClick={handleBulkUploadTemplateDownload}
              >
                download template here
              </Button>
              and upload it below for adding multiple users at once.
              <FakeLabel
                bgColor="#fafafa"
                title={"Company"}
                round="7px"
                elem={
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{
                      width: "100%",
                    }}
                    onChange={setBulkUsersCompanyId}
                    value={bulkUsersCompanyId}
                    dropdownMatchSelectWidth={200}
                  >
                    {companies.map((e) => (
                      <Select.Option key={_.uniqueId()} value={e.id}>
                        {e.title}
                      </Select.Option>
                    ))}
                  </Select>
                }
              />
            </p>
          </div>

          <FileInput
            setFile={handleBulkUploadUser}
            minHeight={bulkUsers?.length ? 50 : 300}
            maxWidth={"100%"}
            accept={".csv, .xlsx"}
          />
          {bulkUsers?.length ? (
            <Table
              columns={[
                {
                  title: "Email",
                  dataIndex: "email",
                  key: "email",
                },
                {
                  title: "Password",
                  dataIndex: "password",
                  key: "password",
                },
                {
                  title: "First Name",
                  dataIndex: "firstName",
                  key: "firstName",
                },
                {
                  title: "Last Name",
                  dataIndex: "lastName",
                  key: "lastName",
                },
                {
                  title: "Phone",
                  dataIndex: "phone",
                  key: "phone",
                },
                {
                  title: "Status",
                  dataIndex: "err",
                  render: (err) =>
                    err ? (
                      <p style={{ color: "red" }}>Error: {err}</p>
                    ) : (
                      <CheckCircleTwoTone twoToneColor={"green"} />
                    ),
                },
              ]}
              dataSource={bulkUsers}
            />
          ) : (
            ""
          )}
        </TabPane>
      </Tabs>
    </Drawer>
  );
}

export default function AddUserDrawerBox(props) {
  return (
    <ErrorBoundary>
      <AddUserDrawer {...props} />
    </ErrorBoundary>
  );
}
