//contains the static routes

export const routePath = {
  login: "/login",
  register: "/login?type=register",
  Profile: "/profile",
  Dashboard: "/dashboard",
  Companies: "/companies",
  Sites: "/sites",
  Users: "/users",
  CompanyDetails: (id, tabName = "profile") => `/company/${id}?tab=${tabName}`,
  SiteDetails: (id) => `/site/${id}`,
  UserDetails: (id) => `/user/${id}`,
  BulkUpload: (name) => `/bulk-upload?name=${name}`,
  Form: (name) => `/form?name=${name}`,
  Result: (name) => (name ? `/results?name=${name}` : `/results`),
  Analysis: (name) => `/analysis?name=${name}`,
  SuperAdminSection: "/super_admin_section",
  combined_data_table: "/combined_data_table",
  FAQ: "/faq",
  Upgrade: "/upgrade",
  Pay: (planKey) => "/pay" + (planKey ? "?planKey=" + planKey : ""),
  Cancel: (id) => "/cancel" + (id ? "?id=" + id : ""),
  Success: (id) => "/success" + (id ? "?id=" + id : ""),
  Offset: "/offset",
  testing: "/testing",
  learn: (id) => "/learn" + (id ? "?id=" + id : ""),
  editAffiliate: (id) => "/edit_af" + (id ? "?id=" + id : ""),
  adminAffiliate: (id) => "/admin_af" + (id ? "?id=" + id : ""),
  adminConn: (id) => "/admin_conn" + (id ? "?id=" + id : ""),
  chat: "/chat",
  downloadRawUserInformation: "/download_raw_user_info",
};
