//contains information related to the current user

import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    doc:undefined
  },
  reducers: {
    setUserDoc: (state,action) => {
      state.doc = action.payload
    },
  },
})


export const {setUserDoc}=userSlice.actions
export const selectUserDoc = (state) => state.user.doc



export default userSlice.reducer
