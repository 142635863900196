import { notification } from "antd";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { selectCompanies, setUsers } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsWithIds, formatUsers } from "../util";
import { isSuperAdmin } from "../../helper/attribute";

// getDoc(doc(db, "airportEmissionFactor", "2021"))
//   .then((docSnap) =>
//   .catch((err) => {
//
//   });
const batchSize = 30; // Specify the batch size here

export const getUserDoc = async () => {
  const uid = getAuth().currentUser.uid;
  const docRef = doc(db, firestoreStatic.users, uid);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    notification.error({ message: "User does not exist", duration: -1 });
    throw "User does not exist!";
  } else {
    let user = docSnap.data();
    user = { ...user, id: docSnap.id };
    return user;
  }
};

export const fetchUsers = async (stealthMode = false) => {
  // if stealthMode is on it will not put undefined to users to not show loading
  if (!stealthMode) store.dispatch(setUsers(undefined));
  const companies = selectCompanies(store.getState());
  const collectionRef = collection(db, firestoreStatic.users);
  let users = [];
  if (false) {
    // Fetch all users without batching
    const q = query(collectionRef);
    const docs = await getDocs(q);
    const allUsers = formatUsers(docsWithIds(docs));
    console.log("all users", allUsers);
    users.push(...allUsers);
  } else {
    // Iterate through companies in batches
    for (let i = 0; i < companies.length; i += batchSize) {
      const batchCompanies = companies.slice(i, i + batchSize);

      // Construct a Firestore query to fetch users for the batch of companies
      const q = query(
        collectionRef,
        where(
          "companyId",
          "in",
          batchCompanies.map((c) => c.id)
        )
      );

      const docs = await getDocs(q);
      const batchUsers = formatUsers(docsWithIds(docs));
      users.push(...batchUsers);
    }
  }

  users = users.sort((a, b) => a.firstName.localeCompare(b.firstName));

  store.dispatch(setUsers(users));
  // // Iterate through companies in batches
  // for (let i = 0; i < companies.length; i += batchSize) {
  //   const batchCompanies = companies.slice(i, i + batchSize);

  //   // Construct a Firestore query to fetch users for the batch of companies
  //   const q = query(
  //     collectionRef,
  //     where(
  //       "companyId",
  //       "in",
  //       batchCompanies.map((c) => c.id)
  //     )
  //   );

  //   const docs = await getDocs(q);
  //   const batchUsers = formatUsers(docsWithIds(docs));
  //   users.push(...batchUsers);
  // }
  // users = users.sort((a, b) => a.firstName.localeCompare(b.firstName));

  // store.dispatch(setUsers(users));
};

export const fetchUserByEmail = async (email) => {
  const usersRef = collection(db, firestoreStatic.users);
  const q = query(usersRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0];
    return { ...doc.data(), id: doc.id };
  } else {
    return null;
  }
};
