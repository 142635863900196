import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../setup";
import { fetchReportBugs } from "./get";

export async function updateBug(id, updatedData) {
  try {
    const bugDocRef = doc(db, "bugs", id);
    await updateDoc(bugDocRef, updatedData);
    //TODO: fetch bugs all
    fetchReportBugs();
  } catch (error) {
    console.error("Error updating bug:", error);
    throw error;
  }
}
