//⚠️ Don't change these.
const vars = {
  result: {
    "Overwritten GHG": "owg",
    "Out Of Scope Emissions": "oose",
    "Overwritten Supplier Factors": "osf",
  },
};

export default vars;
