import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import { Typography, Dropdown, Menu, Button } from "antd";
import React, { useState } from "react";

const FileDisplayer = ({ files, onClickDownload = undefined, compactView = false }) => {
  if (!files) return null;
  if (!Array.isArray(files)) files = [files];

  if (!files.length) {
    return (
      <div>
        <Typography.Text strong>Files: </Typography.Text>No files added
      </div>
    );
  }

  const handleDownload = (file) => {
    if (typeof onClickDownload === "function") return onClickDownload(file);
    const link = document.createElement("a");
    link.href = typeof file === "string" ? file : URL.createObjectURL(file);
    link.download = typeof file === "string" ? file.split("/").pop() : file.name;
    link.click();
  };

  const renderFileList = () => (
    <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
      {files.map((file, index) => (
        <li
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            cursor: "pointer",
            background: "#f9f9f9",
            border: "1px solid #e8e8e8",
            transition: "background-color 0.2s",
          }}
          onClick={() => handleDownload(file)}
          title={typeof file === "string" ? file : file.name}
        >
          <span
            style={{
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#1890ff",
            }}
          >
            {typeof file === "string" ? file.split("/").pop() : file.name}
          </span>
          <DownloadOutlined style={{ color: "#1890ff", fontSize: "16px" }} />
        </li>
      ))}
    </ul>
  );

  if (compactView) {
    const menu = (
      <Menu>
        {files.map((file, index) => (
          <Menu.Item key={index} onClick={() => handleDownload(file)}>
            {typeof file === "string" ? file.split("/").pop() : file.name}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <div style={{ textAlign: "center" }}>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button icon={<FileOutlined />} type="primary">
            Files
          </Button>
        </Dropdown>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: "300px" }}>
      <Typography.Title level={4} style={{ margin: "10px 0", lineHeight: 1 }}>
        📄 Files
      </Typography.Title>
      {renderFileList()}
    </div>
  );
};

export default FileDisplayer;
