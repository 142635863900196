export default function memoize(fn) {
    const cache = new Map();
  
    return function (...args) {
      const key = JSON.stringify(args);
  
      if (cache.has(key)) {
        console.log("Returning from cache:", key);
        return cache.get(key);
      }
  
      const result = fn(...args);
      cache.set(key, result);
      return result;
    };
  }