import { Modal, Select, Space } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { T1 } from "../../../appconfig/texts";
import { updateUser } from "../../../firebase/user/update";
import { except } from "../../../firebase/util";
import { findNotAdminsOfSite } from "../../../helper/attribute";
import { selectUserDoc } from "../../../redux/features/userSlice";
export default function AdminAddSite({ visible, setVisible, _ }) {
  const users = findNotAdminsOfSite(_);
  const userdoc = useSelector(selectUserDoc);
  const [data, setData] = useState({});
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = () => {
    except(async () => {
      let admins = users.find((e) => e.id === data.adminId).admin_of_sites;
      if (userdoc.admin_of_companies?.includes(_.companyId))
        throw "You are already an admin of the parent company of this site.";
      if (!admins) admins = [];

      await updateUser(data.adminId, { admin_of_sites: [...admins, _.id] });
      setVisible(false);
    });
  };
  return (
    <Modal
      title={<T1 pos="site.siteAdmins.add" />}
      open={visible}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Space style={{ paddingTop: 30 }}>
        Select User
        <Select
          value={data.adminId}
          onChange={(adminId) => setData({ ...data, adminId })}
          style={{ width: 300 }}
        >
          {users.map((user) => (
            <Select.Option value={user.id}>
              {user.firstName} {user.lastName}
            </Select.Option>
          ))}
        </Select>
      </Space>
    </Modal>
  );
}
