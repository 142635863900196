// Warning: Don't change Codes.

import store from "../redux/store";

const getGHGs = () => {
  return store.getState().config.ghg_categories;
};

const GHG_CATEGORIES_VALUES = () => getGHGs().map((e) => e.title);
const GHG_CATEGORIES_CODES = () => getGHGs().map((e) => e.code);

function getGHGTitle(code) {
  const foundCategory = getGHGs().find(
    (category) => category.code === parseInt(code)
  );
  return foundCategory ? foundCategory.title : undefined;
}
function findGHG(title, strict = false) {
  const foundCategory = getGHGs().find((category) =>
    strict ? category.title === title : category.title.includes(title)
  );
  return foundCategory;
}

function getGHGCode(code) {
  if (!GHG_CATEGORIES_CODES().includes(code)) return 0;
  return code;
}

export { getGHGTitle, getGHGCode, getGHGs, GHG_CATEGORIES_VALUES, findGHG };
