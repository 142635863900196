import React, { useState } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import axios from "axios";
import SelectSearch from "../../components/select/SearchSelect";
import store from "../../redux/store";
import { auth } from "../../firebase/setup";
import { SendOutlined } from "@ant-design/icons";
import { catcher } from "../../firebase/util";
import { message as msg } from "antd";
const { Option } = Select;

const ContactUsModal = ({ visible, onCancel, data }) => {
  const [form] = Form.useForm();
  const [type, setType] = useState(data?.type);
  const [loading, setLoading] = useState(false);
  console.log(type);

  const handleTypeChange = (value) => {
    setType(value);
  };

  const handleSubmit = async () => {
    catcher(
      async () => {
        const values = await form.validateFields();
        const { message, type, email, name } = values;
        await axios.post(process.env.REACT_APP_BE + "/api/contact", {
          message,
          type,
          email,
          name,
        });
        onCancel(); // Close modal on successful submission
      },
      { setLoading, success_msg:"Email Sent! You will be contacted shortly." }
    );
  };

  return (
    <Modal
      visible={visible}
      title="Contact Us"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleSubmit}
        >
          Send <SendOutlined />
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          email: auth.currentUser.email,
          name:
            store.getState().user.doc.firstName +
            " " +
            store.getState().user.doc.lastName,
          type: data?.type,
          message: data?.message ?? "",
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter your name" }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please enter your email" }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "Please select a type" }]}
        >
          <SelectSearch
            value={"Upgrade Plan"}
            nobg
            nolabel
            fullWidth
            setValue={setType}
            options={[
              "General Inquiry",
              "Bug report",
              "Feature request",
              "Upgrade Plan",
            ]}
          ></SelectSearch>
        </Form.Item>
        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true, message: "Please enter your message" }]}
        >
          <Input.TextArea autoFocus rows={4} placeholder="Enter your message" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContactUsModal;
