import { Input } from "antd";
import React from "react";
import FakeLabel from "./select";

export default function CommentInput({ value, setValue }) {
  return (
    <Input.TextArea
      placeholder="Comments"
      value={value}
      style={{ flex: 1, borderRadius: "3px" }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
