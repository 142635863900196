export function removeLastLine(input) {
    try{

        // Split the input by lines
        const lines = input.split('\n');
        // Remove the last line
        lines.pop();
        // Join the remaining lines and return the result
        return lines.join('\n').trim();
    }catch(err){
        return ""
    }
}


export function arrayToEnglishString(arr) {
    if (arr.length === 0) return '';
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return arr.join(' and ');
    
    // For arrays with more than 2 elements
    return arr.slice(0, -1).join(', ') + ', and ' + arr[arr.length - 1];
  }