import { logout } from "../components/Siderbar";
import {
  admin_designations,
  designations,
  getCurrentUserDesignation,
} from "../helper/attribute";
import { compareDates, getCurrentDate } from "../helper/time";
import store from "../redux/store";

export async function checksAfterLogin() {
  const companies = store.getState().app.companies;
  if (admin_designations.includes(getCurrentUserDesignation())) {
    //admin checks
  } else {
    //non admin checks
    console.log("doing non admin checks");
    if (companies[0]) {
      if (companies[0]?.plan === "demo") {
        if (compareDates(companies[0]?.planExpiry, getCurrentDate()) <= 0) {
          logout();
          alert(
            "Demo expired please contact your account manager for an extension if needed."
          );
        }
      }
    }
  }
}
