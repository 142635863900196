import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import { fetchGroups } from "../../firebase/group/get";
import { catcher } from "../../firebase/util";
import AddGroup from "./Components/AddGroup";
import GroupTable from "./Components/GroupTable";
import { deleteGroup } from "../../firebase/group/delete";
import store from "../../redux/store";
import { setGroups } from "../../redux/features/appSlice";

export default function GroupAdmins() {
  const [settings, setSettings] = useState({
    showAdd: false,
    showEdit: false,
  });
  const groups = useSelector((state) => state.app.groups);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    catcher(
      async () => {
        if (!groups) await fetchGroups();
      },
      { setLoading, success_msg: "" }
    );
  }, []);
  const handleEdit = (groupId) => {
    setSettings({
      ...settings,
      showEdit: groups.find((group) => group.id === groupId),
    });
  };
  const handleDelete = (groupId) => {
    catcher(
      async () => {
        const group = groups.find((group) => group.id === groupId);
        if (
          window.confirm("Confirm to delete this group named: " + group?.name)
        ) {
          await deleteGroup(group);
          store.dispatch(
            setGroups(groups.filter((group) => group.id !== groupId))
          );
        } else {
          throw "Operation cancelled";
        }
      },
      { success_msg: "Operation done" }
    );
  };
  if (loading) return <Loading title="Loading groups!" />;
  return (
    <div>
      <h3>
        Present Groups{" "}
        {!settings.showAddAdmin && (
          <Button
            type="primary"
            onClick={() => setSettings({ ...settings, showAdd: true })}
            icon={<PlusOutlined />}
          >
            New Group
          </Button>
        )}
      </h3>
      <GroupTable
        data={groups}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <AddGroup
        open={settings.showAdd}
        setOpen={(showAdd) => setSettings({ ...settings, showAdd })}
      />
      <AddGroup
        key={settings.showEdit}
        open={settings.showEdit}
        setOpen={(showEdit) => setSettings({ ...settings, showEdit })}
        editModeData={settings.showEdit}
      />
    </div>
  );
}
