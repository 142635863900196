import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Result, theme } from "antd";
import { allRoutes, useCheckCurrentRoute } from "../../routes";
import { routePath } from "../../static/routes.static";
import { getCurrentPlan, isSustraxAnnual } from "../../helper/plans";
import { MailOutlined } from "@ant-design/icons";
import Loading from "../loading";

export default function FourOhFour() {
  const navigate = useNavigate();
  const location = useLocation();
  const routePresent = useCheckCurrentRoute(allRoutes)(); // This might need to be moved inside useEffect if it depends on route changes

  useEffect(() => {
    const plan = getCurrentPlan();
    console.log(location.pathname, routePath.Result());

    if (isSustraxAnnual()) {
      if (location.pathname === routePath.Result()) {
        navigate(routePath.combined_data_table);
        return; // Prevent further execution
      }
    }

    // This logic seems to be for determining if a route is not accessible, so it's kept outside of any condition that would cause a redirect.
    // If it's dynamic or should only be calculated under certain conditions, consider moving it inside the useEffect or adjusting its dependencies.
  }, [location.pathname]); // Depend on location.pathname to re-run this effect when the pathname changes

  if (isSustraxAnnual()) {
    if (location.pathname === routePath.Result()) {
      return <Loading title="redirecting..." />; // Prevent further execution
    }
  }
  if (routePresent) {
    return (
      <Result
        style={{
          background: theme.useToken().token.colorBgContainer,
          minHeight: "100vh",
        }}
        status="403"
        title={
          <small>
            You don't have access to this page. <br />
            Please{" "}
            <b onClick={() => navigate(routePath.Upgrade)}>
              <u>upgrade your plan </u>
            </b>{" "}
            or contact admin.
          </small>
        }
        extra={
          <>
            <Button type="default">
              <a href="mailto:info@carbonfootprint.com">
                Contact Admin <MailOutlined />
              </a>
            </Button>
            <Button type="primary" onClick={() => navigate("/dashboard")}>
              Back Home
            </Button>
          </>
        }
      />
    );
  }

  // Default return for 404
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist or you cannot access it."
      extra={
        <Button type="primary" onClick={() => navigate("/dashboard")}>
          Back Home
        </Button>
      }
    />
  );
}
