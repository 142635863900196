import React, { useRef, useEffect, useState } from "react";
import { Modal, Button, Slider, Select } from "antd";
import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

const { Option } = Select;

const ImageEditModal = ({ visible, setVisible, imageUrl, setDataUrl }) => {
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const isDrawing = useRef(false);
  const [penColor, setPenColor] = useState("#000000");
  const [penWidth, setPenWidth] = useState(3);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (visible) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        ctxRef.current = ctx;
      };
    }
  }, [imageUrl, visible]);

  const getMousePos = (canvas, evt) => {
    const rect = canvas.getBoundingClientRect();
    return {
      x: ((evt.clientX - rect.left) / (rect.right - rect.left)) * canvas.width,
      y: ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height,
    };
  };

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = getMousePos(canvasRef.current, e);
    ctxRef.current.beginPath();
    ctxRef.current.moveTo(pos.x, pos.y);
    saveHistory();
  };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) return;
    const pos = getMousePos(canvasRef.current, e);
    ctxRef.current.lineTo(pos.x, pos.y);
    ctxRef.current.strokeStyle = penColor;
    ctxRef.current.lineWidth = penWidth;
    ctxRef.current.stroke();
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
    ctxRef.current.closePath();
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL("image/png");
    setDataUrl(image);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleErase = () => {
    setPenColor("#FFFFFF"); // Assuming the canvas background is white
  };

  const saveHistory = () => {
    const canvas = canvasRef.current;
    const dataUrl = canvas.toDataURL();
    setHistory([...history, dataUrl]);
  };

  const handleUndo = () => {
    if (history.length > 0) {
      const lastImage = history[history.length - 1];
      setHistory(history.slice(0, -1));
      const img = new Image();
      img.src = lastImage;
      img.onload = () => {
        const ctx = ctxRef.current;
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        ctx.drawImage(img, 0, 0);
      };
    }
  };

  return (
    <Modal
      title="Edit Image"
      open={visible}
      onCancel={handleCancel}
      width="90%"
      bodyStyle={{ height: "80vh", padding: 0, overflow: "hidden" }}
      style={{ top: 0 }}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,

        <Button key="save" onClick={handleSave} style={{ backgroundColor: "green", color: "white" }}>
          Save <SaveOutlined />
        </Button>,
      ]}
    >
      <div style={{ padding: 10 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 10 }}>Pen Color:</span>
          <Select defaultValue={penColor} style={{ width: 120, marginRight: 20 }} onChange={(value) => setPenColor(value)}>
            <Option value="#000000">Black</Option>
            <Option value="#FF0000">Red</Option>
            <Option value="#00FF00">Green</Option>
            <Option value="#0000FF">Blue</Option>
            <Option value="#FFFF00">Yellow</Option>
            <Option value="#FFFFFF">Eraser</Option>
          </Select>
          <span style={{ marginRight: 10 }}>Pen Width:</span>
          <Slider min={1} max={20} defaultValue={penWidth} onChange={(value) => setPenWidth(value)} style={{ width: 200 }} />
          <Button key="undo" onClick={handleUndo}>
            <UndoOutlined /> Undo
          </Button>
        </div>
      </div>
      <div style={{ height: "calc(100vh - 100px)", overflow: "auto" }}>
        <canvas ref={canvasRef} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} style={{ display: "block", margin: "0 auto" }} />
      </div>
    </Modal>
  );
};

export default ImageEditModal;
