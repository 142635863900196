import React, { useEffect } from "react";
import { Input, Checkbox, Typography } from "antd";
import useSize from "../../../../hooks/useSize";

export default function AdditionalDataMetrics({ data, setData }) {
  const { isExtraSmall } = useSize();
  if (!data.inputMetrics) data.inputMetrics = [{}, {}, {}];
  const changeMetricData = (index, prop, val) => {
    const updatedMetrics = [...data.inputMetrics];
    updatedMetrics[index][prop] = val;
    setData({ ...data, inputMetrics: updatedMetrics });
  };

  const toggleNumType = (index, checked) => {
    changeMetricData(index, "type", checked ? "num" : "default");
  };

  const toggleEnabled = (index, checked) => {
    changeMetricData(index, "enabled", checked);
  };

  return (
    <div style={{ marginLeft: 10 }}>
      <Typography.Title level={4}>Additional Data Metrics</Typography.Title>
      {data.inputMetrics.map((inp, index) => (
        <div
          key={index}
          style={{
            width: isExtraSmall ? "260px" : "700px",
            display: "flex",
            flexDirection: isExtraSmall ? "column" : "",
            // alignItems: "end",
          }}
        >
          <Input
            bordered={false}
            placeholder="Title"
            value={inp.title}
            onChange={(e) => changeMetricData(index, "title", e.target.value)}
          />
          <div
            style={{
              display: "flex",
              alignItems: isExtraSmall ? "baseline" : "",
              flexDirection: isExtraSmall ? "" : "",
              marginBottom: "10px",
              marginLeft: isExtraSmall ? "15px" : "",
            }}
          >
            <Checkbox
              style={{
                width: isExtraSmall ? "130px" : "160px",
                marginLeft: "10px",
              }}
              checked={inp.type === "num"}
              onChange={(e) => toggleNumType(index, e.target.checked)}
            >
              Numbers only
            </Checkbox>
            <Checkbox
              checked={inp.enabled}
              onChange={(e) => toggleEnabled(index, e.target.checked)}
            >
              Enabled
            </Checkbox>
          </div>
        </div>
      ))}
    </div>
  );
}
