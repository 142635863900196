import React, { useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import TextInput from "../../../components/input/TextInput";
import SelectSearch from "../../../components/select/SearchSelect";
import { findForm } from "../../../static/formRoutes";
import { genPut } from "../helper/create";

export const INPUTS = (data, setData) => {
  const form = findForm(data.name);
  return [
    {
      elem: DateInput,
      key: "date",
    },
    {
      elem: TextInput,
      key: "product_name",
    },
    {
      elem: SelectSearch,
      key: "uom",
      options: ["kgCO2e", "tCO2e"],
    },
    {
      elem: NumberInput,
      key: "em_emb",
    },
    {
      elem: NumberInput,
      key: "em_dist",
    },
    {
      elem: SelectSearch,
      key: "q1",
      options: ["Yes", "No"],
    },
    {
      elem: NumberInput,
      key: "em_manf",
    },
    {
      elem: NumberInput,
      key: "em_dist_cust",
    },
    {
      elem: SelectSearch,
      key: "q2",
      options: ["Yes", "No"],
    },
    {
      elem: NumberInput,
      key: "em_use",
    },
    {
      elem: NumberInput,
      key: "em_waste",
    },
    {
      elem: NumberInput,
      key: "units",
    },
  ];
};

export default function Product({ data, setData }) {
  const formname = data.name;
  useEffect(() => {
    setData({
      ...data,
      q1: data.q1 || "Yes",
      q2: data.q2 || "Yes",
    });
  }, []);

  return (
    <div>
      {INPUTS(data, setData).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </div>
  );
}
