import styled from "styled-components";

export const StyledWithIcon = styled.p`
  display: flex;
  font-weight: 400;
  gap: 10px;
  cursor: pointer;
  .icon {
    display: none;
  }
  &:hover {
    font-weight: 500;
  }
  &:hover .icon {
    display: block;
  }
`;
