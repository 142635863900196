import { Table, Input, Button, Space, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { removeDuplicates } from "../../helper/wodash";
import { timeFormat } from "../../helper/time";
import moment from "moment";
import SelectSearch from "../select/SearchSelect";

export const getColumnSearchProps = (dataIndex, customRender, shouldOptionsRender, completeList) => {
  let options = [];
  if (shouldOptionsRender) {
    options = removeDuplicates(completeList.map((option) => option[dataIndex]));
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    try {
      confirm();
    } catch (error) {
      console.error("Error in handleSearch:", error);
      message.error("An error occurred while searching.");
    }
  };

  const handleReset = (clearFilters) => {
    try {
      clearFilters();
    } catch (error) {
      console.error("Error in handleReset:", error);
      message.error("An error occurred while resetting filters.");
    }
  };

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {options.length > 0 ? (
          <div style={{ marginBottom: 10 }}>
            <SelectSearch
              nolabel
              fullWidth
              options={options}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              setValue={(value) => setSelectedKeys(value ? [value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            />
          </div>
        ) : (
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      try {
        if (customRender) {
          const text = customRender(record);
          return text.toString().toLowerCase().includes(value.toLowerCase());
        } else {
          // Apply default search logic here if customRender is not provided
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
        }
      } catch (error) {
        console.error("Error in onFilter:", error);
        message.error("An error occurred while filtering.");
        return false;
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        try {
          setTimeout(() => document.getElementById("searchInput").select(), 100);
        } catch (error) {
          console.error("Error in onFilterDropdownVisibleChange:", error);
          message.error("An error occurred.");
        }
      }
    },
  };
};
export const getDropdownFilterProps = (arr, field, fieldKey = field, matchInChild = fieldKey) => {
  return {
    filters: removeDuplicates(arr.map((r) => ({ text: r[field], value: r[fieldKey] }))),
    onFilter: (val, record) => {
      return val === record[matchInChild];
    },
  };
};

export const dateSorter = (key = "date", format = timeFormat) => {
  return (a, b) => moment(a[key], format).toDate().getTime() - moment(b[key], format).toDate().getTime();
};
