import { theme, Typography, Select } from "antd";
import React from "react";
import styled from "styled-components";
import { T1 } from "../../appconfig/texts";
import {
  inputAllowed,
  inputsDoneSentence,
} from "../../pages/form/helper/getter";
import { getForms } from "../../static/formRoutes";
import { useMediaQuery } from "react-responsive";
import { getCurrentPlan } from "../../helper/plans";

const { Option } = Select;

const StyledSelect = styled.div`
  .toto {
    padding: 5px 10px;
    cursor: pointer;
    min-width: ${({ isExtraSmall }) => (isExtraSmall ? "100%" : "160px")};
    max-width: 160px;
    min-height: 60px;
    max-height: 60px;
    font-size: 1em;
    border: 1px solid darkgrey;
    display: flex;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .selected {
    border: 3px solid forestgreen;
    box-shadow: 1px 4px 5px 0px rgba(113, 121, 178, 1);
    font-size: 1rem;
  }
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  max-width: 100%;
`;

export default function SelectDataForms({
  value,
  setValue,
  title = <T1 pos="form.q" />,
}) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const isExtraSmall = useMediaQuery({ query: "(max-width: 600px)" });

  // Get the forms
  const forms = getForms() || [];

  // Handler for the Select component
  const handleSelectChange = (formName) => {
    setValue(formName);
  };

  return (
    <>
      <Typography.Title level={5}>{title}</Typography.Title>
      {isExtraSmall ? (
        <Select
          size="large"
          style={{ width: "100%", padding: "8px 0px" }} // Add padding to the main select input
          placeholder="Select a form"
          value={value}
          onChange={handleSelectChange}
          dropdownStyle={{ padding: "10px" }} // Optional: Ensure dropdown padding does not affect individual items
        >
          {forms.map((form) => (
            <Option key={form.name} value={form.name}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "2px", // Add padding to each dropdown item
                }}
              >
                <img
                  src={form.bg}
                  alt={form.title}
                  style={{ width: "20px", height: "20px" }}
                />
                <span>{form.title}</span>
              </div>
            </Option>
          ))}
        </Select>
      ) : (
        <StyledSelect isExtraSmall={isExtraSmall}>
          {forms.map((form) => (
            <div
              key={form.name}
              onClick={() => setValue(form.name)}
              style={{
                backgroundColor: colorBgContainer,
              }}
              className={`toto ${inputAllowed(form) ? "" : "disabled-div"} ${
                value === form.name ? "selected" : ""
              }`}
            >
              <img src={form.bg} style={{ width: 30 }} alt={form.title} />
              <Typography.Text>
                {form.title}
                {["free", "basic", "premium","demo","Supplier"].includes(getCurrentPlan()) && (
                  <div style={{ fontSize: "9px" }}>
                    {inputsDoneSentence(form)}
                  </div>
                )}
              </Typography.Text>
            </div>
          ))}
        </StyledSelect>
      )}
    </>
  );
}
