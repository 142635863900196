import React, { useEffect, useState } from "react";
import { Modal, Button, Select, Typography } from "antd";
import translations from "../../assets/translations.google.json";
import SelectSearch from "../select/SearchSelect";
import { ChangeLanguage, findCodeByLanguage, findLanguageByCode } from "../../helper/translations.google";
import { replaceElement } from "../../helper/dom";
const { Option } = Select;

const TranslateSite = ({ visible, setVisible }) => {
  const [selectedOption, setSelectedOption] = useState(); // Default option selected
  const [langSet, setLangSet] = useState(false);
  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
    // if (!selectedOption) return;

    // ChangeLanguage(selectedOption);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    setLangSet(true);
    setTimeout(() => {
      replaceElement("gtranslate_wrapper", "google-translate-sustrax");
    }, 100);
  }, [visible]);

  return (
    <Modal title="Language Selection" open={visible} onCancel={handleCancel} onOk={handleOk}>
      {/* <SelectSearch
        title={"Translate Website to"}
        defaultValue={selectedOption}
        setValue={handleOptionChange}
        options={translations.map((t) => t.language)}
        bgless
      /> */}
      <Typography.Title level={5}>Select a language</Typography.Title>
      <div className="google-translate-sustrax"></div>
    </Modal>
  );
};

export default TranslateSite;
