import { DatePicker, Input } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { planValue } from "../../helper/plans";
import { timeFormat } from "../../helper/time";
import FakeLabel from "./select";

function disabledDate(current) {
  // Disable dates after the current year and before 2016
  return current && current.year() > 2024;
}

const VisibleTimeFormat = "DD/MM/YYYY";

export default function DateInput({ value, setValue, title = "Select Date", ...more }) {
  if (value === "Invalid date") value = undefined;
  const elem = (
    <DatePicker
      allowClear={more?.clear ?? false}
      format={VisibleTimeFormat}
      value={value ? dayjs(value, timeFormat) : null}
      onChange={(x, y) => {
        setValue(moment(y, VisibleTimeFormat).format(timeFormat));
      }}
    ></DatePicker>
  );
  let canSelectCustomDate = planValue("canSelectCustomDate");
  if (more.is_consultant_page) canSelectCustomDate = true;
  if (!canSelectCustomDate && !more.canSelectCustomDate) {
    return null; // myles request: 1/2/24
    return <FakeLabel {...more} description={" "} title={"Year"} elem={<Input className="no-select" disabled value={moment().format("YYYY")} />} />;
  }
  return <FakeLabel {...more} title={!canSelectCustomDate ? "Year" : title} elem={canSelectCustomDate ? elem : <Input disabled value={moment().format("YYYY")} />} />;
}
