import React from "react";
import { findFormText } from "../../../helper/attribute";
import { addOrReplaceQueryParam } from "../../../helper/loc";
import { cleanAndMatchSubstring } from "../../../helper/wodash";
export let hasChanged = false;
export const changeHasChanged = (val) => {
  hasChanged = val;
};
export function genPut(input, formname, data, setData) {
  if (input.hidden) return null;
  if (input.UIonly) return input.elem;
  return React.createElement(input.elem, {
    ...input,
    value: input.value || data[input.key],
    setValue: (value) => {
      hasChanged = true;
      if (input.setValue) input.setValue(value);
      else setData({ ...data, [input.key]: value, ...(input.set || {}) });
    },
    title: input.title || findFormText(formname, input.key),
    description: input.description || findFormText(formname, input.key, "desc"),
    error_status: cleanAndMatchSubstring(data.err, input.key) ? data.err : null,
  });
}
