import { addDataToIndexedDB } from "./indexedDbUtils";
import { removePropsFromList } from "../../../helper/obj";
import { scope3overwrite } from "../../../helper/results";
import { getCurrentDate } from "../../../helper/time";

export async function createFormDataOffline(data, history_type = "result_create", checkforfiles = true) {
  data["createdAt"] = getCurrentDate();
  if (!("result" in data)) throw "Emission result not present";
  console.log("creating form data", data);

  let files = data.file;
  if (files && checkforfiles) {
    data.file = files.map((file) => (typeof file === "string" ? file : file.name));
  }

  const id = crypto.randomUUID(); // Generate unique ID
  data.id = id;

  // Save files as blobs in IndexedDB
  if (files && checkforfiles) {
    data.files = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      data.files.push({ name: file.name, blob: file });
    }
  }

  await addDataToIndexedDB("FormDataDB", "FormDataStore", data);

  console.log(`Data stored with ID: ${id}`);
  return scope3overwrite([{ ...data, id }])[0]; // For frontend
}

export async function bulkUploadOffline(rows) {
  removePropsFromList(rows);
  rows = rows.filter((row) => !isNaN(row.result));

  for (const row of rows) {
    row.id = crypto.randomUUID(); // Assign unique IDs to rows
    await addDataToIndexedDB("FormDataDB", "FormDataStore", row);
  }

  console.log("Bulk upload complete");
}
