import React, { useState } from "react";
import { ResponsiveContainer, ComposedChart, LineChart, BarChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { Button, Divider, Empty, Modal, Select, Typography } from "antd";
import { graph_colors } from "../../../extras/testing/charts/config";
import { fillArray, shortenArray } from "../../../../helper/wodash";
import { SettingOutlined } from "@ant-design/icons";
import { LineOptionsForm, BarOptionsForm } from "../../../../components/charts/RechartsOptions";

const { Option } = Select;

const getColor = (index) => graph_colors[index % graph_colors.length];

const chartConfig = {
  margin: { top: 20, right: 30, left: 20, bottom: 20 },
  height: 500,
};

const TargetsCharts = ({ years, rows }) => {
  const [chartType, setChartType] = useState("both");
  const [barOptions, setBarOptions] = useState({
    barWidth: 5,
    barOpacity: 1,
  });
  const [lineOptions, setLineOptions] = useState({
    strokeWidth: 2,
    type: "linear",
    activeDotRadius: 3,
  });

  const chartData = years.map((year, index) => {
    const chartRow = { year };
    rows.forEach(([title, ...values]) => {
      values = fillArray(shortenArray(values), "smooth", [null, undefined, 0]);
      chartRow[title] = values[index];
    });
    return chartRow;
  });

  if (!chartData || chartData.length === 0) return <Empty description="Chart data is empty." />;

  console.log(chartData);
  return (
    <div>
      <Typography.Title level={3}>
        Chart
        <Button
          type="link"
          onClick={() => {
            Modal.info({
              title: "Charts Settings",
              content: (
                <div>
                  <Divider>Line Options</Divider>
                  <LineOptionsForm lineOptions={lineOptions} setLineOptions={setLineOptions} />
                  <Divider>Bar Options</Divider>
                  <BarOptionsForm barOptions={barOptions} setBarOptions={setBarOptions} />
                </div>
              ),
            });
          }}
          icon={<SettingOutlined />}
        ></Button>
      </Typography.Title>

      {/* <Select value={chartType} onChange={(value) => setChartType(value)} style={{ width: 200, marginBottom: 20 }}>
        <Option value="both">Comparison</Option>
        <Option value="line">Line Chart</Option>
        <Option value="bar">Bar Chart</Option>
      </Select> */}

      {/* Composed Chart with both Line and Bar */}
      <div id="targets-charts-1">
        {chartType === "both" && (
          <ResponsiveContainer width="100%" height={chartConfig.height}>
            <ComposedChart data={chartData} margin={chartConfig.margin}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              {rows.map(([title], index) => (
                <>
                  {index === rows.length - 1 && (
                    <Bar
                      key={`bar-${title}`}
                      dataKey={title}
                      fill={getColor(index)}
                      opacity={barOptions.barOpacity}
                      barSize={barOptions.barWidth} // Adjust the bar width here
                    />
                  )}
                  {index !== rows.length - 1 && (
                    <Line
                      key={`line-${title}`}
                      type={lineOptions.type}
                      dataKey={title}
                      stroke={getColor(index)}
                      strokeWidth={lineOptions.strokeWidth} // Increase line stroke width here
                      activeDot={{ r: lineOptions.activeDotRadius }}
                      dot={{ r: lineOptions.activeDotRadius }}
                    />
                  )}
                </>
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        )}

        {/* Line Chart */}
        {chartType === "line" && (
          <ResponsiveContainer width="100%" height={chartConfig.height}>
            <LineChart data={chartData} margin={chartConfig.margin}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              {rows.map(([title], index) => (
                <Line key={`line-${title}`} type="monotone" dataKey={title} stroke={getColor(index)} activeDot={{ r: 8 }} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        )}

        {/* Bar Chart */}
        {chartType === "bar" && (
          <ResponsiveContainer width="100%" height={chartConfig.height}>
            <BarChart data={chartData} margin={chartConfig.margin}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              {rows.map(([title], index) => (
                <Bar key={`bar-${title}`} dataKey={title} fill={getColor(index)} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default TargetsCharts;
