import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

function TV({ url }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  const iframeStyle = {
    width: "100%",
    height: isTabletOrMobile ? "auto" : "330px",
    border: "1px solid black",
    padding: "2px",
    background: "grey",
  };

  const youtube_iframe_component = <iframe style={iframeStyle} src={url || "https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1&controls=0"} title="Embedded Video"></iframe>;
  return youtube_iframe_component;
}

export default TV;
