import { getAuth } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { getCurrentDate } from "../../../helper/time";
import { updateMoreInfo } from "../../MoreInfo/create";
import { db, storage } from "../../setup";

// Create an object in the "Userhelp/bugs" collection
export const createBugReport = async (bugData) => {
  console.log({
    text: bugData.text,
    reported_by: getAuth().currentUser.email,
    createdAt: getCurrentDate(),
    ss: bugData.screenshotUrl ? true : false,
    url: window.location.href,
    tag: bugData.tag,
  });
  try {
    const bugsRef = collection(db, "bugs");
    const newBugRef = await addDoc(bugsRef, {
      text: bugData.text,
      reported_by: getAuth().currentUser.email,
      createdAt: getCurrentDate(),
      ss: bugData.screenshotUrl ? true : false,
      url: window.location.href,
      tag: bugData.tag,
    });
    updateMoreInfo({ location: window.location.href, date: getCurrentDate() }, "bug_" + newBugRef.id);

    if (bugData.screenshotUrl) {
      // Step 1: Obtain the base64-encoded image data
      const imageUrl = bugData.screenshotUrl;

      // Step 2: Convert base64 to Blob
      const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      };

      const imageBlob = dataURItoBlob(imageUrl);

      // Step 3: Create a reference to the Firebase Storage location
      const storageRef = ref(storage, `userhelp/bugs/${newBugRef.id}.png`); // Specify the desired path and file name

      // Step 4: Upload the Blob to Firebase Storage
      await uploadBytes(storageRef, imageBlob);
    }

    return newBugRef.id;
  } catch (error) {
    console.error("Error creating bug: ", error);
  }
};
