import React from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { genPut } from "../helper/create";
import ingrediantFactors from "../../../assets/ingredientsData.json";

// Helper function to filter options based on the provided key and filters
export const filterFactorsIngredients = (filters, key) => {
  return ingrediantFactors
    .filter((factor) => Object.keys(filters).every((filterKey) => factor[filterKey] === filters[filterKey]))
    .map((factor) => factor[key])
    .filter((value, index, self) => value && self.indexOf(value) === index); // Ensure unique values
};

// INPUT component logic using ingredientFactors
export const INPUTS = ({ data, setData }) => {
  if (!data) data = {};

  // Filter options using ingredientFactors data
  const categoryOptions = filterFactorsIngredients({}, "category"); // No production_system filter
  const typeOptions = filterFactorsIngredients({ category: data?.category }, "type"); // No production_system filter
  const columntextOptions = filterFactorsIngredients({ type: data?.type, category: data?.category }, "columntext"); // No production_system filter
  // const productionSystemOptions = filterFactorsIngredients({ type: data?.type, category: data?.category, columntext:data?.columntext }, "production_system"); // Moved after columntextOptions
  
  // Fixed UOM options
  const uomOptions = ["g", "kg", "tonne"];

  return [
    {
      elem: DateInput,
      key: "date",
      value: data?.date,
    },
    {
      elem: SelectSearch,
      key: "category",
      value: data?.category,
      setValue: (category) => setData({ ...data, category, type: null, columntext: null, uom: null }),
      options: categoryOptions,
    },
    {
      elem: SelectSearch,
      key: "type",
      value: data?.type,
      hidden: !data?.category,
      setValue: (type) => setData({ ...data, type, columntext: null, uom: null }),
      options: typeOptions,
    },
    {
      elem: SelectSearch,
      key: "columntext",
      value: data?.columntext,
      hidden: !data?.type,
      setValue: (columntext) => setData({ ...data,columntext, uom: null }),
      options: columntextOptions,
    },
    {
      elem: SelectSearch,
      key: "uom",
      value: data?.uom,
      hidden: !data?.columntext,
      setValue: (uom) => setData({ ...data, uom }),
      options: uomOptions,
    },
    {
      elem: NumberInput,
      key: "amount",
      value: data?.amount,
      setValue: (amount) => setData({ ...data, amount }),
    },
  ];
};

export default function Ingredients({ data, setData }) {
  const formname = "ingredients";

  const inputs = INPUTS({ data, setData });

  return <div>{inputs.map((input) => genPut(input, formname, data, setData))}</div>;
}
