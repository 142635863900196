import React from "react";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom"; // Assuming you're using React Router for routing
import { routePath } from "../../static/routes.static";
import { T1 } from "../../appconfig/texts";

const BackLink = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };
  if (["/dashboard"].includes(location.pathname)) return null;
  return (
    <Button
      type="text"
      size="small"
      icon={<ArrowLeftOutlined />}
      onClick={goBack}
      className="small-back-link"
    >
      <T1 pos="app.back" />
    </Button>
  );
};

export default BackLink;
