import React from "react";
import SiteAnalysis from "../extras/testing/site.analysis";

export default function Site() {
  return (
    <div>
      <SiteAnalysis />
    </div>
  );
}
