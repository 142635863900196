import React, { useState, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button } from 'antd';
import { catcher } from '../../firebase/util';
let index = 0;
const SelectTags = ({ items: itemsInitial = [], handleAdd, value, onChange }) => {
  const [items, setItems] = useState(itemsInitial);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false)
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = async (e) => {
    catcher(async () => {
      e.preventDefault();
      await handleAdd(name)
      setItems([...items, name || `New item ${index++}`]);
      setName('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }, { setLoading })
  };
  return (
    <Select
      style={{
        width: 300,
      }}
      value={value}
      allowClear
      onClear={onChange}
      onSelect={onChange}
      placeholder="Select a tag"
      // dropdownRender={(menu) => (
      //   <>
      //     {menu}
      //     <Divider
      //       style={{
      //         margin: '8px 0',
      //       }}
      //     />
      //     <Space
      //       style={{
      //         padding: '0 8px 4px',
      //       }}
      //     >
      //       <Input
      //         placeholder="Please enter item"
      //         ref={inputRef}
      //         value={name}
      //         onChange={onNameChange}
      //         onKeyDown={(e) => e.stopPropagation()}
      //       />
      //       <Button loading={loading} type="text" icon={<PlusOutlined />} onClick={addItem}>
      //         New Tag
      //       </Button>
      //     </Space>
      //   </>
      // )}
      options={items.map((item) => ({
        label: item,
        value: item,
      }))}
    />
  );
};
export default SelectTags;