import { collection, doc, documentId, getDoc, getDocs, query, where } from "firebase/firestore";
import { designations, getCurrentUserDesignation, isSuperAdmin } from "../../helper/attribute";
import { setCompanies } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { filterForms } from "../../static/formRoutes";
import { db } from "../setup";
import { docsWithIds } from "../util";
import { fetchMyAffiliate } from "../affiliate/get";

export async function fetchCompanies(filterCompanies = false) {
  const userdoc = store.getState().user.doc;
  const designation = getCurrentUserDesignation();
  store.dispatch(setCompanies(undefined));
  let docs = [];
  const collectionRef = collection(db, firestoreStatic.companies);
  //if is super admin
  if (designation === designations.affiliate) {
    const af = await fetchMyAffiliate();
    const q = query(collectionRef, where("af_id", "==", af?.id));
    docs = await getDocs(q);
  } else if (designation === designations.super_admin) {
    docs = await getDocs(collectionRef);
  }
  // if a group admin
  else if (designation === designations.group_admin) {
    //supposing admin of only one group
    const group = store.getState().app.groups[0];

    if (group?.companies) {
      const q = query(collectionRef, where(documentId(), "in", group.companies));
      docs = await getDocs(q);
    }
  }
  //if is company admin
  else if (designation === "Company Admin") {
    const q = query(collectionRef, where(documentId(), "in", userdoc.admin_of_companies));
    docs = await getDocs(q);
  }
  //if is site admin
  else if (designation === "Site Admin") {
    const q = query(collectionRef, where(documentId(), "in", [userdoc?.companyId]));
    docs = await getDocs(q);
  }
  //if simple user
  else {
    const q = query(collectionRef, where(documentId(), "in", [userdoc?.companyId]));
    docs = await getDocs(q);
  }
  let companies = docsWithIds(docs);
  if (filterCompanies) {
    companies = companies.filter((company) => filterCompanies.includes(company.id));
  }

  store.dispatch(setCompanies(companies));

  // setting permissions
  let formFilters = [];
  companies.forEach((company) => {
    if (company.form_permissions) {
      formFilters = [...formFilters, ...company.form_permissions];
    }
  });
  formFilters = [...new Set(formFilters)];
  if (isSuperAdmin()) return companies;
  filterForms(formFilters);

  return companies;
}

export async function fetchCompany(id) {
  const collectionRef = collection(db, firestoreStatic.companies);
  const docRef = doc(collectionRef, id);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    return null;
  }
}

export async function fetchCompaniesByTitles(titles) {
  if (!titles || titles.length === 0) return [];

  const collectionRef = collection(db, firestoreStatic.companies);
  const q = query(collectionRef, where("title", "in", titles));

  try {
    const docs = await getDocs(q);
    const companies = docsWithIds(docs);
    store.dispatch(setCompanies(companies));

    return companies;
  } catch (error) {
    console.error("Error fetching companies: ", error);
    return [];
  }
}
