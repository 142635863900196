import {
  collection,
  addDoc,
  updateDoc,
  doc,
  writeBatch,
} from "firebase/firestore";
import { uniqueId } from "lodash";
import { getCurrentDate } from "../../helper/time";
import { generateRandomString, objDontHave } from "../../helper/wodash";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { updateUser } from "../user/update";
import { fetchGroups } from "./get";

//validations throws error or returns true
function validate(data) {
  //todo: check if the user is admin to access this
  const dontHv = objDontHave(data, ["name", "companies", "admins"]);
  if (dontHv) throw "Please add " + dontHv;
  if (data.companies.length < 2)
    throw "Length of companies must be greater than 2";
  if (data.name.length < 2) throw "Length of name must be greater than 2";
  return true;
}

export async function updateGroup(data, olddata) {
  validate(data);
  validate(olddata);
  data["updatedAt"] = getCurrentDate();

  const batch = writeBatch(db);
  const id = data.id;
  const groupRef = doc(collection(db, firestoreStatic.groups), id);
  batch.set(groupRef, data);
  for (const adminId of data.admins) {
    const userRef = doc(db, firestoreStatic.users, adminId);
    batch.update(userRef, { groupAdmin: id });
  }
  for (const adminId of olddata.admins) {
    if (!data.admins.includes(adminId)) {
      // if don't exist in the new one too, remove groupAdmin property
      const userRef = doc(db, firestoreStatic.users, adminId);
      batch.update(userRef, { groupAdmin: null });
    }
  }

  await batch.commit();
  fetchGroups();

  return id;
}
