import { Typography } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import styled from "styled-components";

const StyledA = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default function List({ arr, max = 1, handleClick, ...more }) {
  const [elems, setElems] = useState(() => arr.slice(0, max));
  const toggleMore = () => {
    setElems(elems.length !== arr.length ? arr : arr.slice(0, max));
  };
  if (!arr.length) return "None";
  return (
    <div {...more} className="notranslate">
      {elems.map((e, index) => (
        <span key={_.uniqueId()}>
          <StyledA onClick={() => handleClick(e)}>
            <Typography.Text>{e}</Typography.Text>
            {elems.length != index + 1 && ","}
          </StyledA>
          {"   "}
        </span>
      ))}
      {arr.length > max ? (
        <StyledA onClick={toggleMore} style={{ color: "darkgreen" }}>
          {elems.length !== arr.length ? "more..." : "...less"}
        </StyledA>
      ):""}
    </div>
  );
}
