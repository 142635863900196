import React, { useState } from "react";
import { Modal, Button, Select, Radio } from "antd";
import { getCurrentYear } from "../../helper/time";
import { getResultDates } from "./resultsCalcs";

const { Option } = Select;

export const applyFilters = (resultsX, filters) => {
  const results = resultsX.map((result) => {
    if (!filters.wtt) {
      result = { ...result, wtt: 0 };
    }
    return result;
  });

  if (!filters.year || filters.year === "default") {
    return results;
  }

  return results.filter((result) => getResultDates(result).fiscalYear === filters.year);
};

const UserFilterModal = ({ open, setOpen, setFilters, filters, extra }) => {
  const [intermediateFilters, setIntermediateFilters] = useState({ ...filters });

  const handleOk = () => {
    setFilters(intermediateFilters);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleYearChange = (value) => {
    setIntermediateFilters({ ...intermediateFilters, year: value });
  };

  const handleWttChange = (e) => {
    setIntermediateFilters({ ...intermediateFilters, wtt: e.target.value });
  };

  return (
    <Modal
      title="Filters"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        extra,
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
    >
      <div>
        <h3>Select Year</h3>
        <Select style={{ width: 150, marginBottom: 20 }} defaultValue={intermediateFilters.year} onChange={handleYearChange}>
          <Option value="default">All Years</Option>
          {Array.from({ length: 6 }, (_, i) => (
            <Option key={i} value={2019 + i}>
              {2019 + i}
            </Option>
          ))}
        </Select>

        <h3>WTT Inclusion</h3>
        <Radio.Group onChange={handleWttChange} value={intermediateFilters.wtt} style={{ display: "block" }}>
          <Radio value={true}>Included</Radio>
          <Radio value={false}>Excluded</Radio>
        </Radio.Group>
      </div>
    </Modal>
  );
};

export default UserFilterModal;
