import {
  Button,
  Checkbox,
  ConfigProvider,
  Popconfirm,
  Table,
  theme,
  Typography,
} from "antd";
import { collection, doc, writeBatch } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../../firebase/setup";
import { catcher } from "../../../firebase/util";
import {
  selectCompanies,
  selectTheme,
  setCompanies,
} from "../../../redux/features/appSlice";
import { firestoreStatic } from "../../../static/firestore.static";
import { dataFormsComplete as dataForms } from "../../../static/formRoutes";

export default function Permissions() {
  const theme_obj = useSelector(selectTheme);
  const companies = useSelector(selectCompanies);
  const [data, setData] = useState([]);
  const [updating, setUpdating] = useState(false);
  const dispatch = useDispatch();
  console.log(dataForms)
  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "name",
      fixed: "left",
      width: "130px",
      render: (_) => <b>{_}</b>,
    },
    ...dataForms.map((form) => ({
      title: form.title,
      key: form.name,
      width: 70,
      render: (company) => {
        const formname = form.name;
        const permissions = company.form_permissions || [];
        return (
          <Checkbox
            onChange={(e) => {
              const checked = e.target.checked;

              if (!company.form_permissions) company.form_permissions = [];
              if (checked) {
                company.form_permissions = [
                  ...company.form_permissions,
                  formname,
                ];
              } else {
                company.form_permissions = company.form_permissions.filter(
                  (name) => formname !== name
                );
              }
              setData(JSON.parse(JSON.stringify(data)));
            }}
            checked={permissions.includes(formname)}
          ></Checkbox>
        );
      },
    })),
  ];
  useEffect(() => {
    if (companies) {
      setData(
        JSON.parse(
          JSON.stringify(
            companies.filter((company) => ["pro", "ma","DC","demo"].includes(company?.plan))
          )
        )
      );
    }
  }, [companies]);

  const handleUpdate = async () => {
    await catcher(
      async () => {
        // compares and finds which companies are updated to batch update them in firebase.
        const batch = writeBatch(db);
        const updated_companies = data.forEach((company) => {
          const maybe_updated_company = companies.find(
            (updatedcompany) => updatedcompany.id === company.id
          );
          if (
            maybe_updated_company.form_permissions !== company.form_permissions
          ) {
            batch.update(
              doc(collection(db, firestoreStatic.companies), company.id),
              {
                form_permissions: company.form_permissions,
              }
            );
          }
        });
        await batch.commit();

        dispatch(setCompanies(data));
      },
      { setLoading: setUpdating }
    );
  };
  return (
    <ConfigProvider
      theme={{
        algorithm: theme_obj?.isDarkMode
          ? [theme.compactAlgorithm, theme.darkAlgorithm]
          : theme.compactAlgorithm,
      }}
    >
      <Table
        scroll={{ y: "calc(100vh - 320px)" }}
        title={() => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 100,
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography.Title level={4}>
                  Permissions (only displaying companies on annual plans)
                </Typography.Title>
                <Typography.Text>
                  Give permissions to companies by checking the list below.
                </Typography.Text>
              </div>
              <Popconfirm
                title="Do you really want to update permissions below?"
                onConfirm={handleUpdate}
                okButtonProps={{ loading: updating }}
              >
                <Button type="primary">Update</Button>
              </Popconfirm>
            </div>
          );
        }}
        bordered
        pagination={false}
        // scroll={{ x: 1500 }}
        dataSource={data}
        columns={columns}
        rowKey={(i) => i.title}
      />
    </ConfigProvider>
  );
}
