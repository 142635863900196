import React, { useState, useEffect } from "react";
import { Button, Input, Space, Tabs, Typography } from "antd";
import Loading from "../../../components/loading";
import { catcher } from "../../../firebase/util";
import { getAppInfo, updateAppInfo } from "../../../firebase/appinfo/create";
import { dataForms } from "../../../static/formRoutes";
import { matchObjects } from "../../../helper/wodash";
import { getPlanTitles } from "../../../helper/plans";

export default function UpdateVidForForms() {
  const [links, setLinks] = useState();
  const [linksOnServer, setLinksOnServer] = useState();
  const [plans, setPlans] = useState(getPlanTitles);
  const [selectedPlan, setPlanSelected] = useState();

  useEffect(() => {
    const fetchData = async () => {
      catcher(async () => {
        const links = await getAppInfo("form_vid_links");
        setLinks(links);
        setLinksOnServer({ ...links });
      }, {});
    };

    fetchData();
  }, []);

  const handleSave = () => {
    catcher(async () => {
      await updateAppInfo(links, "form_vid_links");
      setLinksOnServer({ ...links });
    }, {});
  };
  const handleCancel = () => {
    setLinks({ ...linksOnServer });
  };

  if (!links) return <Loading />;

  const renderLinks = (planKey) => {
    return (
      <div>
        {dataForms.map((form) => {
          const key = form.name + "-" + planKey;
          return (
            <Space key={form.id}>
              {" "}
              {/* Added a key for list rendering */}
              <div style={{ minWidth: "150px", fontWeight: 600 }}>
                {form.title}:
              </div>
              <Input
                value={links[key]}
                onChange={(e) =>
                  setLinks((forms) => ({
                    ...forms,
                    [key]: e.target.value,
                  }))
                }
                placeholder={"Video Link for form: " + key}
                style={{ width: "800px" }}
              />
            </Space>
          );
        })}
      </div>
    );
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      {!matchObjects(links, linksOnServer) && (
        <Space>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            SAVE
          </Button>
        </Space>
      )}
      {/*Plan tabs */}
      <Tabs
        tabPosition="left"
        defaultActiveKey={"free"}
        onChange={(key) => setPlanSelected(key)}
      >
        {plans.map((plan, index) => (
          <Tabs.TabPane tab={plan} key={index}>
            <Typography.Title level={3}>Form Links for {plan}</Typography.Title>
            {renderLinks(plan)}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}
